import { GST_RATE_LIST ,GST_RATE } from '../Constant';
import * as api from '../api/index';

export const create_gst_rate  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.create_gst_rate (formData);
      dispatch({ type: GST_RATE, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };
export const update_gst_rate  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.update_gst_rate (formData);
      dispatch({ type: GST_RATE, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };
export const delete_gst_rate  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.delete_gst_rate (formData);
      dispatch({ type: GST_RATE, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };
export const all_gst_rate_list  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_gst_rate_list (formData);
      dispatch({ type: GST_RATE_LIST, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };