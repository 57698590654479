import { CREATECATEGORY ,CATEGORYLIST } from '../Constant';
import * as api from '../api/index';

export const category_create  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.category_create (formData);
      dispatch({ type: CREATECATEGORY, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const all_category_list  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_category_list (formData);
      dispatch({ type: CATEGORYLIST, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };

  export const category_update = (formData) => async (dispatch) => {
    try{
      const { data } = await api.category_update(formData);
      dispatch({ type: CREATECATEGORY, payload:data });
      return data

    }catch (error) {
      console.log(error);
    }
  }
  export const category_delete  = (formData) => async (dispatch) => {
    try{
      const { data } = await api.category_delete(formData);
      dispatch({ type: CREATECATEGORY, payload:data });
      return data

    }catch (error) {
      console.log(error);
    }
  }