import React, { useEffect, useState } from 'react'
import { Breadcrumbs, H6 } from '../../AbstractElements'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as BookingAction from '../../actions/booking'
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap'
import DataTable from 'react-data-table-component'

export default function TodayBookingAdmin() {
    const dispatch = useDispatch()
    const { getTodaysbookingAdmin } = bindActionCreators(BookingAction, dispatch)
    const [bookingData, setBookingData] = useState([])

    useEffect(() => {
        (async () => {
            const resData = await getTodaysbookingAdmin()
            console.log("Res", resData)
            setBookingData(resData)
        })()
    }, [])

    const productColumns = [
        {
            name: "Basic ",
            selector: (row) => row.name,
            sortable: true,
            // center: true,
            wrap: true,
            // minWidth: "350px",
        },
        {
            name: "Service ",
            selector: (row) => row.Service,
            sortable: true,
            // center: true,
            wrap: true,
            // minWidth: "350px",
        },
        {
            name: "Vendor",
            selector: (row) => row.TotalVendors,
            sortable: true,
            // center: true,
            wrap: true,
            // minWidth: "350px",
        },
        {
            name: "Location ",
            selector: (row) => row.Location,
            sortable: true,
            // center: true,
            wrap: true,
            // minWidth: "350px",
        },
        {
            name: "Start Date",
            selector: (row) => row.StartDate,
            sortable: true,
            // center: true,
            wrap: true,
            // minWidth: "350px",
        },
        {
            name: "End Date",
            selector: (row) => row.EndDate,
            sortable: true,
            // center: true,
            wrap: true,
            // minWidth: "350px",
        },

    ];

    const ProductList =
        bookingData &&
        bookingData?.data?.map((value, index) => ({
            name: (
                <div>
                    <H6>{value?.name}</H6>
                    <p>{value?.email}</p>
                    <p>{value?.contact}</p>
                </div>
            ),
            Service: (
                <div>
                    <p>{value?.category?.category_name}</p>
                    <p>({value?.subcategory?.subcategory_name})</p>
                </div>
            ),
            TotalVendors: (
                <div>
                    <p>{value?.vendor?.length} Vendors</p>
                </div>
            ),
            Location: (
                <div>
                    <p>{value?.house_no} - {value?.residence_name}</p>
                    <p>{value?.area_or_street},{value?.city?.city_name}</p>
                </div>
            ),
            StartDate: (
                <div>
                    <p>{value?.from_date}</p>
                </div>
            ),
            EndDate: (
                <div>
                    <p>{value?.to_date}</p>
                </div>
            ),
            Vendors: value?.vendor

        }));


    const vendorColumns = [
        {
            name: "Company Name",
            selector: (row) => row.Company,
            sortable: true,
            wrap: true,
        },
        {
            name: "Owner Name",
            selector: (row) => row.Owner,
            sortable: true,
            wrap: true,
        },
        {
            name: "Total Workers",
            selector: (row) => row.WorkerCount,
            sortable: true,
            wrap: true,
        },
        {
            name: "Contact",
            selector: (row) => row.Contact,
            sortable: true,
            wrap: true,
        },
        {
            name: "Email",
            selector: (row) => row.Email,
            sortable: true,
            wrap: true,
        },
    ];

    const AnotherExpandedComponent = ({ data }) =>

    (
        <div style={{ width: "100%", border: "", padding: "20px", display: "flex", justifyContent: "center" }}>
            <WorkerComponent data={data} />
        </div>
    );

    const WorkerComponent = ({ data }) => (
        <div style={{ width: "100%", border: "1px solid grey" }}>
            <Table striped >

                <thead className='expand-table'>
                    <tr className="table-secondary" style={{ fontWeight: "900" }}>
                        <th>
                            #
                        </th>
                        <th style={{ fontWeight: "900" }}>
                            First Name
                        </th>
                        <th style={{ fontWeight: "900" }}>
                            Last Name
                        </th>
                        <th style={{ fontWeight: "900" }}>
                            Email
                        </th>
                        <th style={{ fontWeight: "900" }}>
                            Mobile
                        </th>
                        <th style={{ fontWeight: "900" }}>
                            Nationality
                        </th>
                        <th style={{ fontWeight: "900" }}>
                            Work Permit Expiry
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data?.worker?.map((data, index) => (
                            <tr>
                                <th scope="row">
                                    {index + 1}
                                </th>
                                <td>
                                    {data?.worker?.firstname}
                                </td>
                                <td>
                                    {data?.worker?.lastname}
                                </td>
                                <td>
                                    {data?.worker?.email}
                                </td>

                                <td>
                                    {data?.worker?.mobile_number}
                                </td>
                                <td>
                                    {data?.worker?.nationality}
                                </td>
                                <td>
                                    {data?.worker?.work_permit_expire_at}
                                </td>
                            </tr>
                        ))
                    }


                </tbody>
            </Table>

            {/* Add more details as needed */}
        </div>
    )


    const VendorComponent = ({ data }) =>
    (

        <div>
            <DataTable
                noHeader
                pagination={false}
                columns={vendorColumns}
                data={data?.Vendors?.map((vendor) => ({
                    Company: vendor?.vendor.company_name,
                    Owner: vendor?.vendor.contact_person_name,
                    WorkerCount: vendor?.worker?.length + " Workers",
                    Contact: vendor?.vendor.contact_person_phone_number,
                    Email: vendor?.vendor.email,
                    worker: vendor?.worker

                }))}
                highlightOnHover
                striped
                responsive
                expandableRows
                expandableRowsComponent={AnotherExpandedComponent}
            />
        </div>
    );



    const ExpandedComponent = ({ data }) =>

    (
        <div style={{ width: "100%", border: "" }}>
            <VendorComponent data={data} />
        </div>
    );

    return (
        <div className="table-responsive product-table">
            <Breadcrumbs
                parent="Booking"
                title="Today's Booking"
                mainTitle={`Today's Booking`}
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <DataTable
                                    noHeader
                                    pagination
                                    paginationServer
                                    columns={productColumns}
                                    data={ProductList}
                                    highlightOnHover={true}
                                    striped={true}
                                    responsive={true}
                                    expandableRows
                                    expandableRowsComponent={ExpandedComponent}
                                    className="custom-data-table"

                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
