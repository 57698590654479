// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../AbstractElements";
import pic1 from "../../Images/download.png";
import pic2 from "../../Images/photo.png";
import * as Action from "../../actions/category";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { IMAGE } from "../../api";
import SweetAlert from 'sweetalert2';
import * as HomeAction from "../../actions/companyServices";


import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CREATECATEGORY } from "../../Constant";

const CompanyServices = () => {
  const dispatch = useDispatch();
  const { company_services_list } = bindActionCreators(HomeAction, dispatch);
  const [companyServicesData, setCompanyServicesData] = useState([]);
  // const { category_delete} = bindActionCreators(
  //   HomeAction,
  //   dispatch
  // );
  const user = useSelector((state)=> state?.layout?.user)
  useEffect(() => {
   user && (async () => {
      const data = new FormData();
      data.append("company", user?.id);
      // dispatch(bank_detail_list(data));
      const resData = await company_services_list(data);
      setCompanyServicesData(resData);
    })();
  }, [user]);
  const loggedIn = useSelector((state)=> state?.layout?.loggedIn)
  console.log(user ,loggedIn);
  const deleteTask = async(userId) => {
    SweetAlert.fire({

        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this Category!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    }).then(async (result) => {
        if (result.value) {
            // RemoveTask(userId);
            const fData = new FormData()
            fData.append("id",userId)

            // const deleteApi = await category_delete(fData)
            // console.log(deleteApi,"fdfzfz")
            // if(deleteApi?.statuscode == 200){
            //   toast.success(deleteApi?.message);
            //   setTimeout(async () => {
            //       const resData = await all_category_list();
            //       setCategoryData(resData);
            //     dispatch({ type: CREATECATEGORY, payload: undefined });
            //   }, 1000);
            // }else{
            //   toast.error(deleteApi?.message);
            // }
            // console.log(result,result.value,deleteApi,userId,"nfsdf")
            SweetAlert.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            );
        } else {
            SweetAlert.fire(
                'Your imaginary file is safe!'
            );
        }
    });
};
  const productColumns = [
    // {
    //   name: "Image",
    //   selector: (row) => row.image,
    //   sortable: true,
    //   center: true,
    //   minWidth: "100px",
    //   maxWidth: "100px",
    // },
    {
      name: "Services",
      selector: (row) => row.Details,
      sortable: true,
      // center: true,
      wrap: true,
      minWidth: "350px",
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.Status,
    //   sortable: false,
    //   center: true,
    //   minWidth: "200px",
    //   maxWidth: "250px",
    // },
    // {
    //   name: 'Stock',
    //   selector: (row) => row.stock,
    //   sortable: true,
    //   center: true,
    //   minWidth: '120px',
    //   maxWidth: '150px',
    // },
    // {
    //   name: 'Start_date',
    //   selector: (row) => row.start_date,
    //   sortable: true,
    //   center: true,
    //   minWidth: '120px',
    //   maxWidth: '150px',
    // },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      center: true,
      minWidth: "160px",
      maxWidth: "160px",
    },
  ];
  const style = {
    width: 55,
    height: 40,
    borderRadius: 5,
  };
  const style2 = { width: 60, fontSize: 14, padding: 4 };
  const ProductList =
  companyServicesData &&
  companyServicesData?.data?.map((value ,index) => ({
      // image: (
      //   <Image
      //     attrImage={{
      //       src: IMAGE + value?.category_image,
      //       style: style,
      //       alt: "",
      //     }}
      //   />
      // ),
      Details: (
        <div>
          <H6>{value?.service_name}</H6>
        </div>
      ),
      // Status:
      //   value?.status == "Active" ? (
      //     <span
      //       style={{ color: "#00B69B", padding: "5px 13px", padding: "4px" }}
      //     >
      //       {value?.status}
      //     </span>
      //   ) : (
      //     <span
      //       style={{
      //         color: "rgb(238, 54, 140)",
      //         padding: "5px 13px",
      //         padding: "4px",
      //       }}
      //     >
      //       {value?.status}
      //     </span>
      //   ),
      action: (
        <div>
          <span>
            <Btn
              attrBtn={{
                style: style2,
                color: "danger",
                className: "btn btn-xs",
                type: "button",
                onClick : () => deleteTask(value?.id)
              }}
             
            >
              Delete
            </Btn>
          </span>{" "}
          &nbsp;&nbsp;
          <span>
            <Btn
              attrBtn={{
                style: style2,
                color: "success",
                className: "btn btn-xs",
                type: "button",
              }}
            >
             <Link style={{color:"inherit"}} to={"/Update-Company-Services"} state={{data :companyServicesData?.data[index] , isEdit: true}}>Edit</Link>
            </Btn>
          </span>
        </div>
      ),
    }));

  // amount: '$10',
  // stock: <div className='font-success'>In Stock</div>,
  // start_date: '2022/4/19',

  // ]
  return (
    <div className="table-responsive product-table">
      <Breadcrumbs
        parent="Company-Services"
        title="Company Services List"
        mainTitle="Company Services List"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  noHeader
                  pagination
                  paginationServer
                  columns={productColumns}
                  data={ProductList}
                  highlightOnHover={true}
                  striped={true}
                  responsive={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CompanyServices;
