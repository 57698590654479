import React, { useMemo } from 'react';
import { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { configureFakeBackend, authHeader, handleResponse } from '../Services/fack.backend';
import Callback from '../Auth/Callback';
import Loader from '../Layout/Loader';
import LayoutRoutes from '../Route/LayoutRoutes';
import Signin from '../Auth/Signin';
import PrivateRoute from './PrivateRoute';
import { classes } from '../Data/Layouts';
import { authRoutes } from './AuthRoutes';
import { useDispatch, useSelector } from "react-redux";

import { LOGGEDIN, USER_DETAILS } from '../Constant';
import AlertMessage from '../Components/Common/Component/AlertMessage';

// setup fake backend
configureFakeBackend();
const Routers = () => {
  const login = useState(JSON.parse(localStorage.getItem('login')))[0];
  const [authenticated, setAuthenticated] = useState(false);
  const jwt_token = localStorage.getItem('token');
  const defaultLayoutObj = classes.find((item) => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();
  const dispatch = useDispatch()
  const storeMessage = useSelector((state) => state?.auth?.message)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState(null)
  const [type, setType] = useState(null)


  useEffect(() => {
    let abortController = new AbortController();
    const requestOptions = { method: 'GET', headers: authHeader() };
    fetch('/users', requestOptions).then(handleResponse);
    setAuthenticated(JSON.parse(localStorage.getItem('authenticated')));
    console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
    console.disableYellowBox = true;
    return () => {
      abortController.abort();
    };
  }, []);
  const data = JSON.parse(
    sessionStorage.getItem("user") == undefined
      ? "{}"
      : sessionStorage.getItem("user")
  );
  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      dispatch({ type: USER_DETAILS, payload: data?.data });
      dispatch({ type: LOGGEDIN, payload: true });
    } else {
      localStorage.setItem("user", "{}");
    }
  }, []);

  if (open) {
    setTimeout(() => {
      setOpen(false)
    }, 2000)
  }

  useMemo(() => {
    if (storeMessage?.statuscode == 200) {
      setType("success")
      setOpen(true)
      setMessage(storeMessage?.message)

    }
    else if (storeMessage && storeMessage?.statuscode !== 200) {
      setType("error")
      setOpen(true)
      setMessage(storeMessage?.message)
    }
  }, [storeMessage])


  return (
    <BrowserRouter basename={'/'}>
      <AlertMessage message={message} open={open} type={type} />
      <>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path={'/'} element={<PrivateRoute />} />

            <Route path={`/*`} element={<LayoutRoutes />} />
            {/* </Route> */}
            <Route path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback />} />
            <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<Signin />} />
            {authRoutes.map(({ path, Component }, i) => (
              <Route path={path} element={Component} key={i} />
            ))}
          </Routes>
        </Suspense>
      </>
    </BrowserRouter>
  );
};

export default Routers;
