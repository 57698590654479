// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import ListTable from "../../../utils/Table";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {  ADMIN_VENDOR_LAST_MIN_DEAL, VENDOR_DEAL_OF_THE_DAY_LIST } from "../../../Constant";
import { admin_vendor_wise_last_min_deal_list ,all_last_min_deal_list,delete_last_min_deal, update_last_min_deal } from "../../../actions/offeres/last_min_deal";

const AllLastMinDeal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const LastMinDeal = useSelector((state)=>state?.offers?.last_min_deal_list)
  const message = useSelector((state)=>state?.offers?.last_min_deal)
  const login_details = JSON.parse(sessionStorage.getItem("user"))
  const role = login_details?.id?.userrole?.rolename

  console.log(LastMinDeal)
  useMemo(()=>{
      dispatch(all_last_min_deal_list())
  },[])
  const tempTableData = {
    tableTitle: "Last Min Deals",
    disableDelete :true,
    // deleteRecord: delete_last_min_deal,
    updateRecord: update_last_min_deal,
    // deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    onDeleteText: "Are you sure want to delete?",
    onUpdateText:
      "Inactive this Last Minute Deal will remove it from the active deals and make it unavailable for customers. Are you sure you want to proceed?",
    tableHead: [
      {
        id:"product",
        label:"Product"
      },
      {
        id: "product_sub_category",
        label: "Category Name",
        subText : "product_category"
      },
      {
        id: "created_by",
        label: "Ownership",
        // subText :"category"
      },
      {
        id: "uploaded_by_name",
        label: "Uploaded By",
        subText: "company_name",
      },
      {
        id: "date",
        label: "Deal Start",
        subText :"time"
      },
      {
        id :"product_final_price",
        label :"Deal Before Price"
      },
      {
        id :"last_min_deal_type",
        label :"Deal Type"
      },
      {
        id :"last_min_deal_final_amount",
        label :"Deal Price"
      },
      {
        id: "status",
        label: "Status",
        // isButtonDisplay: true,
      },
      // {
      //   id: "edit",
      //   label: "Update",
      //   edit_path :"/update_last_min_deal",
      //   // id : "id",
      //   // handleEdit : (value) => {navigate("/Update-Category" , {state:{id:value?.id}})}
      //   // access: rights?.["Masters"]?.["edit"] == "true",
      // },
    ],
    tableBody: [

    ],
    filterColumn: [
      {
        id: "1",
        title: "Owneship",
        name: "created_by",
        options: ["Admin", "Vendor"],
      },
    ],
  };
  const [tableData, setTableData] = useState({...tempTableData})

  useMemo(()=>{
    if(LastMinDeal?.statuscode == 200){
      const temp = tableData
      temp.tableBody = LastMinDeal?.data?.map((ele)=>({
        ...ele,
        product : ele?.product?.product_name,
        product_category: ele?.product_category?.category_name,
        product_sub_category : ele?.product_sub_category?.sub_category_name,
        time : ele?.start_time + " - " + ele?.end_time,
        uploaded_by_name:
        ele?.created_by == "Vendor"
          ? ele?.vendor?.vendor_name
          : ele?.admin?.name,
      company_name:
        ele?.created_by == "Vendor" ? ele?.vendor?.company_name : " - ",
      }))
      setTableData({...temp})
    }
  },[LastMinDeal])
  useMemo(()=>{
    if(message?.statuscode == 200){
      toast.success(message?.message);

        dispatch(all_last_min_deal_list())
     
      dispatch({ type: ADMIN_VENDOR_LAST_MIN_DEAL, payload: undefined })
    }
  },[message])
  return (
    <div >
      <Breadcrumbs
        parent="Last Min Deal"
        title="Last Min Deals"
        mainTitle="Last Min Deals"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
              <ListTable
              tableData={tableData}
              key={"ListTable"}
              // setForm={setForm}
              setTableData={setTableData}
              // setIsEdit={setIsEdit}
              />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AllLastMinDeal;
