import React, { Fragment, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Box from "@mui/material/Box";
import {
  Breadcrumbs,
  Btn,
  H3,
  H4,
  H5,
  H6,
  Image,
  LI,
  P,
  UL,
} from "../../AbstractElements";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "@mui/material";
import { API_LINK, IMAGE } from "../../api";
import { Button } from "@mui/material";
import "./Project.css";
import "react-tabs/style/react-tabs.css";
import SocialWidget from "../Common/CommonWidgets/SocialWidget";
import { Typography } from "@mui/material";
import Certificate from "../../assets/images/certificate.png";
// import Tex from "../../assets/images/tax.png"
import Id from "../../assets/images/police-id.png";
import Profile from "../../assets/images/profilePic.png";
import {
  getCompletedBookingVendor,
  vendor_details,
  vendor_status_update,
} from "../../actions/vendor";
import DataTable from "react-data-table-component";
import { worker_details } from "../../actions/worker";
export default function WorkerDetails() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(location?.state);
  const message = useSelector((state) => state?.vendor?.vendor_update);
  const vendorDetails = useSelector((state) => state?.vendor?.vendor_details);
  const VendorWorkHistory = useSelector(
    (state) => state?.vendor?.historyVendor
  );
  console.log(VendorWorkHistory, "forTesting");
  const [rejectMsg, setRejectMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [appDisBtn, setAppDisBtn] = useState(false);
  const [rejDisBtn, setRejDisBtn] = useState(false);
  const [isSee, setIsSee] = useState(false);
  useMemo(() => {
    if (location?.state?.id) {
      dispatch(worker_details({ id: location.state?.id }));
      // dispatch(getCompletedBookingVendor({ id: location.state?.id }));
    }
  }, [location.state?.id]);
  const vendorData = vendorDetails?.data;
  const workerData = useSelector((state) => state?.worker?.worker_details_data);
  console.log(workerData, "nfdfsd");
  const companyCertificate = () => {
    window.open(API_LINK + workerData?.data?.passport_photo, "_blank");
  };
  const personIdProof = () => {
    window.open(API_LINK + workerData?.data?.goverment_id_photo, "_blank");
  };
  const taxRegistrationCertificate = () => {
    window.open(API_LINK + workerData?.data?.profile_photo, "_blank");
  };
  const workPermit = () => {
    window.open(API_LINK + workerData?.data?.work_permit, "_blank");
  };

  // useMemo(() => {
  //   if (message?.statuscode == 200) {
  //     dispatch(vendor_details({ id: vendorData?.id }));
  //   }
  // }, [message]);

  const handleOpenPassport = (url) => {
    window.open(IMAGE + url, "_blank");
  };
  // Vendor history
  const productColumnsVendor = [
    {
      name: "Customer Name",
      selector: (row) => row.Name,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Contact",
      selector: (row) => row.Contact,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Service",
      selector: (row) => row.Service,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    // {
    //   name: "Total Workers",
    //   selector: (row) => row.TotalWorkers,
    //   sortable: true,
    //   // center: true,
    //   wrap: true,
    //   // minWidth: "350px",
    // },
    {
      name: "Location",
      selector: (row) => row.Location,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "From",
      selector: (row) => row.StartDate,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "To",
      selector: (row) => row.EndDate,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Status",
      selector: (row) => row.Action,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
  ];

  const ProductListVendor =
    workerData?.assigned_work &&
    workerData?.assigned_work?.map((value, index) => ({
      Name: (
        <div>
          <H6>{value?.booking_request?.name}</H6>
        </div>
      ),
      Contact: (
        <div>
          <p>{value?.booking_request?.email}</p>
          <p>{value?.booking_request?.contact}</p>
        </div>
      ),
      Service: (
        <div>
          <p>{value?.booking_request?.category?.category_name}</p>
          <p>({value?.booking_request?.subcategory?.subcategory_name})</p>
        </div>
      ),
      // TotalWorkers: (
      //   <div>
      //     <p>{value?.worker?.length} Workers</p>
      //   </div>
      // ),
      Location: (
        <div>
          {/* <p>{value?.booking_request?.address}</p>
          <p>({value?.booking_request?.city}-{value?.booking_request?.pincode})</p> */}
          <p>
            {value?.booking_request?.house_no} -{" "}
            {value?.booking_request?.residence_name}
          </p>
          <p>
            {value?.booking_request?.area_or_street},
            {value?.booking_request?.city?.city_name}
          </p>
        </div>
      ),
      StartDate: (
        <div>
          <p>{value?.booking_request?.from_date}</p>
        </div>
      ),
      EndDate: (
        <div>
          <p>{value?.booking_request?.to_date}</p>
        </div>
      ),
      Action: (
        <div
          style={{
            background: "#308b26",
            padding: "8px",
            color: "#ffffff",
            borderRadius: "5px",
          }}
        >
          Completed
        </div>
      ),
      Workers: value?.worker,
    }));

  const ExpandedComponent = ({ data }) => (
    <div style={{ width: "100%", border: "1px solid grey" }}>
      <Table striped>
        <thead className="expand-table">
          <tr className="table-secondary" style={{ fontWeight: "900" }}>
            <th>#</th>
            <th style={{ fontWeight: "900" }}>Name</th>
            <th style={{ fontWeight: "900" }}>Email</th>
            <th style={{ fontWeight: "900" }}>Mobile</th>
            <th style={{ fontWeight: "900" }}>Nationality</th>
            <th style={{ fontWeight: "900" }}>Work Permit Expiry</th>
          </tr>
        </thead>
        <tbody>
          {data?.Workers?.map((data, index) => (
            <tr>
              <th scope="row">{index + 1}</th>
              <td>
                {data?.worker?.firstname} {data?.worker.lastname}
              </td>
              <td>{data?.worker?.email}</td>
              <td>{data?.worker?.mobile_number}</td>

              <td>{data?.worker?.nationality}</td>

              <td>{data?.worker?.work_permit_expire_at}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Add more details as needed */}
    </div>
  );

  return (
    <div>
      <div>
        <Breadcrumbs
          parent="Worker"
          title="Worker Details"
          mainTitle="Worker Details"
        />
        <Fragment>
          <Grid
            container
            rowSpacing={1}
            justifyContent="center"
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid item spacing={4} xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card
                sx={{
                  boxShadow: "none",
                  borderRadius: "10px",
                  // padding: "16px",
                  mb: "15px",
                }}
                className="check"
              >
                <Box mt={2}>
                  <Tabs className="product-details-tabs">
                    <TabList>
                      {/* <Tab>{path == "MovieDetails" ? data?.movie_category : "Episode"}</Tab> */}
                      <Tab>Basic Info</Tab>
                      <Tab>Address</Tab>
                      {workerData?.work_list?.map((ele) => (
                        <Tab>{ele?.category?.category_name}</Tab>
                      ))}
                      {/* {advertise_list && <Tab>Advertisement</Tab>} */}
                    </TabList>

                    <TabPanel>
                      <Card
                        sx={{
                          boxShadow: "none",
                          borderRadius: "10px",
                          p: "25px 20px",
                          mb: "10px",
                          backgroundColor: "#F7FAFF",
                        }}
                      >
                        <Typography
                          as="h3"
                          sx={{
                            fontSize: 16,
                            // mb: "15px",
                            fontWeight: 500,
                            // mt: "-25px",
                            // ml: "-25px",
                            // mr: "-25px",
                            padding: "10px",
                            borderRadius: "8px 8px 0px 0px",
                            background:
                              "linear-gradient(to right, #ec707a, #e9595369)",

                            color: "black",
                          }}
                        >
                          Details
                        </Typography>

                        <Box sx={{ margin: "5px 25px" }}>
                          <Typography
                            sx={{
                              fontSize: 13,
                              mt: "10px",
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                width: "170px",
                                fontWeight: "500",
                                marginRight: "10px",
                              }}
                              // className="mr-10px"
                            >
                              Name :
                            </span>
                            {workerData?.data?.firstname +
                              " " +
                              workerData?.data?.lastname}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: 13,
                              mt: "10px",
                              mr: "20px",
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                width: "170px",
                                fontWeight: "500",
                                marginRight: "10px",
                                paddingRight: "20px",
                              }}
                              className="mr-10px"
                            >
                              Email:
                            </span>
                            {workerData?.data?.email}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 13,
                              mt: "10px",
                              mr: "20px",
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                width: "170px",
                                fontWeight: "500",
                                marginRight: "10px",
                                paddingRight: "20px",
                              }}
                              className="mr-10px"
                            >
                              Mobile Number:
                            </span>
                            {workerData?.data?.mobile_number}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 13,
                              mt: "10px",
                              mr: "20px",
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                width: "170px",
                                fontWeight: "500",
                                marginRight: "10px",
                                paddingRight: "20px",
                              }}
                              className="mr-10px"
                            >
                              Date Of Birth:
                            </span>
                            {workerData?.data?.date_of_birth +
                              "( " +
                              workerData?.data?.age +
                              " )"}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 13,
                              mt: "10px",
                              mr: "20px",
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                width: "170px",
                                fontWeight: "500",
                                marginRight: "10px",
                                paddingRight: "20px",
                              }}
                              className="mr-10px"
                            >
                              Educational Background:
                            </span>
                            {workerData?.data?.educational_background}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 13,
                              mt: "10px",
                              mr: "20px",
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                width: "170px",
                                fontWeight: "500",
                                marginRight: "10px",
                                paddingRight: "20px",
                              }}
                              className="mr-10px"
                            >
                              known Language:
                            </span>
                            {workerData?.data?.language?.map((val, index) =>
                              index ? "," : "" + val?.language_name
                            )}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 13,
                              mt: "10px",
                              mr: "20px",
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                width: "170px",
                                fontWeight: "500",
                                marginRight: "10px",
                                paddingRight: "20px",
                              }}
                              className="mr-10px"
                            >
                              Language:
                            </span>
                            {workerData?.data?.primary_language?.language_name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 13,
                              mt: "10px",
                              mr: "20px",
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                width: "170px",
                                fontWeight: "500",
                                marginRight: "10px",
                                paddingRight: "20px",
                              }}
                              className="mr-10px"
                            >
                              Work Permit Expire At:
                            </span>
                            {workerData?.data?.work_permit_expire_at}
                          </Typography>
                        </Box>
                      </Card>
                    </TabPanel>
                    <TabPanel>
                      <Card
                        sx={{
                          boxShadow: "none",
                          borderRadius: "10px",
                          p: "25px 20px",
                          mb: "10px",
                          backgroundColor: "#F7FAFF",
                        }}
                      >
                        <Typography
                          as="h3"
                          sx={{
                            fontSize: 16,
                            // mb: "15px",
                            fontWeight: 500,
                            // mt: "-25px",
                            // ml: "-25px",
                            // mr: "-25px",
                            padding: "10px",
                            borderRadius: "8px 8px 0px 0px",
                            background:
                              "linear-gradient(to right, #ec707a, #e9595369)",

                            color: "black",
                          }}
                        >
                          Address
                        </Typography>

                        <Box sx={{ margin: "5px 25px" }}>
                          <Typography
                            sx={{
                              fontSize: 13,
                              mt: "10px",
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                width: "170px",
                                fontWeight: "500",
                              }}
                              className="mr-10px"
                            >
                              Nationality:
                            </span>
                            {workerData?.data?.nationality}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: 13,
                              mt: "10px",
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                width: "170px",
                                fontWeight: "500",
                              }}
                              className="mr-10px"
                            >
                              House No:
                            </span>
                            {workerData?.data?.house_no}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 13,
                              mt: "10px",
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                width: "170px",
                                fontWeight: "500",
                              }}
                              className="mr-10px"
                            >
                              Area Name:
                            </span>
                            {workerData?.data?.area_name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 13,
                              mt: "10px",
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                width: "170px",
                                fontWeight: "500",
                              }}
                              className="mr-10px"
                            >
                              Street Name :
                            </span>
                            {workerData?.data?.street_name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 13,
                              mt: "10px",
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                width: "170px",
                                fontWeight: "500",
                              }}
                              className="mr-10px"
                            >
                              City :
                            </span>
                            {workerData?.data?.city}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 13,
                              mt: "10px",
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                width: "170px",
                                fontWeight: "500",
                              }}
                              className="mr-10px"
                            >
                              State :
                            </span>
                            {workerData?.data?.state +
                              " - " +
                              workerData?.data?.pincode}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: 13,
                              mt: "10px",
                              mr: "20px",
                              display: "flex",
                            }}
                          >
                            <span
                              style={{
                                width: "170px",
                                fontWeight: "500",
                                // marginRight: "10px",
                                // paddingRight: "20px",
                              }}
                              className="mr-10px"
                            >
                              Work Permit Expire At:
                            </span>
                            {workerData?.data?.work_permit_expire_at}
                          </Typography>
                        </Box>
                      </Card>
                    </TabPanel>
                    {workerData?.work_list?.map((ele) => (
                      <TabPanel>
                        <Card
                          sx={{
                            boxShadow: "none",
                            borderRadius: "10px",
                            p: "25px 20px",
                            mb: "10px",
                            backgroundColor: "#F7FAFF",
                          }}
                        >
                          <Typography
                            as="h3"
                            sx={{
                              fontSize: 16,
                              // mb: "15px",
                              fontWeight: 500,
                              // mt: "-25px",
                              // ml: "-25px",
                              // mr: "-25px",
                              padding: "10px",
                              borderRadius: "8px 8px 0px 0px",
                              background:
                                "linear-gradient(to right, #ec707a, #e9595369)",

                              color: "black",
                            }}
                          >
                            Prices
                          </Typography>

                          <Box sx={{ margin: "5px 25px" }}>
                            <Typography
                              sx={{
                                fontSize: 13,
                                mt: "10px",
                                display: "flex",
                              }}
                            >
                              <span
                                style={{
                                  width: "170px",
                                  fontWeight: "500",
                                }}
                                className="mr-10px"
                              >
                                Category:
                              </span>
                              {ele?.category?.category_name}
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 13,
                                mt: "10px",
                                display: "flex",
                              }}
                            >
                              <span
                                style={{
                                  width: "170px",
                                  fontWeight: "500",
                                }}
                                className="mr-10px"
                              >
                                Sub category:
                              </span>
                              {ele?.subcategory?.subcategory_name}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: 13,
                                mt: "10px",
                                display: "flex",
                              }}
                            >
                              <span
                                style={{
                                  width: "170px",
                                  fontWeight: "500",
                                }}
                                className="mr-10px"
                              >
                                Expertise:
                              </span>
                              {ele?.expertise}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: 13,
                                mt: "10px",
                                display: "flex",
                              }}
                            >
                              <span
                                style={{
                                  width: "170px",
                                  fontWeight: "500",
                                }}
                                className="mr-10px"
                              >
                                Work Fees Per Hour :
                              </span>
                              {parseFloat(ele?.work_fees_per_hour).toFixed(2) +
                                "﷼"}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: 13,
                                mt: "10px",
                                display: "flex",
                              }}
                            >
                              <span
                                style={{
                                  width: "170px",
                                  fontWeight: "500",
                                }}
                                className="mr-10px"
                              >
                                Work Fees Per Day :
                              </span>
                              {parseFloat(ele?.work_fees_per_day).toFixed(2) +
                                "﷼"}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: 13,
                                mt: "10px",
                                display: "flex",
                              }}
                            >
                              <span
                                style={{
                                  width: "170px",
                                  fontWeight: "500",
                                }}
                                className="mr-10px"
                              >
                                Work Fees Per Week :
                              </span>
                              {parseFloat(ele?.work_fees_per_week).toFixed(2) +
                                "﷼"}
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: 13,
                                mt: "10px",
                                mr: "20px",
                                display: "flex",
                              }}
                            >
                              <span
                                style={{
                                  width: "170px",
                                  fontWeight: "500",
                                  // marginRight: "10px",
                                  // paddingRight: "20px",
                                }}
                                className="mr-10px"
                              >
                                Work Fees Per Month :
                              </span>
                              {parseFloat(ele?.work_fees_per_month).toFixed(2) +
                                "﷼"}
                            </Typography>
                          </Box>
                        </Card>
                      </TabPanel>
                    ))}
                  </Tabs>
                </Box>
              </Card>
            </Grid>
            <Grid item spacing={4} xs={12} sm={12} md={6} lg={6} xl={6}>
              <Card>
                <div className="blog-box blog-list row p-20">
                  <Col sm="12">
                    <H5>
                      <span>Certificates & Documents</span>
                    </H5>
                    <hr style={{ opacity: "1" }} />

                    <Row style={{ display: "flex", justifyContent: "center" }}>
                      {/* <Col sm="12"> */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          height: "180px",
                          marginTop:"-1rem"
                        }}
                      >
                        <div style={{display:"flex" , flexDirection:"column" , alignItems:"center"}}>
                        <img
                          src={IMAGE + workerData?.data?.profile_photo}
                          alt="Member"
                          // width="148px"
                          height="160px"
                          style={{ borderRadius: "10px" }}
                          onClick = {taxRegistrationCertificate}
                        />
                        <p>Profile Photo</p>
                        </div>
                        <div  style={{display:"flex" , flexDirection:"column" , alignItems:"center"}}>
                        <img
                          src={IMAGE + workerData?.data?.goverment_id_photo}
                          alt="Member"
                          // width="148px"
                          height="160px"
                          onClick = {personIdProof}
                          style={{ borderRadius: "10px" }}
                        />
                        <p  >Goverment Id Photo</p>
                     </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          height: "180px",
                          marginTop:"-1rem"
                        }}
                      >
                        <div style={{display:"flex" , flexDirection:"column" , alignItems:"center"}}>
                        <img
                          src={IMAGE + workerData?.data?.passport_photo}
                          alt="Member"
                          // width="148px"
                          height="160px"
                          // onClick = companyCertificate
                          onClick={companyCertificate}
                          style={{ borderRadius: "10px" }}
                        />
                        <p>Passport Photo</p>
                        </div>
                        <div  style={{display:"flex" , flexDirection:"column" , alignItems:"center"}}>
                        <img
                          src={IMAGE + workerData?.data?.work_permit}
                          alt="Member"
                          // width="148px"
                          height="160px"
                          onClick={workPermit}



                          style={{ borderRadius: "10px" }}
                        />
                        <p>Work Permit</p>
                     </div>
                      </div>
                      {/* <Btn
                          attrBtn={{
                            color: "",
                            className: "d-block w-100 mt-3 btn-class",
                            onClick: companyCertificate,
                            style: {
                              padding: "10px",

                            }
                          }}
                        >
                          <img src={Certificate} height="90px" /><br />
                          <span className="span-tag-details">Passport Photo</span>
                        </Btn>
                      </Col>
                      <Col sm="6">
                        <Btn
                          attrBtn={{
                            color: "",
                            className: "d-block w-100 mt-3 btn-class",
                            onClick: personIdProof,
                          }}
                        >
                          <img src={Id} height="90px"  /><br />
                          <span className="span-tag-details">
                          Goverment Id Photo
                          </span>
                        </Btn>
                      </Col>
                      <Col sm="6">
                        <Btn
                          attrBtn={{
                            color: "",
                            className: "d-block w-100 mt-3 btn-class",
                            onClick: taxRegistrationCertificate,
                          }}
                        >
                         <img src={IMAGE + workerData?.data?.profile_photo} height="85px"/><br />
                          <span className="span-tag-details">Profile Photo</span>
                        </Btn>
                      </Col> */}
                    </Row>
                  </Col>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Fragment>
      </div>

      {/* <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>

              <CardBody>
                <h5 className="mb-3">Total Workers</h5>
                <DataTable
                  noHeader
                  pagination
                  paginationServer
                  columns={productColumns}
                  data={ProductList}
                  highlightOnHover={true}
                  striped={true}
                  responsive={true}
                  className="custom-data-table"

                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <h5 className="mb-3">Work History</h5>
                <DataTable
                  noHeader
                  pagination
                  paginationServer
                  columns={productColumnsVendor}
                  data={ProductListVendor}
                  highlightOnHover={true}
                  striped={true}
                  responsive={true}
                  // expandableRows
                  // expandableRowsComponent={ExpandedComponent}
                  className="custom-data-table"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
