import React, { useEffect } from 'react'
import { Breadcrumbs } from '../../../AbstractElements'
import { about_us } from '../../../actions/Setting/about_us'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'reactstrap';

export default function AboutUsVendor() {
    const dispatch = useDispatch();
    const aboutus = useSelector((state) => state.setting?.aboutus?.data)

    console.log("aboutus", aboutus)

    useEffect(() => {
        dispatch(about_us())

    }, [])

    return (
        <div className="">
            <Breadcrumbs
                parent="settings"
                title="About Us"
                mainTitle="About Us"
            />
            <Container>

                {
                    <div>
                        <div style={{ textAlign: "right" }}>
                            <div style={{ fontSize: "30px", letterSpacing: "8px" }}>
                                <p>Follow us on: </p>
                                <a href={aboutus?.facebook_url} target='_blank'><i className="fa fa-facebook-square" style={{ color: "#2c2ccf" }} aria-hidden="true"></i></a>{" "}
                                <a href={aboutus?.youtube_url} target='_blank'><i className="fa fa-youtube-square" style={{ color: "#b12929" }} aria-hidden="true"></i></a>{" "}
                                <a href={aboutus?.instagram_url} target='_blank'><i className="fa fa-instagram" style={{ color: "#c94776" }} aria-hidden="true"></i></a>{" "}
                                <a href={aboutus?.linkedln_url} target='_blank'><i className="fa fa-linkedin-square" style={{ color: "#4c4cf9" }} aria-hidden="true"></i></a>
                            </div>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: aboutus?.description }}></div>
                    </div>

                }
            </Container>
        </div>
    )
}
