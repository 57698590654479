// import React from 'react';
// import { Box, Typography, Grid, Card, CardContent, CardMedia, Paper, Divider } from '@mui/material';
// import { DataGrid } from '@mui/x-data-grid';

// const ProductDetails3 = () => {
//   // Mock data for the product
//   const product = {
//     name: "Product Name",
//     category: "Category",
//     unit: "1 unit",
//     packagingSize: "500g",
//     quantity: 10,
//     images: [
//       "https://via.placeholder.com/150",
//       "https://via.placeholder.com/150",
//       "https://via.placeholder.com/150",
//     ],
//     price: 1000,
//     discount: 10,
//     gst: 5,
//     finalPrice: 900,
//   };

//   // Statistics mock data
//   const statistics = {
//     totalPurchases: 200,
//     averageRating: 4.5,
//     timesAddedToCart: 300,
//     peopleViewed: 500,
//     couponsUsed: 50,
//   };

//   return (
//     <Box sx={{ p: 3 }}>
//       {/* Product Details */}
//       <Typography variant="h4" gutterBottom>
//         Product Details
//       </Typography>
//       <Grid container spacing={3}>
//         <Grid item xs={12} md={6}>
//           <Card>
//             <CardContent>
//               <Typography variant="h5">{product.name}</Typography>
//               <Typography variant="subtitle1" color="textSecondary">
//                 Category: {product.category}
//               </Typography>
//               <Typography>Unit: {product.unit}</Typography>
//               <Typography>Packaging Size: {product.packagingSize}</Typography>
//               <Typography>Quantity: {product.quantity}</Typography>
//               <Typography>Price: ₹{product.price}</Typography>
//               <Typography>Discount: {product.discount}%</Typography>
//               <Typography>GST: {product.gst}%</Typography>
//               <Typography variant="h6" sx={{ mt: 2 }}>
//                 Final Price: ₹{product.finalPrice}
//               </Typography>
//             </CardContent>
//           </Card>
//         </Grid>

//         {/* Product Images */}
//         <Grid item xs={12} md={6}>
//           <Card>
//             <CardContent>
//               <Typography variant="h5">Product Images</Typography>
//               <Grid container spacing={2}>
//                 {product.images.map((image, index) => (
//                   <Grid item xs={4} key={index}>
//                     <CardMedia
//                       component="img"
//                       height="150"
//                       image={image}
//                       alt={`Product image ${index + 1}`}
//                     />
//                   </Grid>
//                 ))}
//               </Grid>
//             </CardContent>
//           </Card>
//         </Grid>
//       </Grid>

//       <Divider sx={{ my: 3 }} />

//       {/* Product Statistics */}
//       <Typography variant="h4" gutterBottom>
//         Product Statistics
//       </Typography>
//       <Grid container spacing={3}>
//         <Grid item xs={12} md={4}>
//           <Paper elevation={3} sx={{ p: 2 }}>
//             <Typography variant="h6">Total Purchases</Typography>
//             <Typography variant="h4">{statistics.totalPurchases}</Typography>
//           </Paper>
//         </Grid>
//         <Grid item xs={12} md={4}>
//           <Paper elevation={3} sx={{ p: 2 }}>
//             <Typography variant="h6">Average Rating</Typography>
//             <Typography variant="h4">{statistics.averageRating} / 5</Typography>
//           </Paper>
//         </Grid>
//         <Grid item xs={12} md={4}>
//           <Paper elevation={3} sx={{ p: 2 }}>
//             <Typography variant="h6">Times Added to Cart</Typography>
//             <Typography variant="h4">{statistics.timesAddedToCart}</Typography>
//           </Paper>
//         </Grid>
//         <Grid item xs={12} md={4}>
//           <Paper elevation={3} sx={{ p: 2 }}>
//             <Typography variant="h6">People Viewed</Typography>
//             <Typography variant="h4">{statistics.peopleViewed}</Typography>
//           </Paper>
//         </Grid>
//         <Grid item xs={12} md={4}>
//           <Paper elevation={3} sx={{ p: 2 }}>
//             <Typography variant="h6">Coupons Used</Typography>
//             <Typography variant="h4">{statistics.couponsUsed}</Typography>
//           </Paper>
//         </Grid>
//       </Grid>

//       <Divider sx={{ my: 3 }} />

//       {/* Related Orders Table */}
//       <Typography variant="h4" gutterBottom>
//         Orders for this Product
//       </Typography>
//       <Box sx={{ height: 400 }}>
//         <DataGrid
//           rows={[
//             { id: 1, customer: "John Doe", quantity: 2, price: 900, date: "2024-10-10", status: "Delivered" },
//             { id: 2, customer: "Jane Smith", quantity: 1, price: 900, date: "2024-10-12", status: "Pending" },
//           ]}
//           columns={[
//             { field: 'id', headerName: 'Order ID', width: 150 },
//             { field: 'customer', headerName: 'Customer', width: 200 },
//             { field: 'quantity', headerName: 'Quantity', width: 150 },
//             { field: 'price', headerName: 'Price', width: 150 },
//             { field: 'date', headerName: 'Date', width: 150 },
//             { field: 'status', headerName: 'Status', width: 150 },
//           ]}
//           pageSize={5}
//           rowsPerPageOptions={[5, 10, 20]}
//           pagination
//         />
//       </Box>
//     </Box>
//   );
// };

// export default ProductDetails3;

import React, { useMemo, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Paper,
  Divider,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import { useDispatch, useSelector } from "react-redux";
import { product_details } from "../../../actions/product";
import { useLocation } from "react-router";
import { IMAGE } from '../../../api';
import { Link } from "react-router-dom";

const ProductDetails3 = () => {
    const [isVendor, setIsVendor] = useState(false)
    const products_detail = useSelector((state) => state?.product?.product_details?.data);
    const products = useSelector((state) => state?.product?.product_details);
    const [isClick , setIsClick] = useState("Orders for this Product")
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 5,
        page: 0,
      });
    console.log("products_detail" ,products_detail)
    const dispatch = useDispatch()
    const location = useLocation()
    useMemo(() => {
        const formData = new FormData();
      formData.append("product_id", location.state?.id);
        dispatch(product_details(formData))
      }, [location?.state?.id]);

  const product = {
    name: products_detail?.product_name,
    category: products_detail?.category?.category_name,
    packagingSize: products_detail?.packaging_size + " " + products_detail?.unit?.unit_symbol,
    quantity: products_detail?.quantity,
    images: [
        products_detail?.image_0,
        products_detail?.image_1,
        products_detail?.image_2,
    ],
    price: parseFloat(products_detail?.price).toFixed(2),
    discount: parseFloat(products_detail?.discounted_price).toFixed(2) + " "+ "(" +parseFloat(products_detail?.discount).toFixed(2) + " ₹" + ")" ,
    gst:  parseFloat(products_detail?.gst_price).toFixed(2) + " "+  "(" +(products_detail?.gst?.gst_rate+ " %") + ")",
    finalPrice: parseFloat(products_detail?.final_price).toFixed(2),
    vender_name : products_detail?.vendor?.vendor_name,
    vender_email : products_detail?.vendor?.email,
    vender_company : products_detail?.vendor?.company_name,
  };
  useMemo(()=>{
    if(products_detail?.vendor !== null){
        setIsVendor(true)
    }else{
        setIsVendor(false)  
    }
  },[products_detail?.vendor])

  const statistics = {
    totalPurchases: products?.product_order_count,
    averageRating: products?.product_wishlist_count,
    timesAddedToCart: products?.product_add_to_cart_count,
    peopleViewed: products_detail?.product_view,
    couponsUsed: 75,
  };
  const orders = isClick =="Orders for this Product" ?  products?.product_order_list?.map((ele)=>({
    id:ele?.id,
    order_id: ele?.order_id,
    user : ele?.user,
    email : ele?.email,
    mobile_number : ele?.mobile_number,
    quantity : ele?.quantity,
    purchased_product_price : parseFloat(ele?.purchased_product_price).toFixed(2),
    status : ele?.status
  })) :
  isClick =="Product Wishlist List" ? products?.product_wishlist_list?.map((ele)=>({
    id:ele?.id,
    user : ele?.user,
    email : ele?.email,
    mobile_number : ele?.mobile_number,
    // quantity : ele?.quantity,
    // purchased_product_price : parseFloat(ele?.purchased_product_price).toFixed(2)
  })) :
  products?.product_add_to_cart_list?.map((ele)=>({
    id:ele?.id,
    // id: ele?.order_id,
    user : ele?.user,
    email : ele?.email,
    mobile_number : ele?.mobile_number,
    quantity : ele?.quantity,
    // quantity : ele?.quantity,
    // purchased_product_price : parseFloat(ele?.purchased_product_price).toFixed(2)
  }))
  const columns = isClick =="Orders for this Product" ? [
    {
        field: "order_id",
        headerName: "Order ID",
        width: 300,
        renderCell: (params) => {
          // Retrieve the full row data
          const row = params.row;

          return (
            <Link
              to={"/order-details"}
              state={{ id: row?.id }}
              style={{ color: "inherit", backgroundColor: "inherit" }}
            >
              {params.value}
            </Link>
          );
        },
      },
      { field: "user", headerName: "Customer", width: 250 },
      { field: "email", headerName: "Email", width: 300 },
      { field: "quantity", headerName: "Quantity", width: 150 },
      { field: "purchased_product_price", headerName: "Price", width: 150 },
      { field: "status", headerName: "Status", width: 180 },

  ] : isClick =="Product Wishlist List" ?  [
    {
        field: "user",
        headerName: "Customer",
        width: 420,
        renderCell: (params) => {
          // Retrieve the full row data
          const row = params.row;

          return (
            <Link
              to={"/customer/details"}
              state={{ id: row?.id }}
              style={{ color: "inherit", backgroundColor: "inherit" }}
            >
              {params.value}
            </Link>
          );
        },
      },
     
      { field: "email", headerName: "Email", width: 500 },
      { field: "mobile_number", headerName: "Mobile NO.", width: 400 },
    //   { field: "quantity", headerName: "Quantity", width: 150 },
    //   { field: "purchased_product_price", headerName: "Price", width: 150 },
    //   { field: "status", headerName: "Status", width: 180 },

  ] :  [
    {
        field: "user",
        headerName: "Customer",
        width: 390,
        renderCell: (params) => {
          // Retrieve the full row data
          const row = params.row;

          return (
            <Link
              to={"/customer/details"}
              state={{ id: row?.id }}
              style={{ color: "inherit", backgroundColor: "inherit" }}
            >
              {params.value}
            </Link>
          );
        },
      },
      { field: "email", headerName: "Email", width: 350 },
      { field: "mobile_number", headerName: "Mobile NO.", width: 400 },
      { field: "quantity", headerName: "Quantity", width: 200 },
    //   { field: "quantity", headerName: "Quantity", width: 150 },
    //   { field: "purchased_product_price", headerName: "Price", width: 150 },
    //   { field: "status", headerName: "Status", width: 180 },

  ]
  return (
    <Box sx={{ p: 3 }}>
      {/* Header */}
      <Typography variant="h4" sx={{ mb: 2, fontWeight: "bold" }}>
        Product Overview
      </Typography>

      {/* Product Information & Images */}
      <Grid container spacing={3}>
        {/* Product Details */}
        <Grid item xs={12} md={6}>
          <Card sx={{ borderRadius: 3, boxShadow: 3 }}>
            <CardContent>
              <Typography
                variant="h5"
                sx={  isVendor ? {fontWeight: "bold", textAlign:"center"} :{ fontWeight: "bold" } }
              >
                {product.name}
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                sx={isVendor && { textAlign: "center" }}
              >
                {product.category}
              </Typography>
              <Box
                sx={isVendor && {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <Box sx={{ mt: 2 }}>
                  {/* <Typography variant="body1">
                    <b>Unit:</b> {product.unit}
                  </Typography> */}
                  <Typography variant="body1">
                    <b>Packaging Size:</b> {product.packagingSize}
                  </Typography>
                  <Typography variant="body1">
                    <b>Quantity:</b> {product.quantity}
                  </Typography>
                  <Typography variant="body1">
                    <b>Price:</b> ₹{product.price}
                  </Typography>
                  <Typography variant="body1">
                    <b>Discount:</b> {product.discount}
                  </Typography>
                  <Typography variant="body1">
                    <b>GST:</b> {product.gst}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ mt: 2, fontWeight: "bold", color: "green" }}
                  >
                    Final Price: ₹{product.finalPrice}
                  </Typography>
                </Box>
               {isVendor && <Divider orientation="vertical" flexItem />}
              { isVendor && <Box sx={{ mt: 2 }}>
                  <Typography variant="body1">
                    <b>Vendor Company:</b> {product.vender_company}
                  </Typography>
                  <Typography variant="body1">
                    <b>Vendor Name:</b> {product.vender_name}
                  </Typography>
                  <Typography variant="body1">
                    <b>Vendor Email:</b> {product.vender_email}
                  </Typography>
                 
                </Box>}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Product Images */}
        <Grid item xs={12} md={6}>
          <Card sx={{ borderRadius: 3, boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Images
              </Typography>
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {product?.images?.length > 0 ? (
                  product.images.map((image, index) => (
                    <Grid item xs={4} key={index}>
                      <CardMedia
                        component="img"
                        image={
                          image ? IMAGE + image : "https://via.placeholder.com/150"
                        }
                        alt={`Product Image ${index + 1}`}
                        sx={{ borderRadius: 2, boxShadow: 2 , width:"200px" , height:"200px" }}
                      />
                    </Grid>
                  ))
                ) : (
                  <Typography variant="body1">
                    No images available for this product.
                  </Typography>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      {/* Product Statistics */}
      <Typography variant="h4" sx={{ mb: 2, fontWeight: "bold" }}>
        Product Statistics
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={4}
            sx={{ p: 3, borderRadius: 2, textAlign: "center" ,cursor:"pointer" }}
            onClick = {()=> setIsClick("Orders for this Product")}
          >
            <ShoppingCartIcon sx={{ fontSize: 40, color: "#4caf50" }} />
            <Typography variant="h6" sx={{ mt: 1 }}>
              Total Purchases
            </Typography>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", color: "#4caf50" }}
            >
              {statistics.totalPurchases}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={4}
            sx={{ p: 3, borderRadius: 2, textAlign: "center" ,cursor:"pointer" }}
            onClick = {()=> setIsClick("Product Wishlist List")}

          >
            <FavoriteBorder sx={{ fontSize: 40, color: "#ff9800" }} />
            <Typography variant="h6" sx={{ mt: 1 }}>
            Added To wishlist
            </Typography>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", color: "#ff9800" }}
            >
              {statistics.averageRating} 
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={4}
            sx={{ p: 3, borderRadius: 2, textAlign: "center",cursor:"pointer" }}
            onClick = {()=> setIsClick("Product Cart List")}

          >
            <ShoppingCartIcon sx={{ fontSize: 40, color: "#3f51b5" }} />
            <Typography variant="h6" sx={{ mt: 1 }}>
              Added to Cart
            </Typography>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", color: "#3f51b5" }}
            >
              {statistics.timesAddedToCart}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Paper
            elevation={4}
            sx={{ p: 3, borderRadius: 2, textAlign: "center",cursor:"pointer" }}
          >
            <PeopleAltIcon sx={{ fontSize: 40, color: "#e91e63" }} />
            <Typography variant="h6" sx={{ mt: 1 }}>
              People Viewed
            </Typography>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", color: "#e91e63" }}
            >
              {statistics.peopleViewed || 0}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Divider sx={{ my: 4 }} />

      {/* Order List */}
      <Typography variant="h4" sx={{ mb: 2, fontWeight: "bold" }}>
       {isClick}
      </Typography>
      <Box sx={{ height: 380 , background:"#fff" ,borderRadius: 3, boxShadow: 3 }}>
        <DataGrid
        rows={orders} // The order data
        columns={columns} // The column structure
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        disableSelectionOnClick
        rowSelection={false}
        />
      </Box>
    </Box>
  );
};

export default ProductDetails3;
