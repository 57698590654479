import React, { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { H5 } from '../../../AbstractElements';
import { DailyDropdown, Visitors } from '../../../Constant';
// import { OptionsVisitorChart } from '../../../Data/Ecommerce/Chart';
import SvgIcon from '../../Common/Component/SvgIcon';
import { useDispatch, useSelector } from "react-redux";
import DropdownCommon from '../../Common/Dropdown';
import { dashboard_graph_visitors } from '../../../actions/dashboard';

const VisitorsCard = () => {
  const total_user = useSelector((state)=>state?.dashboard?.total_user)
  console.log("total_user" , total_user)
  const dispatch = useDispatch()
  const userInfo = JSON.parse(sessionStorage.getItem("user"))
  useEffect(()=>{
    if(userInfo?.id?.id){
      dispatch(dashboard_graph_visitors({admin_id:userInfo?.id?.id}))
    }
  },[])
 const OptionsVisitorChart = {
    series: [
      {
        name: total_user?.data[0]?.name,
        data: total_user?.data[0]?.data,
      },
      {
        name: total_user?.data[1]?.name,
        data: total_user?.data[1]?.data,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 270,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '50%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 6,
        colors: ['transparent'],
      },
      grid: {
        show: true,
        borderColor: 'var(--chart-border)',
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      colors: ['#FFA941', 'var(--theme-deafult)'],
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        // tickAmount: 4,
        tickPlacement: 'between',
        labels: {
          style: {
            fontFamily: 'Rubik, sans-serif',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        // min: 0,
        // max: 100,
        // tickAmount: 5,
        tickPlacement: 'between',
        labels: {
          style: {
            fontFamily: 'Rubik, sans-serif',
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        fontFamily: 'Rubik, sans-serif',
        fontSize: '14px',
        fontWeight: 500,
        labels: {
          colors: 'var(--chart-text-color)',
        },
        markers: {
          width: 6,
          height: 6,
          radius: 12,
        },
        itemMargin: {
          horizontal: 10,
        },
      },
      responsive: [
        {
          breakpoint: 1366,
          options: {
            plotOptions: {
              bar: {
                columnWidth: '80%',
              },
            },
            grid: {
              padding: {
                right: 0,
              },
            },
          },
        },
        {
          breakpoint: 992,
          options: {
            plotOptions: {
              bar: {
                columnWidth: '70%',
              },
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            plotOptions: {
              bar: {
                columnWidth: '60%',
              },
            },
            grid: {
              padding: {
                right: 5,
              },
            },
          },
        },
      ],
    },
  };
  return (
    <Card className='visitor-card'>
      <CardHeader className='card-no-border'>
        <div className='header-top'>
          <H5 attrH5={{ className: 'm-0' }}>
            {/* {Visitors} */}
            Customers : 
            <span className='f-14 font-primary f-w-500 ms-1'>
              <SvgIcon iconId='user-visitor' className='svg-fill me-1' />
             <b>{total_user?.data[2]?.total_users}</b>
            </span>
          </H5>
          <div className='card-header-right-icon'>
            <DropdownCommon dropdownMain={{ className: 'icon-dropdown', direction: 'start' }} options={DailyDropdown} iconName='icon-more-alt' btn={{ tag: 'span' }} />
          </div>
        </div>
      </CardHeader>
      <CardBody className='pt-0'>
        <div className='visitors-container'>
          <ReactApexChart height={270} type='bar' options={OptionsVisitorChart.options} series={OptionsVisitorChart.series} />
        </div>
      </CardBody>
    </Card>
  );
};

export default VisitorsCard;
