import React, { useEffect } from 'react'
import { Breadcrumbs } from '../../../AbstractElements'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'reactstrap';
import { privacy_policy } from '../../../actions/Setting/privacy_policy';
export default function PrivacyPolicyVendor() {

    const dispatch = useDispatch();
    const refundpolicy = useSelector((state) => state.setting?.privacypolicy?.data)

    console.log("aboutus", refundpolicy)

    useEffect(() => {
        dispatch(privacy_policy())

    }, [])

    return (
        <div>
            <Breadcrumbs
                parent="settings"
                title="Privacy Policy"
                mainTitle="Privacy Policy"
            />
            <Container>
                {
                    <div>
                        <div dangerouslySetInnerHTML={{ __html: refundpolicy?.description }}></div>
                    </div>
                }
            </Container>
        </div>
    )
}
