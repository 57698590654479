import React, { Fragment, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn, H4, H6, P, Image } from '../../../AbstractElements';
import logoWhite from '../../../assets/images/logo/logo.png';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux'
import { changePassword, sendOtp, update_password, verifyOtp } from '../../../actions/AuthAction';
import AlertMessage from '../../Common/Component/AlertMessage';
import { RESET } from '../../../Constant';
const ForgetPwd = ({ logoClassMain }) => {
  const [otpSection, setOtpSection] = useState(false)
  const [newPasswordSection, setNewPasswordSection] = useState(false)
  const [otp, setOtp] = useState('');
  const location = useLocation()
  const [formData, setFormData] = useState({
    user_type: location?.state?.type
  })
  const [toggleNewPassword, setToggleNewPassword] = useState(false);
  const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
  const [flag, setFlag] = useState(null)
  const storeMessage = useSelector((state) => state?.auth?.message)
  const navigation = useNavigate()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [type, setType] = useState(null)
  const [message, setMessage] = useState(null)
  const authStore = useSelector((state) => state?.auth?.change_password)

  const handlesendotp = async (e) => {
    e.preventDefault()
    setFlag("SEND")
    dispatch(sendOtp(formData))
  }
  const handleverifyotp = async (e) => {
    e.preventDefault()

    setFlag("VERIFY")
    dispatch(verifyOtp({ ...formData, OTP: otp }))
  }


  const handlechangepassword = (e) => {
    e.preventDefault()
    if (formData.password == formData.confirm_password) {
      formData.password_flag = "update"
      dispatch(update_password(formData))
    }
    else {
      setType("error")
      setOpen(true)
      setMessage("Password and confirm password must be same")
    }
  }

  useMemo(() => {
    if (storeMessage?.statuscode == 200) {
      if (flag == "SEND") {
        setOtpSection(true)
        setOtp(storeMessage?.otp)
      }
      else if (flag == "VERIFY") {
        // navigation('/login', { replace: true })
        setNewPasswordSection(true)
      }
    }
  }, [storeMessage])

  useMemo(() => {
    if (authStore?.statuscode == 200) {
      setType("success")
      setOpen(true)
      setMessage(authStore?.message)
      setTimeout(() => {
        dispatch({ type: RESET, payload: null });
        navigation('/login', { replace: true })
      }, 2000)
    }
    else if (authStore && authStore?.statuscode !== 200) {
      setType("error")
      setOpen(true)
      setMessage(authStore?.message)
    }
  }, [authStore])


  return (
    <Fragment>
      <AlertMessage message={message} open={open} type={type} />
      <section>
        <Container className='p-0 login-page' fluid={true}>
          <Row className='m-0'>
            <Col className='p-0'>
              <div className='login-card'>
                <div>
                  <div>
                    <Link className={`logo ${logoClassMain ? logoClassMain : ''}`}  to={process.env.PUBLIC_URL}>
                      <Image attrImage={{ className: 'img-fluid for-light', style:{height:"70px"},  src: logoWhite, alt: 'looginpage'  }} />
                      <Image attrImage={{ className: 'img-fluid for-dark',style:{height:"70px"} ,src: logoWhite, alt: 'looginpage' }} />
                    </Link>
                  </div>
                  <div className='login-main'>
                    {
                      !otpSection && !newPasswordSection &&
                      <Form className='theme-form login-form' onSubmit={handlesendotp}>
                        <H4>Reset Your Password</H4>
                        <FormGroup>
                          <Label className='m-0 col-form-label'>Enter Your email</Label>
                          <Input className='form-control' type='email' required value={formData?.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                        </FormGroup>
                        <FormGroup className='text-end'>
                          <Btn attrBtn={{ className: 'btn-block ', color: 'primary', type: 'submit' }} >Send</Btn>
                        </FormGroup>

                        <P attrPara={{ className: 'text-start' }}>
                          Already have an password?
                          <div style={{ cursor: "pointer" }} className='ms-2' onClick={() => navigation('/login', { replace: true })}>
                            Sign in
                          </div>
                        </P>
                      </Form>
                    }
                    {
                      otpSection && !newPasswordSection &&
                      <Form className='theme-form login-form' onSubmit={handleverifyotp}>
                        <H4>Reset Your Password</H4>
                        <FormGroup>
                          <Label className='m-0 col-form-label'>Enter otp send on email</Label>
                          {/* <Input className='form-control' type='email' required /> */}
                          <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            inputStyle={{
                              width: "100%",
                              border: "1px solid #CACACA",
                              borderRadius: "1rem",
                              color: "#000000"
                            }}
                            renderSeparator={<span>  </span>}
                            renderInput={(props) => <input {...props} />}
                          />
                        </FormGroup>
                        <FormGroup className='text-end'>
                          <Btn attrBtn={{ className: 'btn-block ', color: 'primary', type: 'submit' }} >Verify</Btn>
                        </FormGroup>
                        <FormGroup className='mb-4 mt-4'>
                          <span className='reset-password-link'>
                            If don't receive OTP ?
                            <a className='btn-link text-danger' href='#javascript'>
                              Resend
                            </a>
                          </span>
                        </FormGroup>

                      </Form>
                    }

                    {
                      newPasswordSection &&
                      <Form className='theme-form login-form' onSubmit={handlechangepassword}>
                        <H4>Reset Your Password</H4>
                        <FormGroup className='position-relative'>
                          <Label className='col-form-label m-0'>New Password</Label>
                          <div className='position-relative'>
                            <Input onChange={(e) => setFormData({ ...formData, password: e.target.value })} className='form-control' type={toggleNewPassword ? 'text' : 'password'} name='login[password]' required placeholder='*********' />
                            <div className='show-hide' onClick={() => setToggleNewPassword(!toggleNewPassword)}>
                              <span className={toggleNewPassword ? '' : 'show'}></span>
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup className='position-relative'>
                          <Label className='col-form-label m-0'>Retype Password</Label>
                          <div className='position-relative'>
                            <Input onChange={(e) => setFormData({ ...formData, confirm_password: e.target.value })} className='form-control' type={toggleConfirmPassword ? 'text' : 'password'} name='login[password]' required placeholder='*********' />
                            <div className='show-hide' onClick={() => setToggleConfirmPassword(!toggleConfirmPassword)}>
                              <span className={toggleConfirmPassword ? '' : 'show'}></span>
                            </div>
                          </div>
                        </FormGroup>
                        <FormGroup className='text-end'>
                          <Btn attrBtn={{ className: 'btn-block ', color: 'primary', type: 'submit' }} >Submit</Btn>
                        </FormGroup>
                      </Form>
                    }

                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default ForgetPwd;
