import { ADMIN_VENDOR_EXPIRED_LAST_MIN_DEAL, ADMIN_VENDOR_LAST_MIN_DEAL, EXPIRED_LAST_MIN_DEAL_LIST, LAST_MIN_DEAL, LAST_MIN_DEAL_LIST } from "../../Constant";
import * as api from '../../api/index';

export const create_last_min_deal = (formData) => async (dispatch) => {
    try {
      const { data } = await api.create_last_min_deal(formData);
      dispatch({ type: LAST_MIN_DEAL, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const update_last_min_deal = (formData) => async (dispatch) => {
    try {
      const { data } = await api.update_last_min_deal(formData);
      dispatch({ type: LAST_MIN_DEAL, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const delete_last_min_deal = (formData) => async (dispatch) => {
    try {
      const { data } = await api.delete_last_min_deal(formData);
      dispatch({ type: LAST_MIN_DEAL, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const all_last_min_deal_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_last_min_deal_list(formData);
      dispatch({ type: LAST_MIN_DEAL_LIST, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const admin_vendor_wise_last_min_deal_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.admin_vendor_wise_last_min_deal_list(formData);
      dispatch({ type: ADMIN_VENDOR_LAST_MIN_DEAL, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const all_expired_last_min_deal_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_expired_last_min_deal_list(formData);
      dispatch({ type: EXPIRED_LAST_MIN_DEAL_LIST, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const admin_vendor_wise_expire_last_min_deal_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.admin_vendor_wise_expire_last_min_deal_list(formData);
      dispatch({ type: ADMIN_VENDOR_EXPIRED_LAST_MIN_DEAL, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
