import {  CITY, CITY_LIST } from '../Constant';
import * as api from '../api/index';

export const  city_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.city_create (formData);
      dispatch({ type: CITY, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };
export const  city_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.city_update (formData);
      dispatch({ type: CITY, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };
export const  all_city_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_city_list (formData);
      dispatch({ type: CITY_LIST, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };