import * as actionType from '../Constant';


const UnitReducer = (state = { unitData: null }, action) => {
  switch (action.type) {
    case actionType.UNIT:
      return { ...state, unit: action.payload };
    case actionType.UNIT_LIST:
      return {...state, unit_list: action.payload}

    default:
      return state;
  }
};

export default UnitReducer;
