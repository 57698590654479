import React, { useEffect, useState } from 'react';
import SvgIcon from '../../../Components/Common/Component/SvgIcon';
import { useDispatch } from 'react-redux'

import { useNavigate } from 'react-router';

const Notificationbar = () => {
  const dispatch = useDispatch()

  const navigation = useNavigate()




  return (
    <li>
      {/* <div className='notification-box' onClick={() => setNotificationDropDown(!notificationDropDown)}> */}
      <div style={{cursor:"pointer" }} onClick={()=>navigation('/notification-list')}>
        <SvgIcon iconId='notification' />
        </div>
        {/* <span className='badge rounded-pill badge-secondary'>{workerPermitExpiry?.data?.length}</span> */}
      {/* </div> */}
      {/* <div className={`notification-dropdown onhover-show-div ${notificationDropDown ? 'active' : ''}`}> */}
        {/* <h6 className='f-18 mb-0 dropdown-title'>Work permit due</h6>
        <ul>
          {
            workerPermitExpiry?.data?.slice(0, 4).map((data, index) => (
              <li className='b-l-primary border-4' key={index}>
                <p>
                  {data?.company_name} <span className='font-danger'>{data?.worker?.length}</span>
                </p>
              </li>
            ))
          }


          <li>
            <div onClick={() => navigation('/Upcoming-Expiry')} className='f-w-700' href='#javascript'>
              {CHECKALL}
            </div>
          </li>
        </ul> */}
      {/* </div> */}
    </li>
  );
};

export default Notificationbar;
