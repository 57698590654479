// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, {  useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import ListTable from "../../../utils/Table";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { all_offers_list, delete_offers, update_offers } from "../../../actions/offeres/offers";
import { OFFERS } from "../../../Constant";


const AllOffers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const Offers = useSelector((state)=>state?.offers?.offers_list)
  const message = useSelector((state)=>state?.offers?.offers)
  const login_details = JSON.parse(sessionStorage.getItem("user"))
  const role = login_details?.id?.userrole?.rolename

  console.log(Offers)
  useMemo(()=>{
    {
      dispatch(all_offers_list())
    }
  },[])
  const tempTableData = {
    tableTitle: "Offers",
    // deleteRecord: delete_offers,
    updateRecord: update_offers,
    // deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    disableDelete : true,
    onDeleteText: "Are you sure want to delete?",
    onUpdateText:
      "Inactive this Offer will remove it from the current offers list and make it unavailable for customers. Are you sure you want to proceed?",
    tableHead: [
      {
        id:"product",
        label:"Product"
      },
      {
        id: "product_sub_category",
        label: "Category Name",
        subText : "product_category"
      },
      {
        id: "created_by",
        label: "Ownership",
        // subText :"category"
      },
      {
        id: "uploaded_by_name",
        label: "Uploaded By",
        subText: "company_name",
      },
      {
        id: "start_date",
        label: "Start Date",
      },
      {
        id:"end_date",
        label:"End Date"
      },
      {
        id :"product_final_price",
        label :"Offer Before Price"
      },
      {
        id :"offer_type",
        label :"Offer Type"
      },
      {
        id :"offer_amount",
        label :"Offer Price"
      },
      {
        id: "status",
        label: "Status",
        // isButtonDisplay: true,
      },
      // {
      //   id: "edit",
      //   label: "Update",
      //   edit_path :"/update_offers",
      //   // id : "id",
      //   // handleEdit : (value) => {navigate("/Update-Category" , {state:{id:value?.id}})}
      //   // access: rights?.["Masters"]?.["edit"] == "true",
      // },
    ],
    tableBody: [],
    filterColumn: [
      {
        id: "1",
        title: "Owneship",
        name: "created_by",
        options: ["Admin", "Vendor"],
      },
    ],
  };
  const [tableData, setTableData] = useState({...tempTableData})

  useMemo(()=>{
    if(Offers?.statuscode == 200){
      const temp = tableData
      temp.tableBody = Offers?.data?.map((ele)=>({
        ...ele,
        product : ele?.product?.product_name,
        product_category: ele?.product_category?.category_name,
        product_sub_category : ele?.product_sub_category?.sub_category_name,
        uploaded_by_name:
        ele?.created_by == "Vendor"
          ? ele?.vendor?.vendor_name
          : ele?.admin?.name,
      company_name:
        ele?.created_by == "Vendor" ? ele?.vendor?.company_name : " - ",
      }))
      setTableData({...temp})
    }
  },[Offers])
  useMemo(()=>{
    if(message?.statuscode == 200){
      toast.success(message?.message);
      if(login_details){
        dispatch(all_offers_list())
      }
      dispatch({ type: OFFERS, payload: undefined })
    }
  },[message])
  return (
    <div >
      <Breadcrumbs
        parent="Offers"
        title="Offers List"
        mainTitle="Offers List"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
              <ListTable
              tableData={tableData}
              key={"ListTable"}
              // setForm={setForm}
              setTableData={setTableData}
              // setIsEdit={setIsEdit}
              />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AllOffers;
