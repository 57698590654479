import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import "./ProductDetailsContent.css";
import { IMAGE } from "../../../api/index";
const ProductProfile= ({data}) => {
	return (
		<>
		
					<Card
						sx={{
							textAlign: "center",
							boxShadow: "var(--themeShadow)",
							borderRadius: "10px",
							p: "15px 15px",
							mb: "15px",
							backgroundColor:"#fff",
							 marginTop:"2rem"
						}}
					><div style={{display:"flex",justifyContent:"space-evenly" ,height:"240px"}}>
						{/* {path=="MovieDetails" &&<img
							src={IMAGE+(path=="MovieDetails"?data?.movie_thumbnail:data?.episode_thumbnail)}
							alt="Member"
							// width="148px"
							height="200px"
							style={{ borderRadius: "10px" }}
						/>} */}
							
								<img
									src={IMAGE+data?.image_0}
									alt="Image"
									height="200px"
									style={{ borderRadius: "10px" }}
								/>
								{ data?.image_1 && <img
									src={IMAGE+ data?.image_1}
									alt="Image"
									height="200px"
									style={{ borderRadius: "10px" }}
								/>}
							{ data?.image_2 &&	<img
									src={IMAGE+data?.image_2}
									alt="Image"
									height="200px"
									style={{ borderRadius: "10px" }}
								/>}
								</div>
						<Typography
							as="h4"
							sx={{
								fontSize: 16,
								fontWeight: 500,
								mt: "10px",
							}}
						>
							{data?.product_name?.length > 30 ?data?.product_name?.substring(0, 50) : data?.product_name}
						</Typography>

						{/* <Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-around",
								textAlign: "center",
								mt: "20px",
							}}
						>
							<Box>
								<Typography color="#A9A9C8"  fontSize="13px">
									Likes
								</Typography>
								<Typography fontWeight="500" fontSize="16px">
								{path=="MovieDetails"?data?.movieLikes:data?.episodeLikes}
								
								</Typography>
							</Box>

							<Box>
								<Typography color="#A9A9C8"  fontSize="13px">
									Downloads
								</Typography>
								<Typography fontWeight="500" fontSize="16px">
								{path=="MovieDetails"?data?.total_downloads:data?.episodeDownloads}
							
								</Typography>
							</Box>

							<Box>
								<Typography color="#A9A9C8"  fontSize="13px">
									Views
								</Typography>
								<Typography fontWeight="500" fontSize="16px">
								{path=="MovieDetails"?data?.movieViews:data?.episodeViews}
							
								</Typography>
							</Box>
						</Box> */}
					</Card>
					
			
			
		</>
	);
};

export default ProductProfile;
