// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../AbstractElements";

import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { API_LINK, IMAGE } from "../../api";
import SweetAlert from 'sweetalert2';
import * as EmpActions from "../../actions/empActions";


import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CREATECATEGORY } from "../../Constant";

const Projects = () => {
  const dispatch = useDispatch();
  const { all_projects_of_manager } = bindActionCreators(EmpActions, dispatch);
  const [employeeData, setEmployeeData] = useState([]);
  const navigation = useNavigate()
  // const { category_delete} = bindActionCreators(
  //   HomeAction,
  //   dispatch
  // );
  const user = useSelector((state) => state?.layout?.user)

  useEffect(() => {
    user && (async () => {
      const data = new FormData();
      data.append("user", user?.id);
      // dispatch(bank_detail_list(data));
      const resData = await all_projects_of_manager(data);
      setEmployeeData(resData);
    })();
  }, [user]);
  const loggedIn = useSelector((state) => state?.layout?.loggedIn)
  const deleteTask = async (userId) => {
    SweetAlert.fire({

      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this Category!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then(async (result) => {
      if (result.value) {
        // RemoveTask(userId);
        const fData = new FormData()
        fData.append("id", userId)

        SweetAlert.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );
      } else {
        SweetAlert.fire(
          'Your imaginary file is safe!'
        );
      }
    });
  };

  const openPDF = (value) => {
    // navigation('/assigned-tasks/Details', { state: value })
    window.open(API_LINK + value, '_blank')
  }

  const viewMore = (value) => {
    navigation('/team-leader-projects/details', { state: value })
  }
  const viewTeam = (id, name) => {
    navigation('/team-leader-projects/view-team-member', { state: { id: id, project_name: name } })
  }
  const productColumns = [
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
      // center: true,
      wrap: true,
      // maxWidth: "150px",
    },
    {
      name: "Start Date",
      selector: (row) => row.Start,
      sortable: true,
      center: true,
      wrap: true,
      // maxWidth: "150px",
    },
    {
      name: "End Date",
      selector: (row) => row.End,
      sortable: true,
      center: true,
      wrap: true,
      // maxWidth: "150px",
    },
    {
      name: "Services",
      selector: (row) => row.Tasks,
      sortable: false,
      center: true,
      // minWidth: "150px",
      // maxWidth: "150px",
    },


    {
      name: "Documents",
      selector: (row) => row.Document,
      sortable: true,
      center: true,
      // minWidth: "160px",
      // maxWidth: "160px",
    },
    {
      name: "",
      selector: (row) => row.View,
      sortable: true,
      center: true,
      // minWidth: "160px",
      // maxWidth: "160px",
    },
  ];
  const style = {
    width: 55,
    height: 40,
    borderRadius: 5,
  };
  const style2 = { width: 60, fontSize: 14, padding: 4, margin: 2 };
  const ProductList =
    employeeData &&
    employeeData?.data?.map((value, index) => ({

      Name: (
        <div>
          <H6>{value?.project_name}</H6>
        </div>
      ),
      Start: (
        <div>
          <p>{value?.start_date}</p>
          {/* <p>{value?.phone_number}</p> */}
        </div>
      ),
      End: (
        <div>
          <p>{value?.end_date}</p>
        </div>
      ),

      Tasks:
        (
          <div>
            {/* <p>{value?.service.map((ele, index) => (index ? ', ' : '') + ele?.service_name)}</p> */}
            <ul >
              {" "}
              {value?.service.map((ele, index) => (
                <li key={index}>- {ele?.service_name}</li>
              ))}{" "}
            </ul>
          </div>
        ),


      Document: (
        <div>
          &nbsp;&nbsp;
          <span>
            {
              value?.documents && value?.documents !== "null" ?
                <Btn
                  attrBtn={{
                    style: style2,
                    color: "success",
                    className: "btn btn-xs",
                    type: "button",
                    onClick: () => openPDF(value?.documents)

                  }}
                >
                  View
                </Btn>
                :
                <>
                  --
                </>
            }

          </span>

        </div>
      ),
      View: (
        <div>
          &nbsp;&nbsp;
          <span>
            <Btn
              attrBtn={{
                style: style2,
                color: "primary",
                className: "btn btn-xs",
                type: "button",
                onClick: () => viewMore(value)

              }}
            >
              More
            </Btn>
          </span>
          <span>
            <Btn
              attrBtn={{
                style: style2,
                color: "success",
                className: "btn btn-xs",
                type: "button",
                onClick: () => viewTeam(value?.id, value?.project_name)

              }}
            >
              Team
            </Btn>
          </span>
        </div>
      ),
    }));

  // amount: '$10',
  // stock: <div className='font-success'>In Stock</div>,
  // start_date: '2022/4/19',

  // ]
  return (
    <div className="table-responsive product-table">
      <Breadcrumbs
        parent="Team Leader"
        title="Team Leader"
        mainTitle="Projects"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  noHeader
                  pagination
                  paginationServer
                  columns={productColumns}
                  data={ProductList}
                  highlightOnHover={true}
                  striped={true}
                  responsive={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Projects;
