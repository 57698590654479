
import { SUBCATEGORY,SUBCATEGORYLIST } from '../Constant/index.jsx';
import * as api from '../api/index.jsx';

export const subcategory_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.subcategory_create(formData);
      dispatch({ type: SUBCATEGORY, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };

  export const subcategory_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.subcategory_update(formData);
      dispatch({ type: SUBCATEGORY, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };
  export const subcategory_delete = (formData) => async (dispatch) => {
    try {
      const { data } = await api.subcategory_delete(formData);
      dispatch({ type: SUBCATEGORY, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };
  export const all_subcategory_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_subcategory_list(formData);
      
      dispatch({ type: SUBCATEGORYLIST, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };
  