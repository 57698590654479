import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import * as Action from "../../../actions/offeres/offers";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { IMAGE } from "../../../api";
import { CREATECATEGORY, DEAL_OF_THE_DAY, OFFERS } from "../../../Constant";
import { Breadcrumbs, H5 } from "../../../AbstractElements";
import Form from "../../../utils/Form";
import { all_category_list } from "../../../actions/category";
import { all_subcategory_list } from "../../../actions/subcategory";
import { product_list_special_offer } from "../../../actions/product";
const CreateOffers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "newLOCS");
  const isEdit = location?.state?.isEdit;
  const category_data = useSelector((state) => state?.category?.category_list);
  const sub_category_data = useSelector(
    (state) => state?.subcategory?.subcategory_list
  );
  const login_details = JSON.parse(sessionStorage.getItem("user"));
  const role = login_details?.id?.userrole?.rolename;
  const product_data = useSelector((state) => state?.product?.product_name);
  console.log(product_data, "gdgrgdr");
  const { create_offers, update_offers } = bindActionCreators(Action, dispatch);

  const [file, setFile] = useState(null);
  const [form, setForm] = useState({});

  useEffect(() => {
    if (location?.state?.form) {
      setForm(location?.state?.form && JSON.parse(location?.state?.form));
    }
  }, [location?.state?.form]);

  useEffect(() => {
    if (login_details) {
      dispatch(all_category_list());
      dispatch(all_subcategory_list());
      dispatch(
        product_list_special_offer({
          user_id: login_details?.id?.id,
          user_type: role,
        })
      );
    }
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const temp_product = form;
    temp_product["tempproduct_category"] = form?.["product_category"];
    temp_product["tempproduct_sub_category"] = form?.["product_sub_category"];
    temp_product["tempproduct"] = form?.["product"];

    temp_product["product_category"] = category_data?.data
      ?.filter((option) => form?.["product_category"] == option.category_name)
      .map((option) => option.id);
    // ?.map(
    //   (option) => form?.["product_category"]?.includes(option?.category_name) && option.id
    // )
    // .filter((e) => e);
    temp_product["product_sub_category"] = sub_category_data?.data
      ?.filter(
        (option) => form?.["product_sub_category"] == option.sub_category_name
      )
      .map((option) => option.id);
    // ?.map(
    //   (option) => form?.["product_sub_category"]?.includes(option?.sub_category_name) && option.id
    // )
    // .filter((e) => e);
    temp_product["product"] = product_data?.data
      ?.filter(
        (option) =>
          form?.["product"] == option.product_name &&
          form?.["tempproduct_category"] == option.category &&
          form?.["tempproduct_sub_category"] == option.subcategory
      )
      .map((option) => option.id);
    // temp_product["product"] = product_data?.data
    // ?.find((option) =>
    //   form?.["product_category"] === option.category &&
    //   form?.["product_sub_category"] === option.subcategory &&
    //   form?.["product"] === option.product_name
    // )?.option?.id;
    // ?.map(
    //   (option) => form?.["product"]?.includes(option?.product_name) && option.id
    // )
    // .filter((e) => e);
    temp_product["created_by_id"] = login_details?.id?.id;
    temp_product["created_by"] = role;
    setForm({ ...temp_product });

    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    formStructure.map((element) => {
      if (
        element.type == "image" &&
        form?.[element.name] &&
        typeof form?.[element.name] != "string"
      ) {
        const temp = form;
        temp["temp" + element.name] = form?.[element.name];
        temp[element.name] = URL.createObjectURL(form?.[element.name]);

        setForm({
          ...temp,
        });
      }
    });

    if (isEdit) {
      const UpdateOffersApi = await update_offers(data);
      if (UpdateOffersApi?.statuscode == 200) {
        toast.success(UpdateOffersApi?.message);
        setTimeout(() => {
          navigate("/offers");
          dispatch({ type: OFFERS, payload: undefined });
        }, 1000);
        setForm({});
      } else {
        toast.error(UpdateOffersApi?.message);
        const tempProduct = form;
        tempProduct["product_category"] = form?.tempproduct_category;
        tempProduct["product_sub_category"] = form?.tempproduct_sub_category;
        tempProduct["product"] = form?.tempproduct;
        setForm({ ...tempProduct });
      }
    } else {
      const CreateDealOfTheDayApi = await create_offers(data);
      if (CreateDealOfTheDayApi?.statuscode == 200) {
        toast.success(CreateDealOfTheDayApi?.message);
        setTimeout(() => {
          navigate("/offers");
          dispatch({ type: OFFERS, payload: undefined });
        }, 1000);
        setForm({});
      } else {
        toast.error(CreateDealOfTheDayApi?.message);
        const tempProduct = form;
        tempProduct["product_category"] = form?.tempproduct_category;
        tempProduct["product_sub_category"] = form?.tempproduct_sub_category;
        tempProduct["product"] = form?.tempproduct;
        setForm({ ...tempProduct });
      }
    }
  };

  const [formStructure, setFormStructure] = useState([
    {
      id: "1",
      type: "select",
      title: "Product Category",
      name: "product_category",
      options: [],
      required: true,
    },
    {
      id: "2",
      type: "select",
      title: "Product Sub Category",
      name: "product_sub_category",
      options: [],
      disabled: true,
      // required: true,
    },
    {
      id: "3",
      type: "select",
      title: "Select Product",
      name: "product",
      options: [],
      required: true,
    },
    {
      id: "4",
      type: "inputBox",
      title: "Product Price",
      name: "product_final_price",
      disabled: true,
    },
    {
      id: "5",
      type: "inputBox",
      variant: "date",
      title: "Offer Start Date",
      default: " ",
      min: new Date().toISOString().split("T")[0],
      name: "start_date",
      // display: "none",
      required: true,
    },
    {
      id: "6",
      type: "inputBox",
      variant: "date",
      title: "Offer End Date",
      default: " ",
      min: new Date().toISOString().split("T")[0],
      name: "end_date",
      // display: "none",
      required: true,
    },
    {
      id: "7",
      type: "select",
      title: "Select Discount Type",
      name: "offer_type",
      options: ["Fixed", "Percentage"],
      required: true,
    },
    {
      id: "8",
      type: "inputBox",
      // variant: "number",
      title: "Discount Amount",
      name: "offer_value",
      regex: /^[0-9]+$/,
      required: true,
    },
    {
      id: "9",
      type: "inputBox",
      // variant: "number",
      title: "Discount Amount",
      name: "offer_amount",
      regex: /^[0-9]+$/,
      required: true,
      disabled: true,
    },
    {
      id: "10",
      type: "toggle",
      title: "Status",
      name: "status",
      default: "Active",
      display: "none",
      size: "6",
      options: [
        { value: "Active", color: "#00B69B" },
        { value: "Inactive", color: "#EE368C" },
      ],
    },

    {
      id: "11",
      type: "button",
      title: "Submit",
    },
  ]);
  const formTitle = isEdit ? "Edit Category" : "Create Category";
  useMemo(() => {
    if (category_data?.statuscode == 200) {
      const temp = formStructure;
      temp[0]["options"] = category_data?.data?.map(
        (ele) => ele?.category_name
      );
      console.log(formStructure, category_data, "checheinf");
      setFormStructure([...temp]);
    }
  }, [category_data]);
  // useMemo(() => {
  //   if (sub_category_data?.statuscode == 200) {
  //     const temp = formStructure;
  //     const tempForm = form;
  //     // const tempFilter = tableData;
  //     // tempFilter["filterColumn"][3]["options"] = sub_category_data?.data;

  //     if (form?.product_category != undefined) {
  //       temp[1]["options"] = sub_category_data?.data
  //         ?.map(
  //           (subcategory) =>
  //             subcategory?.category_name == form?.product_category &&
  //             subcategory?.sub_category_name
  //         )
  //         .filter((e) => e);
  //       tempForm.sub_category = temp[1]["options"].includes(form?.sub_category)
  //         ? form?.sub_category
  //         : "";
  //       // tempFilter["filterColumn"][3]["options"]=subcategories?.data.map((subcategory)=>(subcategory?.category_name==form?.movie_category&&subcategory?.subcategory_name))
  //     } else {
  //       temp[1]["options"] = sub_category_data?.data?.map(
  //         (subcategory) => subcategory?.sub_category_name
  //       );
  //     }
  //     setFormStructure([...temp]);
  //     setForm({ ...tempForm });
  //     // setTableData({ ...tempFilter });
  //   }
  // }, [sub_category_data, form?.product_category]);
  useMemo(() => {
    if (product_data?.statuscode == 200) {
      const temp = formStructure;
      const tempForm = form;
      if (
        form?.product_category != undefined 
      ) {
        temp[2]["options"] = product_data?.data
          ?.map(
            (ele) =>
              ele?.category == form?.product_category &&
              // ele?.subcategory == form?.product_sub_category &&
              ele?.product_name
          )
          .filter((e) => e);
      }

      setFormStructure([...temp]);
    }
  }, [form?.product_category, form?.product_sub_category, product_data]);

  useMemo(() => {
    if (form?.product != undefined) {
      const temp = form;
      const matchingProduct = product_data?.data?.find(
        (ele) =>
          ele?.product_name === form?.product &&
          ele?.category === form?.product_category &&
          ele?.subcategory == form?.product_sub_category
      );

      // If a matching product is found, set its final price
      if (matchingProduct) {
        setForm({ ...temp, product_final_price: matchingProduct?.final_price });
      }
    }
  }, [form?.product]);

  useMemo(() => {
    if (form?.offer_value != undefined) {
      if (form?.offer_type == "Fixed") {
        setForm({
          ...form,
          offer_amount: (
            Number(form?.product_final_price) - Number(form?.offer_value)
          ).toFixed(2),
        });
      } else if (form?.offer_type == "Percentage") {
        setForm({
          ...form,
          offer_amount: (
            Number(form?.product_final_price) -
            Number((form?.product_final_price * form?.offer_value) / 100)
          ).toFixed(2),
        });
      }
    }
  }, [form?.offer_type, form?.offer_value]);
  useMemo(() => {
    if (isEdit) {
      const temp = formStructure;
      temp[0]["disabled"] = true;
      // temp[1]["disabled"] = true;
      temp[2]["disabled"] = true;
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[0]["disabled"] = false;
      // temp[1]["disabled"] = false;
      temp[2]["disabled"] = false;
      setFormStructure([...temp]);
    }
    if (form?.status == "Active") {
      const temp = formStructure;
      temp[9]["display"] = "block";
      setFormStructure([...temp]);
    } else {
      const temp = formStructure;
      temp[9]["display"] = "none";
      setFormStructure([...temp]);
    }
  }, [isEdit]);
  const getNextDay = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1); // Add 1 day to the start_date
    return date.toISOString().split("T")[0]; // Return the next day in YYYY-MM-DD format
  };
  useEffect(() => {
    if (form?.start_date) {
      // Update the end_date minimum value to be 1 day after the selected start_date
      const temp = formStructure;
      const nextDay = getNextDay(form?.start_date);

      temp[5]["min"] = nextDay;
      setFormStructure([...temp]);
    }
  }, [form?.start_date]);
  return (
    <div>
      <Breadcrumbs
        parent="Offers"
        title={isEdit ? "Edit Offer" : "Create Offer"}
        mainTitle={isEdit ? "Edit Offer" : "Create Offer"}
      />

      <Form
        handleSubmit={handleSubmit}
        formStructure={formStructure}
        formTitle={formTitle}
        key={"Form"}
        setForm={setForm}
        form={form}
        // tableData={tableData}
        // setTableData={setTableData}
        isEdit={isEdit}
        // setIsEdit={setIsEdit}
      />
    </div>
  );
};

export default CreateOffers;
