import React, { Fragment, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Box from "@mui/material/Box";
import {
  Breadcrumbs,
  Btn,
  H3,
  H4,
  H5,
  H6,
  Image,
  LI,
  P,
  UL,
} from "../../AbstractElements";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "@mui/material";
import { API_LINK, IMAGE } from "../../api";
import { Button } from "@mui/material";
import "./Project.css";
import "react-tabs/style/react-tabs.css";
import SocialWidget from "../Common/CommonWidgets/SocialWidget";
import { Typography } from "@mui/material";
import Certificate from "../../assets/images/certificate.png"
import Tex from "../../assets/images/tax.png"
import Id from "../../assets/images/police-id.png"
import { getCompletedBookingVendor, vendor_details, vendor_status_update } from "../../actions/vendor";
import DataTable from "react-data-table-component";
export default function ProjectDetails() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(location?.state);
  const message = useSelector((state) => state?.vendor?.vendor_update);
  const vendorDetails = useSelector((state) => state?.vendor?.vendor_details);
  const VendorWorkHistory = useSelector((state) => state?.vendor?.historyVendor);
  console.log(VendorWorkHistory, "forTesting")
  const [rejectMsg, setRejectMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [appDisBtn, setAppDisBtn] = useState(false);
  const [rejDisBtn, setRejDisBtn] = useState(false);
  const [isSee, setIsSee] = useState(false);
  useMemo(() => {
    if (location?.state?.id) {
      dispatch(vendor_details({ id: location.state?.id }));
      dispatch(getCompletedBookingVendor({ id: location.state?.id }));
    }
  }, [location.state?.id]);
  const vendorData = vendorDetails?.data;

  const companyCertificate = () => {
    window.open(
      API_LINK + vendorData?.company_registration_certificate,
      "_blank"
    );
  };
  const personIdProof = () => {
    window.open(API_LINK + vendorData?.contact_person_id_proof, "_blank");
  };
  const taxRegistrationCertificate = () => {
    window.open(
      API_LINK + vendorData?.tax_registration_certificate,
      "_blank"
    );
  };

  useMemo(() => {
    if (vendorData?.status === "Approved") {
      setAppDisBtn(true);
      setRejDisBtn(false);
    }
    // else if (vendorData?.status === "Rejected") {
    //   setRejDisBtn(true);
    //   setAppDisBtn(false);
    // }
    else if (vendorData?.status === "Pending") {
      setAppDisBtn(false);
      setRejDisBtn(false);
    }
    // if (vendorData?.reject_message !== null) {
    //   vendorData && setRejectMsg(vendorData?.reject_message);
    // }
  }, [vendorData]);
  const handleApprove = () => {
    // if (rights?.["Post"]?.["edit"] == "true") {
    setLoading(true);
    const data = new FormData();
    data.append("id", vendorData?.id);
    //  data.append("user_id" ,customer_id )
    data.append("status", "Approved");
    dispatch(vendor_status_update(data));
    //   } else {
    //   setContentAccess(
    //     <p style={{ color: "var(--themeFontColor)" }}>
    //       You do not have permission to Edit data
    //     </p>
    //   );
    //   setOpenAccess(true);
    // }
  };
  const handleReject = () => {
    // if (rights?.["Post"]?.["edit"] == "true") {
    setLoading1(true);
    const data = new FormData();

    data.append("id", vendorData?.id);
    //  data.append("user_id" ,customer_id )
    data.append("status", "Rejected");
    data.append("reject_reason", rejectMsg);
    dispatch(vendor_status_update(data));
    //   } else {
    //   setContentAccess(
    //     <p style={{ color: "var(--themeFontColor)" }}>
    //       You do not have permission to Edit data
    //     </p>
    //   );
    //   setOpenAccess(true);
    // }
  };
  const handleRejectMsg = () => {
    setIsSee(true);
  };

  useMemo(() => {
    if (message?.statuscode == 200) {
      dispatch(vendor_details({ id: vendorData?.id }));
      setLoading(false);
      setLoading1(false);
      setIsSee(false);
      setRejectMsg("");
    }
  }, [message]);

  const handleOpenPassport = (url) => {
    window.open(IMAGE + url, '_blank');
  }

  const productColumns = [
    // {
    //   name: "Image",
    //   selector: (row) => row.image,
    //   sortable: true,
    //   center: true,
    //   // minWidth: "100px",
    //   // maxWidth: "100px",
    // },
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
      // center: true,
      wrap: true,
    }, {
      name: "Email",
      selector: (row) => row.Email,
      sortable: true,
      // center: true,
      wrap: true,
    },
    {
      name: "Mobile",
      selector: (row) => row.Mobile,
      sortable: true,
      // center: true,
      wrap: true,
    },
    {
      name: "Nationality",
      selector: (row) => row.Nationality,
      sortable: true,
      // center: true,
      wrap: true,
    },
    {
      name: "Services",
      selector: (row) => row.Services,
      sortable: true,
      // center: true,
      wrap: true,
    },
    {
      name: "Work Permit Expiry",
      selector: (row) => row.Expiry,
      sortable: true,
      // center: true,
      wrap: true,
    },
    {
      name: "View",
      selector: (row) => row.Passport,
      sortable: true,
      // center: true,
      wrap: true,
    },
  ];

  const style = {
    width: 55,
    height: 40,
    borderRadius: 5,
  };

  const ProductList =
    vendorDetails &&
    vendorDetails?.data?.worker?.map((value, index) => ({
      image:
        value?.profile_photo ?
          (
            <Image
              attrImage={{
                src: IMAGE + value?.profile_photo,
                style: style,
                alt: "",
              }}
            />
          ) : "-",
      Name: (
        <div>
          <H6>{value?.firstname}</H6>
        </div>
      ),
      Email: value?.email,
      Mobile: value?.mobile_number,
      Nationality: value?.nationality,
      Services: (
        <div>
          <ul>
            {
              value?.work?.map((data, index) => (
                <li key={index}>-{data?.subcategory?.subcategory_name}</li>

              ))
            }
          </ul>
        </div>
      ),
      Expiry: value?.work_permit_expire_at,
      Passport: (
        <div>
          <span onClick={() => handleOpenPassport(value?.work_permit)}>
            <i style={{ fontSize: "20px", cursor: "pointer" }} className="fa fa-eye" aria-hidden="true"></i>
          </span>{" "}

        </div>
      ),

    }));



  // Vendor history
  const productColumnsVendor = [
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Contact",
      selector: (row) => row.Contact,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Service",
      selector: (row) => row.Service,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Total Workers",
      selector: (row) => row.TotalWorkers,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Location",
      selector: (row) => row.Location,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "From",
      selector: (row) => row.StartDate,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "To",
      selector: (row) => row.EndDate,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Status",
      selector: (row) => row.Action,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },

  ];

  const ProductListVendor =
    VendorWorkHistory &&
    VendorWorkHistory?.data?.map((value, index) => ({
      Name: (
        <div>
          <H6>{value?.booking_request?.name}</H6>
        </div>
      ),
      Contact: (
        <div>
          <p>{value?.booking_request?.email}</p>
          <p>{value?.booking_request?.contact}</p>
        </div>
      ),
      Service: (
        <div>
          <p>{value?.booking_request?.category?.category_name}</p>
          <p>({value?.booking_request?.subcategory?.subcategory_name})</p>
        </div>
      ),
      TotalWorkers: (
        <div>
          <p>{value?.worker?.length} Workers</p>
        </div>
      ),
      Location: (
        <div>
          {/* <p>{value?.booking_request?.address}</p>
          <p>({value?.booking_request?.city}-{value?.booking_request?.pincode})</p> */}
          <p>{value?.booking_request?.house_no} - {value?.booking_request?.residence_name}</p>
          <p>{value?.booking_request?.area_or_street},{value?.booking_request?.city?.city_name}</p>
        </div>
      ),
      StartDate: (
        <div>
          <p>{value?.booking_request?.from_date}</p>
        </div>
      ),
      EndDate: (
        <div>
          <p>{value?.booking_request?.to_date}</p>
        </div>
      ),
      Action: (
        <div style={{ background: "#308b26", padding: "8px", color: "#ffffff", borderRadius: "5px" }}>
          Completed
        </div>
      ),
      Workers: value?.worker

    }));


  const ExpandedComponent = ({ data }) =>

  (
    <div style={{ width: "100%", border: "1px solid grey" }}>
      <Table striped >

        <thead className='expand-table'>
          <tr className="table-secondary" style={{ fontWeight: "900" }}>
            <th>
              #
            </th>
            <th style={{ fontWeight: "900" }}>
              Name
            </th>
            <th style={{ fontWeight: "900" }}>
              Email
            </th>
            <th style={{ fontWeight: "900" }}>
              Mobile
            </th>
            <th style={{ fontWeight: "900" }}>
              Nationality
            </th>
            <th style={{ fontWeight: "900" }}>
              Work Permit Expiry
            </th>
          </tr>
        </thead>
        <tbody>
          {
            data?.Workers?.map((data, index) => (
              <tr>
                <th scope="row">
                  {index + 1}
                </th>
                <td>
                  {data?.worker?.firstname} {data?.worker.lastname}
                </td>
                <td>
                  {data?.worker?.email}
                </td>
                <td>
                  {data?.worker?.mobile_number}
                </td>

                <td>
                  {data?.worker?.nationality}
                </td>

                <td>
                  {data?.worker?.work_permit_expire_at}
                </td>
              </tr>
            ))
          }


        </tbody>
      </Table>

      {/* Add more details as needed */}
    </div>
  );


  return (
    <div>
      <div>
        <Breadcrumbs
          parent="Vendor Details"
          title={vendorData?.contact_person_name}
          mainTitle="Vendor Information"
        />
        <Fragment>

          <Row>


            <Col xl="6" className="set-col-12 box-col-12">
              <Card>
                <div className="blog-box blog-list row p-20">
                  <Col sm="12">
                    <H5>
                      <span>Personal Details</span>
                    </H5>
                    <hr style={{ opacity: "1" }} />
                    <Row>
                      <Col sm="8" style={{ alignContent: "center" }}>
                        {/* <div className="blog-date digits">From <span>{startday}</span> {`${monthNames[startMonthIndex] + " " + startYear}`} to <span>{endday}</span> {`${monthNames[endMonthIndex] + " " + endYear}`}</div> */}
                        {/* <div className='mt-3'>Service : <b>{location?.state?.service?.service_name}</b></div> */}
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                            Company Name:
                          </span>
                          {vendorData?.company_name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                            Owner Name:
                          </span>
                          {vendorData?.contact_person_name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                            Contact Number:
                          </span>
                          {vendorData?.contact_person_phone_number}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                            Email:
                          </span>
                          {vendorData?.email}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                            Address:
                          </span>
                          {vendorData?.address1 + " , " + vendorData?.address2}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                            City:
                          </span>
                          {vendorData?.city?.city_name + " , " + vendorData?.pincode}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                            Services:
                          </span>
                          <ul>
                            {
                              vendorData?.category?.map((data, index) => (
                                <li key={index}>
                                  {data?.category_name}
                                </li>
                              ))
                            }


                          </ul>
                        </Typography>

                      </Col>
                      {/* <Col sm="4">
                        <SocialWidget data={item} />
                      </Col> */}
                    </Row>
                    <div>
                      <ul>
                        {vendorData?.service?.map((data, index) => (
                          <div style={{ display: "flex" }}>
                            <span>- </span>
                            <li key={index}>{data?.service_name}</li>
                          </div>
                        ))}
                        <li></li>
                      </ul>
                    </div>
                  </Col>
                </div>
              </Card>
            </Col>
            <Col xl="6" className="set-col-12 box-col-12">
              <Card>
                <div className="blog-box blog-list row p-20">
                  <Col sm="12">
                    <H5>
                      <span>Certificates & Documents</span>
                    </H5>
                    <hr style={{ opacity: "1" }} />

                    <Row style={{ display: "flex", justifyContent: "center" }}>
                      <Col sm="12">
                        <Btn
                          attrBtn={{
                            color: "",
                            className: "d-block w-100 mt-3 btn-class",
                            onClick: companyCertificate,
                            style: {
                              padding: "10px",

                            }
                          }}
                        >
                          <img src={Certificate} height="90px"/> <br />
                          <span className="span-tag-details">Company Registration Certificate</span>
                        </Btn>
                      </Col>
                      <Col sm="6">
                        <Btn
                          attrBtn={{
                            color: "",
                            className: "d-block w-100 mt-3 btn-class",
                            onClick: personIdProof,
                          }}
                        >
                            <img src={Id} height="90px"/><br />
                          <span className="span-tag-details">
                            Contact Person Id Proof
                          </span>
                        </Btn>
                      </Col>
                      <Col sm="6">
                        <Btn
                          attrBtn={{
                            color: "",
                            className: "d-block w-100 mt-3 btn-class",
                            onClick: taxRegistrationCertificate,
                          }}
                        >
                           <img src={Tex} height="90px"/><br />
                          <span className="span-tag-details">Tax Registration Certificate</span>
                        </Btn>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </Card>
            </Col>
          </Row>
        </Fragment>
      </div>

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>

              <CardBody>
                <h5 className="mb-3">Total Workers</h5>
                <DataTable
                  noHeader
                  pagination
                  paginationServer
                  columns={productColumns}
                  data={ProductList}
                  highlightOnHover={true}
                  striped={true}
                  responsive={true}
                  className="custom-data-table"

                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>

              <CardBody>
                <h5 className="mb-3">Work History</h5>
                <DataTable
                  noHeader
                  pagination
                  paginationServer
                  columns={productColumnsVendor}
                  data={ProductListVendor}
                  highlightOnHover={true}
                  striped={true}
                  responsive={true}
                  expandableRows
                  expandableRowsComponent={ExpandedComponent}
                  className="custom-data-table"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center">
        <>
          <LoadingButton
            disabled={appDisBtn || loading}
            loading={loading}
            //
            style={{
              background:
                "linear-gradient(225deg,  #ac1600 0%, #500303 91.25%)",
              // display: vendorData?.status === "Rejected" ? "none" : "",
            }}
            sx={{
              textTransform: "capitalize",
              borderRadius: "10px",
              m: "10px",
              p: "10px 30px",
              fontSize: "14px",
              color: "#fff !important",
            }}
            variant="contained"
            className="mr-10px"
            onClick={handleApprove}
          >
            {/* {data?.data?.status === "Approved" ? "Approved" : "Approve"} */}
            {loading ? (
              <span style={{ paddingRight: "1rem" }}>Sending...</span>
            ) : vendorData?.status === "Approved" ? (
              "Approved"
            ) : (
              "Approve"
            )}
          </LoadingButton>
          <Button
            disabled={rejDisBtn || loading}
            style={{
              background:
                "linear-gradient(225deg,  #ac1600 0%, #500303 91.25%)",
              display: vendorData?.status === "Approved" ? "none" : "",
            }}
            sx={{
              textTransform: "capitalize",
              borderRadius: "10px",
              m: "10px",
              p: "10px 30px",
              fontSize: "14px",
              color: "#fff !important",
            }}
            variant="contained"
            className="mr-10px"
            // onClick={handleReject}
            onClick={handleRejectMsg}
          >
            {/* {vendorData?.status === "Rejected" ? "Rejected" : "Reject"} */}
            Reject
          </Button>
        </>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        key={"-grid"}
        lg={12}
        // display={isSee || vendorData?.status === "Rejected" ? "" : "none"}
        display={isSee ? "" : "none"}
        sx={{ mt: "1rem" }}
        textAlign="center"
      >
        <TextField
          autoComplete="Reject Reason"
          name="RejectMsg"
          fullWidth
          id="1"
          type="text"
          // disabled={vendorData?.reject_message !== null}
          required="true"
          //   value={rejectMsg}
          label="Reject Reason"
          onChange={(event) => {
            setRejectMsg(event.target.value);
          }}
          InputProps={{
            inputProps: {
              maxLength: 250,
            },
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "5px",
          }}
        >
          <p style={{ color: "#fff" }}>
            {rejectMsg?.length}/{250}
            {rejectMsg?.length >= 250 && (
              <span style={{ color: "red" }}>Maximum limit reached</span>
            )}
          </p>
        </div>
        <Grid
          item
          xs={12}
          md={12}
          key={"-grid"}
          lg={12}
          display={isSee ? "" : "none"}
          sx={{ mt: "1rem" }}
          textAlign="center"
        >
          <LoadingButton
            disabled={isSee ? "" : "none"}
            display={isSee ? "" : "none"}
            loading={loading1}
            style={{
              background:
                "linear-gradient(225deg,  #ac1600 0%, #500303 91.25%)",
            }}
            sx={{
              textTransform: "capitalize",
              borderRadius: "10px",
              mt: "10px",
              p: "10px 30px",
              fontSize: "14px",
              color: "#fff !important",
            }}
            variant="contained"
            className="mr-10px"
            onClick={handleReject}
          >
            {/* {producerformdata?.status !== "Pending" ? "Approved" : "Approve"} */}
            {loading1 ? (
              <span style={{ paddingRight: "1rem" }}>Sending...</span>
            ) : (
              <span>Submit</span>
            )}
          </LoadingButton>
        </Grid>
      </Grid>



    </div>
  );
}
