import axios from 'axios';
const api = `${process.env.PUBLIC_URL}/api`;
console.log("api", api)

// const baseUrl = "http://122.170.109.73:9001/"
// const API = axios.create({ baseURL: 'http://122.170.109.73:7574/api/' });
// export const API_LINK = 'http://122.170.109.73:7574/'
const API = axios.create({ baseURL: 'https://api.osmagrimart.com/api/' });
export const API_LINK = 'https://api.osmagrimart.com/'
// const API = axios.create({ baseURL: 'http://122.170.109.73:7625/api/' });
// export const API_LINK = 'http://122.170.109.73:7625/'


// export const IMAGE = 'http://122.170.109.73:7625/'
export const IMAGE = 'https://api.osmagrimart.com/'
export const TaskApi = `${api}/task.json`;
export const BookmarkApi = `${api}/bookmark.json`;
export const ChartistApi = `${api}/chartistData.json`;
export const ChartjsApi = `${api}/chartjsData.json`;
export const ChatMemberApi = `${api}/chatMember.json`;
export const ChatApi = `${api}/chat.chats.json`;
export const ContactApi = `${api}/contacts.json`;
export const ProductsApi = `${api}/product.json`;
export const EmailApi = `${api}/email.json`;
export const ImageLightApi = `${api}/image-light.json`;
export const BigImageApi = `${api}/image-big-light.json`;
export const MasonryApi = `${api}/masonry.json`;
export const GooglechartApi = `${api}/googleChartData.json`;
export const AllProjectApi = `${api}/allProject.json`;
export const SearchAllTabsApi = `${api}/searchAllTabs.json`;
export const TableDataApi = `${api}/tableData.json`;
export const HoverableRowsTable = `${api}/hoverableRowsTable.json`;
export const TodoApi = `${api}/todo.json`;
export const JobSearchApi = `${api}/jobSearch.json`;
export const LearningApi = `${api}/learning.json`;
export const KnowledgebaseApi = `${api}/knowledgebaseDB.json`;
export const FaqApi = `${api}/Faq.json`;
export const FileApi = `${api}/files.json`;
export const UserCardApi = `${api}/usercard.json`;
export const UserEditTableAPI = `${api}/user-edit-table.json`;
export const StickyApi = `${api}/sticky.json`;
export const TypeaheadDataApi = `${api}/typeaheadData.json`;
export const FeatherDataApi = `${api}/featherData.json`;
export const FlagIconDataApi = `${api}/flagIconData.json`;
export const FontawsomeDataApi = `${api}/fontawsomeData.json`;
export const IcoDataApi = `${api}/icoData.json`;
export const ThemifyDataApi = `${api}/themifyData.json`;

export const category_create = (formData) => API.post('/create_category/', formData);
export const all_category_list = (formData) => API.post('/all_category_list/', formData);
export const category_update = (formData) => API.post('/update_category/', formData);
export const category_delete = (formData) => API.post('/delete_category/', formData);

export const subcategory_create = (formData) => API.post('/create_sub_category/', formData);
export const all_subcategory_list = (formData) => API.post('/all_sub_category_list/', formData);
export const subcategory_update = (formData) => API.post('/update_sub_category/', formData);
export const subcategory_delete = (formData) => API.post('/delete_sub_category/', formData);

export const create_gst_rate = (formData) => API.post('/create_gst_rate/', formData);
export const update_gst_rate = (formData) => API.post('/update_gst_rate/', formData);
export const delete_gst_rate = (formData) => API.post('/delete_gst_rate/', formData);
export const all_gst_rate_list = (formData) => API.post('/all_gst_rate_list/', formData);

export const create_unit = (formData) => API.post('/create_unit/', formData);
export const update_unit = (formData) => API.post('/update_unit/', formData);
export const delete_unit = (formData) => API.post('/delete_unit/', formData);
export const all_unit_list = (formData) => API.post('/all_unit_list/', formData);

export const tags_create = (formData) => API.post('/tags_create/', formData);
export const tag_list = (formData) => API.post('/tag_list/', formData);
export const tags_update = (formData) => API.post('/tags_update/', formData);
export const tags_delete = (formData) => API.post('/tags_delete/', formData);

export const level_list = (formData) => API.post('/level_list/', formData)

// Post production software
export const company_login = (formData) => API.post('/admin_login/', formData)
export const change_password = (formData) => API.post('/change_password_master/', formData)
export const update_password = (formData) => API.post('/update_password/', formData)

export const send_otp = (formData) => API.post('/forgot_password_send_otp/', formData)
export const verify_otp = (formData) => API.post('/forgot_password_verify_otp/', formData)

//Vendor 

export const vendor_register = (formData) => API.post("/register_vendor/",formData)
export const all_vendor_list = (formData) => API.post("/all_vendor_list/",formData)
export const vendor_update = (formData) => API.post("/update_vendor/",formData)
export const delete_vendor = (formData) => API.post("/delete_vendor/",formData)
export const vendor_status_update = (formData) => API.post("/vendor_status_update/",formData)
export const vendor_details = (formData) => API.post("/vendor_details/",formData)
export const vendor_booking_request_details = (formData) => API.post("/vendor_booking_request_details/",formData)
export const worker_assign_to_booking_request = (formData) => API.post("/worker_assign_to_booking_request/",formData)
export const vendor_booking_request_today_list = (formData) => API.post("/vendor_booking_request_today_list/",formData)
export const vendor_booking_request_history_list = (formData) => API.post("/vendor_booking_request_history_list/",formData)

//Slider Banner 
export const slider_banner_create = (formData) => API.post("/create_advertisement/" , formData)
export const slider_banner_update = (formData) => API.post("/update_advertisement/" , formData)
export const slider_banner_delete = (formData) => API.post("/delete_advertisement/" , formData)
export const all_slider_banner_list = (formData) => API.post("/all_advertisement_list/" , formData)
export const all_expired_slider_banner_list = (formData) => API.post("/all_expired_advertisement_list/" , formData)

//Language

export const language_create = (formData) => API.post("/language_create/" , formData)
export const language_update = (formData) => API.post("/language_update/" , formData)
export const language_delete = (formData) => API.post("/language_delete/" , formData)
export const all_language_list = (formData) => API.post("/all_language_list/" , formData)

//Worker
export const worker_register = (formData) => API.post("/worker_register/" , formData)
export const worker_update = (formData) => API.post("/worker_update/" , formData)
export const worker_list = (formData) => API.post("/worker_list/" , formData)
export const worker_delete = (formData) => API.post("/worker_delete/" , formData)
export const worker_details = (formData) => API.post("/worker_details/" , formData)

// Work Request 
export const booking_request_list = (formData) => API.post("/booking_request_list/" , formData)
export const booking_request_details = (formData) => API.post("/booking_request_details/" , formData)

export const vendor_booking_request_assign_list = (formData) => API.post("/vendor_booking_request_assign_list/" ,formData)
export const vendor_list_for_booking_request = (formData) => API.post("/vendor_list_for_booking_request/" ,formData)
export const vendor_assign_to_booking_request = (formData) => API.post("/vendor_assign_to_booking_request/" ,formData)
export const booking_request_assigned_list = (formData) => API.post("/booking_request_assigned_list/" ,formData)
export const admin_booking_request_today_list = (formData) => API.post("/admin_booking_request_today_list/" ,formData)
export const admin_booking_request_history_list = (formData) => API.post("/admin_booking_request_history_list/" ,formData)
export const pending_booking_request_admin = (formData) => API.post("/pending_booking_request_list_admin/",formData)
export const pending_booking_request_details_admin = (formData) => API.post("/pending_booking_request_details_admin/",formData)
export const vendor_delete_booking_request = (formData) => API.post("/vendor_delete_booking_request/",formData)
export const assign_booking_request = (formData) => API.post("/assign_booking_request/",formData)
export const vendor_assign_worker_booking_request_list = (formData) => API.post("/vendor_assign_worker_booking_request_list/",formData)
export const confirmed_booking_request_list = (formData) => API.post("/confirmed_booking_request_list/" ,formData)
export const rejected_booking_request_list = (formData) => API.post("/rejected_booking_request_list/" ,formData)

// Employee login
export const vendor_login = (formData) => API.post('/vendor_login/', formData)

// Company Services
export const company_service_create = (formData) => API.post("/company_service_create/", formData)
export const company_services_list = (formData) => API.post("/company_services_list/", formData)

export const company_user_create = (formData) => API.post("/company_user_create/", formData)
export const company_user_list = (formData) => API.post("/company_user_list/", formData)
export const company_user_update = (formData) => API.post("/company_user_update/", formData)

export const client_create = (formData) => API.post("/client_create/", formData)
export const all_client_list = (formData) => API.post("/all_client_list/", formData)

export const project_create = (formData) => API.post("/project_create/", formData)
export const company_project_list = (formData) => API.post("/company_project_list/", formData)
export const company_project_details = (formData) => API.post("/company_project_details/", formData)

export const project_work_assign_create = (formData) => API.post("/project_work_assign_create/", formData)
export const project_work_assign_update = (formData) => API.post("/project_work_assign_update/", formData)


// Employee actions
export const user_project_list = (formData) => API.post("/user_project_list/", formData)
export const manager_project_list = (formData) => API.post("/manager_project_list/", formData)

// Notification actions
export const worker_passport_expiry_soon_list_admin = (formData) => API.post("/worker_passport_expiry_soon_list_admin/", formData)
export const worker_passport_expiry_soon_list_vendor = (formData) => API.post("/worker_passport_expiry_soon_list_vendor/", formData)

// Dashboard
export const total_user = (formData) => API.post("/total_user/" ,formData)
export const category_list_dashboard_admin = (formData) => API.post("/category_list_dashboard_admin/" ,formData)
export const recent_vendor_list = (formData) => API.post("/recent_vendor_list/" ,formData)
export const total_booking_count = (formData) => API.post("/total_booking_count/" ,formData)
export const total_booking_count_vendor = (formData) => API.post("/total_booking_count_vendor/" ,formData)
export const worker_count_category_wise_dashboard_admin = (formData) => API.post("/worker_count_category_wise_dashboard_admin/" ,formData)
export const worker_count_category_wise_dashboard_vendor = (formData) => API.post("/worker_count_category_wise_dashboard_vendor/" ,formData)
export const daily_booking_count_dashboard = (formData) => API.post("/daily_booking_count_dashboard/" ,formData)
export const daily_booking_count_dashboard_vendor = (formData) => API.post("/daily_booking_count_dashboard_vendor/" ,formData)
export const count_on_dashboard = (formData) => API.post("/count_on_dashboard/",formData)
export const dashboard_count = (formData) => API.post("/dashboard_count/",formData)
export const dashboard_graph_category_wise_recent_orders = (formData) => API.post("/dashboard_graph_category_wise_recent_orders/",formData)
export const dashboard_graph_monthly_revenue = (formData) => API.post("/dashboard_graph_monthly_revenue/",formData)

// Dashboard 
export const dashboard_graph_valuable_user = (formData) => API.post("/dashboard_graph_valuable_user/" , formData)
export const dashboard_graph_admin_vendor_balance = (formData) => API.post("/dashboard_graph_admin_vendor_balance/" , formData)
export const dashboard_graph_visitors = (formData) => API.post("/dashboard_graph_visitors/" , formData)
export const dashboard_graph_order_overview = (formData) => API.post("/dashboard_graph_order_overview/" , formData)
export const dashboard_graph_category_wise_sales = (formData) => API.post("/dashboard_graph_category_wise_sales/" , formData)

// Customer 
export const customer_list_admin = (formData) => API.post("/all_user_list/" ,formData)
export const customer_detail_admin = (formData) => API.post("/user_details/" ,formData)
export const customer_name_list = (formData) => API.post("/user_name_list/" ,formData)

//Country
export const country_create = (formData) => API.post("/country_create/" ,formData)
export const country_update = (formData) => API.post("/country_update/" ,formData)
export const all_country_list = (formData) => API.post("/all_country_list/" ,formData)

//City
export const city_create = (formData) => API.post("/city_create/" ,formData)
export const city_update = (formData) => API.post("/city_update/" ,formData)
export const all_city_list = (formData) => API.post("/all_city_list/" ,formData)

// Product
export const create_product = (formData) => API.post("/create_product/",formData)
export const update_product = (formData) => API.post("/update_product/",formData)
export const delete_product = (formData) => API.post("/delete_product/",formData)
export const all_product_list = (formData) => API.post("/all_product_list/",formData)
export const all_product_vendor_list = (formData) => API.post("/all_product_vendor_list/",formData)
export const product_details = (formData) => API.post("/product_details/",formData)
export const product_list_special_offer = (formData) => API.post("/product_list_special_offer/",formData)

// Offers
export const create_last_min_deal = (formData) => API.post("/create_last_min_deal/" ,formData)
export const update_last_min_deal = (formData) => API.post("/update_last_min_deal/" ,formData)
export const delete_last_min_deal = (formData) => API.post("/delete_last_min_deal/" ,formData)
export const all_last_min_deal_list = (formData) => API.post("/all_last_min_deal_list/" ,formData)
export const admin_vendor_wise_last_min_deal_list = (formData) => API.post("/admin_vendor_wise_last_min_deal_list/" ,formData)
export const admin_vendor_wise_expire_last_min_deal_list = (formData) => API.post("/admin_vendor_wise_expire_last_min_deal_list/" ,formData)
export const all_expired_last_min_deal_list = (formData) => API.post("/all_expired_last_min_deal_list/" ,formData)

export const create_deal_of_the_day = (formData) => API.post("/create_deal_of_the_day/" ,formData)
export const update_deal_of_the_day = (formData) => API.post("/update_deal_of_the_day/" ,formData)
export const delete_deal_of_the_day = (formData) => API.post("/delete_deal_of_the_day/" ,formData)
export const all_deal_of_the_day_list = (formData) => API.post("/all_deal_of_the_day_list/" ,formData)
export const admin_vendor_wise_deal_of_the_day_list = (formData) => API.post("/admin_vendor_wise_deal_of_the_day_list/" ,formData)
export const all_expired_deal_of_the_day_list = (formData) => API.post("/all_expired_deal_of_the_day_list/" ,formData)
export const admin_vendor_wise_expire_deal_of_the_day_list = (formData) => API.post("/admin_vendor_wise_expire_deal_of_the_day_list/" ,formData)

export const create_offers = (formData) => API.post("/create_offers/" ,formData)
export const update_offers = (formData) => API.post("/update_offers/" ,formData)
export const delete_offers = (formData) => API.post("/delete_offers/" ,formData)
export const all_offers_list = (formData) => API.post("/all_offers_list/" ,formData)
export const all_expired_offers_list = (formData) => API.post("/all_expired_offers_list/" ,formData)
export const admin_vendor_wise_offers_list = (formData) => API.post("/admin_vendor_wise_offers_list/" ,formData)
export const admin_vendor_wise_expire_offers_list = (formData) => API.post("/admin_vendor_wise_expire_offers_list/" ,formData)

export const promocode_create = (formData) => API.post("/promocode_create/" , formData)
export const promocode_update = (formData) => API.post("/promocode_update/" , formData)
export const promocode_delete = (formData) => API.post("/promocode_delete/" , formData)
export const all_promocode_list = (formData) => API.post("/all_promocode_list/" , formData)
export const all_expired_promocode_list = (formData) => API.post("/all_expired_promocode_list/" , formData)
export const all_promocode_history = (formData) => API.post("/all_promocode_history/" , formData)

//Orders
export const order_list = (formData) => API.post("/order_list/" ,formData)
export const order_detail = (formData) => API.post("/order_detail/" ,formData)
export const order_status_update = (formData) => API.post("/order_status_update/" ,formData)
export const vendor_order_list = (formData) => API.post("/vendor_order_list/" ,formData)

//Transaction
export const all_successfull_transaction = (formData) => API.post("/all_successfull_transaction/" ,formData)
export const user_transaction_history_list = (formData) => API.post("/user_transaction_history_list/" ,formData)

//Notification
export const notification_create = (formData) => API.post('/notification_create/',formData)
export const notification_delete = (formData) => API.post('/notification_delete/',formData)
export const all_notification_list = (formData) => API.post('/all_notification_list/',formData)
export const notification_create_mask = (formData) => API.post('/notification_create_mask/',formData)


//Setting-> About us
export const about_us = (formData) => API.post('/about_us/', formData);
export const about_us_update = (formData) => API.post('/about_us_update/', formData);

//Setting-> Refund Policy
export const refund_policy = (formData) => API.post('/refund_policy/', formData);
export const refund_policy_update = (formData) => API.post('/refund_policy_update/', formData);

//Setting-> Privacy Policy
export const privacy_policy = (formData) => API.post('/privacy_policy/', formData);
export const privacy_policy_update = (formData) => API.post('/privacy_policy_update/', formData);

//Setting-> Terms And Conditions
export const terms_and_conditions = (formData) => API.post('/terms_and_conditions/', formData);
export const terms_and_conditions_update = (formData) => API.post('/terms_and_conditions_update/', formData);
