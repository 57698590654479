import {  COUNT_ON_DB, EARNING_BY_CATEGORY, MONTHLY_ORDER, ORDER_OVERVIEW, RECENT_ORDER_BY_CATEGORY, TOTAL_USER, VALUABLE_USER } from '../Constant';
import * as api from '../api/index';

export const dashboard_graph_valuable_user  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.dashboard_graph_valuable_user(formData);
      dispatch({ type: VALUABLE_USER, payload:data })
    } catch (error) {
      console.log(error);
    }
  };
export const dashboard_graph_admin_vendor_balance  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.dashboard_graph_admin_vendor_balance(formData);
      dispatch({ type: COUNT_ON_DB, payload:data })
    } catch (error) {
      console.log(error);
    }
  };
export const dashboard_graph_visitors  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.dashboard_graph_visitors(formData);
      dispatch({ type: TOTAL_USER, payload:data })
    } catch (error) {
      console.log(error);
    }
  };
export const dashboard_graph_order_overview  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.dashboard_graph_order_overview(formData);
      dispatch({ type: ORDER_OVERVIEW, payload:data })
    } catch (error) {
      console.log(error);
    }
  };
export const dashboard_graph_category_wise_sales  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.dashboard_graph_category_wise_sales(formData);
      dispatch({ type: EARNING_BY_CATEGORY, payload:data })
    } catch (error) {
      console.log(error);
    }
  };
export const dashboard_graph_category_wise_recent_orders  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.dashboard_graph_category_wise_recent_orders(formData);
      dispatch({ type: RECENT_ORDER_BY_CATEGORY, payload:data })
      return data
    } catch (error) {
      console.log(error);
    }
  };
export const dashboard_graph_monthly_revenue  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.dashboard_graph_monthly_revenue(formData);
      dispatch({ type: MONTHLY_ORDER, payload:data })
      return data
    } catch (error) {
      console.log(error);
    }
  };
