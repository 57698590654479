import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import * as Action from "../../actions/unit";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { IMAGE } from "../../api";
import { CREATECATEGORY, UNIT } from "../../Constant";
import { Breadcrumbs, H5 } from "../../AbstractElements";
import Form from "../../utils/Form"
const CreateUnit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "newLOCS");
  const isEdit = location?.state?.isEdit;
  const createCategory = useSelector((state) => state);

  const { create_unit,update_unit} = bindActionCreators(
    Action,
    dispatch
  );


  const [file, setFile] = useState(null);
  const [form ,setForm] = useState({})

  useEffect(() => {
    if (location?.state?.form) {
      setForm((location?.state?.form && JSON.parse(location?.state?.form)))
      // const data = location?.state?.data;
      // setForm(data)
    }
  }, [location?.state?.form]);

  



  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    formStructure.map((element) => {
			if (element.type == "image" && form?.[element.name] && typeof (form?.[element.name]) != "string") {
				const temp = form;
				temp["temp" + element.name] = form?.[element.name];
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});
  
      if (isEdit) {
        const UpdateUnitApi = await update_unit(data);
        if (UpdateUnitApi?.statuscode == 200) {
          toast.success(UpdateUnitApi?.message);
          setTimeout(() => {
            navigate("/Unit");
            dispatch({ type: UNIT, payload: undefined });
          }, 1000);
          setForm({});
        } else {
          toast.error(UpdateUnitApi?.message);
        }
      }
       else {
        const createUnitApi = await create_unit(data);
        if (createUnitApi?.statuscode == 200) {
          toast.success(createUnitApi?.message);
          setTimeout(() => {
            navigate("/Unit");
            dispatch({ type: UNIT, payload: undefined });
          }, 1000);
          setForm({});
        } else {
          toast.error(createUnitApi?.message);
        }
      }
    } 


 const formStructure=[
    {
        id:"1",
        type:"inputBox",
        maxLength:30,
        title:"Unit Name",
        name:"unit_name",
        regex : /^[a-zA-Z\s\&]+$/,
        isCaps:true,
        required:true
    },
    {
        id:"1",
        type:"inputBox",
        maxLength:10,
        title:"Unit Symbol",
        name:"unit_symbol",
        // regex : /^[a-zA-Z\s\&]+$/,
        // isCaps:true,
        required:true
    },
 
    {
      id: "3",
      type: "toggle",
      title: "Status",
      name: "status",
      default: "Active",
      display: isEdit? "block" : "none",
      size: "6",
      options: [{ value: "Active", color: "#00B69B" }, { value: "Inactive", color: "#EE368C" }],
  }
    ,
    {
        id:"5",
        type:"button",
        title:"Submit",
        
    }
]
const formTitle = isEdit ? "Edit Unit" : "Create Unit";
  return (
    <div>
       <Breadcrumbs
        parent="Unit"
        title={ isEdit ? "Edit Unit":"Create Unit"}
        mainTitle={isEdit ? "Edit Unit":"Create Unit"}
      />
  
              <Form
					handleSubmit={handleSubmit}
					formStructure={formStructure}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					// tableData={tableData}
					// setTableData={setTableData}
					isEdit={isEdit}
					// setIsEdit={setIsEdit}
				/>
            
    </div>
  );
};

export default CreateUnit;
