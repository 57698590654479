// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import ListTable from "../../../utils/Table";
import { all_category_list, category_delete } from "../../../actions/category";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { CREATECATEGORY, VENDOR_DEAL_OF_THE_DAY_LIST } from "../../../Constant";
import { admin_vendor_wise_deal_of_the_day_list, admin_vendor_wise_expire_deal_of_the_day_list, all_deal_of_the_day_list, delete_deal_of_the_day } from "../../../actions/offeres/deal_of_the_day";

const ExpiredDealOfDay = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const DealOfTheDay = useSelector((state)=>state?.offers?.vendor_admin_expired_deal_of_the_day_list)
  const message = useSelector((state)=>state?.offers?.deal_of_the_day)
  const login_details = JSON.parse(sessionStorage.getItem("user"))
  const role = login_details?.id?.userrole?.rolename

  console.log(DealOfTheDay)
  useMemo(()=>{
    if(login_details){
      dispatch(admin_vendor_wise_expire_deal_of_the_day_list({user_id : login_details?.id?.id , user_type : role}))
    }
  },[])
  const tempTableData = {
    tableTitle: "All My Expired Deals",
    // deleteRecord: delete_deal_of_the_day,
    disableDelete : true,
    // updateRecord: subcategory_update,
    // deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    // onDeleteText: "Are you sure want to delete?",
    onUpdateText:
      "Videos are associated with this category, are you still want to change it.?",
    tableHead: [
      {
        id:"product",
        label:"Product"
      },
      {
        id: "product_sub_category",
        label: "Category Name",
        subText : "product_category"
      },
      {
        id: "start_date",
        label: "Deal Start",
      },
      {
        id: "category_image",
        label: "Category Image",
        isImage : true
      },
      {
        id :"product_final_price",
        label :"Deal Before Price"
      },
      {
        id :"deal_of_the_day_type",
        label :"Deal Type"
      },
      {
        id :"deal_of_the_day_final_amount",
        label :"Deal Price"
      },
      // {
      //   id: "status",
      //   label: "Status",
      //   isButtonDisplay: true,
      // },
      // {
      //   id: "edit",
      //   label: "Update",
      //   edit_path :"/update_deal_of_the_day",
      //   // id : "id",
      //   // handleEdit : (value) => {navigate("/Update-Category" , {state:{id:value?.id}})}
      //   // access: rights?.["Masters"]?.["edit"] == "true",
      // },
    ],
    tableBody: [
      {
        category :"1",
        set_sequence :"1",
        subcategory_name :"78",
        status : "Active",
      },
      {
        category :"1",
        set_sequence :"1",
        subcategory_name :"79",
        status : "Active",
      }
    ],
    filterColumn: [],
  };
  const [tableData, setTableData] = useState({...tempTableData})

  useMemo(()=>{
    if(DealOfTheDay?.statuscode == 200){
      const temp = tableData
      temp.tableBody = DealOfTheDay?.data?.map((ele)=>({
        ...ele,
        product : ele?.product?.product_name,
        product_category: ele?.product_category?.category_name,
        product_sub_category : ele?.product_sub_category?.sub_category_name
      }))
      setTableData({...temp})
    }
  },[DealOfTheDay])
  useMemo(()=>{
    if(message?.statuscode == 200){
      toast.success(message?.message);
      if(login_details){
        dispatch(admin_vendor_wise_deal_of_the_day_list({user_id : login_details?.id?.id , user_type : role}))
      }
      dispatch({ type: VENDOR_DEAL_OF_THE_DAY_LIST, payload: undefined })
    }
  },[message])
  return (
    <div >
      <Breadcrumbs
        parent="Deal Of The Day"
        title="My Expired All Deals List"
        mainTitle="My Expired All Deals List"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
              <ListTable
              tableData={tableData}
              key={"ListTable"}
              // setForm={setForm}
              setTableData={setTableData}
              // setIsEdit={setIsEdit}
              />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ExpiredDealOfDay;
