import vendor from "../../assets/images/dashboard_img/vendors.png"
import booking from "../../assets/images/dashboard_img/booking.png"
import ads from "../../assets/images/dashboard_img/ads.png"
import customer from "../../assets/images/dashboard_img/customer.png"
import Category from "../../assets/images/dashboard_img/Category.png"
import SubCategory from "../../assets/images/dashboard_img/Sub Category.png"
import language from "../../assets/images/dashboard_img/Language.png"
import dashboard from "../../assets/images/dashboard_img/dashboard.png"
import city from "../../assets/images/dashboard_img/City.png"
import AboutUs from "../../assets/images/dashboard_img/office-building.png"
import Worker from "../../assets/images/dashboard_img/workers.png"
import PrivacyPolicy from "../../assets/images/dashboard_img/privacy-policy.png"
import TermsConditions from "../../assets/images/dashboard_img/terms.png"
import Deal from "../../assets/images/deal.png"
import Time from "../../assets/images/time.png"
import Offers from "../../assets/images/special-tag.png"
import orders from "../../assets/images/orders.png"
import Transactions  from "../../assets/images/Transactions.png"
import Coupen  from "../../assets/images/coupen.png"

const userData = JSON.parse(sessionStorage.getItem('user'))
const role = userData?.id?.userrole?.rolename
console.log("Login123456" , userData , role  )
export const MENUITEMS = [
  {
    // menutitle: 'Manage Haloul',
    menucontent: 'Dashboards,Widgets',
    Items: [
    
      {
        title: 'Dashboard',
        icon: 'fa fa-tachometer',
        type: 'link',
        path: `/dashboard`,
        menuFor: 'both',
        img : dashboard
      },
      {
        title: 'Product',
        icon: 'fa fa-pie-chart',
        type: 'sub',
        menuFor: 'both',
        img : Worker,
        children: [
         role == "Admin" && {
            active: false,
            path: `/all_products`,
            title: 'Total Products',
            type: 'link',
          },
          {
            active: false,
            path: `/products`,
            title: 'My Products',
            type: 'link',
          },
          {
            active: false,
            path: `/create-product`,
            title: 'Create Product',
            type: 'link',
          }
        ].filter((e)=>e),
      },
      {
        title: 'Orders',
        icon: 'fa fa-pie-chart',
        type: 'link',
        path: `/order-list`,
        menuFor: 'both',
        img : orders  ,
        // children: [
        //  role == "Admin" && {
        //     active: false,
            
        //     title: 'List Of Orders',
        //     type: 'link',
        //   },
        // ].filter((e)=>e),
      },
      {
        title: 'Customers',
        icon: 'fa fa-users',
        type: 'link',
        path: `/customers`,
        menuFor: 'admin',
        img:customer,
      },
      {
        title: 'Transaction',
        icon: 'fa fa-users',
        type: 'link',
        path: `/transaction-list`,
        menuFor: 'admin',
        img:Transactions,
      },
      {
        title: 'Vendors',
        icon: 'fa fa-user-circle',
        type: 'sub',
        menuFor: 'admin',
        img:vendor,
        children: [
          {
            active: false,
            path: `/vendor`,
            title: 'Total Vendors',
            type: 'link',
          },
          {
            active: false,
            path: `/create-vendor`,
            title: 'Create Vendor',
            type: 'link',
          }
        ],
      },
   
      {
        title: 'Advertisement Banner',
        icon: 'fa fa-pie-chart',
        type: 'sub',
        menuFor: 'admin',
        img:ads,
        children: [
          {
            active: false,
            path: `/advertisement`,
            title: 'Total Banners',
            type: 'link',
          },
          {
            active: false,
            path: `/expired_advertisement`,
            title: 'Total Expired Banners',
            type: 'link',
          },
          {
            active: false,
            path: `/create_advertisement`,
            title: 'Create Banner',
            type: 'link',
          }
        ],
      },
    
      
     
  
     
      // {
      //   title: 'Bookings',
      //   icon: 'fa fa-bookmark',
      //   type: 'sub',
      //   menuFor: 'vendor',
      //   img : booking,

      //   children: [
      //     {
      //       active: false,
      //       path: `/Booking-Request-Vendor`,
      //       title: 'Enquiry',
      //       type: 'link',
      //     },
      //     {
      //       active: false,
      //       path: `/Booking-Assigned-Vendor`,
      //       title: 'Confirm Booking',
      //       type: 'link',
      //     },
      //     {
      //       active: false,
      //       path: `/Booking-Today`,
      //       title: "Today's Booking",
      //       type: 'link',
      //     },
      //     {
      //       active: false,
      //       path: `/Booking-Completed`,
      //       title: "History",
      //       type: 'link',
      //     },
          
      //   ],
      // },
    ],
  },
 {
    menutitle: "Offers",
    menucontent: 'Dashboards,Widgets',
 
    Items: [
     
      {
        title: 'Deal Of The Day',
        icon: 'fa fa-bars',
        type: 'sub',
        menuFor: 'both',
        img: Deal,

        children: [
        //  role == "Admin" && {
        //     active: false,
        //     path: `/all_deal_of_the_day`,
        //     title: 'All Deal List',
        //     type: 'link',
        //   },
        //   role == "Admin" && {
        //     active: false,
        //     path: `/all_expired_deal_of_the_day`,
        //     title: 'All Expired Deal List',
        //     type: 'link',
        //   },
          {
            active: false,
            path: `/deal_of_the_day`,
            title: 'Deal List',
            type: 'link',
          },
          // {
          //   active: false,
          //   path: `/expired_deal_of_the_day`,
          //   title: 'All My Expired Deal List',
          //   type: 'link',
          // },
          {
            active: false,
            path: `/create_deal_of_the_day`,
            title: 'Create Deal',
            type: 'link',
          },
        ],
      },
      {
        title: 'Last min deal',
        icon: 'fa fa-bars',
        type: 'sub',
        menuFor: 'both',
        img: Time,

        children: [
        // role == "Admin" &&  {
        //     active: false,
        //     path: `/all_last_min_deal`,
        //     title: 'All Last Min Deal',
        //     type: 'link',
        //   },
        // role == "Admin" &&  {
        //     active: false,
        //     path: `/all_expried_last_min_deal`,
        //     title: 'All Expired Last Min Deal',
        //     type: 'link',
        //   },
          {
            active: false,
            path: `/last_min_deal`,
            title: 'Last Min Deal',
            type: 'link',
          },
          // {
          //   active: false,
          //   path: `/expried_last_min_deal`,
          //   title: 'All My Expired Last Min Deal',
          //   type: 'link',
          // },
          {
            active: false,
            path: `/create_last_min_deal`,
            title: 'Create Last Min Deal',
            type: 'link',
          },
        ],
      },
      {
        title: 'Offers',
        icon: 'fa fa-bars',
        type: 'sub',
        menuFor: 'both',
        img: Offers,

        children: [
        // role == "Admin" && {
        //     active: false,
        //     path: `/all_offers`,
        //     title: 'All Offers',
        //     type: 'link',
        //   },
        //   role == "Admin" && {
        //     active: false,
        //     path: `/all_expried_offers`,
        //     title: 'All Expried Offers',
        //     type: 'link',
        //   },
          {
            active: false,
            path: `/offers`,
            title: 'All Offers',
            type: 'link',
          },
          // {
          //   active: false,
          //   path: `/expried_offers`,
          //   title: 'All My Expried Offers',
          //   type: 'link',
          // },
          {
            active: false,
            path: `/create_offers`,
            title: 'Create Offers',
            type: 'link',
          },
        ],
      },
    role == "Admin" &&  {
        title: 'Promocode',
        icon: 'fa fa-bars',
        type: 'sub',
        menuFor: 'both',
        img: Coupen,

        children: [
          {
            active: false,
            path: `/promocode`,
            title: 'Promocode List',
            type: 'link',
          },
          {
            active: false,
            path: `/expired_promocode`,
            title: 'Expried Promocode',
            type: 'link',
          },
          {
            active: false,
            path: `/create_promocode`,
            title: 'Create Promocode',
            type: 'link',
          },
        ],
      },

    ].filter((e)=>e),
  },
  role == "Admin" && {
    menutitle: "Master" ,
    menucontent: 'Dashboards,Widgets',
    Items: [
     
      {
        title: 'Categories',
        icon: 'fa fa-bars',
        type: 'sub',
        menuFor: 'admin',
        img: Category,

        children: [
          {
            active: false,
            path: `/Category`,
            title: 'Categories',
            type: 'link',
          },
          {
            active: false,
            path: `/Create-Category`,
            title: 'Create Category',
            type: 'link',
          },
        ],
      },
      {
        title: 'Sub Categories',
        icon: 'fa fa-tasks',
        menuFor: 'admin',
        type: 'sub',
        img:SubCategory,

        children: [
          {
            active: false,
            path: `/Sub-Category`,
            title: 'Sub Categories',
            type: 'link',
          },
          {
            active: false,
            path: `/Create-Sub-Category`,
            title: 'Create Sub Category',
            type: 'link',
          },
        ],
      },
      {
        title: 'Gst Rate',
        icon: 'fa fa-language',
        type: 'sub',
        menuFor: 'admin',
        img:language,

        children: [
          {
            active: false,
            path: `/GstRate`,
            title: 'Gst Rate List',
            type: 'link',
          },
          {
            active: false,
            path: `/Create-GstRate`,
            title: 'Create Gst Rate',
            type: 'link',
          }
        ],
      },
      // {
      //   title: 'Country',
      //   icon: 'fa fa-language',
      //   type: 'sub',
      //   menuFor: 'admin',
      //   children: [
      //     {
      //       active: false,
      //       path: `/Country`,
      //       title: 'All Country',
      //       type: 'link',
      //     },
      //     {
      //       active: false,
      //       path: `/Create-Country`,
      //       title: 'Create Countey',
      //       type: 'link',
      //     }
      //   ],
      // },
      {
        title: 'Unit',
        icon: 'fa fa-map-marker',
        type: 'sub',
        menuFor: 'admin',
        img:city,

        children: [
          {
            active: false,
            path: `/Unit`,
            title: 'Unit List',
            type: 'link',
          },
          {
            active: false,
            path: `/Create-Unit`,
            title: 'Create Unit',
            type: 'link',
          }
        ],
      },

    ],
  },
  role == "Admin" && {
    menutitle:  'Setting',
    menucontent: 'Dashboards,Widgets',
    Items: [
     
      {

        path: `/Setting/About Us/`,
        title: 'About us',
        type: 'link',
        icon: "fa fa-info-circle",
        menuFor: 'admin',
        img : AboutUs

      },
      {

        path: `/Setting/Terms and Condition`,
        title: 'Terms and Condition',
        type: 'link',
        icon: "fa fa-check-square",
        menuFor: 'admin',
        img : TermsConditions


      },
      {

        path: `/Setting/PrivacyPolicy`,
        title: 'Privacy Policy',
        type: 'link',
        icon: "fa fa-refresh",
        menuFor: 'admin',
        img : PrivacyPolicy


      },
      // {

      //   path: `/Setting/About-Us-vendor`,
      //   title: 'About us',
      //   type: 'link',
      //   icon: "fa fa-info-circle",
      //   menuFor: 'vendor',
      //   img : AboutUs



      // },
      // {

      //   path: `/Setting/Terms-Condition-vendor`,
      //   title: 'Terms and Condition',
      //   type: 'link',
      //   icon: "fa fa-check-square",
      //   menuFor: 'vendor',
      //   img : TermsConditions


      // },
      // {

      //   path: `/Setting/Privacy-Policy-vendor`,
      //   title: 'Privacy Policy',
      //   type: 'link',
      //   icon: "fa fa-refresh",
      //   menuFor: 'vendor',
      //   img : PrivacyPolicy

      // },
      // {
        
      //   path: `/Setting/Copy-Right`,
      //   title: 'Copy-Right',
      //   type: 'link',
      //   icon: "fa fa-copyright",
      //   menuFor: 'admin',

      // },
      // ],
      // },

    ],
  },
].filter((e)=>e);
