import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import * as TagAction from "../../actions/tags";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { IMAGE } from "../../api";
import { CREATECATEGORY, TAGS } from "../../Constant";
import { Breadcrumbs, H5 } from "../../AbstractElements";
import Form from "../../utils/Form";
const CreateTags = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "newLOCS123");
  const isEdit = location?.state?.isEdit;
  const createCategory = useSelector((state) => state);

  const { tags_create, tags_update } = bindActionCreators(TagAction, dispatch);

  const [file, setFile] = useState(null);
  const [form, setForm] = useState({});

  useEffect(() => {
    if (location?.state) {
      const data = location?.state?.data;
      setForm(data);
    }
  }, [location?.state]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));

    if (isEdit) {
      const UpdateTagsApi = await tags_update(data);
      if (UpdateTagsApi?.statuscode == 200) {
        toast.success(UpdateTagsApi?.message);
        setTimeout(() => {
          navigate("/Tags");
          dispatch({ type: TAGS, payload: undefined });
        }, 1000);
        setForm({});
      } else {
        toast.error(UpdateTagsApi?.message);
      }
    } else {
      const createTagsApi = await tags_create(data);
      if (createTagsApi?.statuscode == 200) {
        toast.success(createTagsApi?.message);
        setTimeout(() => {
          navigate("/Tags");
          dispatch({ type: TAGS, payload: undefined });
        }, 1000);
        setForm({});
      } else {
        toast.error(createTagsApi?.message);
      }
    }
  };

  const formStructure = [
    {
      id: "1",
      type: "inputBox",
      maxLength: 30,
      title: "Tag Name",
      name: "tag_name",
      regex: /^[a-zA-Z\s\&]+$/,
      isCaps: true,
      required: true,
    },
    ,
    // {
    //     id:"2",
    //     type:"select",
    //     title:"Display Type",
    //     name:"display_type",
    //     options:["Portrait","Landscape"]
    // }
    // ,
    // {
    //     id:"3",
    //     type:"lockedInput",
    //     variant:"number",
    //     min:0,
    //     max:99,
    //     title:"Sequence",
    //     name:"sequence",
    //     required:true
    // }

    ,
    {
      id: "6",
      type: "button",
      title: "Create",
    },
  ];
  const formTitle = isEdit ? "Edit Tags" : "Create Tags";
  return (
    <div>
      <Breadcrumbs
        parent="Tags"
        title={isEdit ? "Edit Tags" : "Create Tags"}
        mainTitle={isEdit ? "Edit Tags" : "Create Tags"}
      />

      <Form
        handleSubmit={handleSubmit}
        formStructure={formStructure}
        formTitle={formTitle}
        key={"Form"}
        setForm={setForm}
        form={form}
        // tableData={tableData}
        // setTableData={setTableData}
        isEdit={isEdit}
        // setIsEdit={setIsEdit}
      />
    </div>
  );
};

export default CreateTags;
