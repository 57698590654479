import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import * as Action from "../../actions/country";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { IMAGE } from "../../api";
import { COUNTRY, CREATECATEGORY } from "../../Constant";
import { Breadcrumbs, H5 } from "../../AbstractElements";
import Form from "../../utils/Form"
const CreateCountry = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "newLOCS");
  const isEdit = location?.state?.isEdit;


  const {country_create,country_update } = bindActionCreators(
    Action,
    dispatch
  );


  const [file, setFile] = useState(null);
  const [form ,setForm] = useState({})

  useEffect(() => {
    if (location?.state) {
      const data = location?.state?.data;
      setForm(data)
    }
  }, [location?.state]);

  



  const handleSubmit = async (event) => {
    event.preventDefault();
    // const data = new FormData();
    // Object.keys(form).map((key) => data.append(key, form?.[key]));
    // formStructure.map((element) => {
		// 	if (element.type == "image" && form?.[element.name] && typeof (form?.[element.name]) != "string") {
		// 		const temp = form;
		// 		temp["temp" + element.name] = form?.[element.name];
		// 		temp[element.name] = URL.createObjectURL(form?.[element.name]);

		// 		setForm({
		// 			...temp,
		// 		});
		// 	}
		// });
  
      if (isEdit) {
        const UpdateCountryApi = await country_update({country_name:form?.country_name , id:form?.id});
        if (UpdateCountryApi?.statuscode == 200) {
          toast.success(UpdateCountryApi?.message);
          setTimeout(() => {
            navigate("/Country");
            dispatch({ type: COUNTRY, payload: undefined });
          }, 1000);
          setForm({});
        } else {
          toast.error(UpdateCountryApi?.message);
        }
      }
       else {
        const createCountryApi = await country_create({country_name:form?.country_name});
        if (createCountryApi?.statuscode == 200) {
          toast.success(createCountryApi?.message);
          setTimeout(() => {
            navigate("/Country");
            dispatch({ type: COUNTRY, payload: undefined });
          }, 1000);
          setForm({});
        } else {
          toast.error(createCountryApi?.message);
        }
      }
    } 


 const formStructure=[
    {
        id:"1",
        type:"inputBox",
        maxLength:30,
        title:"Country Name",
        name:"country_name",
        regex : /^[a-zA-Z\s\&]+$/,
        isCaps:true,
        required:true
    },
    // {
    //     id:"2",
    //     type:"select",
    //     title:"Display Type",
    //     name:"display_type",
    //     options:["Portrait","Landscape"]
    // }
    // ,
    // {
    //     id:"3",
    //     type:"lockedInput",
    //     variant:"number",
    //     min:0,
    //     max:99,
    //     title:"Sequence",
    //     name:"sequence",
    //     required:true
    // }
  //   ,
  //   {
  //     id: "10",
  //     type: "toggle",
  //     title: "Status",
  //     name: "status",
  //     default: "Active",
  //     display: isEdit? "block" : "none",
  //     size: "6",
  //     options: [{ value: "Active", color: "#00B69B" }, { value: "Inactive", color: "#EE368C" }],
  // },
  //   {
  //       id:"4",
  //       type:"image",
  //       title:"Upload Image",
  //       subtitle:"(Resolution : 512px x 512px) *",
  //       subsubtitle:"Max File Size 1MB",
  //       subsubsubtitle:"Support only JPG,PNG,JPEG",
  //       name:"category_image"
  //   }
  //   ,
   
    
    {
        id:"6",
        type:"button",
        title:"Create",
        
    }
]
const formTitle = isEdit ? "Edit Country" : "Create Country";
  return (
    <div>
       <Breadcrumbs
        parent="Country"
        title={ isEdit ? "Edit Country":"Create Country"}
        mainTitle={isEdit ? "Edit Country":"Create Country"}
      />
  
              <Form
					handleSubmit={handleSubmit}
					formStructure={formStructure}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					// tableData={tableData}
					// setTableData={setTableData}
					isEdit={isEdit}
					// setIsEdit={setIsEdit}
				/>
            
    </div>
  );
};

export default CreateCountry;
