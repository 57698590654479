import React, { useEffect, useState } from 'react'
import { Breadcrumbs, H6 } from '../../AbstractElements'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as VendorAction from '../../actions/vendor'
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap'
import DataTable from 'react-data-table-component'

export default function TodayBooking() {

    const vendor = JSON.parse(sessionStorage.getItem('user'))
    const dispatch = useDispatch()
    const { getTodaysbookingDataVendor } = bindActionCreators(VendorAction, dispatch)
    const [bookingData, setBookingData] = useState([])

    useEffect(() => {
        (async () => {
            const resData = await getTodaysbookingDataVendor({ id: vendor?.data?.id })
            console.log("resData: ", resData)
            setBookingData(resData)
        })()
    }, [])


    const productColumns = [
        {
            name: "Name",
            selector: (row) => row.Name,
            sortable: true,
            // center: true,
            wrap: true,
            // minWidth: "350px",
        },
        {
            name: "Contact",
            selector: (row) => row.Contact,
            sortable: true,
            // center: true,
            wrap: true,
            // minWidth: "350px",
        },
        {
            name: "Service",
            selector: (row) => row.Service,
            sortable: true,
            // center: true,
            wrap: true,
            // minWidth: "350px",
        },
        {
            name: "Total Workers",
            selector: (row) => row.TotalWorkers,
            sortable: true,
            // center: true,
            wrap: true,
            // minWidth: "350px",
        },
        {
            name: "Location",
            selector: (row) => row.Location,
            sortable: true,
            // center: true,
            wrap: true,
            // minWidth: "350px",
        },
        {
            name: "From",
            selector: (row) => row.StartDate,
            sortable: true,
            // center: true,
            wrap: true,
            // minWidth: "350px",
        },
        {
            name: "To",
            selector: (row) => row.EndDate,
            sortable: true,
            // center: true,
            wrap: true,
            // minWidth: "350px",
        },
        {
            name: "Status",
            selector: (row) => row.Action,
            sortable: true,
            // center: true,
            wrap: true,
            // minWidth: "350px",
        },

    ];

    const ProductList =
        bookingData &&
        bookingData?.data?.map((value, index) => ({
            Name: (
                <div>
                    <H6>{value?.booking_request?.name}</H6>
                </div>
            ),
            Contact: (
                <div>
                    <p>{value?.booking_request?.email}</p>
                    <p>{value?.booking_request?.contact}</p>
                </div>
            ),
            Service: (
                <div>
                    <p>{value?.booking_request?.category?.category_name}</p>
                    <p>({value?.booking_request?.subcategory?.subcategory_name})</p>
                </div>
            ),
            TotalWorkers: (
                <div>
                    <p>{value?.worker?.length} Workers</p>
                </div>
            ),
            Location: (
                <div>
                    {/* <p>{value?.booking_request?.address}</p>
                    <p>({value?.booking_request?.city}-{value?.booking_request?.pincode})</p> */}
                    <p>{value?.booking_request?.house_no} - {value?.booking_request?.residence_name}</p>
                    <p>{value?.booking_request?.area_or_street},{value?.booking_request?.city?.city_name}</p>
                </div>
            ),
            StartDate: (
                <div>
                    <p>{value?.booking_request?.from_date}</p>
                </div>
            ),
            EndDate: (
                <div>
                    <p>{value?.booking_request?.to_date}</p>
                </div>
            ),
            Action: (
                <div style={{ background: "#26230dcf", padding: "8px", color: "#ffffff", borderRadius: "5px" }}>
                    In Progress
                </div>
            ),
            Workers: value?.worker

        }));


    const ExpandedComponent = ({ data }) =>

    (
        <div style={{ width: "100%", border: "1px solid grey" }}>
            <Table striped >

                <thead className='expand-table'>
                    <tr className="table-secondary" style={{ fontWeight: "900" }}>
                        <th>
                            #
                        </th>
                        <th style={{ fontWeight: "900" }}>
                            Name
                        </th>
                        <th style={{ fontWeight: "900" }}>
                            Email
                        </th>
                        <th style={{ fontWeight: "900" }}>
                            Mobile
                        </th>
                        <th style={{ fontWeight: "900" }}>
                            Nationality
                        </th>
                        <th style={{ fontWeight: "900" }}>
                            Work Permit Expiry
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data?.Workers?.map((data, index) => (
                            <tr>
                                <th scope="row">
                                    {index + 1}
                                </th>
                                <td>
                                    {data?.worker?.firstname} {data?.worker.lastname}
                                </td>
                                <td>
                                    {data?.worker?.email}
                                </td>
                                <td>
                                    {data?.worker?.mobile_number}
                                </td>

                                <td>
                                    {data?.worker?.nationality}
                                </td>

                                <td>
                                    {data?.worker?.work_permit_expire_at}
                                </td>
                            </tr>
                        ))
                    }


                </tbody>
            </Table>

            {/* Add more details as needed */}
        </div>
    );


    return (
        <div className="table-responsive product-table">
            <Breadcrumbs
                parent="Booking"
                title="Today's Booking"
                mainTitle={`Today's Booking`}
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <DataTable
                                    noHeader
                                    pagination
                                    paginationServer
                                    columns={productColumns}
                                    data={ProductList}
                                    highlightOnHover={true}
                                    striped={true}
                                    responsive={true}
                                    expandableRows
                                    expandableRowsComponent={ExpandedComponent}
                                    className="custom-data-table-vendor"

                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
