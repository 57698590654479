import { TRANSACTION_LIST } from "../Constant/index.jsx";
import * as api from "../api/index.jsx";

export const all_successfull_transaction = (formData) => async (dispatch) => {
  try {
    const { data } = await api.all_successfull_transaction(formData);
    dispatch({ type: TRANSACTION_LIST, payload: data });
    return data;
  } catch (error) {
    console.log(error);
  }
};
