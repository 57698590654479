import * as actionType from '../Constant';


const TagsReducer = (state = { tagsData: null }, action) => {
  switch (action.type) {
    case actionType.TAGS:
      return { ...state, tags: action.payload };
    case actionType.TAGSLIST:
      return {...state, tags_list: action.payload}

    default:
      return state;
  }
};

export default TagsReducer;
