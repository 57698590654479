import { COMPANY_SERVICES, CREATE_COMPANY_SERVICES } from '../Constant';
import * as api from '../api/index';

export const company_service_create  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.company_service_create (formData);
      dispatch({ type: CREATE_COMPANY_SERVICES, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };
export const company_services_list  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.company_services_list (formData);
      dispatch({ type: COMPANY_SERVICES, payload:data });
      return data
   
    } catch (error) {
      console.log(error);
    }
  };