import React, { Fragment, useState, useEffect, useContext, useMemo } from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, H4, P } from '../../../AbstractElements';
import { EmailAddress, ForgotPassword, LOGGEDIN, LoginWithJWT, Password, RememberPassword, SignIn, USER_DETAILS } from '../../../Constant';

import { Link, useNavigate } from 'react-router-dom';
import { Jwt_token } from '../../../Config/Config';
import man from '../../../assets/images/dashboard/profile.png';
import { handleResponse } from '../../../Services/fack.backend';
import { useDispatch, useSelector } from "react-redux";
import CustomizerContext from '../../../_helper/Customizer';
import OtherWay from './OtherWay';
import * as AuthStore from '../../../actions/AuthAction';
import { Alert, Snackbar } from '@mui/material';
import { bindActionCreators } from 'redux';

const LoginTab = ({ selected }) => {
  const [email, setEmail] = useState('osmagrimart@gmail.com');
  const [password, setPassword] = useState('123456');
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const dispatch = useDispatch()
  const [value, setValue] = useState(localStorage.getItem('profileURL' || man));
  const [name, setName] = useState(localStorage.getItem('Name'));
  const authStore = useSelector((state) => state?.auth?.company)
  const [open, setOpen] = React.useState(false);
  const { companyLogin, employeeLogin } = bindActionCreators(AuthStore, dispatch)
  useEffect(() => {
    localStorage.setItem('profileURL', man);
    localStorage.setItem('Name', 'Emay Walter');
  }, [value, name]);

  // useMemo(() => {
  //   if (authStore?.statuscode == 200) {
  //     localStorage.setItem("user", JSON.stringify(authStore))
  //     localStorage.setItem("isLoggedIn", true)
  //     setOpen(true)
  //     setTimeout(() => {
  //       history(`${process.env.PUBLIC_URL}/category`);
  //     }, 2500)
  //   }
  //   else if (authStore) {
  //     setOpen(true)

  //   }
  // }, [authStore])

  const loginAuth = async (e) => {
    e.preventDefault();
    const resData = await companyLogin({ email: email, password: password })

    console.log(resData)
    if (resData?.statuscode == 200) {
      if (resData?.first_time_login == "true") {
        setOpen(true)
        setTimeout(() => {
          history(`/pages/authentication/create-password`, { replace: true, state: resData?.id });
        }, 2500)

      }
      else {
        sessionStorage.setItem("user", JSON.stringify({ ...resData, pin: password }))
        sessionStorage.setItem("isLoggedIn", true)
        dispatch({ type: USER_DETAILS, payload: resData?.id });
        dispatch({ type: LOGGEDIN, payload: true });
        setOpen(true)
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 100); 
        // setTimeout(() => {
          
        //   history(`${process.env.PUBLIC_URL}/dashboard`, { replace: true });
        // }, 1500)
      }

    }
    else if (resData) {
      setOpen(true)
    }

  };



  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const empLogin = async (e) => {
    e.preventDefault()
    console.log("email", email, password)
    const resData = await employeeLogin({ email: email, password: password })

    console.log(resData)
    if (resData?.statuscode == 200) {
      if (resData?.first_time_login == "true") {
        setOpen(true)
        setTimeout(() => {
          history(`/pages/authentication/create-password`, { replace: true, state: resData?.id });
        }, 2500)

      }
      else {
        sessionStorage.setItem("user", JSON.stringify({ ...resData, password: password }))
        sessionStorage.setItem("isLoggedIn", true)
        dispatch({ type: USER_DETAILS, payload: resData?.id });
        dispatch({ type: LOGGEDIN, payload: true });
        setOpen(true)
        // setTimeout(() => {
        //   history(`${process.env.PUBLIC_URL}/dashboard`, { replace: true });
        // }, 1500)
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 100); 
      }

    }
    else if (resData) {
      setOpen(true)
    }

  }

  return (
    <Fragment>
      <Form className='theme-form'>
        <H4>{selected === 'simpleLogin' ? 'Sign In (Admin)' : 'Sign In (Vendor)'}</H4>
        <P>{'Enter your email & password to login'}</P>
        <FormGroup>
          <Label className='col-form-label'>{EmailAddress}</Label>
          <Input className='form-control' type='email' onChange={(e) => setEmail(e.target.value)} value={email} />
        </FormGroup>
        <FormGroup className='position-relative'>
          <Label className='col-form-label'>{Password}</Label>
          <div className='position-relative'>
            <Input className='form-control' type={togglePassword ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} value={password} />
            <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
              <span className={togglePassword ? '' : 'show'}></span>
            </div>
          </div>
        </FormGroup>
        <div className='position-relative form-group mb-0'>
          <div className='checkbox'>
            <Input id='checkbox1' type='checkbox' />
            <Label className='text-muted' for='checkbox1'>
              {RememberPassword}
            </Label>
          </div>
          <div style={{cursor:"pointer"}} className='link' onClick={() => history('/pages/authentication/forget-pwd', { state: { type: selected === 'simpleLogin' ? "admin" : "vendor" } })}>
            {ForgotPassword}
          </div>
          {selected === 'simpleLogin' ? (
            <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2', onClick: (e) => loginAuth(e) }}>{SignIn}</Btn>
          ) : (
            <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2', onClick: (e) => empLogin(e) }}>{SignIn}</Btn>
          )}
        </div>
        {/* <OtherWay /> */}
        {/* <div>
        Register as a Vendor
        </div> */}
        <div style={{marginTop:"1rem" ,cursor:"pointer" }}> 
    <Link style={{color:"#575757"}} to={"/register_vendor"}>
        Register as a Vendor
          </Link>
          </div>
      </Form>
     
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={authStore?.statuscode == 200 ? "success" : "error"}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {authStore?.message}
        </Alert>
      </Snackbar>

    </Fragment>
  );
};

export default LoginTab;
