// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import ListTable from "../../utils/Table";
import { all_category_list, category_delete } from "../../actions/category";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { CREATECATEGORY, SLIDER_BANNER } from "../../Constant";
import { all_slider_banner_list, slider_banner_delete } from "../../actions/sliderbanner";

const SliderBanner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const Advertisement_Data = useSelector((state)=>state?.slider_banner?.slider_banner_list)
  const message = useSelector((state)=>state?.slider_banner?.slider_banner)
  console.log(Advertisement_Data)
  useMemo(()=>{
    dispatch(all_slider_banner_list())
  },[])
  const tempTableData = {
    tableTitle: "Advertisements",
    deleteRecord: slider_banner_delete,
    // updateRecord: subcategory_update,
    // deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    onDeleteText: "Are you sure want to delete?",
    onUpdateText:
      "Videos are associated with this category, are you still want to change it.?",
    tableHead: [
      {
        id: "title",
        label: "Ad Title",
      },
      {
        id: "advertisement_photo",
        label: "Ad Image",
        isImage : true
      },
      {
        id: "c_name",
        label: "Client Name",
      },
      // {
      //   id: "mobile_number",
      //   label: "Contact At",
      //   subText :"email"
      // },
      {
        id: "start_date",
        label: "Start Date",
      },
      {
        id: "end_date",
        label: "End Date",
      },
      // {
      //   id: "mobile_number",
      //   label: "Contact At",
      //   subText :"email"
      // },
      {
        id: "set_sequence",
        label: "Sequence",
      },

      {
        id: "status",
        label: "Status",
        isButtonDisplay: true,
      },
      {
        id: "edit",
        label: "Update",
        edit_path :"/update_advertisement",

      },
    ],
    tableBody: [
      {
        category :"1",
        set_sequence :"1",
        subcategory_name :"78",
        status : "Active",
      },
      {
        category :"1",
        set_sequence :"1",
        subcategory_name :"79",
        status : "Active",
      }
    ],
    filterColumn: [],
  };
  const [tableData, setTableData] = useState({...tempTableData})

  useMemo(()=>{
    if(Advertisement_Data?.statuscode == 200){
      const temp = tableData
      temp.tableBody = Advertisement_Data?.data || []
      setTableData({...temp})
    }
  },[Advertisement_Data])
  useMemo(()=>{
    if(message?.statuscode == 200){
      toast.success(message?.message);
      dispatch(all_slider_banner_list())
      dispatch({ type: SLIDER_BANNER, payload: undefined })
    }
  },[message])
  return (
    <div >
      <Breadcrumbs
        parent="Advertisements"
        title="Advertisements"
        mainTitle="All Advertisements"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
              <ListTable
              tableData={tableData}
              key={"ListTable"}
              // setForm={setForm}
              setTableData={setTableData}
              // setIsEdit={setIsEdit}
              />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SliderBanner;
