import { CREATE_EMPLOYEES, CREATE_PROJECT, EMPLOYEES, PROJECT, UPDATE_WORK_ASSIGN } from '../Constant';
import * as api from '../api/index';

export const project_create  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.project_create (formData);
      dispatch({ type: CREATE_PROJECT, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };
export const company_project_list  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.company_project_list (formData);
      dispatch({ type: PROJECT, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };
