import React, { useMemo, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  // Form,
  Label,
  Input,
  Container,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import styles from "./form.module.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Switch from "@mui/material/Switch";
import { DateRangePicker } from "rsuite";
import AddIcon from "@mui/icons-material/Add";
import "rsuite/dist/rsuite.css";
import { RichTextEditor } from "@mantine/rte";
import { IMAGE } from "../api/index";
import { Link } from "react-router-dom";
import imageJPG from "../Images/file1.png";
import Autocomplete from "@mui/material/Autocomplete";
// import validator from "validator";
import PhoneInput from "react-phone-input-2";
import { Breadcrumbs, H5 } from "../AbstractElements";
import "react-phone-input-2/lib/style.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
// import { TimeField } from "@mui/x-date-pickers/TimeField";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import Icon from "./../../images/logo.png";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import dayjs from "dayjs";
import Checkbox from "@mui/material/Checkbox";
// import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from "@mui/material/Snackbar";
import SendIcon from "@mui/icons-material/Send";
import Alert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";

const { beforeToday } = DateRangePicker;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function Form({
  formStructure,
  formTitle,
  setForm,
  form,
  isEdit,
  handleSubmit,
  isMulti,
  showTitle,
  errorMessage,
  setAllowNext,
}) {
  const [openAdError, setOpenAdError] = useState(false);
  const [content, setPopupContent] = useState("");
  const [dateRange_report, setDateRange_report] = useState("");
  // const fileInputRef = useRef(null); // Create ref for file input

  const handleClose = () => {
    setOpenAdError(false);
  };
  const lengthOfInputContainerLG = isMulti
    ? formStructure?.length < 4
      ? 12 / formStructure?.length
      : 6
    : formStructure?.length < 4
    ? 12 / (formStructure?.length - 1)
    : 6;

  // const handleChange = (event) => {
  // 	setForm({ ...form, [event.target.name]: event.target.value });
  // };
  function dateToYMD(date) {
    var d = date.getDate();
    var m = date.getMonth() + 1; //Month from 0 to 11
    var y = date.getFullYear();
    return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
  }
  function makePassword(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
  const button = formStructure[formStructure.length - 1];

  function getDateFromHours(time) {
    time = time.split(":");
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), ...time);
  }
  const [lockedInputs, setLockedInputs] = useState();
  const [displayCount, setDisplayCount] = useState(0);
  const [loading, setLoading] = useState(false);
  useMemo(() => {
    const temp = {};
    setDisplayCount(0);
    console.log(formStructure, "printDataaa");
    formStructure?.map((ele) => {
      if (ele?.type == "lockedInput") temp[ele?.name] = true;
      if (ele?.display != "none") setDisplayCount((prev) => prev + 1);
    });
    setLockedInputs({ ...temp });
  }, [formStructure]);
  const handleLockedinput = (name) => {
    const temp = lockedInputs;
    temp[name] = !temp[name];
    setLockedInputs({ ...temp });
  };
  const [allowSubmit, setAllowSubmit] = useState(true);
  const handleFormSubmit = (e) => {
    var requiredFieldCount = 0;
    var requiredFieldFilledCount = 0;
    formStructure.map((value) => {
      if (value.required && value.display != "none") {
        requiredFieldCount = requiredFieldCount + 1;
        if (
          Object.keys(form).includes(value.name) &&
          form[value.name] != "" &&
          form[value.name] != [] &&
          form[value.name] != null &&
          form[value.name]
        ) {
          requiredFieldFilledCount = requiredFieldFilledCount + 1;
        }
      }
    });
    if (requiredFieldFilledCount == requiredFieldCount) {
      handleSubmit(e);
      setAllowSubmit(true);
      setLoading(true);
      // setTimeout(()=>{
      //   setLoading(false)
      // },10000)
      // setSub(true)
    } else setAllowSubmit(false);
    // setSub(false)
    // allowSubmit && handleSubmit(e)
    // setSub(!sub)
  };
  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const maxWidth = 800; // Set your maximum width here
          const maxHeight = 600; // Set your maximum height here
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            // blob contains the compressed image
            const compressedFile = new File([blob], file.name, {
              type: file.type,
              lastModified: Date.now(),
            });
            resolve(compressedFile);
          }, file.type);
        };
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  const handleCheckBox = (event, index) => {
    // var temp=form?.rights
    //  [event.target.name]= event.target.checked
    // temp[index][event.target.name]=event.target.checked?"true":"false"
  };
  const fileInputRefs = useRef([]); // Move useRef to the top level of the component

  // Helper function to truncate file names longer than 15 characters
  const truncateFileName = (fileName) => {
    if (fileName.length > 15) {
      return fileName.substring(0, 15) + "...";
    }
    return fileName;
  };
  return (
    <>
      {" "}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAdError}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert severity="info" variant="filled" color="success">
          {content}
        </Alert>
      </Snackbar>
      {/* <Breadcrumbs
        parent="Category"
        title={ isEdit ? "Edit Category":"Create Category"}
        mainTitle={isEdit ? "Edit Category":"Create Category"}
      /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                {showTitle && (
                  <Typography
                    as="h3"
                    sx={{
                      fontSize: 18,
                      fontWeight: 500,
                      mb: "15px",
                    }}
                  >
                    {formTitle}
                  </Typography>
                )}
                <Grid
                  container
                  alignItems="flex-center"
                  justifyContent={displayCount > 2 ? "start" : "center"}
                  key={"container-grid"}
                  spacing={3}
                >
                  {formStructure.map((value, index) => {
                    if (value.type == "inputBox") {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          key={index + "-grid"}
                          lg={value?.size || lengthOfInputContainerLG}
                          display={value.display || "block"}
                        >
                          <TextField
                            autoComplete={value.title}
                            name={value.name}
                            fullWidth
                            id={value.id}
                            type={value.variant || "text"}
                            required={value?.required}
                            value={form?.[value.name] || value?.default || ""}
                            label={value.title}
                            helperText={
                              (((isMulti && errorMessage) || !allowSubmit) &&
                                (form?.[value.name] == undefined ||
                                  form?.[value.name]?.length <= 0) &&
                                value?.required && (
                                  <span style={{ color: "red" }}>
                                    Please fill this field
                                  </span>
                                )) ||
                              // ((value?.endsWith || value?.endsWith1 )&&
                              //   !form?.[value.name]?.endsWith((value?.endsWith) ||(value?.endsWith1))  &&
                              //   (value?.errorText || "Incorrect format"))
                              (value?.endsWith &&
                                !(
                                  form?.[value.name]?.endsWith(
                                    value?.endsWith
                                  ) ||
                                  form?.[value.name]?.endsWith(value?.endsWith1)
                                ) &&
                                (value?.errorText || (
                                  <span style={{ color: "red" }}>
                                    Incorrect format
                                  </span>
                                )))
                            }
                            // helperText={(((isMulti && errorMessage) || !allowSubmit)
                            // 	&& (form?.[value.name] == undefined ||
                            // 		form?.[value.name]?.length <= 0) &&
                            // 		value?.required) && "Please fill this field" ||
                            // 		(value?.endsWith &&
                            // 			!(
                            // 			  form?.[value.name]?.endsWith(value?.endsWith) ||
                            // 			  form?.[value.name]?.endsWith(value?.endsWith1)
                            // 			)) &&
                            // 		  (value?.errorText || "Incorrect format")}
                            // helperText={((isMulti && errorMessage && (form?.[value.name] == undefined || form?.[value.name]?.length <= 0) && value?.required) && "Please fill this field") || (value?.endsWith && (!form?.[value.name]?.endsWith(value?.endsWith) && (value?.errorText || "Incorrect format")))}
                            onChange={(event) => {
                              if (value.regex) {
                                if (
                                  event.target.value !== "" &&
                                  !value.regex.test(event.target.value)
                                ) {
                                  return;
                                }
                              }
                              setForm({
                                ...form,
                                [event.target.name]: value.isCaps
                                  ? event.target.value.toUpperCase()
                                  : value?.isCapitalise
                                  ? event.target.value.charAt(0).toUpperCase() +
                                    event.target.value.slice(1)
                                  : event.target.value,
                              });
                            }}
                            onChangeCapture={(event) => {
                              // if (value.variant == "email") {
                              //   !validator.isEmail(event.target.value)
                              //     ? event.target.setCustomValidity("Invalid Email")
                              //     : event.target.setCustomValidity("");
                              // }
                            }}
                            InputProps={{
                              startAdornment: value?.symbol && (
                                <InputAdornment color="secondary">
                                  {value?.symbol}
                                </InputAdornment>
                              ),
                              style: {
                                borderRadius: 8,
                                color: "var(--themeFontColor)",
                                backgroundColor: value.disabled && "#f2f2f2",
                              },
                              inputProps: {
                                min: value?.min,
                                max: value?.max,
                                step: value?.step || 1,
                                maxLength: value?.maxLength,
                              },
                            }}
                            disabled={value.disabled || false}
                          />
                          {(value.title == "Description" ||
                            value.showLimit) && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <p style={{ color: "var(--themeFontColor)" }}>
                                {form?.[value.name]?.length}/
                                {value?.maxLength || "200"}{" "}
                                {form?.[value.name]?.length >=
                                  value?.maxLength && (
                                  <span style={{ color: "red" }}>
                                    Maximum limit reached
                                  </span>
                                )}
                              </p>
                            </div>
                          )}
                        </Grid>
                      );
                    }
                    if (value.type == "lockedInput") {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          key={index + "-grid"}
                          lg={value?.size || lengthOfInputContainerLG}
                          display={value.display || "block"}
                        >
                          <TextField
                            autoComplete={value.title}
                            name={value.name}
                            fullWidth
                            id={value.id}
                            type={value.variant || "text"}
                            required={value?.required}
                            value={form?.[value.name] || ""}
                            label={value.title}
                            disabled={lockedInputs?.[value.name]}
                            onChange={(event) => {
                              setForm({
                                ...form,
                                [event.target.name]: event.target.value,
                              });
                            }}
                            helperText={
                              (((isMulti && errorMessage) || !allowSubmit) &&
                                (form?.[value.name] == undefined ||
                                  form?.[value.name]?.length <= 0) &&
                                value?.required) ||
                              // (value?.endsWith &&
                              //   !form?.[value.name]?.endsWith(value?.endsWith) &&
                              //   (value?.errorText || "Incorrect format"))
                              (value?.endsWith &&
                                !(
                                  form?.[value.name]?.endsWith(
                                    value?.endsWith
                                  ) ||
                                  form?.[value.name]?.endsWith(value?.endsWith1)
                                ) &&
                                (value?.errorText || (
                                  <span style={{ color: "red" }}>
                                    Incorrect format
                                  </span>
                                )))
                            }
                            // helperText={(((isMulti && errorMessage) || !allowSubmit) && (form?.[value.name] == undefined || form?.[value.name]?.length <= 0) && value?.required) && "Please fill this field" || value?.endsWith && (!form?.[value.name]?.endsWith(value?.endsWith) && (value?.errorText || "Incorrect format"))}
                            // helperText={((isMulti && errorMessage && (form?.[value.name] == undefined || form?.[value.name]?.length <= 0) && value?.required) && "Please fill this field") || value?.endsWith && (!form?.[value.name]?.endsWith(value?.endsWith) && (value?.errorText || "Incorrect format"))}
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  color="secondary"
                                  onClick={() => handleLockedinput(value.name)}
                                >
                                  {lockedInputs?.[value.name] ? (
                                    <span style={{ color: "red" }}>
                                      <LockOutlinedIcon />{" "}
                                    </span>
                                  ) : (
                                    <LockOpenOutlinedIcon />
                                  )}
                                </IconButton>
                              ),
                            }}
                          />
                          <p
                            style={{
                              fontSize: "10px",
                              color: "red",
                              width: "100%",
                            }}
                          >
                            {!allowSubmit &&
                              (form[value.name] == "" ||
                                form[value.name] == [] ||
                                form[value.name] == null ||
                                form?.[value.name]?.length <= 0) &&
                              value?.required && (
                                <span style={{ color: "red" }}>
                                  Please fill this field
                                </span>
                              )}
                          </p>
                          <p
                            style={{
                              fontSize: "10px",
                              color: "red",
                              width: "100%",
                            }}
                          >
                            {isMulti &&
                              errorMessage &&
                              (form?.[value.name] == undefined ||
                                form?.[value.name]?.length <= 0) &&
                              value?.required && (
                                <span style={{ color: "red" }}>
                                  Please fill this field
                                </span>
                              )}
                          </p>
                        </Grid>
                      );
                    }
                    if (value.type == "password") {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          key={index + "-grid"}
                          lg={lengthOfInputContainerLG}
                          display={value.display || "block"}
                        >
                          <TextField
                            autoComplete={value.title}
                            name={value.name}
                            fullWidth
                            id={value.id}
                            type={value.variant || "text"}
                            required={value?.required}
                            value={form?.[value.name] || ""}
                            label={value.title}
                            onChange={(event) => {
                              setForm({
                                ...form,
                                [event.target.name]: event.target.value,
                              });
                            }}
                            InputProps={{
                              endAdornment: (
                                <IconButton color="secondary">
                                  <RestartAltIcon
                                    onClick={(e) => {
                                      setForm({
                                        ...form,
                                        [value?.name]: makePassword(10),
                                      });
                                    }}
                                  />
                                </IconButton>
                              ),
                            }}
                          />
                        </Grid>
                      );
                    }

                    if (value.type == "duration") {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          key={index + "-grid"}
                          lg={value?.size || lengthOfInputContainerLG}
                          display={value.display || "block"}
                        >
                          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimeField
                        label={value?.title}
                        name={value?.name}
                        // style={{"-webkit-text-fill-color":value?.color}}
                        value={
                          typeof form?.[value.name] == "string"
                            ? dayjs(getDateFromHours(form?.[value.name]))
                            : form?.[value.name] || ""
                        }
                        onChange={(newValue) => {
                          setForm({
                            ...form,
                            [value?.name]:
                              new Date(newValue)?.getHours() +
                              ":" +
                              new Date(newValue)?.getMinutes() +
                              ":" +
                              new Date(newValue)?.getSeconds(),
                            // &&(newValue?.getHours()+":"+newValue?.getMinutes()+":"+newValue?.getSeconds())
                          });
                        }}
                        InputProps={{
                          style: {
                            "-webkit-text-fill-color": value?.color,
                          },
                        }}
                        format="HH:mm:ss"
                        placeholder="00:00:00"
                      />
                    </LocalizationProvider> */}
                        </Grid>
                      );
                    }
                    if (value.type == "phone") {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          key={index + "-grid"}
                          lg={value.size || lengthOfInputContainerLG}
                          display={value.display || "block"}
                        >
                          <FormControl fullWidth>
                            <PhoneInput
                              labelId="demo-simple-select-label"
                              country={"in"}
                              required={value?.required}
                              value={form?.[value.name]}
                              disabled={lockedInputs?.[value.name]}
                              onChange={(phone, countryCode) => {
                                setForm({
                                  ...form,
                                  [value.name]:
                                    // form?.countryCode == "+" + countryCode.dialCode

                                    countryCode.dialCode == "91"
                                      ? phone[2] >= 6
                                        ? phone
                                        : countryCode.dialCode
                                      : phone,
                                  countryCode: "+" + countryCode.dialCode,
                                });
                              }}
                              // isValid={(value, country) => {
                              //   if (country.dialCode == "91" && value[2] < 6) {
                              //     return "Invalid Number";
                              //   } else {
                              //     return true;
                              //   }
                              // }}
                              dropdownClass={styles.phoneInputDropDown}
                              buttonClass={styles.phoneInputButton}
                              inputClass={styles.phoneInput}
                            />
                          </FormControl>
                        </Grid>
                      );
                    }
                    if (value.type == "phone2") {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          key={index + "-grid"}
                          lg={lengthOfInputContainerLG}
                          display={value.display || "block"}
                        >
                          <FormControl fullWidth>
                            <PhoneInput
                              labelId="demo-simple-select-label"
                              country={"in"}
                              required={value?.required}
                              value={form?.[value.name]}
                              onChange={(phone, countryCode_contact_person) => {
                                setForm({
                                  ...form,
                                  [value.name]:
                                    form?.countryCode_contact_person ==
                                    "+" + countryCode_contact_person.dialCode
                                      ? countryCode_contact_person.dialCode ==
                                          "91" && phone[2] >= 6
                                        ? phone
                                        : countryCode_contact_person.dialCode
                                      : countryCode_contact_person.dialCode,
                                  countryCode_contact_person:
                                    "+" + countryCode_contact_person.dialCode,
                                });
                              }}
                              isValid={(value, country) => {
                                if (country.dialCode == "91" && value[2] < 6) {
                                  return "Invalid Number";
                                } else {
                                  return true;
                                }
                              }}
                              dropdownClass={styles.phoneInputDropDown}
                              buttonClass={styles.phoneInputButton}
                              inputClass={styles.phoneInput}
                            />
                          </FormControl>
                        </Grid>
                      );
                    }

                    if (value.type == "start_date_end_date") {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          key={index + "-grid"}
                          lg={lengthOfInputContainerLG}
                          display={value.display || "block"}
                        >
                          <FormControl fullWidth>
                            <DateRangePicker
                              size="lg"
                              placeholder={value.title}
                              ranges={[]}
                              name={value.name}
                              required={value?.required}
                              value={form?.[value.name] || ""}
                              format="dd-MM-yyyy"
                              // disabledDate={beforeToday()}
                              onChange={(range) => {
                                setForm({
                                  ...form,
                                  [value.name]: range,
                                  [value.formName1]: dateToYMD(range[0]),
                                  [value.formName2]: dateToYMD(range[1]),
                                });
                              }}
                              defaultCalendarValue={[new Date(), new Date()]}
                            />
                          </FormControl>
                        </Grid>
                      );
                    }

                    if (value.type == "select") {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          key={index + "-grid"}
                          lg={value?.size || lengthOfInputContainerLG}
                          display={value.display || "block"}
                          visibility={value.visibility || "visible"}
                        >
                          <FormControl fullWidth>
                            {/* <InputLabel id="demo-simple-select-label">
												{" "}
												{value.title}
											</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												name={value.name}
required={value?.required}
												label={value.title}
												value={form?.[value.name] || ""}
												onChange={(event) => {
		setForm({ ...form, [event.target.name]: event.target.value });
	}}
												defaultValue=""
												
											>
											
												{value.options.map((option, index) => (
													<MenuItem value={option?.value||option} key={index}>
														{option?.label||option}
													</MenuItem>
												))}
											</Select> */}
                            <Autocomplete
                              // ListboxProps={{ style: { maxHeight: 150, overflow: 'auto' } }}
                              id="demo-simple-select-label"
                              options={value.options || []}
                              name={value.name}
                              isOptionEqualToValue={(option, value) =>
                                option?.value === value?.value
                              }
                              label={value?.title}
                              value={form?.[value.name] || ""}
                              onChange={(event, newValue) => {
                                setForm({
                                  ...form,
                                  [value.name]: newValue?.value || newValue,
                                });
                              }}
                              defaultValue={value?.defaultValue}
                              disabled={value?.disabled}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={value.title}
                                  required={value?.required}
                                  inputProps={{
                                    ...params.inputProps,
                                    style: { borderColor: 'rgba(0, 0, 0)', color: 'rgba(0, 0, 0, 0.6)' }, // Change border and value color
                                }}
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    style: { color: 'rgba(0, 0, 0, 0.6)' , borderColor: 'rgba(0, 0, 0)'}, // Change label color
                                }}
                                  helperText={
                                    ((isMulti && errorMessage) ||
                                      !allowSubmit) &&
                                    (form?.[value.name] == undefined ||
                                      form?.[value.name]?.length <= 0) &&
                                    value?.required && (
                                      <span style={{ color: "red" }}>
                                        Please fill this field
                                      </span>
                                    )
                                  }
                                  // helperText={(isMulti && errorMessage && (form?.[value.name] == undefined || form?.[value.name]?.length <= 0) && value?.required) && "Please fill this field"}
                                />
                              )}
                              InputProps={{
                                endAdornment: value?.symbol123 && (
                                  <InputAdornment color="secondary">
                                    {value?.symbol123}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        </Grid>
                      );
                    }
                    if (value.type == "select2") {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          key={index + "-grid"}
                          lg={value?.size || lengthOfInputContainerLG}
                          display={value.display || "block"}
                          visibility={value.visibility || "visible"}
                        >
                          <FormControl fullWidth>
                            {/* <InputLabel id="demo-simple-select-label">
												{" "}
												{value.title}
											</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												name={value.name}
required={value?.required}
												label={value.title}
												value={form?.[value.name] || ""}
												onChange={(event) => {
		setForm({ ...form, [event.target.name]: event.target.value });
	}}
												defaultValue=""
												
											>
											
												{value.options.map((option, index) => (
													<MenuItem value={option?.value||option} key={index}>
														{option?.label||option}
													</MenuItem>
												))}
											</Select> */}
                            <Autocomplete
                              // ListboxProps={{ style: { maxHeight: 150, overflow: 'auto' } }}
                              id="demo-simple-select-label"
                              options={value.options || []}
                              name={value.name}
                              isOptionEqualToValue={(option, value) =>
                                option?.value === value?.value
                              }
                              label={value?.title}
                              value={form?.[value.name] || ""}
                              onChange={(event, newValue) => {
                                setForm({
                                  ...form,
                                  [value.name]: newValue?.value || newValue,
                                });
                              }}
                              defaultValue={value?.defaultValue}
                              disabled={value?.disabled}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={value.title}
                                  required={value?.required}
                                  helperText={
                                    ((isMulti && errorMessage) ||
                                      !allowSubmit) &&
                                    (form?.[value.name] == undefined ||
                                      form?.[value.name]?.length <= 0) &&
                                    value?.required && (
                                      <span style={{ color: "red" }}>
                                        Please fill this field
                                      </span>
                                    )
                                  }
                                  // helperText={(isMulti && errorMessage && (form?.[value.name] == undefined || form?.[value.name]?.length <= 0) && value?.required) && "Please fill this field"}
                                />
                              )}
                              InputProps={{
                                endAdornment: value?.symbol123 && (
                                  <InputAdornment color="secondary">
                                    {value?.symbol123}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        </Grid>
                      );
                    }
                    if (value.type == "hr") {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          key={index + "-grid"}
                          lg={12}
                          display={value.display || "block"}
                        >
                          <hr
                            style={{ width: "100%", backgroundColor: "black" }}
                          />
                        </Grid>
                      );
                    }
                    // if (value.type == "Image") {
                    //   return (
                    //     <Grid
                    //       item
                    //       xs={12}
                    //       md={12}
                    //       key={index + "-grid"}
                    //       lg={6}
                    //       display={value.display || "block"}
                    //     >
                    //       <div>
                    //         <img src={Icon} height={"70px"} width={"150px"} />
                    //       </div>
                    //     </Grid>
                    //   );
                    // }

                    if (value.type == "headind_ad") {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          key={index + "-grid"}
                          lg={value?.size || lengthOfInputContainerLG}
                          display={value.display || "block"}
                        >
                          <p
                            style={{
                              marginTop: value?.margin || "-1.5rem",
                              fontWeight: "500",
                              color: "var(--themeFontColor)",
                            }}
                          >
                            {value.title}
                          </p>
                        </Grid>
                      );
                    }
                    if (value.type == "titleLine") {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          key={index + "-grid"}
                          lg={value?.size || lengthOfInputContainerLG}
                          display={value.display || "block"}
                        >
                          <p
                            style={{
                              textAlign: value.textAlign || "center",
                              padding: "10px",
                              fontWeight: "500",
                            }}
                          >
                            {value.title}
                          </p>
                        </Grid>
                      );
                    }
                    if (value.type == "hr2") {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          key={index + "-grid"}
                          lg={12}
                          display={value.display || "block"}
                        >
                          <hr
                            style={{
                              padding: "5px",
                              width: "100%",
                              height: "5px",
                              marginTop: "-0.5rem",
                            }}
                          />
                        </Grid>
                      );
                    }
                    if (value.type == "BackBtn") {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          key={index + "-grid"}
                          lg={12}
                          display={value.display || "block"}
                        >
                          <Link to="/login">
                            <p
                              style={{
                                textAlign: "center",
                                color: "black",
                              }}
                            >
                              Back To Sign In
                            </p>
                          </Link>
                        </Grid>
                      );
                    }
                    if (value.type == "select_multiple") {
                      return (
                        // <Grid
                        //   item

                        //   xs={12}
                        //   md={12}
                        //   key={index + "-grid"}
                        //   lg={value?.size || lengthOfInputContainerLG}
                        //   // display={ value.isPlusBtnOpen ?value.display ||  "flex" : value.display ||  "block"}
                        // >

                        value?.isPlusBtnOpen ? (
                          <>
                            <Grid
                              item
                              xs={10}
                              md={10}
                              key={index + "-grid"}
                              lg={5}
                              // display={"inline-block"}
                            >
                              <FormControl fullWidth required={value?.required}>
                                <Autocomplete
                                  multiple
                                  id="tags-outlined"
                                  name={value.name}
                                  // ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                                  options={value.options}
                                  getOptionLabel={(option) => option}
                                  onChange={(event, newValue) => {
                                    setForm({
                                      ...form,
                                      [value.name]: newValue,
                                    });
                                  }}
                                  value={form?.[value.name] || []}
                                  filterSelectedOptions
                                  getOptionDisabled={(option) =>
                                    form?.[value.name]?.length ==
                                      (value?.maxSelections || 10) ||
                                    form?.[value.name]?.includes(option)
                                      ? true
                                      : false
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required={value?.required}
                                      label={value.title}
                                      helperText={
                                        (form?.[value.name]?.length ==
                                          (value?.maxSelections || 10) &&
                                          (value?.errorText || (
                                            <span style={{ color: "red" }}>
                                              Maximum number of selections have
                                              been made.
                                            </span>
                                          ))) ||
                                        (((isMulti && errorMessage) ||
                                          !allowSubmit) &&
                                          (form?.[value.name] == undefined ||
                                            form?.[value.name]?.length <= 0) &&
                                          value?.required && (
                                            <span style={{ color: "red" }}>
                                              Please fill this field
                                            </span>
                                          ))
                                      }
                                      // helperText={(form?.[value.name]?.length == (value?.maxSelections || 10) && (value?.errorText || "Maximum number of selections have been made.")) || ((isMulti && errorMessage && (form?.[value.name] == undefined || form?.[value.name]?.length <= 0) && value?.required) && "Please fill this field")}
                                      placeholder={value.title}
                                      inputProps={{
                                        ...params.inputProps,
                                        required:
                                          form?.[value.name]?.length === 0,
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              md={1}
                              key={index + "-grid"}
                              lg={0.5}
                              // display={"inline-block"}
                              // display={value.display || "block"}
                            >
                              {value?.isPlusBtnOpen && (
                                <Button
                                  type="button"
                                  variant="contained"
                                  style={{
                                    background: value?.noBg
                                      ? ""
                                      : "linear-gradient(225deg,  var(--gradientColor1) 0%, var(--gradientColor2) 91.25%)",
                                  }}
                                  sx={{
                                    // mt: 1,
                                    ml: 1,

                                    textTransform: "capitalize",
                                    borderRadius: "8px",
                                    fontWeight: "500",
                                    fontSize: "13px",
                                    padding: "15px 20px",
                                    minWidth: "0",
                                    minHeight: "0",
                                    color:
                                      "var(--tableHeadFontColor) !important",
                                  }}
                                  onClick={(e) => value?.handlePopUp()}
                                >
                                  <AddIcon sx={{ color: "#fff !important" }} />
                                </Button>
                              )}
                            </Grid>
                          </>
                        ) : (
                          <Grid
                            item
                            xs={12}
                            md={12}
                            key={index + "-grid"}
                            lg={value?.size || lengthOfInputContainerLG}
                            display={value.display || "block"}
                          >
                            <FormControl fullWidth required={value?.required}>
                              <Autocomplete
                                multiple
                                id="tags-outlined"
                                name={value.name}
                                // ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
                                options={value.options}
                                getOptionLabel={(option) => option}
                                onChange={(event, newValue) => {
                                  setForm({ ...form, [value.name]: newValue });
                                }}
                                value={form?.[value.name] || []}
                                filterSelectedOptions
                                getOptionDisabled={(option) =>
                                  form?.[value.name]?.length ==
                                    (value?.maxSelections || 10) ||
                                  form?.[value.name]?.includes(option)
                                    ? true
                                    : false
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required={value?.required}
                                    label={value.title}
                                    helperText={
                                      (form?.[value.name]?.length ==
                                        (value?.maxSelections || 10) &&
                                        (value?.errorText || (
                                          <span style={{ color: "red" }}>
                                            Maximum number of selections have
                                            been made.
                                          </span>
                                        ))) ||
                                      (((isMulti && errorMessage) ||
                                        !allowSubmit) &&
                                        (form?.[value.name] == undefined ||
                                          form?.[value.name]?.length <= 0) &&
                                        value?.required && (
                                          <span style={{ color: "red" }}>
                                            Please fill this field
                                          </span>
                                        ))
                                    }
                                    // helperText={(form?.[value.name]?.length == (value?.maxSelections || 10) && (value?.errorText || "Maximum number of selections have been made.")) || ((isMulti && errorMessage && (form?.[value.name] == undefined || form?.[value.name]?.length <= 0) && value?.required) && "Please fill this field")}
                                    placeholder={value.title}
                                    inputProps={{
                                      ...params.inputProps,
                                      required:
                                        form?.[value.name]?.length === 0,
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                        )

                        // </Grid>
                      );
                    }
                    // if(value.type == "Date_Range"){
                    //   <Grid
                    //   item
                    //   xs={10}
                    //   sm={6}
                    //   md={6}
                    //   lg={
                    //    6
                    //   }
                    //   key={"dateRange"}
                    // >
                    //   <FormControl fullWidth>
                    //     <DateRangePicker
                    //       size="md"
                    //       placeholder={"Date range"}
                    //       ranges={[]}
                    //       name={"value.name"}
                    //       value={dateRange_report || ""}
                    //       format="dd-MM-yyyy"
                    //       onChange={(range) => { setDateRange_report(range) }
                    //       }
                    //       defaultCalendarValue={[new Date(), new Date()]}
                    //     />
                    //   </FormControl>
                    // </Grid>
                    // }
                    if (value.type == "Checkbox") {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          key={index + "-grid"}
                          lg={12}
                          display={value.display || "block"}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                inputProps={{ "aria-label": "controlled" }}
                                name={value.name}
                                //   checked={true}
                                //   checked={form?.rights?.[index]?.["edit"] == "true"}
                                onChange={(event) =>
                                  value.forceBox
                                    ? value.handleChanges(event)
                                    : handleFormSubmit(event)
                                }
                              />
                            }
                            label={value.title}
                          />
                        </Grid>
                      );
                    }
                    if (value.type == "switch") {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          key={index + "-grid"}
                          lg={lengthOfInputContainerLG}
                          marginTop={"12px"}
                          container
                          direction="row"
                          alignItems={"center"}
                          display={value.display || "block"}
                        >
                          <FormControlLabel
                            label={value.title}
                            labelPlacement="start"
                            control={
                              <Switch
                                defaultChecked
                                name={value.name}
                                required={value?.required}
                                onChange={(event) => {
                                  setForm({
                                    ...form,
                                    [event.target.name]: event.target.value,
                                  });
                                }}
                              />
                            }
                          />
                        </Grid>
                      );
                    }
                    if (value.type == "toggle") {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          key={index + "-grid"}
                          lg={value?.size || lengthOfInputContainerLG}
                          container
                          direction="row"
                          alignItems={"center"}
                          display={value.display || "block"}
                        >
                          <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">
                              {value.title}
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                            >
                              {value.options.map((option, index) => (
                                <FormControlLabel
                                  key={value.name + index}
                                  value={option.value}
                                  name={value.name}
                                  required={value?.required}
                                  onChange={(event) => {
                                    setForm({
                                      ...form,
                                      [event.target.name]: event.target.value,
                                    });
                                  }}
                                  control={
                                    <Radio
                                      sx={{
                                        "&.Mui-checked": {
                                          color: option?.color,
                                        },
                                      }}
                                      // color={option?.color}
                                      checked={
                                        form?.[value.name] != undefined
                                          ? form?.[value.name] == option.value
                                          : option.value == value?.default
                                      }
                                    />
                                  }
                                  label={option.value}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      );
                    }
                    if (value.type == "description") {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          lg={12}
                          key={index + "-grid"}
                          display={value.display || "block"}
                        >
                          <Typography
                            as="h5"
                            sx={{
                              fontWeight: "500",
                              fontSize: "14px",
                              mb: "12px",
                            }}
                          >
                            {value.title}
                            {value.required && (
                              <span style={{ color: "red", fontSize: "16px" }}>
                                {" "}
                                *
                              </span>
                            )}
                          </Typography>

                          <RichTextEditor
                            id="rte"
                            value={form?.[value.name]}
                            onChange={(content) =>
                              setForm({ ...form, [value.name]: content })
                            }
                            controls={[
                              ["bold", "italic", "underline"],
                              ["unorderedList", "h1", "h2", "h3", "h4"],
                              ["sup", "sub"],
                              ["alignLeft", "alignCenter", "alignRight"],
                            ]}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <p>
                              {form?.[value.name]?.length}/
                              {value?.limit || "200"}
                            </p>
                          </div>
                          <p
                            style={{
                              fontSize: "10px",
                              color: "red",
                              width: "100%",
                            }}
                          >
                            {!allowSubmit &&
                              (form[value.name] == "" ||
                                form[value.name] == [] ||
                                form[value.name] == null ||
                                form?.[value.name]?.length <= 0) &&
                              value?.required && (
                                <span style={{ color: "red" }}>
                                  Please fill this field
                                </span>
                              )}
                          </p>
                          <p
                            style={{
                              fontSize: "10px",
                              color: "red",
                              width: "100%",
                            }}
                          >
                            {isMulti &&
                              errorMessage &&
                              (form?.[value.name] == undefined ||
                                form?.[value.name]?.length <= 0) &&
                              value?.required &&
                              "Please fill this field"}
                          </p>
                        </Grid>
                      );
                    }
                    if (value.type == "image") {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={value?.size || 4}
                          xl={value?.size || 2}
                          key={index + "-grid"}
                          display={value.display || "block"}
                        >
                          <label htmlFor={index + value?.name} className="btn">
                            {" "}
                            <Box
                              sx={{
                                // background: "#F3F6F9",
                                borderRadius: "10px",
                                padding: "10px 5px",
                                paddingBottom: "0px",
                                textAlign: "center",
                              }}
                              className="dark-BG-101010"
                            >
                              <img
                                src={
                                  form?.[value.name]
                                    ? typeof form?.[value.name] == "string"
                                      ? IMAGE + form?.[value.name]
                                      : URL?.createObjectURL(form?.[value.name])
                                    : value?.image || imageJPG
                                }
                                alt="Icon"
                                style={{ borderRadius: "10px" }}
                                height={value?.imageHeight || "130px"}
                                // width={value?.imageWidth || "120px"}
                              />

                              <Typography
                                mt={1}
                                fontWeight="500"
                                fontSize="13px"
                              >
                                {value?.title}
                                {value?.required === true ? (
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "red",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    *
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Typography>
                              {value?.subtitle && (
                                <>
                                  <span
                                    style={{ fontSize: "9px", color: "red" }}
                                  >
                                    {value?.subtitle}
                                  </span>
                                  <br />
                                </>
                              )}
                              {value?.subsubtitle && (
                                <>
                                  <span
                                    style={{ fontSize: "9px", color: "red" }}
                                  >
                                    {value?.subsubtitle}
                                  </span>
                                  <br />
                                </>
                              )}
                              {value?.subsubsubtitle && (
                                <>
                                  <span
                                    style={{ fontSize: "9px", color: "red" }}
                                  >
                                    {value?.subsubsubtitle}
                                  </span>{" "}
                                  <br />
                                </>
                              )}
                              {/* {(Math.round((form?.[value.name]?.size / 1024))>1024)&&
											<>
												<span style={{ fontSize: "10px", color: "red" }}>
											File size should not be more than 1 MB.
												</span><br /></>
												(
													dispatch({ type: MESSAGE, payload:{Message:"File Size too big. Please choose a different file"} })
												setTimeout(()=>{

													delete form?.[value.name]
												},5000)
												
												
						)
						}	 */}
                              {/* {typeof(form?.[value.name])!="string"&&form?.[value.name]&&(!["image/jpeg", "image/png", "image/jpg", "text/srt"].includes(form?.[value.name]?.type))&&
											<>
											<span style={{ fontSize: "10px", color: "red" }}>
												Please Upload Valid file Format
											</span><br /></>
											(
												dispatch({ type: MESSAGE, payload:{Message:"File Size too big. Please choose a different file"} })
											setTimeout(()=>{
										
												delete form?.[value.name]
											},5000)
											
											
					)
					} */}

                              <input
                                type="file"
                                id={index + value?.name}
                                name={value.name}
                                required={value?.required && !isEdit}
                                crossOrigin="true"
                                placeholder="Choose Image"
                                style={{
                                  visibility: "hidden",
                                  position: "relative",
                                  zIndex: "10",
                                  height: "100%",
                                }}
                                accept={value?.accept || ".jpeg,.png,.jpg,.srt"}
                                onClick={(event) => {
                                  event.target.value = [];
                                }}
                                onChange={(event) => {
                                  if (
                                    Math.round(
                                      event.target.files[0]?.size / 1024
                                    ) > 1024
                                  ) {
                                    setOpenAdError(true);
                                    setPopupContent(
                                      "File size should not be more than 1 MB."
                                    );
                                  } else if (
                                    ![
                                      "image/jpeg",
                                      "image/png",
                                      "image/jpg",
                                      "text/srt",
                                      "application/pdf",
                                      "",
                                    ].includes(event.target.files[0]?.type)
                                  ) {
                                    setOpenAdError(true);

                                    setPopupContent(
                                      "Please Upload Valid file Format 1"
                                    );
                                  } else {
                                    setForm({
                                      ...form,
                                      [event.target.name]:
                                        event.target.files[0],
                                    });
                                  }
                                }}
                                // onChange={async (event) => {;
                                //   const file = event.target.files[0];
                                //   if (file) {
                                //     try {
                                //       const compressedFile = await compressImage(file);
                                //       setForm({
                                //         ...form,
                                //         [event.target.name]: compressedFile,
                                //       });

                                //     } catch (error) {
                                //       // console.error("Error compressing image:", error);
                                //       // Handle error
                                //     }
                                //   }
                                // }}
                              />
                            </Box>
                          </label>
                          <p
                            style={{
                              fontSize: "10px",
                              color: "red",
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            {!allowSubmit &&
                              (form[value.name] == "" ||
                                form[value.name] == [] ||
                                form[value.name] == null ||
                                form?.[value.name]?.length <= 0) &&
                              value?.required && (
                                <span style={{ color: "red" }}>
                                  Please fill this field
                                </span>
                              )}
                          </p>
                          <p
                            style={{
                              fontSize: "10px",
                              color: "red",
                              width: "100%",
                              textAlign: "center",
                            }}
                          >
                            {isMulti &&
                              errorMessage &&
                              (form?.[value.name] == undefined ||
                                form?.[value.name]?.length <= 0) &&
                              value?.required && (
                                <span style={{ color: "red" }}>
                                  Please fill this field
                                </span>
                              )}
                          </p>
                        </Grid>
                      );
                    }
                    // if (value.type == "file1") {
                    //   return (
                    //     <Grid
                    //       item
                    //       xs={12}
                    //       sm={6}
                    //       md={4}
                    //       lg={4}
                    //       xl={value?.size || 2}
                    //       key={index + "-grid"}
                    //       display={value.display || "block"}
                    //     >
                    //       <div className={styles.imageInput} style={{marginTop:"50px" , border:"1px solid black" , width:"100%"}}>
                    //         <label for={index + "image"} className="btn">
                    //           {form?.[value.name]
                    //             ? typeof form?.[value.name] == "string"
                    //               ? form?.[value.name].split("/")[4]
                    //               : form?.[value.name]?.name
                    //             : value.title}
                    //           <span style={{ fontSize: "9px", color: "red" }}>
                    //             {value.subtitle}
                    //           </span>
                    //         </label>
                    //         <input
                    //           type="file"
                    //           id={index + "image"}
                    //           name={value.name}
                    //           required={value?.required && !isEdit}
                    //           placeholder="Choose Image"
                    //           style={{
                    //             visibility: "hidden",
                    //             position: "relative",
                    //             zIndex: "10",
                    //             height: "100%",
                    //           }}
                    //           accept={value?.accept || ".jpeg,.png,.jpg,.srt"}
                    //           onClick={(event) => {
                    //             event.target.value = [];
                    //           }}
                    //           onChange={(event) => {
                    //             // console.log(event.target.files[0].type, "test");
                    //             if (
                    //               Math.round(event.target.files[0]?.size / 1024) >
                    //               1024
                    //             ) {
                    //               setOpenAdError(true);
                    //               setPopupContent(
                    //                 "File size should not be more than 1 MB."
                    //               );
                    //             } else if (
                    //               ![
                    //                 "image/jpeg",
                    //                 "image/png",
                    //                 "image/jpg",
                    //                 "text/srt",
                    //                 "application/pdf",
                    //                 "",
                    //                 "audio/mpeg",
                    //               ].includes(event.target.files[0]?.type)
                    //             ) {

                    //               setOpenAdError(true);
                    //               setPopupContent(
                    //                 "Please Upload Valid file Format 3"
                    //               );
                    //             } else {

                    //               setForm({
                    //                 ...form,

                    //                 [event.target.name]: event.target.files[0],
                    //               });
                    //             }
                    //           }}
                    //         />
                    //       </div>

                    //       <p
                    //         style={{
                    //           fontSize: "10px",
                    //           color: "red",
                    //           width: "100%",
                    //           textAlign: "center",
                    //         }}
                    //       >
                    //         {!allowSubmit &&
                    //           (form[value.name] == "" ||
                    //             form[value.name] == [] ||
                    //             form[value.name] == null ||
                    //             form?.[value.name]?.length <= 0) &&
                    //           value?.required &&
                    //           <span style={{color:"red"}}>Please fill this field</span>}
                    //       </p>
                    //       <p
                    //         style={{
                    //           fontSize: "10px",
                    //           color: "red",
                    //           width: "100%",
                    //           textAlign: "center",
                    //         }}
                    //       >
                    //         {isMulti &&
                    //           errorMessage &&
                    //           (form?.[value.name] == undefined ||
                    //             form?.[value.name]?.length <= 0) &&
                    //           value?.required &&
                    //           <span style={{color:"red"}}>Please fill this field</span>}
                    //       </p>
                    //     </Grid>
                    //   );
                    // }
                    // if (value.type == "file1") {
                    //   const fileInputRef = useRef(null); // Create ref for file input

                    //   // Helper function to truncate file names longer than 15 characters
                    //   const truncateFileName = (fileName) => {
                    //     if (fileName.length > 15) {
                    //       return fileName.substring(0, 15) + "...";
                    //     }
                    //     return fileName;
                    //   };

                    //   return (
                    //     <Grid
                    //       item
                    //       xs={12}
                    //       sm={6}
                    //       md={4}
                    //       lg={4}
                    //       xl={value?.size || 2}
                    //       key={index + "-grid"}
                    //       style={{ padding: "20px" }}
                    //     >
                    //       <div
                    //         // className={styles.imageInput}
                    //         style={{
                    //           border: "1px solid #ccc",
                    //           borderRadius: "8px",
                    //           padding: "15px",
                    //           textAlign: "center",
                    //           display: "flex",
                    //           flexDirection: "column",
                    //         }}
                    //       >
                    //         {/* Display the file name if a file is selected */}
                    //         {form?.[value.name] && (
                    //           <div
                    //             style={{
                    //               marginBottom: "10px",
                    //               fontWeight: "bold",
                    //               fontSize: "14px",
                    //               color: "#333",
                    //             }}
                    //           >
                    //             <strong>File Name:</strong>{" "}
                    //             {typeof form?.[value.name] === "string"
                    //               ? truncateFileName(
                    //                   form?.[value.name].split("/")[5]
                    //                 )
                    //               : truncateFileName(form?.[value.name]?.name)}
                    //           </div>
                    //         )}

                    //         {/* Display the title */}
                    //         <label
                    //           htmlFor={index + "image"}
                    //           className={styles.titleLabel}
                    //           style={{
                    //             display: "block",
                    //             marginBottom: "15px",
                    //             fontSize: "16px",
                    //             color: "#555",
                    //           }}
                    //         >
                    //           {value.title}
                    //         </label>

                    //         {/* Button to trigger the file input click */}
                    //         <button
                    //           type="button"
                    //           className="btn"
                    //           style={{
                    //             padding: "10px 20px",
                    //             backgroundColor: "#007bff",
                    //             color: "#fff",
                    //             border: "none",
                    //             borderRadius: "5px",
                    //             cursor: "pointer",
                    //             zIndex: "10", // Ensure the button stays on top
                    //           }}
                    //           onClick={() => fileInputRef.current.click()} // Trigger file input on button click
                    //         >
                    //           Upload
                    //         </button>

                    //         {/* Subtitle under the button */}
                    //         <span
                    //           style={{
                    //             display: "block",
                    //             fontSize: "12px",
                    //             color: "#888",
                    //             marginTop: "10px",
                    //           }}
                    //         >
                    //           {value.subtitle}
                    //         </span>

                    //         {/* Hidden file input */}
                    //         <input
                    //           type="file"
                    //           id={index + "image"}
                    //           name={value.name}
                    //           required={value?.required && !isEdit}
                    //           ref={fileInputRef} // Attach ref to file input
                    //           style={{
                    //             visibility: "hidden",
                    //             position: "absolute", // Hide input field
                    //             zIndex: "-1", // Ensure it's out of view
                    //           }}
                    //           accept={value?.accept || ".jpeg,.png,.jpg,.srt"}
                    //           onClick={(event) => {
                    //             event.target.value = [];
                    //           }}
                    //           onChange={(event) => {
                    //             if (
                    //               Math.round(
                    //                 event.target.files[0]?.size / 1024
                    //               ) > 1024
                    //             ) {
                    //               setOpenAdError(true);
                    //               setPopupContent(
                    //                 "File size should not be more than 1 MB."
                    //               );
                    //             } else if (
                    //               ![
                    //                 "image/jpeg",
                    //                 "image/png",
                    //                 "image/jpg",
                    //                 "text/srt",
                    //                 "application/pdf",
                    //                 "audio/mpeg",
                    //               ].includes(event.target.files[0]?.type)
                    //             ) {
                    //               setOpenAdError(true);
                    //               setPopupContent(
                    //                 "Please Upload Valid file Format 3"
                    //               );
                    //             } else {
                    //               setForm({
                    //                 ...form,
                    //                 [event.target.name]: event.target.files[0],
                    //               });
                    //             }
                    //           }}
                    //         />
                    //       </div>

                    //       {/* Validation messages */}
                    //       <p
                    //         style={{
                    //           fontSize: "10px",
                    //           color: "red",
                    //           width: "100%",
                    //           textAlign: "center",
                    //         }}
                    //       >
                    //         {!allowSubmit &&
                    //           (form[value.name] === "" ||
                    //             form[value.name] === [] ||
                    //             form[value.name] === null ||
                    //             form?.[value.name]?.length <= 0) &&
                    //           value?.required && (
                    //             <span style={{ color: "red" }}>
                    //               Please fill this field
                    //             </span>
                    //           )}
                    //       </p>
                    //       <p
                    //         style={{
                    //           fontSize: "10px",
                    //           color: "red",
                    //           width: "100%",
                    //           textAlign: "center",
                    //         }}
                    //       >
                    //         {isMulti &&
                    //           errorMessage &&
                    //           (form?.[value.name] === undefined ||
                    //             form?.[value.name]?.length <= 0) &&
                    //           value?.required && (
                    //             <span style={{ color: "red" }}>
                    //               Please fill this field
                    //             </span>
                    //           )}
                    //       </p>
                    //     </Grid>
                    //   );
                    // }
                    if (value.type === "file1") {
                      // Make sure each file input has its own ref
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={4}
                          xl={value?.size || 2}
                          key={index + "-grid"}
                          style={{ padding: "20px" }}
                        >
                          <div
                            style={{
                              border: "1px solid #ccc",
                              borderRadius: "8px",
                              padding: "15px",
                              textAlign: "center",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {/* Display the file name if a file is selected */}
                            {form?.[value.name] && (
                              <div
                                style={{
                                  marginBottom: "10px",
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                  color: "#333",
                                }}
                              >
                                <strong>File Name:</strong>{" "}
                                {typeof form?.[value.name] === "string"
                                  ? truncateFileName(form?.[value.name].split("/")[5])
                                  : truncateFileName(form?.[value.name]?.name)}
                              </div>
                            )}
                  
                            {/* Display the title */}
                            <label
                              htmlFor={index + "image"}
                              className={styles.titleLabel}
                              style={{
                                display: "block",
                                marginBottom: "15px",
                                fontSize: "16px",
                                color: "#555",
                              }}
                            >
                              {value.title}
                            </label>
                  
                            {/* Button to trigger the file input click */}
                            <button
                              type="button"
                              className="btn"
                              style={{
                                padding: "10px 20px",
                                backgroundColor: "rgb(81 184 70)",
                                color: "#fff",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer",
                                zIndex: "10", // Ensure the button stays on top
                              }}
                              onClick={() => fileInputRefs.current[index].click()} // Trigger file input for the current index
                            >
                              Upload
                            </button>
                  
                            {/* Subtitle under the button */}
                            <span
                              style={{
                                display: "block",
                                fontSize: "12px",
                                color: "#888",
                                marginTop: "10px",
                              }}
                            >
                              {value.subtitle}
                            </span>
                  
                            {/* Hidden file input */}
                            <input
                              type="file"
                              id={index + "image"}
                              name={value.name}
                              required={value?.required && !isEdit}
                              ref={(el) => (fileInputRefs.current[index] = el)} // Assign unique ref to each input
                              style={{
                                visibility: "hidden",
                                position: "absolute", // Hide input field
                                zIndex: "-1", // Ensure it's out of view
                              }}
                              accept={value?.accept || ".jpeg,.png,.jpg,.srt,.pdf"}
                              onClick={(event) => {
                                event.target.value = [];
                              }}
                              onChange={(event) => {
                                const file = event.target.files[0];
                                if (Math.round(file?.size / 1024) > 1024) {
                                  setOpenAdError(true);
                                  setPopupContent("File size should not be more than 1 MB.");
                                } else if (
                                  ![
                                    "image/jpeg",
                                    "image/png",
                                    "image/jpg",
                                    "text/srt",
                                    "application/pdf",
                                    "audio/mpeg",
                                  ].includes(file?.type)
                                ) {
                                  setOpenAdError(true);
                                  setPopupContent("Please Upload Valid file Format 3");
                                } else {
                                  setForm({
                                    ...form,
                                    [event.target.name]: event.target.files[0],
                                  });
                                }
                              }}
                            />
                          </div>
                  
                          {/* Validation messages */}
                          <p
                            style={{
                              fontSize: "10px",
                              color: "red",
                              width: "100%",
                            }}
                          >
                            {!allowSubmit &&
                              (form[value.name] == "" ||
                                form[value.name] == [] ||
                                form[value.name] == null ||
                                form?.[value.name]?.length <= 0) &&
                              value?.required && (
                                <span style={{ color: "red" ,textAlign:"center" }}>
                                  Please fill this field
                                </span>
                              )}
                          </p>
                          <p
                            style={{
                              fontSize: "10px",
                              color: "red",
                              width: "100%",
                            }}
                          >
                            {isMulti &&
                              errorMessage &&
                              (form?.[value.name] == undefined ||
                                form?.[value.name]?.length <= 0) &&
                              value?.required &&
                              "Please fill this field"}
                          </p>
                        </Grid>
                      );
                    }

                    if (value.type == "undefined") {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={6}
                          key={index + "-grid"}
                          lg={4}
                          xl={value?.size || 2}
                          container
                          direction="row"
                          alignItems={"center"}
                          display={value.display || "block"}
                        ></Grid>
                      );
                    }
                    if (value.type == "specialElement") {
                      return (
                        <Grid
                          item
                          xs={12}
                          md={12}
                          key={index + "-grid"}
                          lg={value?.size || 12}
                          container
                          direction="row"
                          alignItems={"center"}
                          display={value.display || "block"}
                        >
                          {value.element}
                        </Grid>
                      );
                    }
                    if (
                      (value.type == "button" && value.forceShow) ||
                      !isMulti
                    ) {
                      return (
                        <Grid
                          item
                          xs={value?.size || 12}
                          lg={value?.size}
                          key={index + "-grid"}
                          textAlign={value?.align || "center"}
                          display={value.display || "block"}
                        >
                          {/* <Button
                      type={value.forceShow && "button"}
                      variant="contained"
                      disabled={value?.disabled}
                      style={{
                        background: value?.noBg
                          ? ""
                          : "linear-gradient(225deg,  var(--gradientColor1) 0%, var(--gradientColor2) 91.25%)",
                      }}
                      sx={{
                        mt: 1,
                        textTransform: "capitalize",
                        borderRadius: "8px",
                        fontWeight: "500",
                        fontSize: "13px",
                        padding: value?.padding || "12px 20px",
                        minWidth: "0",
                        minHeight: "0",
                        color: "var(--tableHeadFontColor) !important",
                      }}
                      onClick={(e) =>
                        value.forceShow
                          ? value?.handleClick()
                          : handleFormSubmit(e)
                      }
                    >
                      {value.forceShow
                        ? value.title
                        : isEdit
                        ? "Update"
                        : value.title}
                    </Button> */}
                          <Button
                            type={value.forceShow && "button"}
                            variant="contained"
                            disabled={value?.disabled}
                            // endIcon={<SendIcon />}
                            // loading={loading}
                            //  loadingPosition="end"
                            style={{
                              background: value?.noBg
                                ? ""
                                : "linear-gradient(225deg,  var(--view-grid-bg) 0%, var(--view-grid-bg) 91.25%)",
                            }}
                            sx={{
                              mt: 1,
                              textTransform: "capitalize",
                              borderRadius: "8px",
                              fontWeight: "500",
                              fontSize: "13px",
                              padding: value?.padding || "12px 20px",
                              minWidth: "0",
                              minHeight: "0",
                              color: "black !important",
                            }}
                            onClick={(e) =>
                              value.forceShow
                                ? value?.handleClick()
                                : handleFormSubmit(e)
                            }
                          >
                            <span style={{ color: "black" }}>
                              {" "}
                              {value.forceShow
                                ? value.title
                                : isEdit
                                ? "Update"
                                : value.title}
                            </span>
                          </Button>
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
