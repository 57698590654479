import React, { useEffect, useMemo, useState } from "react";

import { Card, CardBody, CardHeader, Nav } from "reactstrap";
import { Image, H5 } from "../../../AbstractElements";
import { DailyDropdown, RecentOrdersTitle } from "../../../Constant";
import DropdownCommon from "../../Common/Dropdown";
import RecentOrderContentTab from "./RecentOrderContentTab";
import useShowClass from "../../../Hooks/useShowClass";
import { useDispatch, useSelector } from "react-redux";
import { all_category_list } from "../../../actions/category";
import { bindActionCreators } from "redux";
import { IMAGE } from "../../../api";
import * as Action from "../../../actions/dashboard";
// import { dashboard_graph_category_wise_recent_orders } from "../../../actions/dashboard";

const RecentOrders = () => {
  const [isActive, setIsActive] = useState("0");
  const dispatch = useDispatch();
  const [show, setShow] = useShowClass("show");
  const [productData, setProductData] = useState([]);
  const RecentOrdersNav = ["1", "2", "3", "4", "5"];
  const category_data = useSelector((state) => state?.category?.category_list);
  const userInfo = JSON.parse(sessionStorage.getItem("user"));
  const { dashboard_graph_category_wise_recent_orders } = bindActionCreators(
    Action,
    dispatch
  );
  const RecentOrder = useSelector(
    (state) => state?.dashboard?.recent_order_by_category
  );
  // console.log("RecentOrder", RecentOrder);
  const activeHandle = async (i) => {
    setIsActive(`${i}`);
    setShow("");
    if(userInfo?.id?.userrole?.rolename == "Admin"){

      const res = await dashboard_graph_category_wise_recent_orders({
        admin_id: userInfo?.id?.id,
        category_id: i,
        user_type :"admin"
      });
      if (res?.statuscode == 200) {
        setProductData(res?.data);
      }
    }else{
      const res = await dashboard_graph_category_wise_recent_orders({
        vendor_id: userInfo?.id?.id,
        category_id: i,
        user_type :"vendor"
      });
      if (res?.statuscode == 200) {
        setProductData(res?.data);
      }
    }
  };

  useEffect(() => {
    dispatch(all_category_list());
  }, []);

  useMemo(() => {
    if (category_data?.statuscode == 200) {
      // console.log("chhhfd");
      (async () => {
        // const data = new FormData();
        // data.append("user", producer_id);
        // dispatch(bank_detail_list(data));
        // console.log("chhhfd2");
        if(userInfo?.id?.userrole?.rolename == "Admin"){
          const resData = await dashboard_graph_category_wise_recent_orders({
            admin_id: userInfo?.id?.id,
            category_id: category_data?.data[0]?.id,
            user_type : "admin"
          });
          // console.log("chhhfd3" ,resData);
          if (resData?.statuscode == 200) {
            // console.log("chhhfd4");
            setProductData(resData?.data);
          } 
        }else{
          const resData = await dashboard_graph_category_wise_recent_orders({
            vendor_id: userInfo?.id?.id,
            category_id: category_data?.data[0]?.id,
            user_type : "vendor"
          });
          // console.log("chhhfd3" ,resData);
          if (resData?.statuscode == 200) {
            // console.log("chhhfd4");
            setProductData(resData?.data);
          } 
        }
      })();
    }
  }, [category_data]);
  // console.log(productData, "ccdd");
  return (
    <Card className="recent-order">
      <CardHeader className="card-no-border">
        <div className="header-top">
          <H5 attrH5={{ className: "m-0" }}>{RecentOrdersTitle}</H5>
          <div className="card-header-right-icon">
            <DropdownCommon
              dropdownMain={{ className: "icon-dropdown", direction: "start" }}
              options={DailyDropdown}
              iconName="icon-more-alt"
              btn={{ tag: "span" }}
            />
          </div>
        </div>
      </CardHeader>
      <CardBody className="pt-0">
        <div className="recent-sliders">
          <Nav tag="div" pills={true} tabs>
            {category_data?.data.map((item, j) => (
              <button
                key={item?.id}
                onClick={(e) => activeHandle(item?.id)}
                className={`frame-box ${
                  isActive === `${item?.id}` && "active"
                }`}
              >
                <span className="frame-image">
                  <Image
                    attrImage={{
                      src: IMAGE + item?.category_image,
                      style: { height: "60px" },
                      alt: "vector T-shirt",
                    }}
                  />
                </span>
              </button>
            ))}
          </Nav>
          <RecentOrderContentTab
            show={show}
            isActive={isActive}
            productData={productData}
            RecentOrdersNav={RecentOrdersNav}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default RecentOrders;
