 import * as actionType from "../Constant/index";

const settingReducer = (state = { settingData: null }, action) => {
	switch (action.type) {
		
	
			case actionType.ABOUTUS:
			return {...state,aboutus: action.payload};

			case actionType.TERMSCONDITIONS:
			return {...state,termsconditions: action.payload};
			case actionType.PRIVACYPOLICY:
			return {...state,privacypolicy: action.payload};
	
			
		default:
			return state;
	}
};

export default settingReducer;
