import React, { useState, useMemo } from "react";
import Card from "@mui/material/Card";
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Form from "./Form";
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';


export default function MultiStepForm({ formStructure,
  formTitle,
  setForm,
  formTitleNew,
  form,
  isEdit,
  handleSubmit }) {
  const [activeStep, setActiveStep] = useState(0)
  const [errorMessage, setErrorMessage] = useState(false)
  const [allowNext, setAllowNext] = useState(false)
  const [loading ,setLoading] = useState(false)
  useMemo(() => {
    var requiredFieldCount = 0
    var requiredFieldFilledCount = 0
    formStructure[activeStep].map((value) => {
      if ((value.required && value.display != "none")) {
        requiredFieldCount = requiredFieldCount + 1
        if (Object.keys(form).includes(value.name) && form[value.name] != "" && form[value.name] != [] && form[value.name] != null) {
          requiredFieldFilledCount = requiredFieldFilledCount + 1
        }
      }
    })
    if (requiredFieldFilledCount == requiredFieldCount)
      setAllowNext(true)
    else
      setAllowNext(false)
  }, [form, activeStep])

  const loadingBtn = () =>{
    console.log("NewParthGohe;")
    setLoading(true)
    setTimeout(()=>{
      setLoading(false)
    },10000)
  }
  return (
    <>
      <Box component="form" onSubmit={handleSubmit}>
        <Card
          sx={{
            boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            borderRadius: "10px",
            p: "25px",
            mb: "15px",
            backgroundColor: "#f8fcff"
          }}
        >


<Box sx={{ width: '100%' }}>
           { !formTitleNew ? <Stepper activeStep={activeStep + 1} alternativeLabel className="direction-ltr">
              {formTitle.map((label, index) => (
                <Step key={index + "step"}>
                  <StepLabel>{label}</StepLabel>

                </Step>
              ))}
            </Stepper> : <Stepper activeStep={activeStep + 1} alternativeLabel className="direction-ltr">
              {formTitleNew.map((label, index) => (
                <Step key={index + "step"}>
                  <StepLabel>{label}</StepLabel>

                </Step>
              ))}
            </Stepper>} 
          </Box>
          <Form
            formStructure={formStructure[activeStep]}
            handleSubmit={handleSubmit}
            formTitle={formTitle[activeStep]}
            key={"Form"}
            setForm={setForm}
            form={form}
            isEdit={isEdit}
            isMulti={true}
            errorMessage={errorMessage}
            setAllowNext={setAllowNext}
          />
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            {activeStep > 0 && <Button
              variant="contained"
              style={{ background: "linear-gradient(225deg,  var(--gradientColor1) 0%, var(--gradientColor2) 91.25%)" }}

              onClick={() => {
                setActiveStep(prev => prev - 1)
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              sx={{ mr: 1 ,color:"black" }}
            >
              Back
            </Button>}
            <Box sx={{ flex: '1 1 auto' }} />


            {activeStep < formTitle.length - 1 ? <Button variant="contained"
              style={{ background: allowNext ? "linear-gradient(225deg,  var(--gradientColor1) 0%, var(--gradientColor2) 91.25%)" : "linear-gradient(225deg,  var(--gradientColorLightest1) 0%, var(--gradientColorLightest2) 91.25%)" ,color:"black" }} onClick={() => {
                if (allowNext) {
                  setActiveStep(prev => prev + 1)
                  setErrorMessage(false)
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }
                else {
                  setErrorMessage(true)
                }
              }}>
              Next
            </Button>
              : 
              // <Button variant="contained"
              //   style={{ background: "linear-gradient(225deg,  var(--gradientColor1) 0%, var(--gradientColor2) 91.25%)" }}
              //   //  type="submit" 
              //   onClick={(e) =>allowNext? (handleSubmit(e),setErrorMessage(false)):setErrorMessage(true)}
              // >
              //   {isEdit ? "UPDATE" : "SUBMIT"}
              // </Button>
               <LoadingButton 
               variant="contained"
               style={{ background: "linear-gradient(225deg,  var(--gradientColor1) 0%, var(--gradientColor2) 91.25%)" }}
               //  type="submit" 
               loading={loading}
               loadingPosition="end"
               onClick={(e) =>allowNext? (handleSubmit(e),setErrorMessage(false),setLoading(true)):setErrorMessage(true)}
             >
               {loading ? <span style={{paddingRight:"1rem",color:"black"}}>Sending...</span> :isEdit ? <span style={{color:"black"}}>UPDATE</span> : <span style={{color:"black"}}>SUBMIT</span>}
             </LoadingButton>
            }
          </Box>
        </Card></Box>
    </>
  );
}
