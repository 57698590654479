import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import * as Action from "../../actions/companyServices";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { IMAGE } from "../../api";
import { CREATE_COMPANY_SERVICES, CREATECATEGORY } from "../../Constant";
import { Breadcrumbs, H5 } from "../../AbstractElements";
import Form from "../../utils/Form"
const CreateCompanyServices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "newLOCS");
  const isEdit = location?.state?.isEdit;
  const createCategory = useSelector((state) => state);

  const { company_service_create } = bindActionCreators(
    Action,
    dispatch
  );


  const [file, setFile] = useState(null);
  const [form ,setForm] = useState({})
  const user = useSelector((state)=> state?.layout?.user)
  useEffect(() => {
    if (location?.state) {
      const data = location?.state?.data;
      setForm(data)
    }
  }, [location?.state]);

  



  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData();
    
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    data.append("company" ,user?.id )
    formStructure.map((element) => {
			if (element.type == "image" && form?.[element.name] && typeof (form?.[element.name]) != "string") {
				const temp = form;
				temp["temp" + element.name] = form?.[element.name];
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});
  
      if (isEdit) {
        // const UpdateCategoryApi = await category_update(data);
        // if (UpdateCategoryApi?.statuscode == 200) {
        //   toast.success(UpdateCategoryApi?.message);
        //   setTimeout(() => {
        //     navigate("/Category");
        //     dispatch({ type: CREATECATEGORY, payload: undefined });
        //   }, 1000);
        //   setForm({});
        // } else {
        //   toast.error(UpdateCategoryApi?.message);
        // }
      }
       else {
        const createCompanyServicesApi = await company_service_create(data);
        if (createCompanyServicesApi?.statuscode == 200) {
          toast.success(createCompanyServicesApi?.message);
          setTimeout(() => {
            navigate("/Company-Services");
            dispatch({ type: CREATE_COMPANY_SERVICES, payload: undefined });
          }, 1000);
          setForm({});
        } else {
          toast.error(createCompanyServicesApi?.message);
        }
      }
    } 


 const formStructure=[
    {
        id:"1",
        type:"inputBox",
        maxLength:30,
        title:"Service Name",
        name:"service_name",
        regex : /^[a-zA-Z\s\&]+$/,
        isCaps:true,
        required:true
    },
    // {
    //     id:"2",
    //     type:"select",
    //     title:"Display Type",
    //     name:"display_type",
    //     options:["Portrait","Landscape"]
    // }
    // ,
    // {
    //     id:"3",
    //     type:"lockedInput",
    //     variant:"number",
    //     min:0,
    //     max:99,
    //     title:"Sequence",
    //     name:"sequence",
    //     required:true
    // }

  //   {
  //     id: "10",
  //     type: "toggle",
  //     title: "Status",
  //     name: "status",
  //     default: "Active",
  //     display: isEdit? "block" : "none",
  //     size: "6",
  //     options: [{ value: "Active", color: "#00B69B" }, { value: "Inactive", color: "#EE368C" }],
  // },
  //   {
  //       id:"4",
  //       type:"image",
  //       title:"Upload Image",
  //       subtitle:"(Resolution : 512px x 512px) *",
  //       subsubtitle:"Max File Size 1MB",
  //       subsubsubtitle:"Support only JPG,PNG,JPEG",
  //       name:"category_image"
  //   }
  //   ,
    ,
    {
        id:"6",
        type:"button",
        title:"Create",
        
    }
]
const formTitle = isEdit ? "Edit Company Services" : "Create Company Services";
  return (
    <div>
       <Breadcrumbs
        parent="Company Services"
        title={ isEdit ? "Edit Company Services":"Create Company Services"}
        mainTitle={isEdit ? "Edit CompanyServices":"Create CompanyServices"}
      />
  
              <Form
					handleSubmit={handleSubmit}
					formStructure={formStructure}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					// tableData={tableData}
					// setTableData={setTableData}
					isEdit={isEdit}
					// setIsEdit={setIsEdit}
				/>
            
    </div>
  );
};

export default CreateCompanyServices;
