import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { AUTH0, JWT } from '../Constant';
import { Image } from '../AbstractElements';
import simpleLogin from '../assets/images/simple-login.svg';
import jwtImg from '../assets/images/jwt.svg';
import authImg from '../assets/images/auth0.svg';
import { Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';
const NavAuth = ({ callbackNav, selected }) => {
  return (
    // <Nav className='border-tab flex-column' tabs>
    //   <NavItem>
    //     <NavLink className={selected === 'simpleLogin' ? 'active' : ''} onClick={() => callbackNav('simpleLogin')}>
    //       <Image attrImage={{ src: `${simpleLogin}`, alt: '' }} />
    //       <span style={{color:"#fff"}}>Admin login</span>
    //     </NavLink>
    //   </NavItem>
    //   <NavItem>
    //     <NavLink className={selected === 'jwt' ? 'active' : ''} onClick={() => callbackNav('jwt')}>
    //       <Image attrImage={{ src: `${jwtImg}`, alt: '' }} />
    //       <span style={{color:"#fff"}}>Vendor login</span>
    //     </NavLink>
    //   </NavItem>
    //   {/* <NavItem>
    //     <NavLink className={selected === 'auth0' ? 'active' : ''} onClick={() => callbackNav('auth0')}>
    //       <Image attrImage={{ src: `${authImg}`, alt: '' }} />
    //       <span>{AUTH0}</span>
    //     </NavLink>
    //   </NavItem> */}
    // </Nav>
    <FormControl component="fieldset">
    <RadioGroup
      row
      aria-labelledby="nav-auth-radio-group"
      name="auth-radio-buttons-group"
      value={selected} // Control selected value with the "selected" prop
      onChange={(event) => callbackNav(event.target.value)} // Call callbackNav with the selected value
    >
      <FormControlLabel
        value="simpleLogin"
        control={<Radio />}
        label={
          <>
            {/* <img src={`${simpleLogin}`} alt="" /> */}
            <span style={{color:"#575757"}}>Admin login</span>
          </>
        }
      />
      <FormControlLabel
        value="jwt"
        control={<Radio />}
        label={
          <>
            {/* <img src={`${jwtImg}`} alt="" /> */}
            <span style={{color:"#575757"}}>Vendor login</span>
          </>
        }
      />
      {/* Uncomment if you want to add another option for auth0 */}
      {/* <FormControlLabel
        value="auth0"
        control={<Radio />}
        label={
          <>
            <img src={`${authImg}`} alt="" />
            <span>{AUTH0}</span>
          </>
        }
      /> */}
    </RadioGroup>
  </FormControl>
  );
};

export default NavAuth;
