// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import ListTable from "../../../utils/Table";
import { all_category_list, category_delete } from "../../../actions/category";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { CREATECATEGORY, PROMOCODE } from "../../../Constant";
import { all_promocode_history } from "../../../actions/offeres/promocode";

const PromocodeHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const Promocode_history = useSelector(
    (state) => state?.offers?.promocode_history
  );
  const message = useSelector((state) => state?.offers?.promocode);
  console.log(Promocode_history);
  useMemo(() => {
    if (location.state?.id) {
      const formData = new FormData();
      formData.append("id", location.state?.id);
      dispatch(all_promocode_history(formData));
    }
  }, [location.state?.id]);
  const tempTableData = {
    tableTitle: "Promocode History",
    disableDelete: true,
    // deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    onDeleteText: "Are you sure want to delete?",
    onUpdateText:
      "Videos are associated with this category, are you still want to change it.?",
    tableHead: [
      {
        id: "promocode",
        label: "Promocode",
      },
      {
        id: "coupon_title",
        label: "Coupon Title",
      },

      {
        id: "select_type",
        label: "Coupon Type",
      },
      {
        id: "user",
        label: "User Name",
      },
      {
        id: "mobile_number",
        label: "Mobile",
      },
      {
        id: "email",
        label: "Email",
      },
      {
        id: "created_at",
        label: "Used On",
      },
    ],
    tableBody: [
      {
        category: "1",
        set_sequence: "1",
        subcategory_name: "78",
        status: "Active",
      },
      {
        category: "1",
        set_sequence: "1",
        subcategory_name: "79",
        status: "Active",
      },
    ],
    filterColumn: [],
  };
  const [tableData, setTableData] = useState({ ...tempTableData });

  useMemo(() => {
    if (Promocode_history?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = Promocode_history?.data?.map((ele) => ({
        ...ele,
        ...ele,
        promocode: ele?.promocode?.promocode,
        coupon_title: ele?.promocode?.title,
        select_type: ele?.promocode?.promocode_type,
        value: ele?.value,
        user: ele?.user?.firstname + " " + ele?.user?.lastname,
        mobile_number: ele?.user?.mobile_number,
        email: ele?.user?.email,
      }));
      setTableData({ ...temp });
    }
  }, [Promocode_history]);
  // useMemo(()=>{
  //   if(message?.statuscode == 200){
  //     toast.success(message?.message);
  //     dispatch(all_promocode_history())
  //     dispatch({ type: PROMOCODE, payload: undefined })
  //   }
  // },[message])
  return (
    <div>
      <Breadcrumbs
        parent="Promocode"
        title="Promocode"
        mainTitle="All Promocode"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <ListTable
                  tableData={tableData}
                  key={"ListTable"}
                  // setForm={setForm}
                  setTableData={setTableData}
                  // setIsEdit={setIsEdit}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PromocodeHistory;
