import React from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Form from "../../../utils/Form"
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import * as Vendor from "../../../actions/vendor";
import { bindActionCreators } from "redux";

import { useMemo } from "react";
import styles from "./singup.module.css";
import { VENDOR } from '../../../Constant';



const RegisterForm = () => {
    const [form, setForm] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
  const [open, setOpen] = React.useState(false);

    // console.log(categoryData,"dsfsd")
    const {vendor_register} = bindActionCreators(
      Vendor,
      dispatch
    ); 
    const [formStructure, setFormStructure] = useState([

      {
          id: "1",
          type: "inputBox",
          title: "Company Name",
          name: "company_name",
          maxLength: 50,
          regex: /^[a-zA-Z0-9\s]+$/,
          // isCapitalise: true,
          required: true,
        },
        {
            id: "2",
            type: "inputBox",
            title: "Vendor Name",
            name: "vendor_name",
            maxLength: 50,
            regex: /^[a-zA-Z\s]+$/,
            // isCapitalise: true,
            required: true,
          },
      {
          id: "3",
          type: "inputBox",
          title: "Email",
          name: "email",
          // maxLength: 30,
          // regex: /^[a-zA-Z\s]+$/,
          // isCapitalise: true,
          required: true,
        },
        {
          id: "4",
          type: "phone",
          title: "Mobile Number",
          maxLength: 12,
          name: "mobile_number",
          required: true,
        },
        {
          id: "5",
          type: "inputBox",
          title: "Gst Number",
          name: "gst_number",
          maxLength: 15,
          // regex: /^[a-zA-Z\s]+$/,
          // isCapitalise: true,
          // required: true,
        },
        {
          id: "6",
          type: "inputBox",
          title: "Address",
          name: "address",
          // maxLength: 30,
          // regex: /^[a-zA-Z\s]+$/,
          // isCapitalise: true,
          required: true,
        },
        // {
        //   id: "7",
        //   type: "inputBox",
        //   title: "Commission (%)",
        //   name: "commission_percentage",
        //   maxLength: 2,
        //   regex: /^[0-9\s]+$/,
        //   // isCapitalise: true,
        //   required: true,
        // },
      //   {
      //     id: "7",
      //     type: "inputBox",
      //     title: "Street Name",
      //     name: "street_name",
      //     // maxLength: 30,
      //     // regex: /^[a-zA-Z\s]+$/,
      //     // isCapitalise: true,
      //     required: true,
      //   },
      //   {
      //     id: "8",
      //     type: "select",
      //     title: "Select City",
      //     name: "city",
      //     options:[],
      //     // maxLength: 30,
      //     // regex: /^[a-zA-Z\s]+$/,
      //     // isCapitalise: true,
      //     required: true,
      //   },
      //   {
      //     id: "9",
      //     type: "inputBox",
      //     title: "Zip Code",
      //     name: "pincode",
      //     maxLength: 6,
      //     regex: /^[0-9\s]+$/,
      //     // isCapitalise: true,
      //     required: true,
      //   },
      // {
      //     id: "10",
      //     type: "select_multiple",
      //     title: "Category",
      //     name: "category",
      //     // maxSelections: "3",
      //     options: ["English", "Hindi", "Gujarati"],
      //     // errorText: "You can select max 3 category at a time",
      //     required: true,
      // },
  
      // {
      //   id:"12",
      //   type:"undefined",
      //   size:"6",
      //   display: "block"
      // },
      // {
      //   id: "4",
      //   type: "lockedInput",
      //   variant: "number",
      //   title: "Set Sequence",
      //   name: "set_sequence",
      //   min: "1",
      //   max: "99",
      //   required: true,
      // },
    //   {
    //     id: "11",
    //     type: "toggle",
    //     title: "Status",
    //     name: "status",
    //     default: "Active",
    //     display: isEdit? "block" : "none",
    //     size: "6",
    //     options: [{ value: "Active", color: "#00B69B" }, { value: "Inactive", color: "#EE368C" }],
    // },
    {
      id: "12",
      type: "file1",
      title: "Upload Company Logo",
      // subtitle:"(Resolution : 512px x 512px) *",
      // subsubtitle:"Max File Size 1MB",
      // subsubsubtitle:"Support only JPG,PNG,JPEG",
      name: "company_logo",
      accept: ".jpg , .jpeg , png",
      size:"4",
      // required: true,
    },
    {
      id: "13",
      type: "file1",
      title: "Upload Registration Certificate",
      // subtitle:"(Resolution : 512px x 512px) *",
      // subsubtitle:"Max File Size 1MB",
      // subsubsubtitle:"Support only JPG,PNG,JPEG",
      name: "company_registration_certificate",
      size:"4",
      accept: ".pdf , .jpg , .jpeg , png",
      required: true,
    },
    {
      id: "14",
      type: "file1",
      title: "Upload Contract Agreement",
      // subtitle:"(Resolution : 512px x 512px) *",
      // subsubtitle:"Max File Size 1MB",
      // subsubsubtitle:"Support only JPG,PNG,JPEG",
      name: "contract_agreement",
      accept: ".pdf , .jpg , .jpeg , png",
      size:"4",
      required: true,
  
    },
      {
        id: "15",
        type: "button",
        title: "Submit",
      },
      {
        id: "116",
        type: "BackBtn",
        // title: "Submit",
      },
    ]);
    const handleSubmit = async(event) => {
      event.preventDefault();
      const temp_form = form;
      const countryCode = form?.countryCode || '';
      let mobileNumber = form?.mobile_number || '';
      
      // Remove the country code from mobile number if it exists
      if (countryCode && mobileNumber.startsWith(countryCode)) {
        mobileNumber = mobileNumber.slice(countryCode.length);
      } else if (mobileNumber.startsWith(countryCode.replace('+', ''))) {
        mobileNumber = mobileNumber.slice(countryCode.length - 1);
      }
      temp_form["tempmobile_number"] = form?.mobile_number;
      temp_form["mobile_number"] = mobileNumber;
      setForm({ ...temp_form });
      const data = new FormData();
      Object.keys(form).map((key) => data.append(key, form?.[key]));
      formStructure.map((element) => {
        if (element.type == "image" && form?.[element.name] && typeof (form?.[element.name]) != "string") {
          const temp = form;
          temp["temp" + element.name] = form?.[element.name];
          temp[element.name] = URL.createObjectURL(form?.[element.name]);
  
          setForm({
            ...temp,
          });
        }
      });
    
 
          const CreateVendorApi = await vendor_register(data);
          console.log(CreateVendorApi,"sfddffdf")
          if (CreateVendorApi?.statuscode == 200) {
            toast.success(CreateVendorApi?.message);
            setTimeout(() => {
              navigate("/login");
              dispatch({ type: VENDOR, payload: undefined });
            }, 3000);
            setForm({});
          } else {
            toast.error(CreateVendorApi?.message);
          }
        
      } 
  return (
    <div style={{ padding: "2% 20%" }} className={styles.SingInForm}>
      {/* <div style={{width:"700px" ,height:"70vh" ,display:"flex",alignItems:"center",justifyContent:"center"}}> */}
    <Form
    formStructure={formStructure}
    handleSubmit={handleSubmit}
    // formTitle={formTitle}
    key={"Form"}
    form={form}
    setForm={setForm}

    // isEdit={isEdit}
    // setIsEdit={setIsEdit}
  />
  {/* </div> */}
   {/* <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={authStore?.statuscode == 200 ? "success" : "error"}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {authStore?.message}
        </Alert>
      </Snackbar> */}
  </div>
  
  )
}

export default RegisterForm