import { BOOKING_HISTORY_VENDOR, BOOKING_VENDOR, TODAY_BOOKING, VENDOR, VENDOR_DETAILS, VENDOR_LIST, VENDOR_UPDATE } from '../Constant/index.jsx';
import * as api from '../api/index.jsx';

export const vendor_register = (formData) => async (dispatch) => {
  try {
    const { data } = await api.vendor_register(formData);
    dispatch({ type: VENDOR, payload: data });
    return data


  } catch (error) {
    console.log(error);
  }
};
export const vendor_update = (formData) => async (dispatch) => {
  try {
    const { data } = await api.vendor_update(formData);
    dispatch({ type: VENDOR, payload: data });
    return data


  } catch (error) {
    console.log(error);
  }
};
export const all_vendor_list = (formData) => async (dispatch) => {
  try {
    const { data } = await api.all_vendor_list(formData);
    dispatch({ type: VENDOR_LIST, payload: data });
    return data


  } catch (error) {
    console.log(error);
  }
};
export const delete_vendor = (formData) => async (dispatch) => {
  try {
    const { data } = await api.delete_vendor(formData);
    dispatch({ type: VENDOR, payload: data });
    return data


  } catch (error) {
    console.log(error);
  }
};
export const vendor_status_update = (formData) => async (dispatch) => {
  try {
    const { data } = await api.vendor_status_update(formData);
    dispatch({ type: VENDOR_UPDATE, payload: data });
    return data


  } catch (error) {
    console.log(error);
  }
};
export const vendor_details = (formData) => async (dispatch) => {
  try {
    const { data } = await api.vendor_details(formData);
    dispatch({ type: VENDOR_DETAILS, payload: data });
    return data
  } catch (error) {
    console.log(error);
  }
};
export const getBookingInfoVendor = (formData) => async (dispatch) => {
  try {
    const { data } = await api.vendor_booking_request_details(formData);
    dispatch({ type: BOOKING_VENDOR, payload: data });
    return data
  } catch (error) {
    console.log(error);
  }
};
export const assignWorker = (formData) => async (dispatch) => {
  try {
    const { data } = await api.worker_assign_to_booking_request(formData);
    dispatch({ type: BOOKING_VENDOR, payload: data });
    return data
  } catch (error) {
    console.log(error);
  }
};
export const getTodaysbookingDataVendor = (formData) => async (dispatch) => {
  try {
    const { data } = await api.vendor_booking_request_today_list(formData);
    dispatch({ type: TODAY_BOOKING, payload: data });
    return data
  } catch (error) {
    console.log(error);
  }
};
export const getCompletedBookingVendor = (formData) => async (dispatch) => {
  try {
    const { data } = await api.vendor_booking_request_history_list(formData);
    dispatch({ type: BOOKING_HISTORY_VENDOR, payload: data });
    return data
  } catch (error) {
    console.log(error);
  }
};