import * as actionType from '../Constant';


const dashboardReducer = (state = { dashboardData: null }, action) => {
  switch (action.type) {
    case actionType.VALUABLE_USER:
      return { ...state, valuableusers: action.payload };
    case actionType.COUNT_ON_DB:
      return { ...state, count_on_dashboard: action.payload };
    case actionType.TOTAL_USER:
      return { ...state, total_user: action.payload };
    case actionType.ORDER_OVERVIEW:
      return { ...state, order_overview: action.payload };
    case actionType.EARNING_BY_CATEGORY:
      return { ...state, earning_by_category: action.payload };
    case actionType.RECENT_ORDER_BY_CATEGORY:
      return { ...state, recent_order_by_category: action.payload };
    case actionType.MONTHLY_ORDER:
      return { ...state, monthly_order: action.payload };

    default:
      return state;
  }
};

export default dashboardReducer;