import React, { useState } from 'react'

export default function SearchTable(props) {


    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearch(value);
        const filteredItems = props?.data?.filter((item) => {

            console.log("item", item?.Name?.props?.children?.toLowerCase())
            if (props?.type !== "styled") {
                if (item?.Name?.toLowerCase()?.startsWith(value)) {
                    return true;
                }

            }
            else {
                if (item?.Name?.props?.children?.toLowerCase()?.startsWith(value)) {
                    console.log("in if", value, item?.Name?.props?.children?.toLowerCase())

                    return true;
                }

            }

            return false;

        }
        );

        if(value){
            props.setFilteredData(filteredItems);

        }
        else{
            props.setFilteredData(null);

        }

    };

    return (
        <div>
            <input
                type='text'
                placeholder='Search...'
                className='search-input'
                style={{ marginBottom: '10px', padding: '10px', width: '200px', borderRadius: '10px', border: "1px solid grey" }}
                onChange={handleSearch}
            />
        </div>
    )
}
