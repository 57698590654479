import * as actionType from '../Constant';


const empReducer = (state = { tasks: null, manager_projects: null }, action) => {
    switch (action.type) {
        case actionType.EMPLOYEE_TASKS:
            return { ...state, tasks: action.payload };
        case actionType.MANAGER_PROJECTS:
            return { ...state, manager_projects: action.payload };
        default:
            return state;
    }
};

export default empReducer;
