// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import ListTable from "../../../utils/Table";
import { all_category_list, category_delete } from "../../../actions/category";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {  PROMOCODE } from "../../../Constant";
import { all_expired_promocode_list, all_promocode_list, promocode_delete } from "../../../actions/offeres/promocode";

const ExpiredPromocode = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const Promocode_data = useSelector((state)=>state?.offers?.expired_promocode_list)
  const message = useSelector((state)=>state?.offers?.promocode)
  console.log(Promocode_data)
  useMemo(()=>{
    dispatch(all_expired_promocode_list())
  },[])
  const tempTableData = {
    tableTitle: "Expired Promocode",
    disableDelete : true,

    // deleteRecord: promocode_delete,
    // updateRecord: subcategory_update,
    // deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    onDeleteText: "Are you sure want to delete?",
    onUpdateText:
      "Videos are associated with this category, are you still want to change it.?",
    tableHead: [
      {
        id: "promocode",
        label: "Promo code",
        link: "/Coupon/PromocodeHistory",
        color: "#4267B2",
      },
      {
        id: "title",
        label: "Title",
      },

      {
        id: "value1",
        label: "Discount",
        isSpecial: true,
        align: "left",
      },
      {
        id: "promocode_type",
        label: "Type",
      },
      {
        id: "minimum_amount1",
        label: "Min. Amount",
      },
      {
        id: "limit",
        label: "Limit",
      },
      {
        id: "used",
        label: "Used",
      },
      {
        id: "remaining",
        label: "Remaining",
      },
      {
        id: "valid_up_to",
        label: "Valid Up to",
        isSpecial: true,
        align: "left",
      },

      // {
      //   id: "edit",
      //   label: "Update",
      //   edit_path :"/update_promocode",
      // },
      // {
      // 	id: "promocode_image",
      // 	label: "",
      // 	isSpecial: true,
      // },
    ],
    tableBody: [],
    filterColumn: [],
  };
  const [tableData, setTableData] = useState({...tempTableData})

  useMemo(()=>{
    if(Promocode_data?.statuscode == 200){
      const temp = tableData
      temp.tableBody = Promocode_data?.data?.map((ele)=>({
        ...ele,
        // edit: new Date(ele?.expiry_date) <= new Date() && ele?.no_of_user_limit - ele?.coupon_used_count <= 0,
        value1:
          ele?.promocode_type == "Fixed" ? (
            <p style={{ color: "var(--themeFontColor)" }}>
              {" "}
              {parseFloat(ele?.value).toFixed(2)} ₹
            </p>
          ) : (
            <p style={{ color: "var(--themeFontColor)" }}>{ele?.value} %</p>
          ),
		  minimum_amount1 : parseFloat(ele?.minimum_amount).toFixed(2),
		  // minimum_amount : ,
        valid_up_to:
          new Date(ele?.expairy_date) > new Date() &&
          ele?.limit - ele?.used > 0 ? (
            <p style={{ color: "var(--themeFontColor)" }}>
              {ele?.expairy_date}
            </p>
          ) : (
            <p style={{ color: "red" }}>Expired</p>
          ),
      }))
      setTableData({...temp})
    }
  },[Promocode_data])
  useMemo(()=>{
    if(message?.statuscode == 200){
      toast.success(message?.message);
      dispatch(all_promocode_list())
      dispatch({ type: PROMOCODE, payload: undefined })
    }
  },[message])
  return (
    <div >
      <Breadcrumbs
        parent="Promocode"
        title="Expired Promocode"
        mainTitle="Expired Promocode"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
              <ListTable
              tableData={tableData}
              key={"ListTable"}
              // setForm={setForm}
              setTableData={setTableData}
              // setIsEdit={setIsEdit}
              />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ExpiredPromocode;
