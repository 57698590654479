import React, { Fragment, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../AbstractElements";
import { useLocation  } from 'react-router'
import SweetAlert from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { company_project_details } from '../../actions/workAssign';

const WorkAssign = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const Project_id = location?.state?.id;
    const Project_name = location?.state?.project_name;
    const isUpdate = location?.state?.isUpdate;
   
    
    useEffect(() => {
        if (Project_id) {
            const data = new FormData();
            data.append("project", Project_id);
            dispatch(company_project_details(data));
        }
    }, [Project_id]);
    
    const ProjectWorkAssign = useSelector((state)=> state?.work_assign?.project_work_assign)
    console.log(ProjectWorkAssign , location,"Dataqwe")
    const productColumns = [
        // {
        //   name: "Image",
        //   selector: (row) => row.image,
        //   sortable: true,
        //   center: true,
        //   minWidth: "100px",
        //   maxWidth: "100px",
        // },
        {
          name: "Services",
          selector: (row) => row.ServicesName,
          sortable: true,
          // center: true,
          wrap: true,
          // minWidth: "350px",
        },
        {
          name: "Duration",
          selector: (row) => row.Date,
          sortable: true,
          // center: true,
          wrap: true,
          // minWidth: "350px",
        },
        // {
        //   name: "Team",
        //   selector: (row) => row.Team,
        //   sortable: true,
        //   // center: true,
        //   wrap: true,
        //   // minWidth: "350px",
        // },
        {
          name: "Status",
          selector: (row) => row.Status,
          sortable: true,
          center: true,
          wrap: true,
          // minWidth: "350px",
        },
        
        {
          name: "Action",
          selector: (row) => row.action,
          sortable: true,
          center: true,
          minWidth: "160px",
          maxWidth: "160px",
        },
      ];
      const style = {
        width: 55,
        height: 40,
        borderRadius: 5,
      };
      const style2 = { width: 60, fontSize: 14, padding: 4 };
      const ProductList =
      ProjectWorkAssign &&
      ProjectWorkAssign?.data?.map((value, index) => ({
          // image: (
          //   <Image
          //     attrImage={{
          //       src: IMAGE + value?.category_image,
          //       style: style,
          //       alt: "",
          //     }}
          //   />
          // ),
          ServicesName: (
            <div>
              <H6>{value?.service?.service_name}</H6>
            </div>
          ),

          Date: (
            <div>
              <p>{value?.start_date}</p>
              <p>To</p>
              <p>{value?.end_date}</p>
            </div>
          ),
          // Team: (
          //   <div>
          //     {/* <p>{value?.service.map((ele, index) => (index ? ', ' : '') + ele?.service_name)}</p> */}
          //     <ul >
          //       {" "}
          //       {value?.team_member?.map((ele, index) => (
          //         <li>- {ele?.name}</li>
          //       ))}{" "}
          //     </ul>
          //   </div>
          // ),
          // Team:(
          //   <div>
          //   {/* <p>{value?.service.map((ele, index) => (index ? ', ' : '') + ele?.service_name)}</p> */}
          //   <ul >
          //     {" "}
          //     {value?.team_member.map((ele, index) => (
          //       <li>- {ele?.name}</li>
          //     ))}{" "}
          //   </ul>
          // </div>
          // ),
          Status:
            value?.status == "Active" ? (
              <span
                style={{ color: "#00B69B", padding: "5px 13px", padding: "4px" }}
              >
                {value?.status}
              </span>
            ) : (
              <span
                style={{
                  color: "rgb(238, 54, 140)",
                  padding: "5px 13px",
                  padding: "4px",
                }}
              >
                {value?.status}
              </span>
            ),
          action: (
            <div>
              {/* <span>
                <Btn
                  attrBtn={{
                    style: style2,
                    color: "danger",
                    className: "btn btn-xs",
                    type: "button",
                    // onClick: () => navigateData(value?.id) ,
                  }}
                >
                  View
                </Btn>
              </span>{" "}
              &nbsp;&nbsp; */}
              <span>
                <Btn
                  attrBtn={{
                    style: style2,
                    color: "success",
                    className: "btn btn-xs",
                    type: "button",
                  }}
                >
                  <Link
                    style={{ color: "inherit" }}
                    to={"/team-leader-projects/update-work-assign"}
                    state={{ data: ProjectWorkAssign?.data[index], isEdit: true }}
                  >
                    Edit
                  </Link>
                </Btn>
              </span>
            </div>
          ),
        }));
    
  return (

    <div className="table-responsive product-table">
    <Breadcrumbs
      parent="Project"
      title={`${Project_name} Work Assign List`}
      mainTitle={`${Project_name} - Work Assign List`}

    />
    <Container fluid={true}>
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <DataTable
                noHeader
                pagination
                paginationServer
                columns={productColumns}
                data={ProductList}
                highlightOnHover={true}
                striped={true}
                responsive={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
  )
}

export default WorkAssign