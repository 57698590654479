import * as actionType from "../Constant";

const OffersReducer = (state = { offersData: null }, action) => {
  switch (action.type) {
    case actionType.LAST_MIN_DEAL:
      return { ...state, last_min_deal: action.payload };
    case actionType.LAST_MIN_DEAL_LIST:
      return { ...state, last_min_deal_list: action.payload };
    case actionType.ADMIN_VENDOR_LAST_MIN_DEAL:
      return { ...state, vendor_admin_last_min_deal_list: action.payload };
    case actionType.ADMIN_VENDOR_EXPIRED_LAST_MIN_DEAL:
      return {
        ...state,
        vendor_admin_expired_last_min_deal_list: action.payload,
      };
    case actionType.EXPIRED_LAST_MIN_DEAL_LIST:
      return { ...state, expired_last_min_deal_list: action.payload };

    case actionType.DEAL_OF_THE_DAY:
      return { ...state, deal_of_the_day: action.payload };
    case actionType.DEAL_OF_THE_DAY_LIST:
      return { ...state, deal_of_the_day_list: action.payload };

    case actionType.VENDOR_DEAL_OF_THE_DAY_LIST:
      return { ...state, vendor_admin_deal_of_the_day_list: action.payload };

    case actionType.ADMIN_VENDOR_EXPIRED_DEAL_OF_THE_DAY:
      return {
        ...state,
        vendor_admin_expired_deal_of_the_day_list: action.payload,
      };
    case actionType.EXPIRED_DEAL_OF_THE_DAY_LIST:
      return { ...state, expired_deal_of_the_day_list: action.payload };

    case actionType.OFFERS:
      return { ...state, offers: action.payload };
    case actionType.OFFERS_LIST:
      return { ...state, offers_list: action.payload };
    case actionType.EXPIRED_OFFERS_LIST:
      return { ...state, expired_offers_list: action.payload };
    case actionType.ADMIN_VENDOR_OFFERS_LIST:
      return { ...state, admin_vendor_offers_list: action.payload };
    case actionType.ADMIN_VENDOR_EXPIRED_OFFERS_LIST:
      return { ...state, admin_vendor_expired_offers_list: action.payload };

    case actionType.PROMOCODE:
      return { ...state, promocode: action.payload };
    case actionType.PROMOCODE_LIST:
      return { ...state, promocode_list: action.payload };
    case actionType.EXPIRED_PROMOCODE_LIST:
      return { ...state, expired_promocode_list: action.payload };
    case actionType.PROMOCODE_HISTORY:
      return { ...state, promocode_history: action.payload };

    default:
      return state;
  }
};

export default OffersReducer;
