import * as actionType from '../Constant';


const emaployeesReducer = (state = { emaployeesData: null }, action) => {
  switch (action.type) {
    case actionType.CREATE_EMPLOYEES:
      return { ...state, employees: action.payload };
    case actionType.EMPLOYEES:
      return { ...state, employee_list: action.payload };
    default:
      return state;
  }
};

export default emaployeesReducer;
