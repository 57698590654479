import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import * as Action from "../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { IMAGE } from "../../api";
import { CREATE_EMPLOYEES, CREATECATEGORY } from "../../Constant";
import { Breadcrumbs, H5 } from "../../AbstractElements";
import Form from "../../utils/Form"
import { company_services_list } from "../../actions/companyServices";
const CreateEmployees = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location, "newLOCS");
  const isEdit = location?.state?.isEdit;
  const createCategory = useSelector((state) => state);
  const user = useSelector((state)=> state?.layout?.user)

  const {company_user_create ,company_user_update} = bindActionCreators(
    Action,
    dispatch
  );


  const [file, setFile] = useState(null);
  const [form ,setForm] = useState({})

  useEffect(() => {
    if (location?.state) {
      const data = location?.state?.data;
      setForm({...data ,service : data?.service?.map((ele)=>ele?.service_name) })
    }
  }, [location?.state]);
useEffect(()=>{
  if(user){

    const data = new FormData()
    data.append("company" , user?.id)
    dispatch(company_services_list(data))
  }
},[user])
  
const Company_services_list = useSelector((state)=> state?.company_services?.company_services_list)


  const handleSubmit = async (event) => {
    event.preventDefault();
    const temp_form = form
    temp_form["temp_service"] = form?.["service"]
    temp_form["isUserManager"] = form?.["isUserManager"] ? form?.["isUserManager"] : "No"
    temp_form["service"] = Company_services_list?.data
    // ?.filter((option)=>form?.["service"] == option.service_name)
    // .map((option) => option.id)
    ?.map((option) =>
      form?.["service"]?.includes(option?.service_name) && option?.id
  ).filter((e) => e);
    setForm({
      ...temp_form,
    });
    const data = new FormData();
    data.append("company" , user?.id)
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    formStructure.map((element) => {
			if (element.type == "image" && form?.[element.name] && typeof (form?.[element.name]) != "string") {
				const temp = form;
				temp["temp" + element.name] = form?.[element.name];
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});
  
      if (isEdit) {
        const UpdateEmployeeApi = await company_user_update(data);
        if (UpdateEmployeeApi?.statuscode == 200) {
          toast.success(UpdateEmployeeApi?.message);
          setTimeout(() => {
            navigate("/Employees");
            dispatch({ type: CREATE_EMPLOYEES, payload: undefined });
          }, 1000);
          setForm({});
        } else {
          toast.error(UpdateEmployeeApi?.message);
        }
      }
       else {
        const createEmployeeApi = await company_user_create(data);
        if (createEmployeeApi?.statuscode == 200) {
          toast.success(createEmployeeApi?.message);
          setTimeout(() => {
            navigate("/Employees");
            dispatch({ type: CREATE_EMPLOYEES, payload: undefined });
          }, 1000);
          setForm({});
        } else {
          toast.error(createEmployeeApi?.message);
        }
      }
    } 


 const [formStructure , setFormStructure]= useState([
    {
        id:"1",
        type:"inputBox",
        maxLength:30,
        title:"Employee Name",
        name:"name",
        regex : /^[a-zA-Z\s\&]+$/,
        // isCaps:true,
        required:true
    },
    {
      id:"2",
      type:"inputBox",
      maxLength:30,
      title:"Email Id",
      name:"email",
      // regex : /^[a-zA-Z\s\&]+$/,
      // isCaps:true,
      required:true
  },
  {
    id: "3",
    type: "phone",
    title: "Mobile Number",
    maxLength: 12,
    name: "phone_number",
    required: true,
  },
  {
    id: "4",
    type: "inputBox",
    variant: "date",
    title: "Joining Date",
    default : " ",
    max: new Date().toISOString().split("T")[0],
    name: "joining_date",
    // display: "none",
    required: true,
  },
  {
    id: "5",
    type: "toggle",
    title: "Is Manager",
    name: "isUserManager",
    default: "No",
    display: !isEdit? "block" : "none",
    size: "6",
    options: [{ value: "Yes", color: "#00B69B" }, { value: "No", color: "#EE368C" }],
},
  {
    id: "6",
    type: "select_multiple",
    title: "Service",
    name: "service",
    maxSelections: "3",
    options: ["English", "Hindi", "Gujarati"],
    errorText: "You can select max 3 Services at a time",
    required: true,
    // isPlusBtnOpen :true,
  },  
    // ,
    // {
    //     id:"3",
    //     type:"lockedInput",
    //     variant:"number",
    //     min:0,
    //     max:99,
    //     title:"Sequence",
    //     name:"sequence",
    //     required:true
    // }
  
    {
      id: "7",
      type: "toggle",
      title: "Status",
      name: "status",
      default: "Active",
      display: isEdit? "block" : "none",
      size: "6",
      options: [{ value: "Active", color: "#00B69B" }, { value: "Inactive", color: "#EE368C" }],
  },
  //   {
  //       id:"4",
  //       type:"image",
  //       title:"Upload Image",
  //       subtitle:"(Resolution : 512px x 512px) *",
  //       subsubtitle:"Max File Size 1MB",
  //       subsubsubtitle:"Support only JPG,PNG,JPEG",
  //       name:"category_image"
  //   }
  //   ,
    {
        id:"8",
        type:"button",
        title:"Create",
        
    }
])
const formTitle = isEdit ? "Edit Category" : "Create Category";

useMemo(()=>{
  if(form?.isUserManager == "Yes"){
    const temp = formStructure
    temp[5]["display"] = "none"
    setFormStructure([...temp])
  }else if(form?.isUserManager == "No"){
    const temp = formStructure
    temp[5]["display"] = "block"
    setFormStructure([...temp])
  }
},[form?.isUserManager])
useMemo(()=>{
  if(Company_services_list?.statuscode == 200){
    console.log(Company_services_list,"newc")

    const temp = formStructure
    temp[5]["options"] = Company_services_list?.data?.map((ele)=>ele?.service_name)
    setFormStructure([...temp])
    console.log(formStructure)
  }
},[Company_services_list])
  return (
    <div>
       <Breadcrumbs
        parent="Employees"
        title={ isEdit ? "Edit Employees":"Create Employees"}
        mainTitle={isEdit ? "Edit Employees":"Create Employees"}
      />
  
          <Form
					handleSubmit={handleSubmit}
					formStructure={formStructure}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					// tableData={tableData}
					// setTableData={setTableData}
					isEdit={isEdit}
					// setIsEdit={setIsEdit}
				/>
            
    </div>
  );
};

export default CreateEmployees;
