// //Samp lePage
import SamplePage from '../Components/Pages/PageLayout/SimplePage';
import Category from '../Components/Category/Category';
import CreateCategory from '../Components/Category/CreateCategory';
import SubCategory from '../Components/SubCategory/SubCategory';
import CreateSubCategory from '../Components/SubCategory/CreateSubCategory';
import Levels from '../Components/Levels/Levels';
import Tags from '../Components/Tags/Tags';
import CreateTags from '../Components/Tags/CreateTags';
import AboutUs from '../Components/Setting/AboutUs';
import TermsConditions from '../Components/Setting/TermsConditions';
import CopyRight from '../Components/Setting/CopyRight';
import CreateCompanyServices from '../Components/CompanyServices/CreateCompanyServices';
import CompanyServices from '../Components/CompanyServices/CompanyServices';
import Employees from '../Components/Employees/Employees';
import CreateEmployees from '../Components/Employees/CreateEmployees';
import Client from '../Components/Client/Client';
import CreateClient from '../Components/Client/CreateClient';
import Project from '../Components/Project/Project';
import CreateProject from '../Components/Project/CreateProject';
import CreateWorkAssign from '../Components/WorkAssign/CreateWorkAssign';
import WorkAssign from '../Components/WorkAssign/WorkAssign';
import UpdateWorkAssign from '../Components/WorkAssign/UpdateWorkAssign';
import Dashboard from '../Components/Dashboard/Dashboard';
import Tasks from '../Components/Tasks/Tasks';
import TaskInfo from '../Components/Tasks/TaskInfo';
import Projects from '../Components/TeamLeader/ProjectsTeamLeader';
import ProjectDetails from '../Components/TeamLeader/ProjectDetails';
import EmployesTask from '../Components/EmployesTask/EmployesTask';
import CreateVendor from '../Components/Vendor/CreateVendor';
import Vendor from '../Components/Vendor/Vendor';
import CreateSliderBanner from '../Components/SliderBanner/CreateSliderBanner';
import SliderBanner from '../Components/SliderBanner/SliderBanner';
import CreateLanguage from '../Components/Language/CreateLanguage';
import Language from '../Components/Language/Language';
import CreateWorkers from '../Components/Workers/CreateWorkers';
import Worker from '../Components/Workers/Worker';
import BookingRequest from '../Components/Booking/BookingRequest';
import BookingDetails from '../Components/Booking/BookingDetails';
import BookingVendorRequest from '../Components/BookingVendor/BookingVendorRequest';
import BookingVendorDetails from '../Components/BookingVendor/BookingVendorDetails';
import AssignedBooking from '../Components/Booking/AssignedBooking';
import TodayBooking from '../Components/BookingVendor/TodayBooking';
import CompletedBooking from '../Components/BookingVendor/CompletedBooking';
import TodayBookingAdmin from '../Components/Booking/TodayBookingAdmin';
import BookingHistory from '../Components/Booking/BookingHistory';
import UpcomingExpiry from '../Components/Workers/UpcomingExpiry';
import UpcomingExpiryVendor from '../Components/Vendor/UpcomingExpiryVendor';
import Customer from '../Components/Customer/Customer';
import Country from '../Components/Country/Country';
import CreateCountry from '../Components/Country/CreateCountry';
import City from '../Components/City/City';
import CreateCity from '../Components/City/CreateCity';
import PrivacyPolicy from '../Components/Setting/PrivacyPolicy';
import AboutUsVendor from '../Components/Setting/Vendor/AboutUsVendor';
import TermsandConditionVendor from '../Components/Setting/Vendor/TermsandConditionVendor';
import PrivacyPolicyVendor from '../Components/Setting/Vendor/PrivacyPolicyVendor';
import PendingBookingRequest from '../Components/Booking/PendingBookingRequest';
import PendingBookingDetails from '../Components/Booking/PendingBookingDetails2';
import VendorAssignedBooking from '../Components/BookingVendor/VendorAssignedBooking';
import WorkerDetails from '../Components/Workers/WorkerDetails';
import CustomerDetails from '../Components/Customer/CustomerDetails';
import RejectedBookingRequest from '../Components/Booking/RejectedBookingRequest';
import ConfirmedBookingRequest from '../Components/Booking/ConfirmedBookingRequest';
import Category2 from '../Components/Category/Category2';
import Unit from '../Components/Unit/Unit';
import CreateUnit from '../Components/Unit/CreateUnit';
import GstRate from '../Components/GstRate/GstRate';
import CreateGstRate from '../Components/GstRate/CreateGstRate';
import Products from '../Components/Products/Products';
import CreateProduct from '../Components/Products/CreateProduct';
import DealOfDay from '../Components/Offers/DealOfDay/DealOfDay';
import CreateDealOfDay from '../Components/Offers/DealOfDay/CreateDealOfDay';
import LastMinDeal from '../Components/Offers/LastMinDeal/LastMinDeal';
import CreateLastMinDeal from '../Components/Offers/LastMinDeal/CreateLastMinDeal';
import Offers from '../Components/Offers/Offers/Offers';
import CreateOffers from '../Components/Offers/Offers/CreateOffers';
import ExpiredOffers from '../Components/Offers/Offers/ExpiredOffers';
import ExpiredLastMinDeal from '../Components/Offers/LastMinDeal/ExpiredLastMinDeal';
import ExpiredDealOfDay from '../Components/Offers/DealOfDay/ExpiredDealOfDay';
import Promocode from '../Components/Offers/Promocode/Promocode';
import CreatePromocode from '../Components/Offers/Promocode/CreatePromocode';
import ExpiredPromocode from '../Components/Offers/Promocode/ExpiredPromocode';
import PromocodeHistory from '../Components/Offers/Promocode/PromocodeHistory';
import ExpiredSliderBanner from '../Components/SliderBanner/ExpiredSliderBanner';
import ProductDetailsContent from '../Components/Products/ProductDetails/ProductDetailsContent';
import AllProduct from '../Components/Products/AllProduct';
import ProductDetailsContent2 from '../Components/Products/ProductDetails/ProductDetailsContent2';
import AllOffers from '../Components/Offers/Offers/AllOffers';
import AllExpiredOffers from '../Components/Offers/Offers/AllExpiredOffers';
import AllLastMinDeal from '../Components/Offers/LastMinDeal/AllLastMinDeal';
import AllDealOfDay from '../Components/Offers/DealOfDay/AllDealOfDay';
import AllExpiredDealOfDay from '../Components/Offers/DealOfDay/AllExpiredDealOfDay';
import Order from '../Components/Order/Order';
import OrderDetails from '../Components/Order/OrderDetails/OrderDetails';
import Transactions from '../Components/Transactions/Transactions';
import Notification from '../Components/Notification/Notification';
import CreateNotification from '../Components/Notification/CreateNotification';
import ProductDetails3 from '../Components/Products/ProductDetails/ProductDetailsContent3';


export const routes = [
  // //page
  { path: `${process.env.PUBLIC_URL}/pages/sample-page`, Component: <SamplePage /> },
  { path: `/dashboard`, Component: <Dashboard /> },

  { path: `/Category`, Component: <Category2 /> },
  { path: `/Create-Category`, Component: <CreateCategory /> },
  { path: `/Update-Category`, Component: <CreateCategory /> },

  { path: `/Unit`, Component: <Unit /> },
  { path: `/Create-Unit`, Component: <CreateUnit /> },
  { path: `/Update-Unit`, Component: <CreateUnit /> },

  { path: `/GstRate`, Component: <GstRate /> },
  { path: `/Create-GstRate`, Component: <CreateGstRate /> },
  { path: `/Update-GstRate`, Component: <CreateGstRate /> },

  { path: `/Sub-Category`, Component: <SubCategory /> },
  { path: `/Create-Sub-Category`, Component: <CreateSubCategory /> },
  { path: `/Update-Sub-Category`, Component: <CreateSubCategory /> },

  { path: `/Level`, Component: <Levels /> },

  { path: `/Tags`, Component: <Tags /> },
  { path: `/Create-Tags`, Component: <CreateTags /> },
  { path: `/Update-Tags`, Component: <CreateTags /> },

  { path: `/Company-Services`, Component: <CompanyServices /> },
  { path: `/Create-Company-Services`, Component: <CreateCompanyServices /> },
  { path: `/Update-Company-Services`, Component: <CreateCompanyServices /> },

  { path: `/Employees`, Component: <Employees /> },
  { path: `/Create-Employee`, Component: <CreateEmployees /> },
  { path: `/Update-Employee`, Component: <CreateEmployees /> },

  { path: `/products`, Component: <Products /> },
  { path: `/create-product`, Component: <CreateProduct /> },
  { path: `/update-product`, Component: <CreateProduct /> },
  { path: `/product_details`, Component: <ProductDetails3 /> },
  // { path: `/product_details`, Component: <ProductDetailsContent2 /> },
  { path: `/all_products`, Component: <AllProduct /> },

  { path: `/Client`, Component: <Client /> },
  { path: `/Create-Client`, Component: <CreateClient /> },
  { path: `/Update-Client`, Component: <CreateClient /> },

  { path: `/Project`, Component: <Project /> },
  { path: `/Create-Project`, Component: <CreateProject /> },
  { path: `/Update-Project`, Component: <CreateProject /> },

  { path: `/order-list`, Component: <Order /> },
  { path: `/order-details`, Component: <OrderDetails /> },

  { path: `/transaction-list`, Component : <Transactions />},

  { path: `/notification-list`, Component : <Notification />},
  { path: `/create-notification`, Component : <CreateNotification />},

  { path: `/deal_of_the_day`, Component: <DealOfDay /> },
  { path: `/all_deal_of_the_day`, Component: <AllDealOfDay /> },
  { path: `/create_deal_of_the_day`, Component: <CreateDealOfDay /> },
  { path: `/update_deal_of_the_day`, Component: <CreateDealOfDay /> },
  { path: `/all_expired_deal_of_the_day`, Component: <AllExpiredDealOfDay /> },

  { path: `/last_min_deal`, Component: <LastMinDeal /> },
  { path: `/all_last_min_deal`, Component: <AllLastMinDeal /> },
  { path: `/create_last_min_deal`, Component: <CreateLastMinDeal /> },
  { path: `/update_last_min_deal`, Component: <CreateLastMinDeal /> },
  { path: `/expried_last_min_deal`, Component: <ExpiredLastMinDeal /> },
  { path: `/all_expried_last_min_deal`, Component: <AllExpiredOffers /> },

  { path: `/offers`, Component: <Offers /> },
  { path: `/create_offers`, Component: <CreateOffers /> },
  { path: `/update_offers`, Component: <CreateOffers /> },
  { path: `/expried_offers`, Component: <ExpiredOffers /> },
  { path: `/all_expried_offers`, Component: <AllExpiredOffers /> },
  { path: `/all_offers`, Component: <AllOffers /> },

  { path: `/promocode`, Component: <Promocode /> },
  { path: `/create_promocode`, Component: <CreatePromocode /> },
  { path: `/update_promocode`, Component: <CreatePromocode /> },
  { path: `/expired_promocode`, Component: <ExpiredPromocode /> },
  { path: `/promocode_history`, Component: <PromocodeHistory /> },


  // { path: `/Project`, Component: <Project /> },
  { path: `/create-vendor`, Component: <CreateVendor /> },
  { path: `/update-vendor`, Component: <CreateVendor /> },
  { path: `/vendor`, Component: <Vendor /> },
  { path: `/Upcoming-Expiry-Workers`, Component: <UpcomingExpiryVendor /> },

  { path: `/create_advertisement`, Component: <CreateSliderBanner /> },
  { path: `/update_advertisement`, Component: <CreateSliderBanner /> },
  { path: `/advertisement`, Component: <SliderBanner /> },
  { path: `/expired_advertisement`, Component: <ExpiredSliderBanner /> },

  { path: `/Create-Language`, Component: <CreateLanguage /> },
  { path: `/Update-Language`, Component: <CreateLanguage /> },
  { path: `/Language`, Component: <Language /> },

  { path: `/Create-Worker`, Component: <CreateWorkers /> },
  { path: `/Update-Worker`, Component: <CreateWorkers /> },
  { path: `/Worker`, Component: <Worker /> },
  { path: `/Worker/Worker-Details`, Component: <WorkerDetails /> },
  { path: `/Upcoming-Expiry`, Component: <UpcomingExpiry /> },

  { path: `/Customers`, Component: <Customer /> },
  { path: `/customer/details`, Component: <CustomerDetails /> },
  
  { path: `/City`, Component: <City /> },
  { path: `/Create-City`, Component: <CreateCity /> },
  { path: `/Update-City`, Component: <CreateCity /> },
  
  

  { path: `/Country`, Component: <Country /> },
  { path: `/Create-Country`, Component: <CreateCountry /> },
  { path: `/Update-Country`, Component: <CreateCountry /> },

  { path: `/Booking-Request`, Component: <BookingRequest /> },
  { path: `/Pending-Booking-Request`, Component: <PendingBookingRequest /> },
  { path: `/Booking-Request/Details/:id`, Component: <BookingDetails /> },
  { path: `/Pending-Booking-Request/Details/:id`, Component: <PendingBookingDetails /> },
  { path: `/Booking-Assigned`, Component: <AssignedBooking /> },
  { path: `/All-Booking-Today`, Component: <TodayBookingAdmin /> },
  { path: `/Booking-History`, Component: <BookingHistory /> },
  
  { path: `/Booking-Assigned-Vendor`, Component: <VendorAssignedBooking /> },
  { path: `/Booking-Request-Vendor`, Component: <BookingVendorRequest /> },
  { path: `/Booking-Request-Vendor/Details/:id`, Component: <BookingVendorDetails /> },
  { path: `/Booking-Today`, Component: <TodayBooking /> },
  { path: `/Booking-Completed`, Component: <CompletedBooking /> },
  { path: `/Rejected-Booking-Request`, Component: <RejectedBookingRequest /> },    
  { path: `/Confirmed-Booking-Request`, Component: <ConfirmedBookingRequest /> },    



  { path: `/Setting/About Us`, Component: <AboutUs /> },
  { path: `/Setting/Terms and Condition`, Component: <TermsConditions /> },
  { path: `/Setting/PrivacyPolicy`, Component: <PrivacyPolicy /> },
  { path: `/Setting/Copy-Right`, Component: <CopyRight /> },

  { path: `/Setting/About-Us-vendor`, Component: <AboutUsVendor /> },
  { path: `/Setting/Terms-Condition-vendor`, Component: <TermsandConditionVendor /> },
  { path: `/Setting/Privacy-Policy-vendor`, Component: <PrivacyPolicyVendor /> },
  // { path: `/Setting/Copy-Right`, Component: <CopyRight /> },



  { path: `/assigned-tasks`, Component: <Tasks /> },
  { path: `/assigned-tasks/Details`, Component: <TaskInfo /> },

  { path: `/team-leader-projects`, Component: <Projects /> },
  { path: `/vendor/details`, Component: <ProjectDetails /> },
  { path: `/team-leader-projects/details/task`, Component: <EmployesTask /> },
  // <div className=""></div>
  { path: `/team-leader-projects/details/create-team`, Component: <CreateWorkAssign /> },
  { path: `/team-leader-projects/view-team-member`, Component: <WorkAssign /> },
  { path: `/team-leader-projects/update-work-assign`, Component: <UpdateWorkAssign /> },

];
