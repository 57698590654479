import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import * as Action from "../../actions/project";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { IMAGE } from "../../api";
import {
  CREATE_PROJECT,
} from "../../Constant";
import { Breadcrumbs, H5 } from "../../AbstractElements";
import Form from "../../utils/Form";
import { company_services_list } from "../../actions/companyServices";
import { company_user_list } from "../../actions/employee";
import { all_client_list } from "../../actions/client";
const CreateProject = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "newLOCS");
  const isEdit = location?.state?.isEdit;
  const user = useSelector((state) => state?.layout?.user);
  const Employees = useSelector((state) => state?.employees?.employee_list);
  const Client = useSelector((state)=> state?.client?.client_list)
  console.log(Employees, "test");
  const { project_create } = bindActionCreators(Action, dispatch);

  const [file, setFile] = useState(null);
  const [form, setForm] = useState({});

  useEffect(() => {
    if (location?.state) {
      const data = location?.state?.data;
      setForm({
        ...data,
        service: data?.service?.map((ele) => ele?.service_name),
      });
    }
  }, [location?.state]);
  useEffect(() => {
    if (user) {
      const data = new FormData();
      data.append("company", user?.id);
      dispatch(company_services_list(data));
      dispatch(company_user_list(data));
      dispatch(all_client_list(data));
    }
  }, [user]);

  const Company_services_list = useSelector(
    (state) => state?.company_services?.company_services_list
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    const temp_form = form;
    temp_form["temp_service"] = form?.["service"];
    temp_form["temp_project_owner"] = form?.["project_owner"];

    temp_form["temp_project_manager"] = form?.["project_manager"];
    temp_form["budget"] = parseFloat(form?.budget).toFixed(2);
    temp_form["temp_team_member"] = form?.["team_member"];
    temp_form["service"] = Company_services_list?.data
      ?.map(
        (option) =>
          form?.["service"]?.includes(option?.service_name) && option?.id
      )
      .filter((e) => e);
    // temp_form["service"] = Company_services_list?.data
    //   ?.filter((option) => form?.["service"] == option.service_name)
    //   .map((option) => option.id);
    temp_form["project_manager"] = Employees?.data
      ?.filter((option) => form?.["project_manager"] == option.name)
      .map((option) => option.id);
    temp_form["team_member"] = Employees?.data
      ?.map(
        (option) => form?.["team_member"]?.includes(option?.name) && option?.id
      )
      .filter((e) => e);
    temp_form["project_owner"] = Client?.data
      ?.map(
        (option) => form?.["project_owner"]?.includes(option?.name) && option?.id
      )
      .filter((e) => e);
    setForm({
      ...temp_form,
    });
    const data = new FormData();
    data.append("company", user?.id);
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    formStructure.map((element) => {
      if (
        element.type == "image" &&
        form?.[element.name] &&
        typeof form?.[element.name] != "string"
      ) {
        const temp = form;
        temp["temp" + element.name] = form?.[element.name];
        temp[element.name] = URL.createObjectURL(form?.[element.name]);

        setForm({
          ...temp,
        });
      }
    });

    if (isEdit) {
      // const UpdateEmployeeApi = await company_user_update(data);
      // if (UpdateEmployeeApi?.statuscode == 200) {
      //   toast.success(UpdateEmployeeApi?.message);
      //   setTimeout(() => {
      //     navigate("/Employees");
      //     dispatch({ type: CREATE_EMPLOYEES, payload: undefined });
      //   }, 1000);
      //   setForm({});
      // } else {
      //   toast.error(UpdateEmployeeApi?.message);
      // }
    } else {
      const createProjectApi = await project_create(data);
      if (createProjectApi?.statuscode == 200) {
        toast.success(createProjectApi?.message);
        setTimeout(() => {
          navigate("/Project");
          dispatch({ type: CREATE_PROJECT, payload: undefined });
        }, 1000);
        setForm({});
      } else {
        toast.error(createProjectApi?.message);
        const tempForm = form;
        tempForm["service"] = form?.["temp_service"];
        tempForm["project_manager"] = form?.["temp_project_manager"];
        tempForm["team_member"] = form?.["temp_team_member"];
        setForm({ ...tempForm });
      }
    }
  };

  const [formStructure, setFormStructure] = useState([
    {
      id: "1",
      type: "inputBox",
      maxLength: 30,
      title: "Project Name",
      name: "project_name",
      regex: /^[a-zA-Z\s\&]+$/,
      // isCaps:true,
      required: true,
    },
    {
        id: "2",
        type: "select",
        title: "Project Owner",
        name: "project_owner",
        // maxSelections: "3",
        options: ["English", "Hindi", "Gujarati"],
        // errorText: "You can select max 3 Services at a time",
        required: true,
        // isPlusBtnOpen :true,
      },
    {
      id: "3",
      type: "inputBox",
      variant: "date",
      title: "Start Date",
      default: " ",
      min: new Date().toISOString().split("T")[0],
      name: "start_date",
      // display: "none",
      required: true,
    },
    {
      id: "4",
      type: "inputBox",
      variant: "date",
      title: "End Date",
      default: " ",
      min: new Date().toISOString().split("T")[0] + 1,
      name: "end_date",
      // display: "none",
      required: true,
    },

    {
      id: "5",
      type: "select_multiple",
      title: "Service",
      name: "service",
      maxSelections: "3",
      options: ["English", "Hindi", "Gujarati"],
      errorText: "You can select max 3 Services at a time",
      required: true,
      // isPlusBtnOpen :true,
    },
    {
      id: "6",
      type: "select",
      title: "Project Manager",
      name: "project_manager",
      // maxSelections: "3",
      options: ["English", "Hindi", "Gujarati"],
      // errorText: "You can select max 3 Services at a time",
      required: true,
      // isPlusBtnOpen :true,
    },
    // {
    //   id: "7",
    //   type: "select_multiple",
    //   title: "Team Members",
    //   name: "team_member",
    //   maxSelections: "3",
    //   options: ["English", "Hindi", "Gujarati"],
    //   errorText: "You can select max 3 Services at a time",
    //   required: true,
    //   // isPlusBtnOpen :true,
    // },
    {
      id: "8",
      type: "inputBox",
      title: "Budget",
      regex: /^[0-9\.]+$/,
      name: "budget",
      required: true,
    },
    {
      id: "9",
      type: "description",
      title: "Description",
      name: "description",
      required: true,
    },
    // ,
    // {
    //     id:"3",
    //     type:"lockedInput",
    //     variant:"number",
    //     min:0,
    //     max:99,
    //     title:"Sequence",
    //     name:"sequence",
    //     required:true
    // }
    {
      id: "10",
      type: "toggle",
      title: "Status",
      name: "status",
      default: "Active",
      display: isEdit ? "block" : "none",
      size: "6",
      options: [
        { value: "Active", color: "#00B69B" },
        { value: "Inactive", color: "#EE368C" },
      ],
    },
    {
      id: "11",
      type: "file1",
      title: "Upload documents",
      // subtitle:"(Resolution : 512px x 512px) *",
      // subsubtitle:"Max File Size 1MB",
      // subsubsubtitle:"Support only JPG,PNG,JPEG",
      name: "documents",
      accept: ".pdf ,.xlsx , .docx",
    },
    {
      id: "12",
      type: "button",
      title: "Create",
    },
  ]);
  const formTitle = isEdit ? "Edit Category" : "Create Category";
  useMemo(() => {
    if (Client?.statuscode == 200) {
      console.log(Client, "newc");

      const temp = formStructure;
      temp[1]["options"] = Client?.data?.map(
        (ele) => ele?.name);
      setFormStructure([...temp]);
      console.log(formStructure);
    }
  }, [Client]);
  useMemo(() => {
    if (Company_services_list?.statuscode == 200) {
      console.log(Company_services_list, "newc");

      const temp = formStructure;
      temp[4]["options"] = Company_services_list?.data?.map(
        (ele) => ele?.service_name
      );
      setFormStructure([...temp]);
      console.log(formStructure);
    }
  }, [Company_services_list]);
  useMemo(() => {
    if (Employees?.statuscode == 200) {
      console.log(Employees, "newc2");

      const temp = formStructure;
      temp[5]["options"] = Employees?.data?.map((ele) => ele?.name);
      setFormStructure([...temp]);
      console.log(formStructure);
    }
  }, [Employees]);
  // useMemo(() => {
  //   if (Employees?.statuscode == 200 && form?.project_manager) {
  //     console.log(Company_services_list, "newc");

  //     const temp = formStructure;
  //     temp[6]["options"] = Employees?.data?.map(
  //       (ele) => form?.project_manager != ele?.name && ele?.name
  //     );
  //     setFormStructure([...temp]);
  //     console.log(formStructure);
  //   }
  // }, [Employees, form?.project_manager]);
  return (
    <div>
      <Breadcrumbs
        parent="Project"
        title={isEdit ? "Edit Project" : "Create Project"}
        mainTitle={isEdit ? "Edit Project" : "Create Project"}
      />

      <Form
        handleSubmit={handleSubmit}
        formStructure={formStructure}
        formTitle={formTitle}
        key={"Form"}
        setForm={setForm}
        form={form}
        // tableData={tableData}
        // setTableData={setTableData}
        isEdit={isEdit}
        // setIsEdit={setIsEdit}
      />
    </div>
  );
};

export default CreateProject;
