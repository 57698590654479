import * as actionType from '../Constant';


const SubCategoryReducer = (state = { subCategoryData: null }, action) => {
  switch (action.type) {
    case actionType.SUBCATEGORY:
      return { ...state, subcategory: action.payload };
    case actionType.SUBCATEGORYLIST:
      return {...state, subcategory_list: action.payload}

    default:
      return state;
  }
};

export default SubCategoryReducer;
