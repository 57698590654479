import * as actionType from '../Constant';


const LayoutReducer = (state = { categoryData: null }, action) => {
  switch (action.type) {
    case actionType.USER_DETAILS:
      return { ...state, user: action.payload };
    case actionType.LOGGEDIN:
      return {...state, loggedIn: action.payload}

    default:
      return state;
  }
};

export default LayoutReducer;
