import * as actionType from '../Constant';


const CityReducer = (state = { cityData: null }, action) => {
  switch (action.type) {
    case actionType.CITY:
      return { ...state, city: action.payload };
    case actionType.CITY_LIST:
      return {...state, city_list: action.payload}

    default:
      return state;
  }
};

export default CityReducer;
