// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import ListTable from "../../../utils/Table";
import { all_category_list, category_delete } from "../../../actions/category";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { OFFERS } from "../../../Constant";
import { admin_vendor_wise_expire_offers_list, admin_vendor_wise_offers_list, all_expired_offers_list } from "../../../actions/offeres/offers";


const AllExpiredOffers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const Offers = useSelector((state)=>state?.offers?.expired_offers_list)
  const message = useSelector((state)=>state?.offers?.offers)
  const login_details = JSON.parse(sessionStorage.getItem("user"))
  const role = login_details?.id?.userrole?.rolename

  console.log(Offers)
  useMemo(()=>{
    if(login_details){
      dispatch(all_expired_offers_list())
    }
  },[])
  const tempTableData = {
    tableTitle: "All Expired Offers",
    // deleteRecord: delete_offers,
    disableDelete : true,
    // updateRecord: subcategory_update,
    // deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    onDeleteText: "Are you sure want to delete?",
    onUpdateText:
      "Videos are associated with this category, are you still want to change it.?",
    tableHead: [
      {
        id:"product",
        label:"Product"
      },
      {
        id: "product_sub_category",
        label: "Category Name",
        subText : "product_category"
      },
      {
        id: "start_date",
        label: "Start Date",
      },
      {
        id:"end_date",
        label:"End Date"
      },
      {
        id :"product_final_price",
        label :"Offer Before Price"
      },
      {
        id :"offer_type",
        label :"Offer Type"
      },
      {
        id :"offer_amount",
        label :"Offer Price"
      },
      // {
      //   id: "status",
      //   label: "Status",
      //   isButtonDisplay: true,
      // },
      // {
      //   id: "edit",
      //   label: "Update",
      //   edit_path :"/update_offers",
      //   // id : "id",
      //   // handleEdit : (value) => {navigate("/Update-Category" , {state:{id:value?.id}})}
      //   // access: rights?.["Masters"]?.["edit"] == "true",
      // },
    ],
    tableBody: [
      {
        category :"1",
        set_sequence :"1",
        subcategory_name :"78",
        status : "Active",
      },
      {
        category :"1",
        set_sequence :"1",
        subcategory_name :"79",
        status : "Active",
      }
    ],
    filterColumn: [],
  };
  const [tableData, setTableData] = useState({...tempTableData})

  useMemo(()=>{
    if(Offers?.statuscode == 200){
      const temp = tableData
      temp.tableBody = Offers?.data?.map((ele)=>({
        ...ele,
        product : ele?.product?.product_name,
        product_category: ele?.product_category?.category_name,
        product_sub_category : ele?.product_sub_category?.sub_category_name
      }))
      setTableData({...temp})
    }
  },[Offers])
  // useMemo(()=>{
  //   if(message?.statuscode == 200){
  //     toast.success(message?.message);
  //     if(login_details){
  //       dispatch(all_expired_offers_list())
  //     }
  //     dispatch({ type: OFFERS, payload: undefined })
  //   }
  // },[message])
  return (
    <div >
      <Breadcrumbs
        parent="Offers"
        title="All Expired Offers List"
        mainTitle="All Expired Offers List"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
              <ListTable
              tableData={tableData}
              key={"ListTable"}
              // setForm={setForm}
              setTableData={setTableData}
              // setIsEdit={setIsEdit}
              />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AllExpiredOffers;
