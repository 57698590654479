import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import * as Action from "../../actions/workAssign";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { IMAGE } from "../../api";
import { CREATE_PROJECT } from "../../Constant";
import { Breadcrumbs, H5 } from "../../AbstractElements";
import Form from "../../utils/Form";
import { company_services_list } from "../../actions/companyServices";
import { company_user_list } from "../../actions/employee";
import { all_client_list } from "../../actions/client";
import { company_project_list } from "../../actions/project";
import { all_projects_of_manager } from "../../actions/empActions";
const CreateWorkAssign = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "newLOCS");
  const isEdit = location?.state?.isEdit;
  const user = useSelector((state) => state?.layout?.user);
  // const company = useSelector((state) => state?.layout?.company);
  const Employees = useSelector((state) => state?.employees?.employee_list);
  const Client = useSelector((state) => state?.client?.client_list);
  console.log(Employees, "test");
  const { project_work_assign_create } = bindActionCreators(Action, dispatch);

  const [file, setFile] = useState(null);
  const [form, setForm] = useState({});

  useEffect(() => {
    if (location?.state) {
      const data = location?.state?.data;
      setForm({
        ...data,
        service: data?.service?.map((ele) => ele?.service_name),
      });
    }
  }, [location?.state]);
  useEffect(() => {
    if (user) {
      const data = new FormData();
      data.append("company", user?.company);
      data.append("user", user?.id);
      dispatch(company_services_list(data));
      dispatch(company_user_list(data));
      dispatch(all_client_list(data));
      dispatch(all_projects_of_manager(data));
    }
  }, [user]);

  const Company_services_list = useSelector(
    (state) => state?.company_services?.company_services_list
  );
  const Project_list = useSelector((state) => state?.emp?.manager_projects);
  const getServiceIdByName = (serviceName) => {
    const service = Project_list?.data?.find(
      (proj) => proj.project_name === form?.project
    )?.service;
    const serviceData = service?.find((s) => s.service_name === serviceName);
    return serviceData ? serviceData.id : null;
  };

  // Function to transform form data
  const transformFormData = (form) => {
    const transformedData = {};
    for (let key in form) {
      if (key.startsWith("service")) {
        const serviceName = form[key];
        // const serviceId = getServiceIdByName(serviceName);
        const serviceId = Company_services_list?.data
          ?.map((ele) => serviceName?.includes(ele?.service_name) && ele?.id)
          .filter((e) => e);
        if (serviceId) {
          transformedData[key] = serviceId;
        }
      } else if (key.startsWith("team_member")) {
        const memberName = form[key];
        const memberId = Employees?.data
          ?.map((ele) => memberName?.includes(ele?.name) && ele?.id)
          .filter((e) => e);
        if (memberId) {
          transformedData[key] = memberId;
        }
      } else if (key.startsWith("project")) {
        const memberName = form[key];
        const memberId = Project_list?.data
          ?.map(
            (option) => memberName?.includes(option?.project_name) && option?.id
          )
          .filter((e) => e);
        if (memberId) {
          transformedData[key] = memberId;
        }
      } else {
        transformedData[key] = form[key];
      }
    }

    return transformedData;
  };


  console.log(transformFormData, "data12364");
  const handleSubmit = async (event) => {
    event.preventDefault();
    const temp_form = form;

    const transformedData = transformFormData(form);
    setForm({
      ...temp_form,
    });
    const data = new FormData();
    const project_id = Project_list?.data
      ?.map(
        (option) =>
          form?.["project"]?.includes(option?.project_name) && option?.id
      )
      .filter((e) => e);
    data.append("company", project_id);
    data.append("project_manager", user?.id);
    // data.append("datas", JSON.stringify(transformedData))
    Object.keys(transformedData).map((key) =>
      data.append(key, transformedData?.[key])
    );
    formStructure.map((element) => {
      if (
        element.type == "image" &&
        form?.[element.name] &&
        typeof form?.[element.name] != "string"
      ) {
        const temp = form;
        temp["temp" + element.name] = form?.[element.name];
        temp[element.name] = URL.createObjectURL(form?.[element.name]);

        setForm({
          ...temp,
        });
      }
    });

    const createProjectApi = await project_work_assign_create(data);
    if (createProjectApi?.statuscode == 200) {
      toast.success(createProjectApi?.message);
      setTimeout(() => {
        navigate("/team-leader-projects/view-team-member");
        dispatch({ type: CREATE_PROJECT, payload: undefined });
      }, 1000);
      setForm({});
    } else {
      toast.error(createProjectApi?.message);
      const tempForm = form;
      tempForm["service"] = form?.["temp_service"];
      tempForm["project_manager"] = form?.["temp_project_manager"];
      tempForm["team_member"] = form?.["temp_team_member"];
      setForm({ ...tempForm });
    }

  };

  const [formStructure, setFormStructure] = useState([

    {
      id: "1",
      type: "select",
      title: "List Of Projects",
      name: "project",
      // maxSelections: "3",
      options: ["English", "Hindi", "Gujarati"],
      // errorText: "You can select max 3 Services at a time",
      required: true,
      size: "12",
      // isPlusBtnOpen :true,
    },

  ]);
  const formTitle = isEdit ? "Edit Category" : "Create Category";


  useEffect(() => {
    if (form?.project !== undefined) {
      const newServiceFields = Project_list?.data?.reduce((acc, ele) => {
        if (ele?.project_name === form?.project) {
          const serviceFields = ele?.service?.reduce((serviceAcc, s, index) => {
            // Update form state with the service value
            setForm((prevForm) => ({
              ...prevForm,
              [`service${index}`]: s.service_name,
            }));

            return [
              ...serviceAcc,
              {
                id: `service_${index}`,
                type: "inputBox",
                title: `Service ${index + 1}`,
                name: `service${index}`,
                required: true,
                disabled: true,
              },
              {
                id: `members_${index}`,
                type: "select_multiple",
                title: `Select Members For Service ${index + 1}`,
                name: `team_member${index}`,
                options: Employees && Employees?.data?.map((ele) => ele?.name),
                required: true,
              },
              {
                id: `start_date_${index}`,
                type: "inputBox",
                variant: "date",
                title: `Start Date For Service ${index + 1}`,
                default: " ",
                min: new Date().toISOString().split("T")[0],
                name: `start_date${index}`,
                required: true,
              },
              {
                id: `end_date_${index}`,
                type: "inputBox",
                variant: "date",
                title: `End Date For Service ${index + 1}`,
                default: " ",
                min: new Date().toISOString().split("T")[0],
                name: `end_date${index}`,
                required: true,
              },
              {
                id: `description_${index}`,
                type: "description",
                title: `Description For Service ${index + 1}`,
                name: `description${index}`,
                required: true,
              },
              {
                id: `line_${index}`,
                type: "hr",
              },
            ];
          }, []);
          return [...acc, ...serviceFields];
        }

        return acc;
      }, []);

      setFormStructure((prevFormStructure) => [
        ...prevFormStructure.filter(
          (field) =>
            !field.id.startsWith("service_") &&
            !field.id.startsWith("members_") &&
            !field.id.startsWith("start_date_") &&
            !field.id.startsWith("end_date_") &&
            !field.id.startsWith("description_") &&
            !field.id.startsWith("line_")
        ),
        ...newServiceFields,
        {
          id: "120",
          type: "button",
          title: "Create",
        },
      ]);
    }
  }, [form?.project, Project_list, setForm]);

  useMemo(() => {
    if (Employees?.statuscode == 200) {
      console.log(Employees, "newc2");

      const temp = formStructure;
      const EmployeeData = Employees?.data?.map((ele) => ele?.name);
      setFormStructure([...temp]);
      console.log(formStructure);
    }
  }, [Employees]);
  useMemo(() => {
    if (Project_list?.statuscode == 200) {
      console.log(Project_list, "newc2");

      const temp = formStructure;
      temp[0]["options"] = Project_list?.data?.map((ele) => ele?.project_name);
      setFormStructure([...temp]);
      console.log(formStructure);
    }
  }, [Project_list]);
  return (
    <div>
      <Breadcrumbs
        parent="Project"
        title={isEdit ? "Edit Work-Assign" : "Create Work-Assign"}
        mainTitle={isEdit ? "Edit Work-Assign" : "Create Work-Assign"}
      />

      <Form
        handleSubmit={handleSubmit}
        formStructure={formStructure}
        formTitle={formTitle}
        key={"Form"}
        setForm={setForm}
        form={form}
        // tableData={tableData}
        // setTableData={setTableData}
        isEdit={isEdit}
      // setIsEdit={setIsEdit}
      />
    </div>
  );
};

export default CreateWorkAssign;
