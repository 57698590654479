import { LEVELLIST } from "../Constant";
import * as api from '../api/index.jsx';


export const level_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.level_list(formData);
      
      dispatch({ type: LEVELLIST, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };
  