import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import * as VendorAction from '../../actions/vendor'
import { bindActionCreators } from 'redux'
import { Breadcrumbs, H6 } from '../../AbstractElements'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'
import DataTable from 'react-data-table-component'
import { toast } from 'react-toastify'
import { Avatar, Divider, Grid, Paper, styled } from '@mui/material'
import { deepPurple } from '@mui/material/colors'

const BookingVendorDetails = () => {
  const para = useParams()
  const dispatch = useDispatch()
  const { getBookingInfoVendor, assignWorker } = bindActionCreators(VendorAction, dispatch)
  const [details, setDetails] = useState()
  const [save, setSave] = useState(false)
  useEffect(() => {
    (async () => {
      if (para?.id) {
        console.log(para)
        const resData = await getBookingInfoVendor({ id: para.id })
        console.log("Result: ", resData)
        setDetails(resData)
      }
    })();
  }, [save])

  const handleAddWorker = async (worker_id) => {
    const resData = await assignWorker({
      vendor: details?.data?.vendor,
      booking_request: details?.data?.booking_request?.id,
      worker: worker_id
    })
    if (resData?.statuscode == 200) {
      toast.success(resData?.message)
      setSave(!save)
    }
    else {
      toast.error(resData?.message)

    }
  }

  const productColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Contact",
      selector: (row) => row.contact,
      sortable: true,
      wrap: true,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
      wrap: true,
    },
    {
      name: "Permit Expiry",
      selector: (row) => row.permitExpiry,
      sortable: true,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      center: true,
    },
  ];

  const ProductList =
    details &&
    details?.data?.worker?.map((value, index) => ({
      name: (
        <div>
          <H6>{value?.firstname} {value?.lastname}</H6>
        </div>
      ),
      contact: (
        <div>
          <p>{value?.email}</p>
          <p>{value?.mobile_number}</p>
        </div>
      ),
      location: (
        <div>
          <p>{value?.area_or_street}</p>
          <p>{value?.city}-{value?.pincode}</p>
        </div>
      ),
      permitExpiry: (
        <div>
          <p>{value?.work_permit_expire_at}</p>
        </div>
      ),
      action: (
        <div>
          <span>
            <Button
              onClick={() => handleAddWorker(value?.id)}
              style={{
                background: "rgba(235, 97, 6, 0.1)",
                color: "#ffffff",
                padding: "5px 13px",
                borderRadius: "4px",
              }}
              disabled={value?.worker_assign === "true" ? true : false}
            >
              {" "}
              {value?.worker_assign == "false" ? "Add" : "Remove"}
              {" "}
            </Button>
          </span>
        </div>
      ),

    }));

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
      backgroundColor: '#1A2027',
    }),
  }));


  return (
    <div className="table-responsive product-table">
      <Breadcrumbs
        parent="Booking"
        title={details?.data?.booking_request?.name}
        mainTitle="Booking Details"
      />
      <Grid container spacing={2} className='mb-5'>
        <Grid item xs={4}>
          <Item style={{ display: "flex", justifyContent: "center", padding: "50px" }}>
            <Avatar sx={{ bgcolor: "#e800268f", width: "100px", height: "100px" }}>{details?.data?.booking_request?.name?.[0]?.toUpperCase()}{details?.data?.booking_request?.name?.[1]?.toUpperCase()}</Avatar>
          </Item>
        </Grid>
        <Grid item xs={8}>
          <Item>
            <Grid container spacing={2}>

              <Grid item xs={5} style={{ textAlign: "left" }}>
                <p>Customer Name</p>
              </Grid>
              <Grid item xs={7} style={{ textAlign: "left" }}>
                <h6>
                  {details?.data?.booking_request?.name}
                </h6>
              </Grid>
              <hr style={{ color: "red" }} />
              <Grid item xs={5} style={{ textAlign: "left" }}>
                <p>Email</p>
              </Grid>
              <Grid item xs={7} style={{ textAlign: "left" }}>
                <h6>
                  {details?.data?.booking_request?.email}
                </h6>
              </Grid>
              <Grid item xs={5} style={{ textAlign: "left" }}>
                <p>Contact No.</p>
              </Grid>
              <Grid item xs={7} style={{ textAlign: "left" }}>
                <h6>
                  {details?.data?.booking_request?.contact}
                </h6>
              </Grid>
              <Grid item xs={5} style={{ textAlign: "left" }}>
                <p>Start Data</p>
              </Grid>
              <Grid item xs={7} style={{ textAlign: "left" }}>
                <h6>
                  {details?.data?.booking_request?.from_date}
                </h6>
              </Grid>
              <Grid item xs={5} style={{ textAlign: "left" }}>
                <p>End Date</p>
              </Grid>
              <Grid item xs={7} style={{ textAlign: "left" }}>
                <h6>
                  {details?.data?.booking_request?.to_date}
                </h6>
              </Grid>
              <Grid item xs={5} style={{ textAlign: "left" }}>
                <p>Required Service</p>
              </Grid>
              <Grid item xs={7} style={{ textAlign: "left" }}>
                <h6>
                  {details?.data?.booking_request?.category?.category_name}({details?.data?.booking_request?.subcategory?.subcategory_name})
                </h6>
              </Grid>
              <Grid item xs={5} style={{ textAlign: "left" }}>
                <p>Location</p>
              </Grid>
              <Grid item xs={7} style={{ textAlign: "left" }}>
                <h6>
                  {/* {details?.data?.booking_request?.address} */}
                </h6>
                <h6>
                  {/* {details?.data?.booking_request?.city}-{details?.data?.booking_request?.pincode} */}
                </h6>
                <h6>
                  <p>{details?.data?.booking_request?.house_no} - {details?.data?.booking_request?.residence_name}</p>
                  <p>{details?.data?.booking_request?.area_or_street},{details?.data?.booking_request?.city?.city_name}</p>
                </h6>
              </Grid>
              <Grid item xs={5} style={{ textAlign: "left" }}>
                <p>Total Required Workers</p>
              </Grid>
              <Grid item xs={7} style={{ textAlign: "left" }}>
                <h6>
                  {details?.data?.total_worker} Workers
                </h6>
              </Grid>
            </Grid>
          </Item>
        </Grid>

      </Grid>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  noHeader
                  pagination
                  paginationServer
                  columns={productColumns}
                  data={ProductList}
                  highlightOnHover={true}
                  striped={true}
                  responsive={true}
                  className="custom-data-table"
                  // className="custom-data-table-vendor"

                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default BookingVendorDetails