import React from 'react'
import { useSelector } from 'react-redux'
import { Breadcrumbs, H6 } from '../../AbstractElements'
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap'
import DataTable from 'react-data-table-component'
import { API_LINK } from '../../api'
export default function UpcomingExpiryVendor() {
    const WorkerList = useSelector((state) => state?.Notification?.expiryPermitVendor)
    console.log("WorkerList", WorkerList)


    const productColumns = [
        {
            name: "Name",
            selector: (row) => row.Name,
            sortable: true,
            // center: true,
        },
        {
            name: "Contact",
            selector: (row) => row.Contact,
            sortable: true,
            // center: true,
        },
        {
            name: "Email",
            selector: (row) => row.Address,
            sortable: true,
            // center: true,
        },
        {
            name: "Contact",
            selector: (row) => row.Expiry,
            sortable: true,
            // center: true,
        },
        {
            name: "ID Proof",
            selector: (row) => row.ID,
            sortable: true,
            // center: true,
        },
        {
            name: "Passport",
            selector: (row) => row.Passport,
            sortable: true,
            // center: true,
        },
        {
            name: "Work Permit",
            selector: (row) => row.Work,
            sortable: true,
            // center: true,
        },



    ];

    const handleDownload = (url) => {
        window.open(API_LINK + url, '__blank');
    }

    const ProductList =
        WorkerList &&
        WorkerList?.data?.map((value, index) => ({
            Name: (
                <div>
                    <H6>{value?.firstname + " " + value?.lastname}</H6>
                </div>
            ),

            Contact: (
                <div>
                    <p>{value?.email}</p>
                    <p>{value?.mobile_number}</p>
                </div>
            ),
            Address: (
                <div>
                    <p>{value?.area_or_street},{value?.city}</p>
                    <p>{value?.state}-{value?.pincode}</p>
                </div>
            ),
            Expiry: (
                <div>
                    <p>{value?.work_permit_expire_at}</p>
                </div>
            ),
            ID: (
                <div onClick={()=>handleDownload(value?.goverment_id_photo)}>
                    <i className="fa fa-download pointer" aria-hidden="true"></i>
                </div>
            ),
            Passport: (
                <div onClick={()=>handleDownload(value?.passport_photo)}>
                    <i className="fa fa-download pointer" aria-hidden="true"></i>
                </div>
            ),
            Work: (
                <div onClick={()=>handleDownload(value?.work_permit)}>
                    <i className="fa fa-download pointer" aria-hidden="true"></i>
                </div>
            ),


            workers: value?.worker

        }));





    return (
        <div className="table-responsive product-table">
            <Breadcrumbs
                parent="Workers"
                title="Upcoming Expiry"
                mainTitle="Work Permit Due"
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <DataTable
                                    noHeader
                                    pagination
                                    paginationServer
                                    columns={productColumns}
                                    data={ProductList || []}
                                    highlightOnHover={true}
                                    striped={true}
                                    responsive={true}
                                    className="custom-data-table"

                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}
