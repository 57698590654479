import category from "./category"
import subcategory from "./subcategory"
import tags from "./tags"
import auth from "./AuthStore"
import emp from "./empStore"
import layout from "./layout"
import company_services from "./companyServices"
import employees from "./emaployees"
import client from "./client"
import project from "./project"
import work_assign from "./workAssign"
import vendor from "./vendor"
import slider_banner from "./sliderbanner"
import language from "./language"
import booking from "./booking"
import notification from "./Notification"
import dashboard from "./dashboard"
import customer from "./customer"
import city from "./city"
import setting from "./setting"
import gst_rate from "./gst_rate"
import offers from "./offers"
import unit from "./unit"
import product from "./product"
import worker from "./worker"
import transaction from "./transaction"
import order from "./order"
import { combineReducers } from 'redux';

export const reducers = combineReducers
    ({
        category,
        subcategory,
        client,
        city,
        layout,
        project,
        gst_rate,
        company_services,
        slider_banner,
        notification,
        offers,
        setting, 
        language,
        order,
        dashboard,
        customer,
        employees,
        unit,
        product,
        booking,
        work_assign,
        tags,
        transaction,
        worker,
        vendor,
        auth,
        emp,
    });
