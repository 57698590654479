import React, { useMemo } from 'react';
import './ProductDetailsPage.css'; // Import the CSS for styling
import { Carousel } from 'react-responsive-carousel'; // Using a carousel for images
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import { Grid } from '@mui/material';
import { product_details } from "../../../actions/product";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router';
import { IMAGE } from '../../../api';
const ProductDetailsContent2 = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const role = useSelector((state) => state.layout.role);
  // clg
  useMemo(() => {
    const formData = new FormData();
  formData.append("product_id", location.state?.id);
    dispatch(product_details(formData))
  }, [location?.state?.id]);
  const products_detail = useSelector((state) => state?.product?.product_details?.data);
  console.log(products_detail ,"NreProducry" )
    const product = {
        name: products_detail?.product_name,
        category: products_detail?.category?.category_name,
        subcategory: products_detail?.subcategory?.sub_category_name,
        unit: products_detail?.unit?.unit_name,
        packagingSize: products_detail?.packaging_size,
        quantity: products_detail?.quantity,
        price: parseFloat(products_detail?.price).toFixed(2),
        discount: parseFloat(products_detail?.discount).toFixed(2),
        discounted_price: parseFloat(products_detail?.discounted_price).toFixed(2),
        gst_price: parseFloat(products_detail?.gst_price).toFixed(2),
        gst: products_detail?.gst?.gst_rate,
        final_price: parseFloat(products_detail?.final_price).toFixed(2),
        images: [
          IMAGE +products_detail?.image_0, // Replace with your image URLs
          IMAGE +products_detail?.image_1,
          IMAGE +products_detail?.image_2,
        ],
        vendor: {
          company_name: products_detail?.vendor?.company_name,
          name: products_detail?.vendor?.vendor_name,
          mobile_number: products_detail?.vendor?.mobile_number,
          email: products_detail?.vendor?.email,
          // commission: products_detail?.vendor?.commission_percentage,
        },
      };
  return (
    // <div className="product-page">
    // <Grid   
    // container
    // rowSpacing={1}
    // justifyContent="center"
    // columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }} >
   
    //     <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={"sdcsdc"}>
    //   {/* Product Header */}
    //   <header className="product-header">
    //     <h1 className="product-title">{product.name}</h1>
    //     <p className="product-category">Category: {product.category}</p>
    //   </header>
    //   </Grid>
    //   {/* Product Image Carousel */}
     
    //   <section className="product-gallery">
    //     <Carousel
    //       showThumbs={false}
    //       autoPlay
    //       infiniteLoop
    //       className="image-carousel"
    //     >
    //       {product.images.map((image, index) => (
    //         <div key={index}>
    //           <img src={image} alt={`Product image ${index + 1}`} />
    //         </div>
    //       ))}
    //     </Carousel>
    //   </section>

    //   {/* Product Details Section */}
    //   <section className="product-details">
    //     <div className="detail-card">
    //       <h2>Product Details</h2>
    //       <p><strong>Subcategory:</strong> {product.subcategory}</p>
    //       <p><strong>Unit:</strong> {product.unit}</p>
    //       <p><strong>Packaging Size:</strong> {product.packagingSize}</p>
    //       <p><strong>Quantity:</strong> {product.quantity}</p>
    //     </div>
        
    //     {/* Vendor Details */}
    //     <div className="vendor-details">
    //       <h2>Vendor Details</h2>
    //       <p><strong>Vendor Company:</strong> {product.vendor.company_name}</p>
    //       <p><strong>Vendor Name:</strong> {product.vendor.name}</p>
    //       <p><strong>Mobile Number:</strong> {product.vendor.mobile_number}</p>
    //       <p><strong>Vendor Email:</strong> {product.vendor.email}</p>
    //       {/* <p><strong>Contact:</strong> {product.vendor.contact}</p> */}
    //     </div>

    //     {/* Price Details */}
    //     <div className="price-details">
    //       <h2>Price</h2>
    //       <p><strong>Price:</strong> {product.price}</p>
    //       <p><strong>Discount:</strong> {product.discount}</p>
    //       <p><strong>Discounted Price:</strong> {product.discounted_price}</p>
    //       <p><strong>Gst Price:</strong> {product.gst_price + " (" + product?.gst + " %) "}</p>
    //       <p><strong>Final Price:</strong> {product.final_price}</p>
    //     </div>
    //   </section>

    //   {/* Add to Cart Button */}
    //   {/* <button className="add-to-cart-button">Add to Cart</button> */}
    // </Grid>
    // </div>
    <div className="product-page">
    {/* <Grid   
    container
    rowSpacing={1}
    justifyContent="center"
    columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }} > */}

        {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={"sdcsdc"}> */}
        {/* Product Header */}
        <header className="product-header">
            <h1 className="product-title">{product.name}</h1>
            <p className="product-category">Category: {product.category}</p>
        </header>
        {/* </Grid> */}
        
        {/* Product Image Carousel */}
        <div style={{display:"flex", alignItems:"center"}}>
        <section className="product-gallery">
            <Carousel
            showThumbs={false}
            autoPlay
            infiniteLoop
            className="image-carousel"
            >
            {product.images.map((image, index) => (
                <div key={index}>
                <img src={image} alt={`Product image ${index + 1}`} />
                </div>
            ))}
            </Carousel>
        </section>


        {/* Product Details Section */}
        <section className="product-details">
            <div className="detail-card">
            <h2>Product Details</h2>
            <p><strong>Subcategory:</strong> {product.subcategory}</p>
            <p><strong>Unit:</strong> {product.unit}</p>
            <p><strong>Packaging Size:</strong> {product.packagingSize}</p>
            <p><strong>Quantity:</strong> {product.quantity}</p>
            </div>
            
            {/* Vendor Details - Conditional Rendering */}
            {products_detail?.created_by === "Vendor" && (
                <div className="vendor-details">
                    <h2>Vendor Details</h2>
                    <p><strong>Vendor Company:</strong> {product.vendor.company_name}</p>
                    <p><strong>Vendor Name:</strong> {product.vendor.name}</p>
                    <p><strong>Mobile Number:</strong> {product.vendor.mobile_number}</p>
                    <p><strong>Vendor Email:</strong> {product.vendor.email}</p>
                </div>
            )}

            {/* Price Details */}
            <div className="price-details">
                <h2>Price</h2>
                <p><strong>Price:</strong> {product.price}</p>
                <p><strong>Discount:</strong> {product.discount}</p>
                <p><strong>Discounted Price:</strong> {product.discounted_price}</p>
                <p><strong>Gst Price:</strong> {product.gst_price + " (" + product?.gst + " %) "}</p>
                <p><strong>Final Price:</strong> {product.final_price}</p>
            </div>
        </section>
        </div>
    {/* // </Grid> */}
</div>
  );
};
  
export default ProductDetailsContent2