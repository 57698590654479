// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../AbstractElements";

import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { API_LINK, IMAGE } from "../../api";
import SweetAlert from "sweetalert2";
import * as HomeAction from "../../actions/project";

import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CREATECATEGORY } from "../../Constant";

const Project = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { company_project_list } = bindActionCreators(HomeAction, dispatch);
  const [projectData, setProjectData] = useState([]);
  // const { category_delete} = bindActionCreators(
  //   HomeAction,
  //   dispatch
  // );
  const user = useSelector((state) => state?.layout?.user);

  useEffect(() => {
    user &&
      (async () => {
        const data = new FormData();
        data.append("company", user?.id);
        // dispatch(bank_detail_list(data));
        const resData = await company_project_list(data);
        setProjectData(resData);
      })();
  }, [user]);
  const loggedIn = useSelector((state) => state?.layout?.loggedIn);
  console.log(user, loggedIn);
  const deleteTask = async (userId) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Category!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        // RemoveTask(userId);
        const fData = new FormData();
        fData.append("id", userId);

        // const deleteApi = await category_delete(fData)
        // console.log(deleteApi,"fdfzfz")
        // if(deleteApi?.statuscode == 200){
        //   toast.success(deleteApi?.message);
        //   setTimeout(async () => {
        //       const resData = await company_user_list();
        //       setEmployeeData(resData);
        //     dispatch({ type: CREATECATEGORY, payload: undefined });
        //   }, 1000);
        // }else{
        //   toast.error(deleteApi?.message);
        // }
        // console.log(result,result.value,deleteApi,userId,"nfsdf")
        SweetAlert.fire("Deleted!", "Your file has been deleted.", "success");
      } else {
        SweetAlert.fire("Your imaginary file is safe!");
      }
    });
  };
  const navigateData = (id, name) => {
    navigate("/Project-work-Assign", { state: { id: id, project_name: name } })
  }

  const openPDF = (id, link) => {
    window.open(API_LINK + link, '_blank')
  }

  const productColumns = [
    // {
    //   name: "Image",
    //   selector: (row) => row.image,
    //   sortable: true,
    //   center: true,
    //   minWidth: "100px",
    //   maxWidth: "100px",
    // },
    {
      name: "Project",
      selector: (row) => row.Name,
      sortable: true,
      // center: true,
      wrap: true,
      sortFunction: (a, b) => a.Name.props.children.props.children.localeCompare(b.Name.props.children.props.children)
      // minWidth: "350px",
    },
    {
      name: "Client",
      selector: (row) => row.Client,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Budget",
      selector: (row) => row.Budget,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Manager",
      selector: (row) => row.Manager,
      sortable: true,
      center: true,
      wrap: true,
      // minWidth: "350px",
    },
    // {
    //   name: "Team",
    //   selector: (row) => row.Team,
    //   sortable: true,
    //   center: true,
    //   wrap: true,
    //   // minWidth: "350px",
    // },
    {
      name: "Duration",
      selector: (row) => row.Date,
      sortable: true,
      center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "service",
      selector: (row) => row.Service,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "150px",
    },
    {
      name: "Document",
      selector: (row) => row.Document,
      sortable: true,
      center: true,
      minWidth: "120px",
      // maxWidth: "90px",
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.Status,
    //   sortable: false,
    //   center: true,
    //   minWidth: "100px",
    //   maxWidth: "100px",
    // },
    // {
    //   name: 'Stock',
    //   selector: (row) => row.stock,
    //   sortable: true,
    //   center: true,
    //   minWidth: '120px',
    //   maxWidth: '150px',
    // },
    // {
    //   name: 'Start_date',
    //   selector: (row) => row.start_date,
    //   sortable: true,
    //   center: true,
    //   minWidth: '120px',
    //   maxWidth: '150px',
    // },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      center: true,
      minWidth: "160px",
      maxWidth: "160px",
    },
  ];
  const style = {
    width: 55,
    height: 40,
    borderRadius: 5,
  };
  const style2 = { width: 50, fontSize: 14, padding: 4 };
  const ProductList =
    projectData &&
    projectData?.data?.map((value, index) => ({
      // image: (
      //   <Image
      //     attrImage={{
      //       src: IMAGE + value?.category_image,
      //       style: style,
      //       alt: "",
      //     }}
      //   />
      // ),
      Name: (
        <div>
          <h6>{value?.project_name}</h6>
        </div>
      ),
      Client: (
        <div>
          <p>{value?.project_owner?.name}</p>
        </div>
      ),
      Manager: (
        <div>
          <p>{value?.project_manager?.name}</p>
        </div>
      ),
      Budget: (
        <p>{value?.budget}</p>
      ),
      Date: (
        <div>
          <p>{value?.start_date}</p>
          <p>To</p>
          <p>{value?.end_date}</p>
        </div>
      ),
      Service: (
        <div>
          {/* <p>{value?.service.map((ele, index) => (index ? ', ' : '') + ele?.service_name)}</p> */}
          <ul >
            {" "}
            {value?.service.map((ele, index) => (
              <li>- {ele?.service_name}</li>
            ))}{" "}
          </ul>
        </div>
      ),
      Document: (
        <div>
          <span>
            <Btn
              attrBtn={{
                style: style2,
                color: "primary",
                className: "btn btn-xs",
                type: "button",
                onClick: () => openPDF(value?.id, value?.documents),
              }}
            >
              View
            </Btn>
          </span>
        </div>
      ),
      // Team:(
      //   <div>
      //   {/* <p>{value?.service.map((ele, index) => (index ? ', ' : '') + ele?.service_name)}</p> */}
      //   <ul >
      //     {" "}
      //     {value?.team_member.map((ele, index) => (
      //       <li>- {ele?.name}</li>
      //     ))}{" "}
      //   </ul>
      // </div>
      // ),
      Status:
        value?.status == "Active" ? (
          <span
            style={{ color: "#00B69B", padding: "5px 13px", padding: "4px" }}
          >
            {value?.status}
          </span>
        ) : (
          <span
            style={{
              color: "rgb(238, 54, 140)",
              padding: "5px 13px",
              padding: "4px",
            }}
          >
            {value?.status}
          </span>
        ),
      action: (
        <div>
          <span>
            <Btn
              attrBtn={{
                style: style2,
                color: "danger",
                className: "btn btn-xs",
                type: "button",
                onClick: () => navigateData(value?.id, value?.project_name),
              }}
            >
              View
            </Btn>
          </span>{" "}
          &nbsp;&nbsp;
          <span>
            <Btn
              attrBtn={{
                style: style2,
                color: "success",
                className: "btn btn-xs",
                type: "button",
              }}
            >
              <Link
                style={{ color: "inherit" }}
                to={"/Update-Employee"}
                state={{ data: projectData?.data[index], isEdit: true }}
              >
                Edit
              </Link>
            </Btn>
          </span>
        </div>
      ),
    }));

  // amount: '$10',
  // stock: <div className='font-success'>In Stock</div>,
  // start_date: '2022/4/19',

  // ]
  return (
    <div className="table-responsive product-table">
      <Breadcrumbs
        parent="Project"
        title="Project List"
        mainTitle="Project List"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  noHeader
                  pagination
                  paginationServer
                  columns={productColumns}
                  data={ProductList}
                  highlightOnHover={true}
                  striped={true}
                  responsive={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Project;
