import React, { useEffect } from 'react'
import { Breadcrumbs } from '../../../AbstractElements'
import { useDispatch, useSelector } from 'react-redux'
import { terms_and_conditions } from '../../../actions/Setting/terms_conditions';
import { Container } from 'reactstrap';


export default function TermsandConditionVendor() {

    const dispatch = useDispatch();
    const termsconditions = useSelector((state) => state.setting?.termsconditions?.data)

    console.log("aboutus", termsconditions)

    useEffect(() => {
        dispatch(terms_and_conditions())

    }, [])

    return (
        <div className="">
            <Breadcrumbs
                parent="settings"
                title="Terms and Conditions"
                mainTitle="Terms and Conditions"
            />
            <Container>
                {
                    <div dangerouslySetInnerHTML={{ __html: termsconditions?.description }}></div>
                }
            </Container>
        </div>
    )
}
