import { ADMIN_VENDOR_EXPIRED_DEAL_OF_THE_DAY, DEAL_OF_THE_DAY, DEAL_OF_THE_DAY_LIST, EXPIRED_DEAL_OF_THE_DAY_LIST, VENDOR_DEAL_OF_THE_DAY_LIST } from "../../Constant";
import * as api from '../../api/index';

export const create_deal_of_the_day = (formData) => async (dispatch) => {
    try {
      const { data } = await api.create_deal_of_the_day(formData);
      dispatch({ type: DEAL_OF_THE_DAY, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const update_deal_of_the_day = (formData) => async (dispatch) => {
    try {
      const { data } = await api.update_deal_of_the_day(formData);
      dispatch({ type: DEAL_OF_THE_DAY, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const delete_deal_of_the_day = (formData) => async (dispatch) => {
    try {
      const { data } = await api.delete_deal_of_the_day(formData);
      dispatch({ type: DEAL_OF_THE_DAY, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const all_deal_of_the_day_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_deal_of_the_day_list(formData);
      dispatch({ type: DEAL_OF_THE_DAY_LIST, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const admin_vendor_wise_deal_of_the_day_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.admin_vendor_wise_deal_of_the_day_list(formData);
      dispatch({ type: VENDOR_DEAL_OF_THE_DAY_LIST, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const all_expired_deal_of_the_day_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_expired_deal_of_the_day_list(formData);
      dispatch({ type: EXPIRED_DEAL_OF_THE_DAY_LIST, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const admin_vendor_wise_expire_deal_of_the_day_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.admin_vendor_wise_expire_deal_of_the_day_list(formData);
      dispatch({ type: ADMIN_VENDOR_EXPIRED_DEAL_OF_THE_DAY, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };