import * as actionType from '../Constant';


const SliderBannerReducer = (state = { SliderBannerData: null }, action) => {
  switch (action.type) {
    case actionType.SLIDER_BANNER:
      return { ...state, slider_banner: action.payload }
    case actionType.SLIDER_BANNER_LIST:
      return { ...state, slider_banner_list: action.payload }
    case actionType.EXPIRE_SLIDER_BANNER_LIST:
      return { ...state, expired_slider_banner_list: action.payload }
    default:
      return state;
  }
};

export default SliderBannerReducer;
