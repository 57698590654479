import React , { useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { H5, UL, LI, H6 } from "../../../AbstractElements";
import {
  DailyDropdown,
  Earning,
  Order,
  OrderOverviewTitle,
  Refunds,
} from "../../../Constant";
// import { LightCardData2 } from "../../../Data/DefaultDashboard";
import {
  // OptionsOverView,
  // OptionsOverViewBarChart,
} from "../../../Data/Ecommerce/Chart";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
// import LightCardBox from "../../Default/LightCardBox";
import SvgIcon from "../../Common/Component/SvgIcon";
import DropdownCommon from "../../Common/Dropdown";
import { dashboard_graph_order_overview } from '../../../actions/dashboard';

const OrderOverview = () => {
  const OrderOverView = useSelector((state)=>state?.dashboard?.order_overview)
  console.log("OrderOverView" , OrderOverView)
  const dispatch = useDispatch()
  const userInfo = JSON.parse(sessionStorage.getItem("user"))
  useEffect(()=>{
    if(userInfo?.id?.id){
      userInfo?.id?.userrole?.rolename == "Admin" ?
      dispatch(dashboard_graph_order_overview({admin_id:userInfo?.id?.id ,  user_type :"admin"}))
      :
      dispatch(dashboard_graph_order_overview({vendor_id:userInfo?.id?.id , user_type :"vendor"}))

    }
  },[])
  const OptionsOverView = {
    series: [
      {
        name: 'Earning',
        type: 'area',
        data: OrderOverView?.data[1]?.data // 12 data points, including Dec
      },
      {
        name: 'Order',
        type: 'area',
        data: OrderOverView?.data[0]?.data, // 12 data points, including Dec
      },
      userInfo?.id?.userrole?.rolename == "Admin" &&   {
        name: 'Customer',
        type: 'area',
        data: OrderOverView?.data[2]?.data, // 12 data points, including Dec
      },
    ].filter((e)=>e),
    options: {
      chart: {
        height: 300,
        type: 'line',
        stacked: false,
        toolbar: {
          show: false,
        },
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          color: '#000',
          opacity: 0.08,
        },
      },
      stroke: {
        width: [2, 2, 2],
        curve: 'smooth',
      },
      grid: {
        show: true,
        borderColor: 'var(--chart-border)',
        strokeDashArray: 0,
        position: 'back',
        xaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      colors: ['#7064F5', '#54BA4A', '#FF3364'],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'vertical',
          opacityFrom: 0.4,
          opacityTo: 0,
          stops: [0, 100],
        },
      },
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], // 12 labels
      markers: {
        discrete: [
          // Marker configuration remains the same
        ],
        hover: {
          size: 5,
          sizeOffset: 0,
        },
      },
      xaxis: {
        type: 'category',
        tickAmount: 12, // Ensure tick amount matches the number of data points
        tickPlacement: 'on', // Avoid extra space by placing ticks directly under the data points
        tooltip: {
          enabled: true,
        },
        axisBorder: {
          color: 'var(--chart-border)',
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        min: 0, // Set minimum value
        // max: 70, // Adjust this to fit your data range and avoid extra space
        tickAmount: 8,
        tickPlacement: 'on', // Ensure consistent placement with xaxis
      },
      tooltip: {
        shared: true,
        intersect: false,
        x: {
          show: true,
        },
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              height: 250,
            },
          },
        },
      ],
    },
  };
  const LightCardData2 = [
    {
      title: 'Orders',
      icon: 'orders',
      price: OrderOverView?.data[3]?.total_orders,
      color: 'success',
    },
    {
      title: 'Earning',
      icon: 'expense',
      price: parseFloat(OrderOverView?.data[3]?.total_earnings).toFixed(2),
      color: 'danger',
    },
    userInfo?.id?.userrole?.rolename == "Admin" && {
      title: 'Customer',
      icon: 'doller-return',
      price: OrderOverView?.data[3]?.total_users,
    },
  ].filter((e)=>e);
  return (
    <Card>
      <CardHeader className="card-no-border">
        <H5>{OrderOverviewTitle}</H5>
      </CardHeader>
      <CardBody className="pt-0">
        <Row className="m-0 overall-card">
          <Col xl="9" md="8" sm="7" className="box-col-7 p-0">
            <div className="chart-right">
              <Row>
                <Col xl="12">
                  <CardBody className="p-0">
                    {/* <UL
                      attrUL={{
                        horizontal: true,
                        className: "d-flex balance-data",
                      }}
                    >
                      <LI>
                        <span className="circle bg-secondary" />
                        <span className="f-light ms-1">Customer</span>
                      </LI>
                      <LI>
                        <span className="circle bg-primary"> </span>
                        <span className="f-light ms-1">{Earning}</span>
                      </LI>
                      <LI>
                        <span className="circle bg-success"> </span>
                        <span className="f-light ms-1">{Order}</span>
                      </LI>
                    </UL> */}
                    <div className="current-sale-container order-container">
                      <ReactApexChart
                        className="overview-wrapper"
                        type="line"
                        height={300}
                        options={OptionsOverView.options}
                        series={OptionsOverView.series}
                      />
                      {/* <div className="back-bar-container">
                        <ReactApexChart
                          type="bar"
                          height={180}
                          options={OptionsOverViewBarChart.options}
                          series={OptionsOverViewBarChart.series}
                        />
                      </div> */}
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xl="3" md="4" sm="5" className="box-col-5 p-0">
            <Row className="g-sm-3 g-2">
              {LightCardData2.map((data, i) => (
                <Col key={i} md="12">
                  <div className="light-card balance-card widget-hover">
                    <div className="svg-box">
                      <SvgIcon className="svg-fill" iconId={data.icon} />
                    </div>
                    <div>
                      <span className="f-light">{data.title}</span>
                      <H6 attrH6={{ className: "mt-1 mb-0" }}>{data.price}</H6>
                    </div>
                    <div className="ms-auto text-end">
                      <DropdownCommon
                        dropdownMain={{
                          className: "icon-dropdown",
                          direction: "start",
                        }}
                        options={DailyDropdown}
                        iconName="icon-more-alt"
                        btn={{ tag: "span" }}
                      />
                      {data.gros && (
                        <span
                          className={`d-inline-block mt-1 font-${data.color}`}
                        >
                          {data.gros}
                        </span>
                      )}
                    </div>
                  </div>
                  {/* <LightCardBox data={data} /> */}
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default OrderOverview;
