import * as api from "../api/index";
import { ORDER_DETAILS, ORDER_LIST ,ORDER ,VENDOR_ORDER_LIST} from "../Constant";

export const order_list = (formData) => async (dispatch) => {
  try {
    const { data } = await api.order_list(formData);
    dispatch({ type: ORDER_LIST, payload: data });
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const order_detail = (formData) => async (dispatch) => {
  try {
    const { data } = await api.order_detail(formData);
    dispatch({ type: ORDER_DETAILS, payload: data });
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const order_status_update = (formData) => async (dispatch) => {
  try {
    const { data } = await api.order_status_update(formData);
    dispatch({ type: ORDER, payload: data });
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const vendor_order_list = (formData) => async (dispatch) => {
  try {
    const { data } = await api.vendor_order_list(formData);
    dispatch({ type: VENDOR_ORDER_LIST, payload: data });
    return data;
  } catch (error) {
    console.log(error);
  }
};
