import * as actionType from "../Constant";

const OrderReducer = (state = { orderData: null }, action) => {
  switch (action.type) {
    case actionType.ORDER_LIST:
      return { ...state, order_list: action.payload };
    case actionType.ORDER_DETAILS:
      return { ...state, order_details: action.payload };
    case actionType.ORDER:
      return { ...state, order: action.payload };
    case actionType.VENDOR_ORDER_LIST:
      return { ...state, vendor_order: action.payload };

    default:
      return state;
  }
};

export default OrderReducer;
