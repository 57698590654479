import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import "./ProductDetailsContent.css";

import ProductProfile from "./ProductProfile";
import { useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";


import EnlargedView from "../../../utils/EnlargedView";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Button, TextField } from "@mui/material";
import { product_details } from "../../../actions/product";


const ProductDetailsContent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const role = useSelector((state) => state.layout.role);
  const path = location.pathname
    .split("/")
    .filter((x) => x)
    .slice(-1);
  const rights = useSelector((state) => state.layout.rights);
  const [contentAccess, setContentAccess] = useState();
  const [openAccess, setOpenAccess] = useState(false);

 
  const navigate = useNavigate();
  // clg
  useMemo(() => {
    const formData = new FormData();
  formData.append("product_id", location.state?.id);
    dispatch(product_details(formData))
  }, [location?.state?.id]);
  const products_detail = useSelector((state) => state?.product?.product_details);
  console.log(products_detail ,"NreProducry" )

  return (
    <>
      <Grid
        container
        rowSpacing={1}
        justifyContent="center"
        columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
      >
        <EnlargedView
          open={openAccess}
          setOpen={setOpenAccess}
          content={contentAccess}
        />
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7} key={"sdcsdc"}>
          <ProductProfile data={products_detail?.data} />
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <Card
            sx={{
              boxShadow: "var(--themeShadow)",
              borderRadius: "10px",
              p: "15px 25px",
              pb: "8px",
              mb: "15px",
              backgroundColor: "#fff",
              marginTop:"2rem"
            }}
          >
            <Box mt={2}>
              <Tabs className="product-details-tabs">
                <TabList>
                  <Tab>
                   Product
                  </Tab>
                { products_detail?.data?.created_by == "Vendor" && <Tab>Vendor</Tab>}
                  <Tab>Price</Tab>
                
              
                </TabList>

                <TabPanel>
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "10px",
                      p: "25px 20px",
                      pb: "0",
                      mb: "10px",
                      backgroundColor: "var(--themeColor)",
                    }}
                  >
                    <Typography
                      as="h3"
                      sx={{
                        fontSize: 16,
                        mb: "15px",
                        fontWeight: 500,
                        mt: "-25px",
                        ml: "-25px",
                        mr: "-25px",
                        padding: "10px 25px",
                        borderRadius: "8px 8px 0px 0px",
                        background:
                          "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                        color: "var(--themeColor)",
                      }}
                    >
                      <span style={{ color: "var(--tableHeadFontColor)" }}>
                        Details
                      </span>
                    </Typography>

                    <Box>
                       
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                            {/* Sub Category: */}
                            Category
                          </span>
                         {products_detail?.data?.category?.category_name}
                        </Typography>
                      

                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Subcategory:
                        </span>
                        {products_detail?.data?.subcategory?.sub_category_name}
                      </Typography>
                     
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                           Unit:
                          </span>
                          {products_detail?.data?.unit?.unit_name}
                        </Typography>
                      
                 
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                         <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                            Packaging Size:
                          </span>
                          {products_detail?.data?.packaging_size}
                        </Typography>
                     
                      <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                       <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                            Quantity:
                          </span>
                         {products_detail?.data?.quantity}
                      </Typography>
             
                       
                         
                    </Box>
                  </Card>
                </TabPanel>
                { products_detail?.data?.created_by == "Vendor" &&
                  <TabPanel>
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "10px",
                      p: "25px 20px",
                      pb: "0",
                      mb: "10px",
                      backgroundColor: "var(--themeColor)",
                    }}
                  >
                    <Typography
                      as="h3"
                      sx={{
                        fontSize: 16,
                        fontWeight: 500,
                        mb: "15px",
                        mt: "-25px",
                        ml: "-25px",
                        mr: "-25px",
                        padding: "10px 25px",
                        borderRadius: "8px 8px 0px 0px",
                        background:
                          "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                        color: "var(--themeColor)",
                      }}
                    >
                      <span style={{ color: "var(--tableHeadFontColor)" }}>
                      Vendor Details
                      </span>
                    </Typography>

                    <Box>
                     
                      <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                            Company Name:
                          </span>
                         {products_detail?.data?.vendor?.company_name}
                        </Typography>
            
                     
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",

                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                           Vendor Name:
                          </span>
                        
                          {products_detail?.data?.vendor?.vendor_name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",

                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                          Email :
                          </span>
                        
                          {products_detail?.data?.vendor?.email}
                        </Typography>
             
                      
                        <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Mobile Number:
                        </span>
                        {products_detail?.data?.vendor?.mobile_number}
                      </Typography>
                        <Typography
                        sx={{
                          fontSize: 13,
                          mt: "10px",
                          display: "flex",
                        }}
                      >
                        <span
                          style={{
                            width: "150px",
                            fontWeight: "500",
                          }}
                          className="mr-10px"
                        >
                          Commission (%):
                        </span>
                        {products_detail?.data?.vendor?.commission_percentage}
                      </Typography>
                     
                    </Box>
                  </Card>
                </TabPanel>
                }
                <TabPanel>
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "10px",
                      p: "25px 20px",
                      pb: "0",
                      mb: "10px",
                      backgroundColor: "var(--themeColor)",
                    }}
                  >
                    <Typography
                      as="h3"
                      sx={{
                        fontSize: 16,
                        fontWeight: 500,
                        mb: "15px",
                        mt: "-25px",
                        ml: "-25px",
                        mr: "-25px",
                        padding: "10px 25px",
                        borderRadius: "8px 8px 0px 0px",
                        background:
                          "linear-gradient(225deg,  var(--gradientColorLighter1) 0%, var(--gradientColorLighter2) 91.25%)",

                        color: "var(--themeColor)",
                      }}
                    >
                      <span style={{ color: "var(--tableHeadFontColor)" }}>
                     All Price
                      </span>
                    </Typography>

                    <Box>
                     
                      <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",
                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                            Actual Price:
                          </span>
                         {products_detail?.data?.price}
                        </Typography>
            
                     
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",

                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                           Discount:
                          </span>
                        
                          {products_detail?.data?.discount}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",

                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                           Discounted Price:
                          </span>
                        
                          {products_detail?.data?.discounted_price}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",

                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                           Gst Price:
                          </span>
                        
                          {products_detail?.data?.gst_price + " (" +products_detail?.data?.gst?.gst_rate + ") " }
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 13,
                            mt: "10px",

                            display: "flex",
                          }}
                        >
                          <span
                            style={{
                              width: "150px",
                              fontWeight: "500",
                            }}
                            className="mr-10px"
                          >
                           Final Price:
                          </span>
                        
                          {products_detail?.data?.final_price}
                        </Typography>
             
                      
                      
                     
                    </Box>
                  </Card>
                </TabPanel>
              
              </Tabs>
            </Box>
          </Card>
        </Grid>
     
      
            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {" "}
              <WatchedCustomers id={location.state?.id} path={path} />
            </Grid> */}
      
          
         </Grid>
     
      </>
  );
};

export default ProductDetailsContent;
