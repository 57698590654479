// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import ListTable from "../../utils/Table";
import { all_category_list, category_delete } from "../../actions/category";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { CREATECATEGORY, PRODUCT } from "../../Constant";
import { all_product_list, all_product_vendor_list, delete_product } from "../../actions/product";

const Products = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const category_data = useSelector((state)=>state?.categort?.category_list)
  const login_details = JSON.parse(sessionStorage.getItem("user"))
  const role = login_details?.id?.userrole?.rolename
  const message = useSelector((state)=>state?.product?.product)
  console.log(category_data)
  useMemo(()=>{
    if(login_details){
      const data = new FormData()
      data.append("id" ,login_details?.id?.id )
      data.append("user_type" ,  role)
    dispatch(all_product_vendor_list(data))
  }
 
  },[])

  const VendorProduct = useSelector((state)=> state?.product?.product_list_vendor)
  const AllProduct = useSelector((state)=> state?.product?.product_list)
  const tempTableData = {
    tableTitle: "Products",
    deleteRecord: delete_product,
    // updateRecord: subcategory_update,
    // deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    onDeleteText: "Are you sure want to delete?",
    onUpdateText:
      "Videos are associated with this category, are you still want to change it.?",
    tableHead: [
      {
        id : "product_name",
        label:"Product Name",
        link :"/product_details"
      },
      {
        id: "category",
        label: "Category",
        // subText :"category"
      },
      {
        id: "packaging_size",
        label: "Packaging Size",
        subText: "unit",
      },
      {
        id: "image_0",
        label: "Image",
        isImage : true
      },
      {
        id : "quantity",
        label:"Available Quantity"
      },
      // {
      //   id : "price",
      //   label:"Actual Price"
      // },
      // {
      //   id : "discount",
      //   label:"Discount"
      // },
      // {
      //   id : "discounted_price",
      //   label:"Discounted Price"
      // },
      // {
      //   id : "gst_price",
      //   label:"Gst Price"
      // },
      {
        id : "final_price",
        label:"Selling Price"
      },
      // {
      //   id : "created_at",
      //   label:"Created At"
      // },
      {
        id: "status",
        label: "Status",
        isButtonDisplay: true,
      },
      {
        id: "edit",
        label: "Update",
        edit_path :"/update-product",
        // id : "id",
        // handleEdit : (value) => {navigate("/Update-Category" , {state:{id:value?.id}})}
        // access: rights?.["Masters"]?.["edit"] == "true",
      },
    ],
    tableBody: [],
    filterColumn: [],
  };
  const [tableData, setTableData] = useState({...tempTableData})

  useMemo(()=>{
    if(VendorProduct?.statuscode == 200){
      const temp = tableData
      temp.tableBody = VendorProduct?.data?.map((ele)=>({
        ...ele,
        category : ele?.category?.category_name,
        sub_category : ele?.subcategory?.sub_category_name,
        gst_rate : ele?.gst?.gst_rate,
        unit : ele?.unit?.unit_name,
        price : parseFloat(ele?.price).toFixed(2),
        discount : parseFloat(ele?.discount).toFixed(2),

      }))
      setTableData({...temp})
    }
  },[VendorProduct])
  useMemo(()=>{
    if(message?.statuscode == 200){
      toast.success(message?.message);
      if(login_details){
        const data = new FormData()
        data.append("id" ,login_details?.id?.id )
        data.append("user_type" ,  role)
      dispatch(all_product_vendor_list(data))
    }
      dispatch({ type: PRODUCT, payload: undefined })
    }
  },[message])
  return (
    <div >
      <Breadcrumbs
        parent="Product"
        title="Products"
        mainTitle="All Product"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              {/* <CardBody> */}
              <ListTable
              tableData={tableData}
              key={"ListTable"}
              // setForm={setForm}
              setTableData={setTableData}
              // setIsEdit={setIsEdit}
              />
              {/* </CardBody> */}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Products;
