import { LANGUAGE, LANGUAGE_LIST } from "../Constant";
import * as api from '../api/index.jsx';


export const language_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.language_create(formData);
      
      dispatch({ type: LANGUAGE, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };
export const language_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.language_update(formData);
      
      dispatch({ type: LANGUAGE, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };
export const language_delete = (formData) => async (dispatch) => {
    try {
      const { data } = await api.language_delete(formData);
      
      dispatch({ type: LANGUAGE, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };
export const all_language_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_language_list(formData);
      
      dispatch({ type: LANGUAGE_LIST, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };
  