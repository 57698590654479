import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import * as Action from "../../actions/product";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { IMAGE } from "../../api";
import { CREATECATEGORY, PRODUCT } from "../../Constant";
import { Breadcrumbs, H5 } from "../../AbstractElements";
import Form from "../../utils/Form";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import { all_category_list } from "../../actions/category";
import { all_subcategory_list } from "../../actions/subcategory";
import { all_unit_list } from "../../actions/unit";
import { all_gst_rate_list } from "../../actions/gst_rate";
const CreateProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const login_details = JSON.parse(sessionStorage.getItem("user"))
  console.log(location, "newLOCS");
  const isEdit = location?.state?.isEdit;
  const { create_product, update_product } = bindActionCreators(
    Action,
    dispatch
  );
useEffect(()=>{
  dispatch(all_category_list())
  dispatch(all_subcategory_list())
  dispatch(all_unit_list())
  dispatch(all_gst_rate_list())
},[])
const category_data = useSelector((state)=>state?.category?.category_list)
const sub_category_data = useSelector((state)=>state?.subcategory?.subcategory_list)
const [file, setFile] = useState(null);
console.log(sub_category_data,"gdgrrsd")
const unit_data = useSelector((state)=>state?.unit?.unit_list)
const Gst_rate_data = useSelector((state)=>state?.gst_rate?.gst_rate_list)
const [openAdError, setOpenAdError] = useState(false);
const [content, setPopupContent] = useState("");
const [form, setForm] = useState({});
  useEffect(() => {
    if (location?.state?.form) {
      setForm(location?.state?.form && JSON.parse(location?.state?.form));
    }
  }, [location?.state?.form]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const temp_product = form;
    temp_product["tempcategory"] = form?.["category"];
    temp_product["tempsub_category"] = form?.["sub_category"];
    temp_product["tempunit"] = form?.["unit"];
    temp_product["tempgst_rate"] = form?.["gst_rate"];
    temp_product["category"] = category_data?.data
    ?.map(
      (option) => form?.["category"]?.includes(option?.category_name) && option.id
    )
    .filter((e) => e);
    temp_product["sub_category"] = sub_category_data?.data
    // ?.map(
    //   (option) => form?.["sub_category"]?.includes(option?.sub_category_name) && option.id
    // )
    // .filter((e) => e);
    ?.filter((option) => form?.["sub_category"] == option.sub_category_name)
    .map((option) => option.id);
    
    temp_product["unit"] = unit_data?.data
    // ?.map(
    //   (option) => form?.["unit"]?.includes(option.unit_name) && option.id
    // )
    // .filter((e) => e);
    ?.filter((option) => form?.["unit"] == option.unit_name)
    .map((option) => option.id);
    temp_product["gst_rate"] = Gst_rate_data?.data
    ?.filter((option) => form?.["gst_rate"] == option.gst_rate)
    .map((option) => option.id);
    // ?.map(
    //   (option) => form?.["gst_rate"]?.includes(option.gst_rate) && option.id
    // )
    // .filter((e) => e);

    // temp_product["qr_code"] = JSON.stringify(qr);
    temp_product["created_by_id"] = login_details?.id?.id;
    temp_product["created_by"] = login_details?.id?.userrole?.rolename;

    setForm({...form})
    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    formStructure.map((element) => {
      if (
        element.type == "image" &&
        form?.[element.name] &&
        typeof form?.[element.name] != "string"
      ) {
        const temp = form;
        temp["temp" + element.name] = form?.[element.name];
        temp[element.name] = URL.createObjectURL(form?.[element.name]);

        setForm({
          ...temp,
        });
      }
    });

    if (isEdit) {
      const UpdateProductApi = await update_product(data);
      if (UpdateProductApi?.statuscode == 200) {
        toast.success(UpdateProductApi?.message);
        setTimeout(() => {
          navigate("/products");
          dispatch({ type: PRODUCT, payload: undefined });
        }, 1000);
        setForm({});
      } else {
        toast.error(UpdateProductApi?.message);
      }
    } else {
      const createProductApi = await create_product(data);
      if (createProductApi?.statuscode == 200) {
        toast.success(createProductApi?.message);
        setTimeout(() => {
          navigate("/products");
          dispatch({ type: PRODUCT, payload: undefined });
        }, 1000);
        setForm({});
      } else {
        toast.error(createProductApi?.message);
      }
    }
  };

  const [formStructure, setFormStructure] = useState([
    {
      id: "1",
      type: "select",
      title: "Product Category",
      name: "category",
      options: [],
      required: true,
    },
    {
      id: "2",
      type: "select",
      title: "Product Sub Category",
      name: "sub_category",
      options: [],
      // required: true,
    },
    {
      id: "3",
      type: "inputBox",
      title: "Product Name",
      name: "product_name",
      maxLength: 50,
      // regex: /^[a-zA-Z0-9\s\&]+$/,
      required: true,
    },
    {
      id: "4",
      type: "inputBox",
      title: "Available Quantity",
      regex: /^[0-9\.]+$/,
      name: "quantity",
      // required: true,
    },
    {
      id: "5",
      type: "select",
      title: "Unit",
      name: "unit",
      options: [],
      // required: true,
    },
    {
      id: "6",
      type: "inputBox",
      title: "Packaging Size",
      regex: /^[0-9\.]+$/,
      name: "packaging_size",
      maxLength:4
      // required: true,
    },
    {
      id: "7",
      type: "inputBox",
      title: "Actual Price",
      regex: /^[0-9\.]+$/,
      name: "price",
      maxLength:4
      // required: true,
    },
    {
      id: "8",
      type: "inputBox",
      title: "Enter Discount Amount (In INR)",
      regex: /^[0-9\.]+$/,
      name: "discount",
      maxLength:3
      // required: true,
    },
    {
      id: "9",
      type: "inputBox",
      title: "Discounted Price",
      regex: /^[0-9\.]+$/,
      name: "discounted_price",
      // required: true,
      disabled: true,
    },

    {
      id: "10",
      type: "select",
      title: "Select GST",
      name: "gst_rate",
      options: [],
      // required: true,
    },
    {
      id: "11",
      type: "inputBox",
      title: "GST Price",
      regex: /^[0-9\.]+$/,
      name: "gst_price",
      // required: true,/
      disabled: true,
    },
    {
      id: "12",
      type: "inputBox",
      title: "Final Price",
      regex: /^[0-9\.]+$/,
      name: "final_price",
      // required: true,
      disabled: true,
    },
    // {
    //   id: "13",
    //   type: "inputBox",
    //   title: "Admin Commission",
    //   regex: /^[0-9\.]+$/,
    //   name: "admin_commission",
    //   required: true,
    //   disabled: true,
    // },
    // {
    //   id: "14",
    //   type: "inputBox",
    //   title: "Selling Price",
    //   regex: /^[0-9\.]+$/,
    //   name: "selling_price",
    //   required: true,
    //   disabled: true,
    // },
    {
      id: "15",
      type: "toggle",
      title: "Status",
      name: "status",
      default: "Active",
      display: "none",
      size: "6",
      options: [
        { value: "Active", color: "success" },
        { value: "Inactive", color: "danger" },
      ],
    },
    {
      id: "16",
      type: "inputBox",
      title: "Description",
      showLimit: true,
      name: "description",
      maxLength: 1000,
      size: "12",
      // regex: /^[a-zA-Z0-9\s\&]+$/,
      // required: true,
    },

    {
      id: "17",
      type: "image",
      title: "Upload Product Image 1",
      name: "image_0",
      subtitle: "(Resolution : 1500px x 1500px) *",
      subsubtitle: "Max File Size 1MB",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      size: "4",
      required: true,
    },
    {
      id: "18",
      type: "image",
      title: "Upload Product Image 2",
      name: "image_1",
      subtitle: "(Resolution : 1500px x 1500px) ",
      subsubtitle: "Max File Size 1MB",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      size: "4",
      // required: true,
    },
    {
      id: "19",
      type: "image",
      title: "Upload Product Image 3",
      name: "image_2",
      subtitle: "(Resolution : 1500px x 1500px) ",
      subsubtitle: "Max File Size 1MB",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      size: "4",
      // required: true,
    },
    {
      id: "20",
      type: "button",
      title: "Submit",
    },
  ]);
  const formTitle = isEdit ? "Edit Product" : "Create Product";
  console.log( category_data ,"cheche")

  useMemo(()=>{
    if(category_data?.statuscode == 200){
      const temp = formStructure
      temp[0]["options"] = category_data?.data?.map((ele)=>ele?.category_name)
      console.log(formStructure , category_data ,"checheinf")
      setFormStructure([...temp])
    }
  },[category_data])
  useMemo(() => {
    if (sub_category_data?.statuscode == 200) {
      const temp = formStructure;
      const tempForm = form;
      // const tempFilter = tableData;
      // tempFilter["filterColumn"][3]["options"] = sub_category_data?.data;

      if (form?.category != undefined) {
        temp[1]["options"] = sub_category_data?.data
          ?.map(
            (subcategory) =>
              subcategory?.category_name == form?.category &&
              subcategory?.sub_category_name
          )
          .filter((e) => e);
        tempForm.sub_category = temp[1]["options"].includes(
          form?.sub_category
        )
          ? form?.sub_category
          : "";
        // tempFilter["filterColumn"][3]["options"]=subcategories?.data.map((subcategory)=>(subcategory?.category_name==form?.movie_category&&subcategory?.subcategory_name))
      } else {
        temp[1]["options"] = sub_category_data?.data?.map(
          (subcategory) => subcategory?.sub_category_name
        );
      }
      setFormStructure([...temp]);
      setForm({ ...tempForm });
      // setTableData({ ...tempFilter });
    }
  }, [sub_category_data, form?.category]);
  useMemo(()=>{
    if(Gst_rate_data?.statuscode == 200){
      const temp = formStructure
      temp[9]["options"] = Gst_rate_data?.data?.map((ele)=>ele?.gst_rate)
      console.log(formStructure , Gst_rate_data ,"checheinf")
      setFormStructure([...temp])
    }
  },[Gst_rate_data])
  useMemo(()=>{
    if(unit_data?.statuscode == 200){
      const temp = formStructure
      temp[4]["options"] = unit_data?.data?.map((ele)=>ele?.unit_name)
      // console.log(formStructure , cateunit_datagory_data ,"checheinf")
      setFormStructure([...temp])
    }
  },[unit_data])
  useMemo(()=>{
    if(form?.gst_rate){
      setForm({...form,
        final_price :  (
        Number(form?.discounted_price) +
        Number((form?.discounted_price * form?.gst_rate) / 100)
        
      ).toFixed(2),
      gst_price: Number((form?.discounted_price * form?.gst_rate) / 100).toFixed(2)
    
    })
    }
  },[form?.gst_rate , form?.discounted_price])
  useMemo(()=>{
    if(form?.price && form?.discount)
    if(Number(form?.discount) <= Number(form?.price)){
    
      setForm({...form,discounted_price: (Number(form?.price)- Number(form?.discount)).toFixed(2)})
    }else{
      // console.log("TestingPrice")
      setForm({...form,discount:""})
      setOpenAdError(true);
      setPopupContent("Your receivable amount goes into Minus(-)"); 
    }

  },[form?.discount,form?.price])
  const handleClose = () => {
    setOpenAdError(false);
  }
  return (
    <div>
      <Breadcrumbs
        parent="Products"
        title={isEdit ? "Edit Product" : "Create Product"}
        mainTitle={isEdit ? "Edit Product" : "Create Product"}
      />
    <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openAdError}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert severity="info" variant="filled" color="success">
          {content}
        </Alert>
      </Snackbar>
      <Form
        handleSubmit={handleSubmit}
        formStructure={formStructure}
        formTitle={formTitle}
        key={"Form"}
        setForm={setForm}
        form={form}
        // tableData={tableData}
        // setTableData={setTableData}
        isEdit={isEdit}
        // setIsEdit={setIsEdit}
      />
    </div>
  );
};

export default CreateProduct;
