import React from 'react'
import { useSelector } from 'react-redux'
import { Breadcrumbs, H6 } from '../../AbstractElements'
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap'
import DataTable from 'react-data-table-component'
import { API_LINK } from '../../api'
export default function UpcomingExpiry() {
    const WorkerList = useSelector((state) => state?.Notification?.expiryPermit)
    console.log("WorkerList", WorkerList)


    const productColumns = [
        {
            name: "Company",
            selector: (row) => row.Company,
            sortable: true,
            // center: true,
        },
        {
            name: "Owner",
            selector: (row) => row.Name,
            sortable: true,
            // center: true,
        },
        {
            name: "Email",
            selector: (row) => row.Basic,
            sortable: true,
            // center: true,
        },
        {
            name: "Contact",
            selector: (row) => row.Contact,
            sortable: true,
            // center: true,
        },
        {
            name: "Workers",
            selector: (row) => row.TotalWorker,
            sortable: true,
            // center: true,
        },


    ];

    const ProductList =
        WorkerList &&
        WorkerList?.data?.map((value, index) => ({
            Company: (
                <div>
                    <H6>{value?.company_name}</H6>
                </div>
            ),
            Name: (
                <div>
                    <p>{value?.contact_person_name}</p>
                </div>
            ),
            Basic: (
                <div>
                    <p>{value?.email}</p>
                    {/* <p>{value?.contact_person_phone_number}</p> */}
                </div>
            ),
            Contact: (
                <div>
                    {/* <p>{value?.email}</p> */}
                    <p>{value?.contact_person_phone_number}</p>
                </div>
            ),
            TotalWorker: (
                <div>
                    <p>{value?.worker?.length} Workers</p>
                </div>
            ),


            workers: value?.worker

        }));

    const handleDownload = (url) => {
        window.open(API_LINK + url, '__blank');
    }


    const ExpandedComponent = ({ data }) =>

    (
        <div style={{ width: "100%", border: "1px solid grey" }}>
            <Table striped >
                <thead>
                    <tr style={{ fontWeight: "900" }}>
                        <th>
                            #
                        </th>
                        <th style={{ fontWeight: "900" }}>
                            Full Name
                        </th>
                        <th style={{ fontWeight: "900" }}>
                            Contact
                        </th>
                        <th style={{ fontWeight: "900" }}>
                            Address
                        </th>
                        <th style={{ fontWeight: "900" }}>
                            Expiry
                        </th>
                        <th style={{ fontWeight: "900" }}>
                            ID Proof
                        </th>
                        <th style={{ fontWeight: "900" }}>
                            Passport
                        </th>
                        <th style={{ fontWeight: "900" }}>
                            Work Permit
                        </th>

                    </tr>
                </thead>
                <tbody>
                    {
                        data?.workers?.map((data, index) => (
                            <tr key={index}>
                                <th scope="row">
                                    {index + 1}
                                </th>
                                <td>
                                    {data?.firstname} {data?.lastname}
                                </td>
                                <td>
                                    <p>{data?.email}</p>
                                    <p>{data?.mobile_number}</p>
                                </td>
                                <td>
                                    <p>{data?.area_or_street},{data?.city}</p>
                                    <p>{data?.state}-{data?.pincode}</p>
                                </td>
                                <td>
                                    <p>{data?.work_permit_expire_at}</p>
                                </td>
                                <td onClick={() => handleDownload(data?.goverment_id_photo)}>
                                    <i className="fa fa-download pointer" aria-hidden="true"></i>
                                </td>
                                <td onClick={() => handleDownload(data?.passport_photo)}>
                                    <i className="fa fa-download pointer" aria-hidden="true"></i>
                                </td>
                                <td onClick={() => handleDownload(data?.work_permit)}>
                                    <i className="fa fa-download pointer" aria-hidden="true"></i>
                                </td>



                            </tr>
                        ))
                    }


                </tbody>
            </Table>

            {/* Add more details as needed */}
        </div>
    );


    return (
        <div className="table-responsive product-table">
            <Breadcrumbs
                parent="Workers"
                title="Upcoming Expiry"
                mainTitle="workpermit Due for Renewal"
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <DataTable
                                    noHeader
                                    pagination
                                    paginationServer
                                    columns={productColumns}
                                    data={ProductList || []}
                                    highlightOnHover={true}
                                    striped={true}
                                    responsive={true}
                                    expandableRows
                                    expandableRowsComponent={ExpandedComponent}
                                    className="custom-data-table"

                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
