import React, { Fragment, useEffect, useMemo } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { routes } from './Routes';
import AppLayout from '../Layout/Layout';
import { useDispatch, useSelector } from "react-redux";
import { companyLogin, employeeLogin } from '../actions/AuthAction';
import { LOGGEDIN, USER_DETAILS } from '../Constant';

const LayoutRoutes = () => {

  const login_details = JSON.parse(sessionStorage.getItem("user"))
  console.log(login_details?.id,"hbgtgf")
  const login = sessionStorage.getItem("isLoggedIn")
  const dispatch = useDispatch()
  const authStore = useSelector((state) => state?.auth?.company)

  const navigation = useNavigate()

  useMemo(() => {
    console.log(login_details , "checkmate")
    if (authStore?.statuscode !== 200) {
      navigation(`${process.env.PUBLIC_URL}/login`, { replace: true });
    console.log(authStore , "checkmate1")

    }
    // else {
    //   navigation(`${process.env.PUBLIC_URL}/category`);

    // }
  }, [authStore])

  useEffect(() => {
    // if (login) {
    if (login_details?.id?.userrole?.rolename == "Vendor") {
      dispatch(employeeLogin({ email: login_details?.id?.email, password: login_details?.password }))
      dispatch({ type: USER_DETAILS, payload: login_details?.id });
        dispatch({ type: LOGGEDIN, payload: true });

    } else {
      dispatch(companyLogin({ email: login_details?.id?.email, password: login_details?.pin }))
      dispatch({ type: USER_DETAILS, payload: login_details?.id });
        dispatch({ type: LOGGEDIN, payload: true });
    }
    // }
  }, [])
//   const user = useSelector((state) => state?.layout?.user)
// console.log(user ,"31243535")
  return (
    <>
      <Routes>
        {routes.map(({ path, Component }, i) => (
          <Fragment key={i}>
            <Route element={<AppLayout />} key={i}>
              <Route path={path} element={Component} />
            </Route>
          </Fragment>
        ))}
      </Routes>
    </>
  );
};

export default LayoutRoutes;