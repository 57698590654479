// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import ListTable from "../../utils/Table";
import { all_category_list, category_delete } from "../../actions/category";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { CREATECATEGORY, GST_RATE } from "../../Constant";
import { all_gst_rate_list, delete_gst_rate } from "../../actions/gst_rate";

const GstRate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const Gst_rate_data = useSelector((state)=>state?.gst_rate?.gst_rate_list)
  const message = useSelector((state)=>state?.gst_rate?.gst_rate)
  console.log(Gst_rate_data)
  useMemo(()=>{
    dispatch(all_gst_rate_list())
  },[])
  const tempTableData = {
    tableTitle: "Gst Rate",
    deleteRecord: delete_gst_rate,
    // updateRecord: subcategory_update,
    // deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    onDeleteText: "Are you sure want to delete?",
    onUpdateText:
      "Videos are associated with this category, are you still want to change it.?",
    tableHead: [
      {
        id: "gst_rate",
        label: "Gst Rate (%)",
      },

      {
        id: "edit",
        label: "Update",
        edit_path :"/Update-GstRate",
        // id : "id",
        // handleEdit : (value) => {navigate("/Update-Category" , {state:{id:value?.id}})}
        // access: rights?.["Masters"]?.["edit"] == "true",
      },
    ],
    tableBody: [],
    filterColumn: [],
  };
  const [tableData, setTableData] = useState({...tempTableData})

  useMemo(()=>{
    if(Gst_rate_data?.statuscode == 200){
      const temp = tableData
      temp.tableBody = Gst_rate_data?.data || []
      setTableData({...temp})
    }
  },[Gst_rate_data])
  useMemo(()=>{
    if(message?.statuscode == 200){
      toast.success(message?.message);
      dispatch(all_gst_rate_list())
      dispatch({ type: GST_RATE, payload: undefined })
    }
  },[message])
  return (
    <div >
      <Breadcrumbs
        parent="Gst Rate"
        title="Gst Rates"
        mainTitle="Gst Rates"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
              <ListTable
              tableData={tableData}
              key={"ListTable"}
              // setForm={setForm}
              setTableData={setTableData}
              // setIsEdit={setIsEdit}
              />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GstRate;
