import React, { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { H5 } from '../../../AbstractElements';
// import { MonthlyProfitsGrowth, MonthlyProfitsTitle } from '../../../Constant';
import { MonthlyProfitsChartData } from '../../../Data/Ecommerce/Chart';
import { dashboard_graph_category_wise_sales } from '../../../actions/dashboard';
import { useDispatch, useSelector } from "react-redux";


const MonthlyProfits = () => {
  const earningByCategory = useSelector((state)=>state?.dashboard?.earning_by_category)
  console.log("earningByCategory" , earningByCategory)
  const dispatch = useDispatch()
  const userInfo = JSON.parse(sessionStorage.getItem("user"))
  useEffect(()=>{
    if(userInfo?.id?.id){
      userInfo?.id?.userrole?.rolename == "Admin" ?
      dispatch(dashboard_graph_category_wise_sales({admin_id:userInfo?.id?.id ,  user_type :"admin"}))
      :
      dispatch(dashboard_graph_category_wise_sales({vendor_id:userInfo?.id?.id , user_type :"vendor"}))
    }
  },[])
 const MonthlyProfitsChartData = {
    series: earningByCategory? earningByCategory?.product_count_list : [],
    options: {
      labels:earningByCategory? earningByCategory?.category_list :[],
      chart: {
        type: 'donut',
        height: 350,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: 'bottom',
        fontSize: '14px',
        fontFamily: 'Rubik, sans-serif',
        fontWeight: 500,
        labels: {
          colors: ['var(--chart-text-color)'],
        },
        markers: {
          width: 6,
          height: 6,
        },
        itemMargin: {
          horizontal: 7,
          vertical: 0,
        },
      },
      stroke: {
        width: 10,
        colors: ['var(--light2)'],
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '83%',
            labels: {
              show: true,
              name: {
                offsetY: 4,
              },
              total: {
                show: true,
                fontSize: '20px',
                fontFamily: 'Rubik, sans-serif',
                fontWeight: 500,
                label: '₹ ' +earningByCategory?.yearly_total_balance,
                formatter: () => 'Total Profit',
              },
            },
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
          },
        },
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
          },
        },
      },
      colors: ['#54BA4A', 'var(--theme-deafult)', '#FFA941' , "#d62915" ,"#945b00"],
      responsive: [
        {
          breakpoint: 1630,
          options: {
            chart: {
              height: 360,
            },
          },
        },
        {
          breakpoint: 1584,
          options: {
            chart: {
              height: 400,
            },
          },
        },
        {
          breakpoint: 1473,
          options: {
            chart: {
              height: 250,
            },
          },
        },
        {
          breakpoint: 1425,
          options: {
            chart: {
              height: 270,
            },
          },
        },
        {
          breakpoint: 1400,
          options: {
            chart: {
              height: 320,
            },
          },
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 250,
            },
          },
        },
      ],
    },
  };
  return (
    <Card>
      <CardHeader className='card-no-border'>
        <H5>Category Wise Sales</H5>
        {/* <span className='f-light f-w-500 f-14'>({MonthlyProfitsGrowth})</span> */}
      </CardHeader>
      <CardBody className='pt-0'>
        <div className='monthly-profit'>
          <ReactApexChart type='donut' height={380} series={MonthlyProfitsChartData.series} options={MonthlyProfitsChartData.options} />
        </div>
      </CardBody>
    </Card>
  );
};

export default MonthlyProfits;
