import * as api from "../api/index";
import { NOTIFICATION, NOTIFICATION_LIST, NOTIFICATION_MASK } from "../Constant";

export const notification_create = (formData) => async (dispatch) => {
  try {
    const { data } = await api.notification_create(formData);
    dispatch({ type: NOTIFICATION, payload: data });
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const notification_delete = (formData) => async (dispatch) => {
  try {
    const { data } = await api.notification_delete(formData);
    dispatch({ type: NOTIFICATION, payload: data });
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const all_notification_list = (formData) => async (dispatch) => {
  try {
    const { data } = await api.all_notification_list(formData);
    dispatch({ type: NOTIFICATION_LIST, payload: data });
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const notification_create_mask = (formData) => async (dispatch) => {
  try {
    const { data } = await api.notification_create_mask(formData);
    dispatch({ type: NOTIFICATION_MASK, payload: data });
    return data;
  } catch (error) {
    console.log(error);
  }
};
