import React, { useMemo } from 'react';
import {  Col, Row } from 'reactstrap';
// import { SmallWidgetsData } from '../../../Data/Ecommerce';
import SmallWidgets from '../../Common/CommonWidgets/SmallWidgets';
import TotalBalance from './TotalBalance';
import { useDispatch, useSelector } from "react-redux";
import { dashboard_graph_admin_vendor_balance } from '../../../actions/dashboard';
const WidgetsGrid = () => {
  const CountOnDashboard = useSelector((state)=>state?.dashboard?.count_on_dashboard)
  console.log("CountOnDashboard" , CountOnDashboard)
  const dispatch = useDispatch()
  const userInfo = JSON.parse(sessionStorage.getItem("user"))
  useMemo(()=>{
    if(userInfo?.id?.id)
      userInfo?.id?.userrole?.rolename == "Admin" ?
    dispatch(dashboard_graph_admin_vendor_balance({admin_id :userInfo?.id?.id ,user_type:"admin"})) 
    :
    dispatch(dashboard_graph_admin_vendor_balance({vendor_id :userInfo?.id?.id ,user_type:"vendor" }))
  },[])
  const SmallWidgetsData = [
    {
      title: 'New Orders (Monthly)',
      color: 'primary',
      total: CountOnDashboard?.new_orders,
      gros: Math.round(CountOnDashboard?.current_and_previous_order_ratio),
      icon: 'new-order',
    },
    {
      title: 'New Customers (Monthly)',
      color: 'warning',
      total: CountOnDashboard?.current_month_users,
      gros: Math.round(CountOnDashboard?.current_and_previous_users_ratio),
      icon: 'customers',
    },
    {
      title: 'Average Sale (Monthly)',
      color: 'secondary',
      total: parseFloat(CountOnDashboard?.average_total_sale).toFixed(2),
      gros: Math.round(CountOnDashboard?.current_and_previous_total_balance_ratio),
      prefix: '₹',
      icon: 'sale',
      suffix: 'k',
    },
    {
      title: 'Total Products',
      color: 'success',
      total: CountOnDashboard?.total_products,
      // gros: 80,
      // prefix: '₹',
      icon: 'profit',
    },]
  return (
    <Col xxl='5' md='7' className='box-col-7'>
      <Row>
        <Col sm='12'>
          <TotalBalance total = {CountOnDashboard?.total_balance} />
        </Col>
        {SmallWidgetsData?.map((data, i) => (
          <Col xs='6' key={i}>
            <SmallWidgets data={data} />
          </Col>
        ))}
      </Row>
    </Col>
  );
};

export default WidgetsGrid;
