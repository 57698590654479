// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import ListTable from "../../../utils/Table";
import { all_category_list, category_delete } from "../../../actions/category";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { CREATECATEGORY, VENDOR_DEAL_OF_THE_DAY_LIST } from "../../../Constant";
import { admin_vendor_wise_deal_of_the_day_list, all_deal_of_the_day_list, delete_deal_of_the_day, update_deal_of_the_day } from "../../../actions/offeres/deal_of_the_day";

const AllDealOfDay = () => {
  const dispatch = useDispatch();
  const DealOfTheDay = useSelector((state)=>state?.offers?.deal_of_the_day_list)
  const message = useSelector((state)=>state?.offers?.deal_of_the_day)
  useMemo(()=>{
 
      dispatch(all_deal_of_the_day_list())
 
  },[])
  const tempTableData = {
    tableTitle: "Deals",
    disableDelete : true,
    deleteRecord: delete_deal_of_the_day,
    updateRecord: update_deal_of_the_day,
    // deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    onDeleteText: "Are you sure want to delete?",
    onUpdateText:
      "Inactive this Deal of the Day will remove it from the active deals and make it unavailable for customers. Are you sure you want to proceed?",
    tableHead: [
      {
        id:"product",
        label:"Product"
      },
      {
        id: "product_sub_category",
        label: "Category Name",
        subText : "product_category"
      },
      {
        id: "created_by",
        label: "Ownership",
        // subText :"category"
      },
      {
        id: "uploaded_by_name",
        label: "Uploaded By",
        subText: "company_name",
      },
      {
        id: "start_date",
        label: "Deal Start",
      },
      {
        id :"product_final_price",
        label :"Deal Before Price"
      },
      {
        id :"deal_of_the_day_type",
        label :"Deal Type"
      },
      {
        id :"deal_of_the_day_final_amount",
        label :"Deal Price"
      },
      {
        id: "status",
        label: "Status",
        // isButtonDisplay: true,
      },
      // {
      //   id: "edit",
      //   label: "Update",
      //   edit_path :"/update_deal_of_the_day",
      //   // id : "id",
      //   // handleEdit : (value) => {navigate("/Update-Category" , {state:{id:value?.id}})}
      //   // access: rights?.["Masters"]?.["edit"] == "true",
      // },
    ],
    tableBody: [

    ],
    filterColumn: [
      {
        id: "1",
        title: "Owneship",
        name: "created_by",
        options: ["Admin", "Vendor"],
      },
    ],
  };
  const [tableData, setTableData] = useState({...tempTableData})

  useMemo(()=>{
    if(DealOfTheDay?.statuscode == 200){
      const temp = tableData
      temp.tableBody = DealOfTheDay?.data?.map((ele)=>({
        ...ele,
        product : ele?.product?.product_name,
        product_category: ele?.product_category?.category_name,
        product_sub_category : ele?.product_sub_category?.sub_category_name,
        uploaded_by_name:
        ele?.created_by == "Vendor"
          ? ele?.vendor?.vendor_name
          : ele?.admin?.name,
      company_name:
        ele?.created_by == "Vendor" ? ele?.vendor?.company_name : " - ",
      }))
      setTableData({...temp})
    }
  },[DealOfTheDay])
  useMemo(()=>{
    if(message?.statuscode == 200){
      toast.success(message?.message);
     
        dispatch(all_deal_of_the_day_list())
    
      dispatch({ type: VENDOR_DEAL_OF_THE_DAY_LIST, payload: undefined })
    }
  },[message])
  return (
    <div >
      <Breadcrumbs
        parent="Deal Of The Day"
        title="Deals List"
        mainTitle="Deals List"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
              <ListTable
              tableData={tableData}
              key={"ListTable"}
              // setForm={setForm}
              setTableData={setTableData}
              // setIsEdit={setIsEdit}
              />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AllDealOfDay;
