import * as actionType from '../Constant';


const WorkAssignReducer = (state = { WorkAssignData: null }, action) => {
  switch (action.type) {
    case actionType.CREATE_WORK_ASSIGN:
      return { ...state, work_assign: action.payload }
    case actionType.PROJECT_WORK_ASSIGN:
      return { ...state, project_work_assign: action.payload }
    case actionType.UPDATE_WORK_ASSIGN:
      return { ...state, update_work_assign: action.payload }
    default:
      return state;
  }
};

export default WorkAssignReducer;
