import React from "react";
import { useState, useMemo, useEffect } from "react";

import Form from "../../utils/Form";
import { terms_and_conditions,terms_and_conditions_update } from "../../actions/Setting/terms_conditions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Breadcrumbs } from "../../AbstractElements";
export default function TermsConditions() {
	
	const user = useSelector((state) => state.layout.profile);
	const dispatch = useDispatch();
	const navigate = useNavigate()

	const [form, setForm] = useState({});
	useEffect(()=>{
		dispatch(terms_and_conditions())
	},[])
	const termsconditions=useSelector((state)=>state.setting?.termsconditions?.data[0])
	useMemo(()=>{
		setForm({...termsconditions , id : termsconditions?.id})
	},[termsconditions])
	

	const formStructure= [
		{
			id: "1",
			type: "description",
			name:"description",
			title: "",
			limit:"2000"
		},

		
		{
			id: "8",
			type: "button",
			title: "Update" ,
		},
	]

	
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData();
		
		
		Object.keys(form).map((key) => data.append(key, form?.[key]));
		
		
		data.append("id", termsconditions?.id);
		data.append("user", user?.id);
		dispatch(terms_and_conditions_update(data));
		navigate("/dashboard")

		
		
	};

	return (
		<>
							    <Breadcrumbs
        parent="Setting"
        title="Terms Conditions"
        mainTitle="Terms Conditions"
      />
				<Form
					formStructure={formStructure}
					handleSubmit={handleSubmit}
					formTitle={"Terms & Conditions"}
					key={"Form"}
					setForm={setForm}
					form={form}
				/>
		
		</>
	);
}
