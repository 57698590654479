import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { IMAGE } from "../../api";
import AddIcon from "@mui/icons-material/Add";
import { CREATECATEGORY, WORKER } from "../../Constant";
import RemoveIcon from "@mui/icons-material/Remove";
import { Breadcrumbs, H5 } from "../../AbstractElements";
import Form from "../../utils/Form"
import * as Action from "../../actions/worker"
import { FormStructure } from "./FormStructure";
import MultiStepForm from "../../utils/MultiStepForm";
import { all_language_list } from "../../actions/language";
import { all_category_list } from "../../actions/category";
import { Country, State, City } from "country-state-city";
import { all_subcategory_list } from "../../actions/subcategory";
import _ from 'lodash'; // If lodash is available for deep cloning
import Popup from "../../utils/Popup";
const CreateWorkers = () => {
  console.log(Country.getAllCountries() , "checkk")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [content, setPopupContent] = useState("");
  const [openAdError, setOpenAdError] = useState(false);
  // console.log(location, "newLOCS");
  const isEdit = location?.state?.isEdit;
  const createCategory = useSelector((state) => state);
  const tempFormStruct = FormStructure(isEdit)
  const lauguage = useSelector((state)=>state?.language?.language_list)
  const category = useSelector((state)=>state?.categort?.category_list)
  const subCategory = useSelector((state)=>state?.subcategory?.subcategory_list)
  const [formStructure, setFormStructure] = useState([...tempFormStruct]);
  const [categoryPosition, setCategoryPosition] = useState();
  const user = useSelector((state) => state?.layout?.user)
//   console.log(user?.id)
  const [result, setResult] = useState(undefined);
  const { worker_register , worker_update} = bindActionCreators(
    Action,
    dispatch
  );

  const [file, setFile] = useState(null);
  const [form ,setForm] = useState({})
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (location?.state) {
      const data = location?.state?.data;
      // const data = location?.state?.data;
    const files = {};

    data?.work_data?.forEach((ele, index) => {
      files["category_" + index] = ele?.category?.category_name;
      files["subcategory_" + index] = ele?.subcategory?.subcategory_name;
      files["expertise_" + index] = ele?.expertise;
      files["work_fees_per_hour_" + index] = ele?.work_fees_per_hour;
      files["work_fees_per_day_" + index] = ele?.work_fees_per_day;
      files["work_fees_per_week_" + index] = ele?.work_fees_per_week;
      files["work_fees_per_month_" + index] = ele?.work_fees_per_month;
      files["work_id_" + index] = ele?.id;
    });

    // Combine the existing data with the spread WorkFees object
    // const updatedForm = {
    //   ...data,
    //   ...files, // Spread WorkFees object directly into the form state
    //   // language : data?.language?.map((ele)=>ele?.language_name)
    // };

    // setForm(updatedForm);
    setForm({...data,...files , language : data?.language?.map((ele)=> ele?.language_name) ,primary_language : data?.primary_language?.language_name})
    }
  }, [location?.state]);
  useEffect(()=>{
    dispatch(all_language_list())  
    dispatch(all_category_list())  
    dispatch(all_subcategory_list())  
  },[])
  useMemo(()=>{
    if(lauguage?.statuscode == 200){
        const temp = formStructure
        temp[0][7]["options"] = lauguage?.data?.map((ele)=>ele?.language_name)
        temp[0][8]["options"] = lauguage?.data?.map((ele)=>ele?.language_name)
        setFormStructure([...temp])
    }
  },[lauguage])
  useMemo(()=>{
    if(form?.date_of_birth !== undefined){
      function calculateAge(birthdate) {
        const today = new Date();
        const birthDate = new Date(birthdate);
        
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
      
        // Check if the birthdate hasn't occurred yet this year
        if (
          monthDifference < 0 ||
          (monthDifference === 0 && today.getDate() < birthDate.getDate())
        ) {
          age--;
        }
      
        return age;
      }
      setForm({...form,age :calculateAge(form?.date_of_birth)})
    }
  },[form?.date_of_birth])
  const handleClick = () => {
    if (formStructure[3].length < 40) {
        const temp = formStructure;
        // const index = temp[3].length - 3;
        const randomName = formStructure[3].length / 8;
        temp[3].push(
            {
                id: "32",
                type: "select",
                title: "Category",
                name: "category_" + randomName,
                // maxSelections: "3",
                options: temp[3][1]["options"],
                position: randomName,
                // errorText: "You can select max 3 langauge at a time",
                size:"2.2",
                required: true,
            },
            {
                id: "34",
                type: "select2",
                title: "Sub Category",
                name: "subcategory_" + randomName,
                options: temp[3][2]["options"],
                position: randomName,
                size:"2.2",
                required: true,
            },
            {
              id: "34",
              type: "inputBox",
              // maxLength: 6,
              title: "Expertise",
              name: "expertise_" + randomName,
              // regex: /^[0-9\s\&]+$/,
              size: "1.7",
              position:randomName,
              // isCaps: true,
              required: true,
            },
            {
              id: "35",
              type: "inputBox",
              maxLength: 4,
              title: "Hourly Pay",
              name: "work_fees_per_hour_" + randomName,
              regex: /^[0-9\s\&]+$/,
              size: "1.3",
              symbol:"﷼ ",
              position:randomName,
              // isCaps: true,
              required: true,
            },
            {
                id: "35",
                type: "inputBox",
                maxLength: 4,
                title: "Daily Pay",
                name: "work_fees_per_day_" + randomName,
                regex: /^[0-9\s\&]+$/,
                position: randomName,
                size:"1.3",
                symbol:"﷼ ",
                // isCaps: true,
                // required: true,
              },
            {
                id: "35",
                type: "inputBox",
                maxLength: 4,
                title: "Weekly Pay",
                name: "work_fees_per_week_" + randomName,
                regex: /^[0-9\s\&]+$/,
                position: randomName,
                size:"1.3",
                symbol:"﷼ ",
                // isCaps: true,
                required: true,
              },
            {
                id: "35",
                type: "inputBox",
                maxLength: 4,
                title: "Monthly Pay",
                name: "work_fees_per_month_" + randomName,
                position: randomName,
                symbol:"﷼ ",
                regex: /^[0-9\s\&]+$/,
                size:"1.5",
                // isCaps: true,
                required: true,
              },
            {
                id: "23",
                type: "button",
                title: <RemoveIcon sx={{ color: "#000 !important" ,marginTop:"5px" }} />,
                align: "right",
                size: "0.5",
                padding: "2px 2px",
                position: randomName,
                forceShow: true,
                handleClick: (e) => {

                    setOpen(true);
                    setCategoryPosition(randomName);
                    setPopupContent(
                        "Do you want to remove this Work Fees from this Worker?"
                    );
                },
            }
        );

        setFormStructure([...temp]);
    } else {
        setOpenAdError(true);
        setPopupContent("You can add max 5 Work Fees in a Worker");
    }
};
  useEffect(()=>{
    const temp = formStructure
    temp[3][0]["handleClick"] = handleClick;
    setFormStructure([...temp])
  },[])
  useMemo(()=>{
    if(isEdit){
      if (form?.work_data?.length > (formStructure?.[3].length ) / 6){

          form?.work_data?.map(
            (ele) =>
              form?.work_data?.length > (formStructure?.[3].length ) / 6 &&
              handleClick()
          );
      }
    }
   
  },[isEdit ,form])
  useMemo(()=>{
    const temp = formStructure;
    formStructure &&  formStructure?.map((arr, i) =>
      arr?.map(
        (ele, j) =>
          !["select","select2", "select_multiple"].includes(ele.type) &&
          (temp[i][j] = ele)
      )
    );
    temp[3][0]["handleClick"] = handleClick;
    setFormStructure([...temp]);
  },[isEdit])
  

useMemo(() => {
  if (category?.statuscode === 200) {
      // console.log('Form Structure:', formStructure); // Log the entire structure for debugging
      
      if (Array.isArray(formStructure) && formStructure[3] && Array.isArray(formStructure[3])) {
          let updated = false;
          const temp = [...formStructure]; // Create a shallow copy of formStructure
          
          // Check and update different indices
          if (formStructure[3][1]) {
              temp[3][1]["options"] = category?.data?.map((ele) => ele?.category_name).filter(Boolean);
              updated = true;
          } 
          if (formStructure[3][8]) {
              temp[3][8]["options"] = category?.data?.map((ele) => ele?.category_name).filter(Boolean);
              updated = true;
          } 
          if (formStructure[3][16]) {
              temp[3][16]["options"] = category?.data?.map((ele) => ele?.category_name).filter(Boolean);
              updated = true;
          } 
          if (formStructure[3][24]) {
              temp[3][24]["options"] = category?.data?.map((ele) => ele?.category_name).filter(Boolean);
              updated = true;
          } 
          if (formStructure[3][32]) {
              temp[3][32]["options"] = category?.data?.map((ele) => ele?.category_name).filter(Boolean);
              updated = true;
          } 

          // If any updates were made, set the updated structure
          if (updated) {
              setFormStructure(temp);
              // console.log('Updated Form Structure:', temp);
          } else {
              console.error("None of the expected indices were found in formStructure[3]");
          }
      } else {
          console.error("Unexpected structure in formStructure at index 2 or formStructure is not an array");
      }
  }
}, [category]);
  useMemo(() => {
    if (subCategory?.statuscode === 200 && form?.category_0) {
        // Check if formStructure is an array
        if (Array.isArray(formStructure)) {
            // Create a shallow copy of formStructure
            const temp = [...formStructure];
            
            // Ensure that temp[2] exists and has at least 3 elements
            if (temp[3] && temp[3][2]) {
                temp[3][2]["options"] = subCategory?.data
                    .map(
                        (subcategory) =>
                            subcategory?.category?.category_name === form?.category_0 &&
                            subcategory?.subcategory_name
                    )
                    .filter((e) => e); // Filter out any falsey values
                
                // Update the state with the modified structure
                setFormStructure(temp);
            } else {
                console.error("Unexpected structure in formStructure at index 2 or 2[2]");
            }
        } else {
            console.error("formStructure is not an array or is undefined");
        }
    }
}, [subCategory, form?.category_0]);
useMemo(() => {
  if (subCategory?.statuscode === 200 && form?.category_1) {
      // Check if formStructure is an array
      if (Array.isArray(formStructure)) {
          // Create a shallow copy of formStructure
          const temp = [...formStructure];
          
          // Ensure that temp[2] exists and has at least 3 elements
          if (temp[3] && temp[3][9]) {
              temp[3][9]["options"] = subCategory?.data
                  .map(
                      (subcategory) =>
                          subcategory?.category?.category_name === form?.category_1 &&
                          subcategory?.subcategory_name
                  )
                  .filter((e) => e); // Filter out any falsey values
              
              // Update the state with the modified structure
              setFormStructure(temp);
          } else {
              console.error("Unexpected structure in formStructure at index 2 or 2[2]");
          }
      } else {
          console.error("formStructure is not an array or is undefined");
      }
  }
}, [subCategory, form?.category_1]);
useMemo(() => {
  if (subCategory?.statuscode === 200 && form?.category_2) {
      // Check if formStructure is an array
      if (Array.isArray(formStructure)) {
          // Create a shallow copy of formStructure
          const temp = [...formStructure];
          
          // Ensure that temp[2] exists and has at least 3 elements
          if (temp[3] && temp[3][17]) {
              temp[3][17]["options"] = subCategory?.data
                  .map(
                      (subcategory) =>
                          subcategory?.category?.category_name === form?.category_2 &&
                          subcategory?.subcategory_name
                  )
                  .filter((e) => e); // Filter out any falsey values
              
              // Update the state with the modified structure
              setFormStructure(temp);
          } else {
              console.error("Unexpected structure in formStructure at index 2 or 2[2]");
          }
      } else {
          console.error("formStructure is not an array or is undefined");
      }
  }
}, [subCategory, form?.category_2]);
useMemo(() => {
  if (subCategory?.statuscode === 200 && form?.category_3) {
      // Check if formStructure is an array
      if (Array.isArray(formStructure)) {
          // Create a shallow copy of formStructure
          const temp = [...formStructure];
          
          // Ensure that temp[2] exists and has at least 3 elements
          if (temp[3] && temp[3][25]) {
              temp[3][25]["options"] = subCategory?.data
                  .map(
                      (subcategory) =>
                          subcategory?.category?.category_name === form?.category_3 &&
                          subcategory?.subcategory_name
                  )
                  .filter((e) => e); // Filter out any falsey values
              
              // Update the state with the modified structure
              setFormStructure(temp);
          } else {
              console.error("Unexpected structure in formStructure at index 2 or 2[3]");
          }
      } else {
          console.error("formStructure is not an array or is undefined");
      }
  }
}, [subCategory, form?.category_3]);
useMemo(() => {
  if (subCategory?.statuscode === 200 && form?.category_4) {
      // Check if formStructure is an array
      if (Array.isArray(formStructure)) {
          // Create a shallow copy of formStructure
          const temp = [...formStructure];
          
          // Ensure that temp[2] exists and has at least 3 elements
          if (temp[3] && temp[3][33]) {
              temp[3][33]["options"] = subCategory?.data
                  .map(
                      (subcategory) =>
                          subcategory?.category?.category_name === form?.category_4 &&
                          subcategory?.subcategory_name
                  )
                  .filter((e) => e); // Filter out any falsey values
              
              // Update the state with the modified structure
              setFormStructure(temp);
          } else {
              console.error("Unexpected structure in formStructure at index 2 or 2[3]");
          }
      } else {
          console.error("formStructure is not an array or is undefined");
      }
  }
}, [subCategory, form?.category_4]);

useMemo(() => {
  const temp = formStructure;
  temp[1][0]["options"] = Country.getAllCountries()?.map((ele) => ele?.name);
  setFormStructure([...temp]);
}, [Country]);

useMemo(() => {
  const temp = formStructure;
  const tempArray = []
  const CountryName = Country.getAllCountries()
    .map((ele) => ele?.name == form?.nationality && ele?.isoCode)
    .filter((e) => e)
    .toString();
    const StateName = State.getStatesOfCountry(CountryName)
    .map((ele) => ele?.name == form?.state && ele?.isoCode)
    .filter((e) => e)
    .toString();
  // console.log(StateName, "newSelect");
  // setForm({...form , state_code : StateName})
  City.getCitiesOfState(CountryName, StateName).map(
    (ele) => tempArray.push(ele?.name
  ));
  // tempArray.push("Other")
  temp[1][5]["options"] = tempArray
  // temp[5]["options"] = City.getCitiesOfState("IN", StateName).map(
  //   (ele) => ele?.name
  // );
  setFormStructure([...temp]);
}, [form?.state, City]);

useMemo(() => {
  const temp = formStructure;
  const tempArray = []
  const CountryName = Country.getAllCountries()
    .map((ele) => ele?.name == form?.nationality && ele?.isoCode)
    .filter((e) => e)
    .toString();
  // console.log(StateName, "newSelect");
  // setForm({...form , state_code : StateName})
  State.getStatesOfCountry(CountryName).map(
    (ele) => tempArray.push(ele?.name
  ));
  // tempArray.push("Other")
  temp[1][4]["options"] = tempArray
  // temp[5]["options"] = City.getCitiesOfState("IN", StateName).map(
  //   (ele) => ele?.name
  // );
  setFormStructure([...temp]);
}, [form?.nationality, State]);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const temp_form = form
    const countryCode = form?.countryCode || "";  // Corrected to match the field name
    let mobileNumber = form?.mobile_number || "";
  
    // Check if the mobile number starts with the country code and slice it off correctly
    if (countryCode && mobileNumber.startsWith(countryCode.replace("+", ""))) {
      mobileNumber = mobileNumber.slice(countryCode.length);
    } else if (mobileNumber.startsWith(countryCode.replace("+", ""))) {
      mobileNumber = mobileNumber.slice(countryCode.replace("+", "").length);
    }
  
    // Store the full mobile number in tempmobile_number
    temp_form["tempmobile_number"] = form?.mobile_number;
    // Update mobile_number with the sliced version
    temp_form["mobile_number"] = mobileNumber;
  
    // Update the form state
    setForm(temp_form);
    const categoryMap = category?.data?.reduce((map, obj) => {
        map[obj.category_name] = obj?.id;
        return map;
      }, {});
      const subCategoryMap = subCategory?.data?.reduce((map, obj) => {
        map[obj?.subcategory_name] = obj.id;
        return map;
      }, {});
      
      // Transform form data by replacing category names with IDs
      const transformedForm = Object.keys(form).reduce((result, key) => {
        if (key.startsWith("category_")) {
          result[key] = categoryMap[form[key]] || form[key]; // Map category name to ID, keep as is if not found
        } else if (key.startsWith("subcategory_")){
            // result[key] = form[key].map(name => subCategoryMap[name] || name);
            result[key] = [subCategoryMap[form[key]] || form[key]];
        } else if (key.startsWith("language")){
            const memberName = form[key];
            console.log(memberName,"chehcong")
            const memberId = lauguage?.data
          ?.map(
            (option) => memberName?.includes(option?.language_name) && option?.id
          )
          .filter((e) => e);
          console.log(memberId, result[key] = memberId,"chehcong")
          if (memberId) {
            result[key] = memberId;
          console.log(memberId, result[key] = memberId,"chehcong12")

          }
        } else if (key.startsWith("primary")){
          const memberName = form[key];
          const memberId = lauguage?.data
        ?.map(
          (option) => memberName?.includes(option?.language_name) && option?.id
        )
        .filter((e) => e);
        if (memberId) {
          result[key] = memberId;
        }
      }
         else {
          result[key] = form[key]; // Keep other fields unchanged
        }
        return result;
      }, {});
      
      // console.log(transformedForm ,form,"checkkin"); 
    const data = new FormData();
    Object.keys(transformedForm).map((key) => data.append(key, transformedForm?.[key]));
    data.append("vendor" , user?.id)
    // formStructure.map((element) => {
	// 		if (element.type == "image" && form?.[element.name] && typeof (form?.[element.name]) != "string") {
	// 			const temp = form;
	// 			temp["temp" + element.name] = form?.[element.name];
	// 			temp[element.name] = URL.createObjectURL(form?.[element.name]);

	// 			setForm({
	// 				...temp,
	// 			});
	// 		}
	// 	});
  
      if (isEdit) {
        const UpdateWorkerApi = await worker_update(data);
        if (UpdateWorkerApi?.statuscode == 200) {
          toast.success(UpdateWorkerApi?.message);
          setTimeout(() => {
            navigate("/Worker");
            dispatch({ type: WORKER, payload: undefined });
          }, 1000);
          setForm({});
        } else {
          toast.error(UpdateWorkerApi?.message);
        }
      }
       else {
        const createWorkerApi = await worker_register(data);
        if (createWorkerApi?.statuscode == 200) {
          toast.success(createWorkerApi?.message);
          setTimeout(() => {
            navigate("/Worker");
            dispatch({ type: WORKER, payload: undefined });
          }, 1000);
          setForm({});
        } else {
          toast.error(createWorkerApi?.message);
        }
      }
    } 
const formTitle = [	
    "Basic Info",
    "Address And Id Proof",
    "Previous Experience Details",
    "Work Fees"];

  useEffect(() => {
    if (result && categoryPosition !== undefined) {
        // Create a new copy of the formStructure
        const tempFormStructure = [...formStructure];
        
        // Get the sub-array that needs updating
        const formFields = tempFormStructure[3];
        
        // Find the index of the item to be removed
        const itemIndex = formFields.findIndex(item => item.position === categoryPosition);
        
        if (itemIndex !== -1) {
            // Remove the item and its associated fields
            const updatedFormFields = formFields.filter(item => item.position !== categoryPosition);
            
            // Update form fields
            const tempForm = { ...form };
            tempForm[`category_${categoryPosition}`] = "";
            tempForm[`subcategory_${categoryPosition}`] = "";
            tempForm[`expertise_${categoryPosition}`] = "";
            tempForm[`work_fees_per_hour_${categoryPosition}`] = "";
            tempForm[`work_fees_per_day_${categoryPosition}`] = "";
            tempForm[`work_fees_per_week_${categoryPosition}`] = "";
            tempForm[`work_fees_per_month_${categoryPosition}`] = "";
            
            setForm(tempForm);
            
            // Set updated formStructure
            setTimeout(()=>{

              tempFormStructure[3] = updatedFormFields;
            },500)
            setTimeout(()=>{

              setFormStructure(tempFormStructure);
            },1000)
            
            setCategoryPosition(undefined); // Reset category position
        } else {
            console.error('No matching categoryPosition found');
        }
        
        // Reset result
        setResult(undefined);
    }
}, [result, categoryPosition]);


    const categoryList = category?.data?.map((ele, index) => eval("form?.category_" + index))
  
  useEffect(() => {
    const newCategoryList = category?.data?.map((ele) =>
      !categoryList.includes(ele?.category_name) && ele?.category_name
    ).filter(Boolean);
  
    if (Array.isArray(formStructure) && Array.isArray(formStructure[3])) {
      const temp = [...formStructure]; // Clone the array to avoid mutating the original state directly
      temp[3].forEach((ele, index) => {
        if (ele.type === "select") {
          temp[3][index]["options"] = newCategoryList;
        }
      });
      setFormStructure(temp);
    } else {
      console.log("formStructure[3] is not an array");
    }
  }, [form]);

  return (
    <div>
       <Breadcrumbs
        parent="Workes"
        title={ isEdit ? "Edit Workes":"Create Workes"}
        mainTitle={isEdit ? "Edit Workes":"Create Workes"}
      />
      			<Popup
				open={open}
				setOpen={setOpen}
				content={content}
				setResult={setResult}
			/>
  
              <MultiStepForm
					handleSubmit={handleSubmit}
					formStructure={formStructure}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					// tableData={tableData}
					// setTableData={setTableData}
					isEdit={isEdit}
					// setIsEdit={setIsEdit}
				/>
            
    </div>
  );
};

export default CreateWorkers;
