import React from "react";
import { Breadcrumbs } from "../../AbstractElements";
// import TotalCustomers from "./elements/TotalCustomers";
import { Grid } from "@mui/material";
// import Features from "./elements/Features";
// import Booking from "./elements/Booking";
// import TotalVendor from "./elements/TotalVendor";
// import Activities from "./elements/Activities";
// import TotalWorker from "./elements/TotalWorker";
// import DailyBooking from "./elements/DailyBooking";
// import Shortcuts from "./elements/Shortcuts";
import Ecommerce from "./Ecommerce"

export default function Dashboard() {

  const userInfo = JSON.parse(sessionStorage.getItem("user"))
  console.log("userInfo: ", userInfo)
  return (
    // <div className="table-responsive product-table">
    <>
      <Breadcrumbs parent="Home" title="Dashboard" mainTitle="Dashboard" />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
      <Grid item xs={12} md={12} lg={12} xl={12}>
          {/* <Shortcuts />

           */}
           <Ecommerce />
        </Grid>
        {/* {
          userInfo?.data?.role != "vendor" &&
          <>

            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Features />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <TotalCustomers />
            </Grid>
            <Grid item xs={12} md={8} lg={8} xl={8}>
              <TotalVendor />
            </Grid>
            <Grid item xs={12} md={4} lg={4} xl={4}>
              <Activities />
            </Grid>
          </>
        }
    

        <Grid item xs={12} md={12} lg={12} xl={12}>
          <Booking />
        </Grid>
        <Grid item xs={12} md={7} lg={7} xl={7}>
          <TotalWorker />
        </Grid>
        <Grid item xs={12} md={57} lg={5} xl={5}>
          <DailyBooking />
        </Grid> */}
      </Grid>
    </>
  );
}
