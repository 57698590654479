// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { useMemo, useState } from "react";

import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../AbstractElements";
import ListTable from "../../utils/Table";
import SvgIcon from "../Common/Component/SvgIcon";
import { customer_list_admin } from "../../actions/customer"
import { useDispatch, useSelector } from "react-redux";
import notification_icon from "../../assets/images/notification.png"
import { useNavigate } from "react-router";


const Customer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const message = useSelector((state)=>state?.categort?.category)
  const Customer_Data = useSelector((state)=>state?.customer?.customers)

  useMemo(()=>{
    dispatch(customer_list_admin())
  },[])
  const sendNotification = (id)=>{
   
      console.log("checking")
      navigate("/create-notification" ,{state:{ customer: id, send: "nofitication" }})
   
  }
  const tempTableData = {
    tableTitle: "Customer",
    // deleteRecord: ,
    // updateRecord: subcategory_update,
    // deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    disableDelete : true,
    onDeleteText: "Are you sure want to delete?",
    onUpdateText:
      "Videos are associated with this category, are you still want to change it.?",
    tableHead: [
      {
        id: "name",
        label: "Customer Name",
        link:"/customer/details"
      },
      {
        id:"mobile_number",
        label: "Mobile No.",
      },
      {
        id:"email",
        label: "Email",
      },
      // {
      //   id: "location",
      //   label: "Location",
      // },
      {
        id: "created_at",
        label: "Join On",
      },
      {
        id: "notification",
        isSpecial: true,
        align: "center",
        label: "Notification",
      },
      // {
      //   id: "edit",
      //   label: "Update",
      //   edit_path :"/Update-Category",
      //   // id : "id",
      //   // handleEdit : (value) => {navigate("/Update-Category" , {state:{id:value?.id}})}
      //   // access: rights?.["Masters"]?.["edit"] == "true",
      // },
    ],
    tableBody: [
     
    ],
    filterColumn: [],
  };
  const [tableData, setTableData] = useState({...tempTableData})
  useMemo(()=>{
    if(Customer_Data?.statuscode == 200){
      const temp = tableData
      temp.tableBody = Customer_Data?.data?.map((ele)=>({
        ...ele,
        notification:  <img
             src={notification_icon}
             onClick={()=>sendNotification(ele?.id)}
             alt="Notifications"
             height={"25px"}
        style={{ marginRight: "5px" }}
      />
      })) || []
      setTableData({...temp})
    }
  },[Customer_Data])
  // useMemo(()=>{
  //   if(message?.statuscode == 200){
  //     toast.success(message?.message);
  //     dispatch(all_category_list())
  //     dispatch({ type: CREATECATEGORY, payload: undefined })
  //   }
  // },[message])
  return (
    <div>
      <Breadcrumbs
        parent="Customer"
        title="Customer"
        mainTitle="All Customers"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
              <ListTable
              tableData={tableData}
              key={"ListTable"}
              // setForm={setForm}
              setTableData={setTableData}
              // setIsEdit={setIsEdit}
              />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Customer;
