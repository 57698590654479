import * as actionType from '../Constant';


const CustomerReducer = (state = { customerData: null }, action) => {
  switch (action.type) {
    case actionType.CUSTOMERS:
      return { ...state, customers: action.payload };
    case actionType.CUSTOMER_DETAILS:
      return { ...state, customer_details: action.payload };
    case actionType.CUSTOMER_NAME:
      return { ...state, customer_name: action.payload };


    default:
      return state;
  }
};

export default CustomerReducer;