import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import * as Action from "../../actions/sliderbanner";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { IMAGE } from "../../api";
import { CREATECATEGORY, SLIDER_BANNER } from "../../Constant";
import { Breadcrumbs, H5 } from "../../AbstractElements";
import Form from "../../utils/Form";
const CreateSliderBanner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "newLOCS");
  const isEdit = location?.state?.isEdit;
  const { slider_banner_create, slider_banner_update } = bindActionCreators(
    Action,
    dispatch
  );

  const [file, setFile] = useState(null);
  const [form, setForm] = useState({});

  useEffect(() => {
    if (location?.state?.form) {
      setForm(location?.state?.form && JSON.parse(location?.state?.form));
    }
  }, [location?.state?.form]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const temp_form = form;
    const countryCode = form?.countryCode || '';
    let mobileNumber = form?.mobile_number || '';
    
    // Remove the country code from mobile number if it exists
    if (countryCode && mobileNumber.startsWith(countryCode)) {
      mobileNumber = mobileNumber.slice(countryCode.length);
    } else if (mobileNumber.startsWith(countryCode.replace('+', ''))) {
      mobileNumber = mobileNumber.slice(countryCode.length - 1);
    }
    temp_form["tempmobile_number"] = form?.mobile_number;
    temp_form["mobile_number"] = mobileNumber;
    setForm({ ...temp_form });
    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    formStructure.map((element) => {
      if (
        element.type == "image" &&
        form?.[element.name] &&
        typeof form?.[element.name] != "string"
      ) {
        const temp = form;
        temp["temp" + element.name] = form?.[element.name];
        temp[element.name] = URL.createObjectURL(form?.[element.name]);

        setForm({
          ...temp,
        });
      }
    });

    if (isEdit) {
      const UpdateSliderBannerApi = await slider_banner_update(data);
      if (UpdateSliderBannerApi?.statuscode == 200) {
        toast.success(UpdateSliderBannerApi?.message);
        setTimeout(() => {
          navigate("/advertisement");
          dispatch({ type: SLIDER_BANNER, payload: undefined });
        }, 1000);
        setForm({});
      } else {
        toast.error(UpdateSliderBannerApi?.message);
      }
    } else {
      const createSliderBannerApi = await slider_banner_create(data);
      if (createSliderBannerApi?.statuscode == 200) {
        toast.success(createSliderBannerApi?.message);
        setTimeout(() => {
          navigate("/advertisement");
          dispatch({ type: SLIDER_BANNER, payload: undefined });
        }, 1000);
        setForm({});
      } else {
        toast.error(createSliderBannerApi?.message);
      }
    }
  };

  const [formStructure, setFormStructure] = useState([
    {
      id: "1",
      type: "inputBox",
      maxLength: 30,
      title: "Advertisement Title",
      name: "title",
      regex: /^[a-zA-Z\s\&]+$/,
      // isCaps:true,
      required: true,
    },
    {
      id: "2",
      type: "inputBox",
      maxLength: 30,
      title: "Client Name",
      name: "c_name",
      regex: /^[a-zA-Z\s\&]+$/,
      // isCaps:true,
      required: true,
    },
    {
      id: "3",
      type: "phone",
      title: "Mobile Number",
      maxLength: 12,
      name: "mobile_number",
      required: true,
    },
    {
      id: "4",
      type: "inputBox",
      // maxLength:30,
      title: "Email",
      name: "email",
      // regex : /^[a-zA-Z\s\&]+$/,
      // isCaps:true,
      required: true,
    },
    {
      id: "5",
      type: "inputBox",
      // maxLength:30,
      title: "link",
      name: "promotional_link",
      // regex : /^[a-zA-Z\s\&]+$/,
      // isCaps:true,
      // required: true,
    },
    {
      id: "6",
      type: "inputBox",
      variant: "date",
      title: "Start Date",
      default: " ",
      min : new Date().toISOString().split("T")[0],
      name: "start_date",
      // display: "none",
      required: true,
    },
    {
      id: "7",
      type: "inputBox",
      variant: "date",
      title: "End Date",
      default: " ",
      min :  new Date().toISOString().split("T")[0],
      name: "end_date",
      // display: "none",
      required: true,
    },
    {
      id:"8",
      type:"lockedInput",
      variant:"number",
      min:0,
      max:99,
      title:"set_sequence",
      display: isEdit? "block" : "none",
      name:"set_sequence",
      required:true
  },
    {
      id: "9",
      type: "inputBox",
      title: "Description",
      showLimit: true,
      name: "description",
      maxLength: 250,
      size: "12",
      // regex: /^[a-zA-Z0-9\s\&]+$/,
      required: true,
    },
  

    {
      id: "10",
      type: "toggle",
      title: "Status",
      name: "status",
      default: "Active",
      display: isEdit ? "block" : "none",
      size: "6",
      options: [
        { value: "Active", color: "#00B69B" },
        { value: "Inactive", color: "#EE368C" },
      ],
    },
    {
      id: "11",
      type: "image",
      title: "Upload Image",
      subtitle: "(Resolution : 512px x 512px) *",
      subsubtitle: "Max File Size 1MB",
      subsubsubtitle: "Support only JPG,PNG,JPEG",
      name: "advertisement_photo",
    },
    
    {
      id: "12",
      type: "button",
      title: isEdit ? "Update" : "Create",
    }
  ]);
  const getNextDay = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);  // Add 1 day to the start_date
    return date.toISOString().split("T")[0];  // Return the next day in YYYY-MM-DD format
  };
  useMemo(() => {
    if (form?.start_date) {
      // Update the end_date minimum value to be 1 day after the selected start_date
        const temp = formStructure
        const nextDay = getNextDay(form?.start_date);
  
        temp[6]["min"] =nextDay
          setFormStructure([...temp])
    }
  }, [form?.start_date]);
  const formTitle = isEdit ? "Edit " : "Create Slider Banner";
  return (
    <div>
      <Breadcrumbs
        parent="Advertisement Banner"
        title={
          isEdit ? "Edit Advertisement Banner" : "Create Advertisement Banner"
        }
        mainTitle={
          isEdit ? "Edit Advertisement Banner" : "Create Advertisement Banner"
        }
      />

      <Form
        handleSubmit={handleSubmit}
        formStructure={formStructure}
        formTitle={formTitle}
        key={"Form"}
        setForm={setForm}
        form={form}
        // tableData={tableData}
        // setTableData={setTableData}
        isEdit={isEdit}
        // setIsEdit={setIsEdit}
      />
    </div>
  );
};

export default CreateSliderBanner;
