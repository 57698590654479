import * as actionType from "../Constant";

const NotificationReducer = (state = { expiryPermit: null }, action) => {
  switch (action.type) {
    case actionType.NOTIFICATION:
      return { ...state, notification: action.payload };
    case actionType.NOTIFICATION_LIST:
      return { ...state, notification_list: action.payload };

    default:
      return state;
  }
};

export default NotificationReducer;
