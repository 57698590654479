import * as actionType from '../Constant';


const languageReducer = (state = { languageData: null }, action) => {
  switch (action.type) {
    case actionType.LANGUAGE:
      return { ...state, language: action.payload };
    case actionType.LANGUAGE_LIST:
      return {...state, language_list: action.payload}

    default:
      return state;
  }
};

export default languageReducer;
