// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../AbstractElements";
import pic1 from "../../Images/download.png";
import pic2 from "../../Images/photo.png";
import * as Action from "../../actions/category";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { IMAGE } from "../../api";
import SweetAlert from 'sweetalert2';
import * as HomeAction from "../../actions/city";
import { Link ,useNavigate} from "react-router-dom";
import { toast } from "react-toastify";
import { CREATECATEGORY } from "../../Constant";
import SearchTable from "../../CommonElements/Search/SearchTable";
import { Button, Menu, MenuItem } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

const City = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const { all_city_list } = bindActionCreators(HomeAction, dispatch);
  const [cityData, setCityData] = useState([]);
  const [selectedId, setSelectedId] = useState(null)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, index) => {
    setSelectedId(index);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  useEffect(() => {
    (async () => {
      // const data = new FormData();
      // data.append("user", producer_id);
      // dispatch(bank_detail_list(data));
      const resData = await all_city_list();
      setCityData(resData);
    })();
  }, []);
  const user = useSelector((state) => state?.layout?.user)
  const loggedIn = useSelector((state) => state?.layout?.loggedIn)
  console.log(user, loggedIn);
  const deleteTask = async (userId) => {
    SweetAlert.fire({

      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this Category!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then(async (result) => {
      if (result.value) {
        // RemoveTask(userId);
        const fData = new FormData()
        fData.append("id", userId)

        // const deleteApi = await category_delete(fData)
        // console.log(deleteApi, "fdfzfz")
        // if (deleteApi?.statuscode == 200) {
        //   toast.success(deleteApi?.message);
        //   setTimeout(async () => {
        //     const resData = await all_country_list();
        //     setCityData(resData);
        //     dispatch({ type: CREATECATEGORY, payload: undefined });
        //   }, 1000);
        // } else {
        //   toast.error(deleteApi?.message);
        // }
        // console.log(result, result.value, deleteApi, userId, "nfsdf")
        SweetAlert.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );
      } else {
        SweetAlert.fire(
          'Your imaginary file is safe!'
        );
      }
    });
  };
  const productColumns = [
    {
      name: "City Name",
      selector: (row) => row.Name,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
   
    // {
    //   name: 'Stock',
    //   selector: (row) => row.stock,
    //   sortable: true,
    //   center: true,
    //   minWidth: '120px',
    //   maxWidth: '150px',
    // },
    // {
    //   name: 'Start_date',
    //   selector: (row) => row.start_date,
    //   sortable: true,
    //   center: true,
    //   minWidth: '120px',
    //   maxWidth: '150px',
    // },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      center: true,
      // minWidth: "160px",
      // maxWidth: "160px",
    },
  ];
  const style = {
    width: 55,
    height: 40,
    borderRadius: 5,
  };
  const style2 = { width: 60, fontSize: 14, padding: 4 };
  const ProductList =
  cityData &&
  cityData?.data?.map((value, index) => ({
      
      Name: value?.city_name,
    
      action: (
        <div>
          {/* <span>
            <Btn
              attrBtn={{
                style: style2,
                color: "danger",
                className: "btn btn-xs",
                type: "button",
                onClick: () => deleteTask(value?.id)
              }}

            >
              Delete
            </Btn>
          </span>{" "}
          &nbsp;&nbsp;
          <span>
            <Btn
              attrBtn={{
                style: style2,
                color: "success",
                className: "btn btn-xs",
                type: "button",
              }}
            >
              <Link style={{ color: "inherit" }} to={"/Update-City"} state={{ data: cityData?.data[index], isEdit: true }}>Edit</Link>
            </Btn>
          </span> */}
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={(e) => handleClick(e, index)}
          >
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>

          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => deleteTask(value?.id)}>
              <DeleteIcon sx={{ fontSize: "20px" }} /> Delete
            </MenuItem>
            <MenuItem onClick={() => navigate('/Update-City', { state: { data: cityData?.data[selectedId], isEdit: true } })}>
              <ModeEditIcon sx={{ fontSize: "20px" }} /> Edit</MenuItem>
          </Menu>
        </div>
      ),
    }));

  // amount: '$10',
  // stock: <div className='font-success'>In Stock</div>,
  // start_date: '2022/4/19',

  // ]

  const [filteredData, setFilteredData] = useState(null);


  const subHeaderComponent = (
    <SearchTable data={ProductList} setFilteredData={setFilteredData} />
  );


  return (
    <div className="table-responsive product-table">
      <Breadcrumbs
        parent="City"
        title="City"
        mainTitle="All City"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  noHeader
                  pagination
                  paginationServer
                  columns={productColumns}
                  data={filteredData ? filteredData : ProductList}
                  highlightOnHover={true}
                  striped={true}
                  responsive={true}
                  className="custom-data-table"
                  subHeader
                  subHeaderComponent={subHeaderComponent}
                  noDataComponent={<div style={{ padding: '20px', textAlign: 'center', fontWeight: "700" }}>No result found.</div>}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default City;
