import * as actionType from '../Constant';


const VendorReducer = (state = { vendorData: null, booking: null }, action) => {
  switch (action.type) {
    case actionType.VENDOR:
      return { ...state, vendor: action.payload };
    case actionType.VENDOR_LIST:
      return { ...state, vendor_list: action.payload }
    case actionType.VENDOR_UPDATE:
      return { ...state, vendor_update: action.payload }
    case actionType.VENDOR_DETAILS:
      return { ...state, vendor_details: action.payload }
    case actionType.BOOKING_VENDOR:
      return { ...state, booking: action.payload }
    case actionType.TODAY_BOOKING:
      return { ...state, today: action.payload }
    case actionType.BOOKING_HISTORY_VENDOR:
      return { ...state, historyVendor: action.payload }

    default:
      return state;
  }
};

export default VendorReducer;
