// import React from 'react';
// import { Box, Typography, Grid, Card, CardContent, Avatar, Divider, Table, TableBody, TableCell, TableHead, TableRow, Button, IconButton } from '@mui/material';
// import { ShoppingCart, AttachMoney, ShoppingBag, CheckCircle, FavoriteBorder, Receipt, LocalOffer } from '@mui/icons-material'; // Icons for stats

// const CustomerDetails = () => {
//   // Sample data for statistics and orders
//   const statistics = {
//     totalOrders: 15,
//     totalRevenue: '$1200',
//     avgOrderValue: '$80',
//     cartOrders: 2,
//     completedOrders: 12,
//     totalProductsPurchased: 40,
//     wishlistItemsCount: 5,
//     totalCouponsUsed: 3,
//   };

//   const orders = [
//     { id: 1, product: 'Product A', date: '2024-10-14', status: 'Completed', amount: '$50' },
//     { id: 2, product: 'Product B', date: '2024-10-10', status: 'Pending', amount: '$30' },
//     { id: 3, product: 'Product C', date: '2024-09-30', status: 'Cancelled', amount: '$40' },
//   ];

//   return (
//     <Box p={3} sx={{ backgroundColor: '#f4f6f8', minHeight: '100vh' }}>
//       <Grid container spacing={3}>

//         {/* Customer Basic Info */}
//         <Grid item xs={12} md={6}>
//           <Card sx={{ boxShadow: 3, borderRadius: '16px' }}>
//             <CardContent>
//               <Grid container alignItems="center" spacing={2}>
//                 <Grid item>
//                   <Avatar sx={{ bgcolor: '#1e88e5', width: 64, height: 64, fontSize: 24 }}>SB</Avatar>
//                 </Grid>
//                 <Grid item>
//                   <Typography variant="h5" fontWeight="600">Swarup Biswas</Typography>
//                   <Typography variant="body2" color="textSecondary">swarrupsaree@gmail.com</Typography>
//                 </Grid>
//               </Grid>
//               <Divider sx={{ my: 1 }} />
//               <Table sx={{ '& td': { borderBottom: 'none' } }}>
//                 <TableBody>
//                   <TableRow>
//                     <TableCell>Device Type</TableCell>
//                     <TableCell>iOS</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell>Device Name</TableCell>
//                     <TableCell>Unknown</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell>Registered On</TableCell>
//                     <TableCell>2024-10-16</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell>Mobile Number</TableCell>
//                     <TableCell>+91 9474787848</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell>Village</TableCell>
//                     <TableCell>Belgoria</TableCell>
//                   </TableRow>
//                   <TableRow>
//                     <TableCell>Taluka</TableCell>
//                     <TableCell>Kolkata, West Bengal, 741402</TableCell>
//                   </TableRow>
//                 </TableBody>
//               </Table>
//             </CardContent>
//           </Card>
//         </Grid>

//         {/* Statistics */}
//         <Grid item xs={12} md={6}>
//           <Card sx={{ boxShadow: 3, borderRadius: '16px', background: 'linear-gradient(to right, #43cea2, #185a9d)', color: 'white' }}>
//             <CardContent>
//               <Typography variant="h6" fontWeight="600" gutterBottom>Statistics</Typography>
//               <Grid container spacing={2}>
//                 <Grid item xs={6}>
//                   <Typography variant="body2">Total Orders</Typography>
//                   <Box display="flex" alignItems="center">
//                     <ShoppingBag sx={{ mr: 1 }} />
//                     <Typography variant="h6">{statistics.totalOrders}</Typography>
//                   </Box>
//                 </Grid>
//                 <Grid item xs={6}>
//                   <Typography variant="body2">Total Revenue Generated</Typography>
//                   <Box display="flex" alignItems="center">
//                     <AttachMoney sx={{ mr: 1 }} />
//                     <Typography variant="h6">{statistics.totalRevenue}</Typography>
//                   </Box>
//                 </Grid>
//                 <Grid item xs={6}>
//                   <Typography variant="body2">Average Order Value</Typography>
//                   <Box display="flex" alignItems="center">
//                     <Receipt sx={{ mr: 1 }} />
//                     <Typography variant="h6">{statistics.avgOrderValue}</Typography>
//                   </Box>
//                 </Grid>
//                 <Grid item xs={6}>
//                   <Typography variant="body2">Cart Orders</Typography>
//                   <Box display="flex" alignItems="center">
//                     <ShoppingCart sx={{ mr: 1 }} />
//                     <Typography variant="h6">{statistics.cartOrders}</Typography>
//                   </Box>
//                 </Grid>
//                 <Grid item xs={6}>
//                   <Typography variant="body2">Completed Orders</Typography>
//                   <Box display="flex" alignItems="center">
//                     <CheckCircle sx={{ mr: 1 }} />
//                     <Typography variant="h6">{statistics.completedOrders}</Typography>
//                   </Box>
//                 </Grid>
//                 <Grid item xs={6}>
//                   <Typography variant="body2">Total Products Purchased</Typography>
//                   <Box display="flex" alignItems="center">
//                     <ShoppingBag sx={{ mr: 1 }} />
//                     <Typography variant="h6">{statistics.totalProductsPurchased}</Typography>
//                   </Box>
//                 </Grid>
//                 <Grid item xs={6}>
//                   <Typography variant="body2">Wishlist Items Count</Typography>
//                   <Box display="flex" alignItems="center">
//                     <FavoriteBorder sx={{ mr: 1 }} />
//                     <Typography variant="h6">{statistics.wishlistItemsCount}</Typography>
//                   </Box>
//                 </Grid>
//                 <Grid item xs={6}>
//                   <Typography variant="body2">Total Coupons Used</Typography>
//                   <Box display="flex" alignItems="center">
//                     <LocalOffer sx={{ mr: 1 }} />
//                     <Typography variant="h6">{statistics.totalCouponsUsed}</Typography>
//                   </Box>
//                 </Grid>
//               </Grid>
//             </CardContent>
//           </Card>
//         </Grid>

//         {/* Order List */}
//         <Grid item xs={12}>
//           <Card sx={{ boxShadow: 3, borderRadius: '16px' }}>
//             <CardContent>
//               <Typography variant="h6" gutterBottom>Order List</Typography>
//               <Table>
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>Order ID</TableCell>
//                     <TableCell>Product</TableCell>
//                     <TableCell>Date</TableCell>
//                     <TableCell>Status</TableCell>
//                     <TableCell>Amount</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {orders.map(order => (
//                     <TableRow key={order.id} hover sx={{ '&:nth-of-type(even)': { backgroundColor: '#f9f9f9' } }}>
//                       <TableCell>{order.id}</TableCell>
//                       <TableCell>{order.product}</TableCell>
//                       <TableCell>{order.date}</TableCell>
//                       <TableCell>{order.status}</TableCell>
//                       <TableCell>{order.amount}</TableCell>
//                     </TableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//               <Box display="flex" justifyContent="flex-end" mt={2}>
//                 <Button variant="contained" color="primary">View All Orders</Button>
//               </Box>
//             </CardContent>
//           </Card>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default CustomerDetails;

import React, { useMemo, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
} from "@mui/material";
import {
  ShoppingCart,
  AttachMoney,
  ShoppingBag,
  CheckCircle,
  FavoriteBorder,
  Receipt,
  LocalOffer,
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid"; // Import DataGrid for table with pagination
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { customer_detail_admin } from "../../actions/customer";
import { Link } from "react-router-dom";

const CustomerDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  useMemo(() => {
    if (location?.state?.id) {
      dispatch(customer_detail_admin({ id: location?.state?.id }));
    }
  }, [location?.state?.id]);
  const CustomerData = useSelector(
    (state) => state?.customer?.customer_details
  );
  console.log("CustomerData", CustomerData);
  const [openOrdersDialog, setOpenOrdersDialog] = useState(false); // State for orders dialog
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 5,
    page: 0,
  });
  const [isClick, setIsClick] = useState("Order");
  // Sample data for statistics and orders
  const statistics = {
    totalOrders: CustomerData?.total_orders,
    totalRevenue: "₹ " + parseFloat(CustomerData?.total_user_spends).toFixed(2),
    avgOrderValue:
      "₹ " + parseFloat(CustomerData?.average_order_value).toFixed(2),
    cartOrders: CustomerData?.product_in_user_cart,
    completedOrders: CustomerData?.completed_orders,
    totalProductsPurchased: CustomerData?.total_products_purchased,
    wishlistItemsCount: CustomerData?.wishlist_item_count,
    totalCouponsUsed: CustomerData?.total_coupon_used,
  };

  const orders =
    isClick == "Order" || isClick == "Completed Order"
      ? CustomerData?.user_order_list
          ?.map((ele) =>
            isClick == "Completed Order"
              ? ele?.status == "Delivered" && {
                  order_id: ele?.id,
                  id: ele?.order_id,
                  paid_amount: parseFloat(ele?.paid_amount).toFixed(2),
                  date: ele?.created_at,
                  status: ele?.status,
                  payment_id: ele?.payment_id,
                }
              : {
                  order_id: ele?.id,
                  id: ele?.order_id,
                  paid_amount: parseFloat(ele?.paid_amount).toFixed(2),
                  date: ele?.created_at,
                  status: ele?.status,
                  payment_id: ele?.payment_id,
                }
          )
          .filter((e) => e)
      : isClick == "Cart"
      ? CustomerData?.user_cart?.map((ele) => ({
          id: ele?.id,
          p_name:
            ele?.product_name?.length > 20
              ? ele?.product_name?.substring(0, 40) + "..."
              : ele?.product_name,
          p_category: ele?.category,
          quantity: ele?.quantity,
          price: ele?.final_price,
        }))
      : isClick == "wishlist"
      ? CustomerData?.user_wishlist?.map((ele) => ({
          id: ele?.id,
          p_name:
            ele?.product_name?.length > 40
              ? ele?.product_name?.substring(0, 40) + "..."
              : ele?.product_name,
          p_category: ele?.category,
          price: ele?.final_price,
        }))
      : CustomerData?.product_detail?.map((ele) => ({
          p_name:
            ele?.product?.product_name?.length > 40
              ? ele?.product?.product_name?.substring(0, 40) + "..."
              : ele?.product?.product_name,
          p_category: ele?.product?.category,
          quantity: ele?.quantity,
          id: ele?.product?.id,
          price: ele?.purchased_product_price,
        }));

  // Table columns for DataGrid
  const columns =
    isClick == "Order" || isClick == "Completed Order"
      ? [
          {
            field: "id",
            headerName: "Order ID",
            width: 200,
            renderCell: (params) => {
              // Retrieve the full row data
              const row = params.row;

              return (
                <Link
                  to={"/order-details"}
                  state={{ id: row?.order_id }}
                  style={{ color: "inherit", backgroundColor: "inherit" }}
                >
                  {params.value}
                </Link>
              );
            },
          },
          { field: "paid_amount", headerName: "Amount", width: 150 },
          // {
          //   field: 'products',
          //   headerName: 'Products',
          //   width: 300,
          //   renderCell: (params) => (
          //     <ul style={{ paddingLeft: '15px' }}>
          //       {params.value.map((product, index) => (
          //         <li key={index}>
          //           {product.name} - Qty: {product.quantity} - Price: {product.price}
          //         </li>
          //       ))}
          //     </ul>
          //   ),
          // },
          { field: "payment_id", headerName: "Payment Id", width: 200 },
          { field: "date", headerName: "Date", width: 150 },
          { field: "status", headerName: "Status", width: 150 },
        ]
      : isClick == "Cart"
      ? [
          {
            field: "p_name",
            headerName: "Product",
            width: 300,
            renderCell: (params) => {
              // Retrieve the full row data
              const row = params.row;

              return (
                <Link
                  to={"/product_details"}
                  state={{ id: row?.id }}
                  style={{ color: "inherit", backgroundColor: "inherit" }}
                >
                  {params.value}
                </Link>
              );
            },
          },
          { field: "p_category", headerName: "Category", width: 200 },
          { field: "quantity", headerName: "Quantity", width: 200 },
          { field: "price", headerName: "Price", width: 100 },
        ]
      : isClick == "wishlist"
      ? [
          {
            field: "p_name",
            headerName: "Product",
            width: 300,
            renderCell: (params) => {
              // Retrieve the full row data
              const row = params.row;

              return (
                <Link
                  to={"/product_details"}
                  state={{ id: row?.id }}
                  style={{ color: "inherit", backgroundColor: "inherit" }}
                >
                  {params.value}
                </Link>
              );
            },
          },
          { field: "p_category", headerName: "Category", width: 300 },
          { field: "price", headerName: "Price", width: 200 },
        ]
      : [
          {
            field: "p_name",
            headerName: "Product",
            width: 300,
            renderCell: (params) => {
              // Retrieve the full row data
              const row = params.row;

              return (
                <Link
                  to={"/product_details"}
                  state={{ id: row?.id }}
                  style={{ color: "inherit", backgroundColor: "inherit" }}
                >
                  {params.value}
                </Link>
              );
            },
          },
          { field: "p_category", headerName: "Category", width: 220 },
          { field: "quantity", headerName: "Quantity", width: 200 },
          { field: "price", headerName: "Price", width: 100 },
        ];
  console.log("columns", columns);
  // Handle dialog open/close
  const handleOpenOrdersDialog = () => setOpenOrdersDialog(true);
  const handleCloseOrdersDialog = () => setOpenOrdersDialog(false);

  return (
    <Box p={3} sx={{ backgroundColor: "#f4f6f8", minHeight: "100vh" }}>
      <Grid container spacing={3}>
        {/* Customer Basic Info */}
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3, borderRadius: "16px" }}>
            <CardContent>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Avatar
                    sx={{
                      bgcolor: "#1e88e5",
                      width: 64,
                      height: 64,
                      fontSize: 24,
                    }}
                  >
                    {CustomerData?.data?.name?.substring(0,1)}
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography variant="h5" fontWeight="600">
                  {CustomerData?.data?.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {CustomerData?.data?.email}
                  </Typography>
                </Grid>
              </Grid>
              <Divider sx={{ my: 1 }} />
              <Table sx={{ "& td": { borderBottom: "none" } }}>
                <TableBody>
                  <TableRow>
                    <TableCell>Device Type</TableCell>
                    <TableCell>{CustomerData?.data?.device_type ? CustomerData?.data?.device_type:"Web" }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Device Name</TableCell>
                    <TableCell>{CustomerData?.data?.device_type ? CustomerData?.data?.device_name:"-" }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Registered On</TableCell>
                    <TableCell>{CustomerData?.data?.created_at}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell>{CustomerData?.data?.mobile_number}</TableCell>
                  </TableRow>
                  {/* <TableRow>
                    <TableCell>Village</TableCell>
                    <TableCell>Belgoria</TableCell>
                  </TableRow>*/}
                  <TableRow>
                    <TableCell>City</TableCell>
                    <TableCell>{CustomerData?.data?.city ? CustomerData?.data?.city + " " + CustomerData?.data?.state + " " + CustomerData?.data?.pincode : "-" }</TableCell>
                  </TableRow> 
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>

        {/* Statistics */}
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              boxShadow: 3,
              borderRadius: "16px",
              background: "linear-gradient(to right, #43cea2, #185a9d)",
              color: "white",
            }}
          >
            <CardContent>
              <Typography variant="h6" fontWeight="600" gutterBottom>
                Statistics
              </Typography>
              {/* <Grid container spacing={2}> */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body2">Total Orders</Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                    onClick={() => setIsClick("Order")}
                  >
                    <ShoppingBag sx={{ mr: 1 }} />
                    <Typography variant="h6">
                      {statistics.totalOrders}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    Total Revenue Generated
                  </Typography>
                  <Box display="flex" alignItems="center">
                    <AttachMoney sx={{ mr: 1 }} />
                    <Typography variant="h6">
                      {statistics.totalRevenue}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">Average Order Value</Typography>
                  <Box display="flex" alignItems="center">
                    <Receipt sx={{ mr: 1 }} />
                    <Typography variant="h6">
                      {statistics.avgOrderValue}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">Cart Orders</Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                    onClick={() => setIsClick("Cart")}
                  >
                    <ShoppingCart sx={{ mr: 1 }} />
                    <Typography variant="h6">
                      {statistics.cartOrders}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">Completed Orders</Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                    onClick={() => setIsClick("Completed Order")}
                  >
                    <CheckCircle sx={{ mr: 1 }} />
                    <Typography variant="h6">
                      {statistics.completedOrders}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    Total Products Purchased
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                    onClick={() => setIsClick("Products Purchased")}
                  >
                    <ShoppingBag sx={{ mr: 1 }} />
                    <Typography variant="h6">
                      {statistics.totalProductsPurchased}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">Wishlist Items Count</Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                    onClick={() => setIsClick("wishlist")}
                  >
                    <FavoriteBorder sx={{ mr: 1 }} />
                    <Typography variant="h6">
                      {statistics.wishlistItemsCount}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">Total Coupons Used</Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                    onClick={() => setIsClick("coupon")}
                  >
                    <LocalOffer sx={{ mr: 1 }} />
                    <Typography variant="h6">
                      {statistics.totalCouponsUsed}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              {/* </Grid> */}
            </CardContent>
          </Card>
        </Grid>

        {/* Order List with View All Orders */}
        <Grid item xs={12}>
          <Card sx={{ boxShadow: 3, borderRadius: "16px" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                {isClick}
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    {isClick == "Order" || isClick == "Completed Order" ? (
                      <>
                        {" "}
                        <TableCell>Order ID</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Payment ID</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Status</TableCell>
                      </>
                    ) : isClick == "Cart" ? (
                      <>
                        {" "}
                        <TableCell>Product</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Price</TableCell>
                      </>
                    ) : isClick == "wishlist" ? (
                      <>
                        {" "}
                        <TableCell>Product</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Price</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>Product</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Price</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders?.slice(0, 3).map((order) => (
                    <TableRow
                      key={order.id}
                      hover
                      sx={{
                        "&:nth-of-type(even)": { backgroundColor: "#f9f9f9" },
                      }}
                    >
                      {isClick == "Order" || isClick == "Completed Order" ? (
                        <>
                          <Link
                            to={"/order-details"}
                            state={{ id: order?.order_id }}
                            style={{
                              color: "inherit",
                              backgroundColor: "inherit",
                            }}
                          >
                            {" "}
                            <TableCell>{order.id}</TableCell>
                          </Link>
                          <TableCell>{order.paid_amount}</TableCell>
                          <TableCell>{order.payment_id}</TableCell>
                          <TableCell>{order.date}</TableCell>
                          <TableCell>{order.status}</TableCell>
                        </>
                      ) : isClick == "Cart" ? (
                        <>
                          <Link
                            to={"/product_details"}
                            state={{ id: order?.id }}
                            style={{
                              color: "inherit",
                              backgroundColor: "inherit",
                            }}
                          >
                            <TableCell>{order.p_name}</TableCell>
                          </Link>
                          <TableCell>{order.p_category}</TableCell>
                          <TableCell>{order.quantity}</TableCell>
                          <TableCell>{order.price}</TableCell>
                        </>
                      ) : isClick == "wishlist" ? (
                        <>
                          <Link
                            to={"/product_details"}
                            state={{ id: order?.id }}
                            style={{
                              color: "inherit",
                              backgroundColor: "inherit",
                            }}
                          >
                            <TableCell>{order.p_name}</TableCell>
                          </Link>

                          <TableCell>{order.p_category}</TableCell>
                          <TableCell>{order.price}</TableCell>
                        </>
                      ) : (
                        <>
                          <Link
                            to={"/product_details"}
                            state={{ id: order?.id }}
                            style={{
                              color: "inherit",
                              backgroundColor: "inherit",
                            }}
                          >
                            <TableCell>{order.p_name}</TableCell>
                          </Link>

                          <TableCell>{order.p_category}</TableCell>
                          <TableCell>{order.quantity}</TableCell>
                          <TableCell>{order.price}</TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              { orders?.length > 3 &&
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenOrdersDialog}
                  >
                    View All Orders
                  </Button>
                </Box>
              }
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Dialog for Viewing All Orders */}
      <Dialog
        open={openOrdersDialog}
        onClose={handleCloseOrdersDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>All Orders</DialogTitle>
        <DialogContent>
          {/* DataGrid Table with Pagination */}
          <Box sx={{ height: 373, width: "100%" }}>
            <DataGrid
              rows={orders} // The order data
              columns={columns} // The column structure
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              disableSelectionOnClick
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CustomerDetails;
