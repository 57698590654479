import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as BookingAction from "../../actions/booking";
import { Breadcrumbs, H6 } from "../../AbstractElements";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import {  Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";

const PendingBookingDetails = () => {
  const params = useParams();
  console.log("para", params?.id);
  const dispatch = useDispatch();
  const {
    getPendingBookingDetailsAdmin,
    finalAssignRequest,
    vendor_delete_booking_request,
    assign_booking_request,
  } = bindActionCreators(BookingAction, dispatch);
  const [bookingInfo, setBookingInfo] = useState();
  const [showAddBtn, setShowAddBtn] = useState(false);
  const [reqWorker, setReqWorker] = useState(0);
  const [allInfo, setAllInfo] = useState();
  const [disPlayData, setDisPlayData] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);
  const [adminFees, setAdminFees] = useState(0);
  const navigation = useNavigate();
  const calculateTotalDays = (fromDate, toDate) => {
    if (fromDate && toDate) {
      const from = new Date(fromDate);
      const to = new Date(toDate);
      
      // Calculate the time difference in milliseconds
      const timeDiff = to - from;
      
      // Convert milliseconds to days (1000 ms * 60 sec * 60 min * 24 hours)
      const totalDays = timeDiff / (1000 * 60 * 60 * 24);
      
      return totalDays;
    }
    return 0; // Return 0 if either of the dates is not provided
  };
const fromDate = bookingInfo?.from_date;
const toDate = bookingInfo?.to_date;
const totalDays = calculateTotalDays(fromDate, toDate);
console.log(totalDays); // Output: 9

  useEffect(() => {
    (async () => {
      if (params?.id) {
        const resData = await getPendingBookingDetailsAdmin({ id: params?.id });
        console.log("Res", resData);
        setBookingInfo(resData?.data);
        const totalWorker = resData?.total_required_worker;
        if (totalWorker > 0) {
          // console.log(totalWorker, "hcfdfs");
          setShowAddBtn(true);
        } else {
          // console.log(totalWorker, "hcfdfs123");
          setShowAddBtn(false);
        }
        setReqWorker(resData?.total_required_worker);
        setAllInfo(Number(resData?.total_required_worker));
        setDisPlayData(
          Number(resData?.total_needed_worker) -
            Number(resData?.total_assign_worker)
        );
      }
    })();
  }, []);
  const vendorDelete = (vendor_id) => {
    (async () => {
      let userObj = {
        booking_request: Number(params?.id),
        // total_workers: allInfo,
        vendor: vendor_id,
      };

      // console.log(userObj, "testimg");
      const resData = await vendor_delete_booking_request(userObj);
      if (resData?.statuscode === 200) {
        toast.success(resData?.message);
        const resData1 = await getPendingBookingDetailsAdmin({
          id: params?.id,
        });
        console.log("Res", resData1);
        setBookingInfo(resData1?.data);
        const totalWorker = resData1?.total_required_worker;
        setAllInfo(Number(totalWorker));
        if (totalWorker > 0) {
          console.log(totalWorker, "hcfdfs");
          setShowAddBtn(true);
        } else {
          console.log(totalWorker, "hcfdfs123");
          setShowAddBtn(false);
        }
      } else {
        toast.error(resData?.message);
      }
      console.log("result final submit", resData);
    })();
  };
  // useMemo(()=>{
  //   if(reqWorker > 1){
  //     console.log("hcfdfs")
  //     setShowAddBtn(true)
  //   } else{
  //     console.log(reqWorker,"hcfdfs123")

  //     setShowAddBtn(false)
  //   }
  // },[reqWorker])
  const buttonStyle = {
    backgroundColor: "#ec707a",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    padding: "5px 10px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  };

  const buttonHoverStyle = {
    backgroundColor: "#e95953",
  };

  const countStyle = {
    margin: "0 8px",
    fontWeight: "bold",
    fontSize: "1.2em",
  };
  const buttonDisabledStyle = {
    backgroundColor: "#d3d3d3", // Gray background for disabled state
    color: "#8a8a8a", // Lighter text color
    cursor: "not-allowed", // Disable pointer interaction
    opacity: "0.6", // Slightly reduce opacity
  };
  const [counts, setCounts] = useState(() => {
    return (
      bookingInfo?.vendor?.map((item) => ({ id: item.id, count: 0 })) || []
    );
  });
  useEffect(() => {
    console.log("BookingInfo:", bookingInfo);
    if (bookingInfo) {
      const initialCounts = bookingInfo?.vendor?.map((item) => ({
        id: item.id,
        count: 0,
      }));
      console.log("Initial Counts:", initialCounts); // Log the initial counts
      setCounts(initialCounts);
    }
  }, [bookingInfo]);
  const productColumns = [
    {
      name: "Company",
      selector: (row) => row.c_name,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Details",
      selector: (row) => row.name,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Assign Workers",
      selector: (row) => row.staff,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
  ];
  const productColumnsTable2 = [
    {
      name: "Company",
      selector: (row) => row.c_name,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Details",
      selector: (row) => row.name,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Required Worker",
      selector: (row) => row.required_worker,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Assign Workers",
      selector: (row) => row.staff,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Action",
      selector: (row) => row.Action,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
  ];
  const productColumnsTable3 = [
    {
      name: "Company",
      selector: (row) => row.c_name,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Details",
      selector: (row) => row.name,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },

    {
      name: "Available Workers",
      selector: (row) => row.Available,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    showAddBtn
      ? {
          name: "Assign Worker",
          selector: (row) => row.AssignWorker,
          sortable: true,
          // center: true,
          wrap: true,
          // minWidth: "350px",
        }
      : {
          name: "Action",
          selector: (row) => row.Action,
          sortable: true,
          // center: true,
          wrap: true,
          // minWidth: "350px",
        },
  ];
  useEffect(() => {
    console.log("Updated counts:", counts);
  }, [counts]); // Define the max total
  const totalCount = counts.reduce((acc, item) => acc + item.count, 0);
  const handleIncrement = (id, maxCount) => {
    // Check if the total count exceeds maxTotal
    if (totalCount >= allInfo) {
      console.error(`Max total of ${allInfo} reached.`);
      alert(
        `Max total count of ${allInfo} reached. No more increments allowed.`
      );
      return; // Prevent further increments
    }

    setCounts((prevCounts) =>
      prevCounts.map((item) => {
        if (item.id === id) {
          if (item.count < maxCount) {
            return { ...item, count: item.count + 1 };
          } else {
            console.error(`Max count of ${maxCount} reached for ID: ${id}`);
            alert(`Max count of ${maxCount} reached for this item.`);
          }
        }
        return item;
      })
    );
  };
  const handleDecrement = (id, maxCount) => {
    console.log("Decrementing ID:", id);
    setCounts((prevCounts) =>
      prevCounts.map((item) =>
        item.id === id
          ? { ...item, count: item.count > 0 ? item.count - 1 : 0 }
          : item
      )
    );
  };
  useMemo(() => {
    console.log(totalCount, allInfo, "whyyfi");
    if (totalCount == allInfo && allInfo !== 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [totalCount]);
  // const finalSubmit = () =>{
  //   // const filteredData = counts.filter(item => item.count > 0);
  //   const transformedData = counts
  // .map(item => ({
  //   vendor_id: item.id,
  //   total_worker: item.count
  // }))
  // .filter(item => item.total_worker > 0);
  //   console.log("finalSubmit Test" ,transformedData)

  //   // const resData = finalAssignRequest()
  //   (async () => {
  //     if (params?.id) {
  //       console.log("whyy")
  //       const resData = await finalAssignRequest({ id: params?.id , vendor_list : transformedData})
  //       if (resData?.statuscode === 200) {
  //         toast.success(resData?.message);
  //         const resData1 = await getPendingBookingDetailsAdmin({ id: params?.id });
  //         // console.log("Res", resData1);
  //         setBookingInfo(resData1?.data);
  //         const totalWorker = resData1?.total_required_worker;
  //         setAllInfo(Number(totalWorker));
  //       } else {
  //         toast.error(resData?.message);
  //       }
  //     }
  //   })()

  // }
  const finalSubmit = async () => {
    // Filter and transform data
    const transformedData = counts
      .map((item) => ({
        vendor_id: item.id,
        total_worker: item.count,
      }))
      .filter((item) => item.total_worker > 0);

    console.log("finalSubmit Test", transformedData); // Should log [{vendor_id: 7, total_worker: 2}, {vendor_id: 8, total_worker: 2}]

    try {
      if (params?.id) {
        console.log("Submitting request..."); // Make sure this is logged
        const resData = await finalAssignRequest({
          booking_request: params?.id,
          vendor_list: transformedData,
        });

        if (resData?.statuscode === 200) {
          toast.success(resData?.message);
          const resData1 = await getPendingBookingDetailsAdmin({
            id: params?.id,
          });

          setBookingInfo(resData1?.data);
          const totalWorker = resData1?.total_required_worker;
          setAllInfo(Number(totalWorker));
          if (totalWorker > 0) {
            console.log(totalWorker, "hcfdfs");
            setShowAddBtn(true);
          } else {
            console.log(totalWorker, "hcfdfs123");
            setShowAddBtn(false);
          }
          console.log(allInfo, totalWorker, "cjggfrsfd");
        } else {
          toast.error(resData?.message);
        }
      }
    } catch (error) {
      console.error("Error in finalSubmit:", error);
    }
  };

  const ProductList2 =
    bookingInfo?.vendor &&
    bookingInfo?.vendor
      ?.map(
        (value, index) =>
          value?.vendor_already_assign == "true" &&
          value?.worker?.length == 0 && {
            c_name: (
              <div>
                <H6>{value?.company_name}</H6>
              </div>
            ),
            name: (
              <div>
                <H6>{value?.contact_person_name}</H6>
                <p>{value?.email}</p>
                <p>{value?.contact_person_phone_number}</p>
              </div>
            ),
            required_worker: (
              <div>
                <H6>{value?.required_worker} Workers</H6>
              </div>
            ),
            staff: (
              <div>
                <H6>{value?.worker?.length} Workers</H6>
              </div>
            ),
            Action: (
              <DeleteIcon
                sx={{ fontSize: "20px" }}
                onClick={() => vendorDelete(value?.id)}
              />
            ),

            workers: value?.worker,
          }
      )
      .filter((e) => e);

  const ProductList3 =
    bookingInfo?.vendor &&
    bookingInfo?.vendor
      .filter(
        (value) =>
          value?.vendor_already_assign == "false" && value?.worker?.length == 0
      ) // Filter out invalid entries first
      .map((value, index) => {
        const currentCount =
          counts.find((item) => item.id === value.id)?.count || 0;
        return {
          c_name: (
            <div>
              <H6>{value?.company_name}</H6>
            </div>
          ),
          name: (
            <div>
              <H6>{value?.contact_person_name}</H6>
              <p>{value?.email}</p>
              <p>{value?.contact_person_phone_number}</p>
            </div>
          ),
          Available: (
            <div>
              <H6>{value?.worker_count} Workers</H6>
            </div>
          ),
          // Assuming you want to show a delete action
          Action: "Available",
          AssignWorker:
            currentCount === 0 ? (
              <button
                style={buttonStyle}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor =
                    buttonHoverStyle.backgroundColor)
                }
                onMouseOut={(e) =>
                  (e.target.style.backgroundColor = buttonStyle.backgroundColor)
                }
                onClick={() => handleIncrement(value.id, value?.worker_count)}
              >
                + Add
              </button>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <button
                  style={buttonStyle}
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor =
                      buttonHoverStyle.backgroundColor)
                  }
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor =
                      buttonStyle.backgroundColor)
                  }
                  onClick={() => handleDecrement(value.id, value?.worker_count)}
                >
                  -
                </button>
                <span style={countStyle}>{currentCount}</span>
                <button
                  style={buttonStyle}
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor =
                      buttonHoverStyle.backgroundColor)
                  }
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor =
                      buttonStyle.backgroundColor)
                  }
                  onClick={() => handleIncrement(value.id, value?.worker_count)}
                >
                  +
                </button>
              </div>
            ),
          workers: value?.worker,
        };
      });

  const ProductList =
    bookingInfo?.vendor &&
    bookingInfo?.vendor
      ?.map(
        (value, index) =>
          value?.vendor_already_assign == "true" &&
          value?.worker?.length > 0 && {
            c_name: (
              <div>
                <H6>{value?.company_name}</H6>
              </div>
            ),
            name: (
              <div>
                <H6>{value?.contact_person_name}</H6>
                <p>{value?.email}</p>
                <p>{value?.contact_person_phone_number}</p>
              </div>
            ),
            staff: (
              <div>
                <H6>{value?.worker?.length} Workers</H6>
              </div>
            ),
            // city: (
            //   <div>
            //     <p>{value?.address1}</p>
            //     <p>{value?.city}</p>
            //     <p>{value?.pincode}</p>
            //   </div>
            // ),

            workers: value?.worker,
          }
      )
      .filter((e) => e);


  //  const FeesData =   ProductList&& ProductList?.forEach((item, index) => {
  //       if (item.workers) {
  //         // console.log(`Workers for data ${index}:`);
  //         item?.workers?.map((worker, workerIndex) => {
  //           console.log(`Worker:`, worker);
  //         });
  //       }
  //     });
  //     console.log(FeesData ,"fessDara")
      
  const FeeData = ProductList?.reduce((acc, item) => {
    if (item.workers) {
      return acc.concat(item.workers); // Merge workers into the accumulator
    }
    return acc;
  }, []);
  
  console.log(FeeData, "FeeData");
  
  // Now you can map over FeeData to display each worker
  FeeData?.map((worker, index) => {
    console.log(`Worker ${index}:`, worker);
  });
  const ExpandedComponent = ({ data }) => (
    <div style={{ width: "100%", border: "1px solid grey" }}>
      <Table striped>
        <thead className="expand-table">
          <tr className="table-secondary" style={{ fontWeight: "900" }}>
            <th>#</th>
            <th style={{ fontWeight: "900" }}>First Name</th>
            <th style={{ fontWeight: "900" }}>Last Name</th>
            <th style={{ fontWeight: "900" }}>Email</th>
            <th style={{ fontWeight: "900" }}>Mobile</th>
            <th style={{ fontWeight: "900" }}>Nationality</th>
            <th style={{ fontWeight: "900" }}>Work Permit Expiry</th>
          </tr>
        </thead>
        <tbody>
          {data?.workers?.map((data, index) => (
            <tr>
              <th scope="row">{index + 1}</th>
              <td>{data?.firstname}</td>
              <td>{data?.lastname}</td>
              <td>{data?.email}</td>

              <td>{data?.mobile_number}</td>
              <td>{data?.nationality}</td>
              <td>{data?.work_permit_expire_at}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Add more details as needed */}
    </div>
  );
  const totalAmount = FeeData?.reduce((acc, data) => {
    const workerTotal = parseFloat(data?.work_fees_per_day * totalDays) || 0;
    return acc + workerTotal;
  }, 0);

  const grandTotal = totalAmount + parseFloat(adminFees || 0);
  const assignCustomer = () => {
    (async () => {
      // if (allInfo <= 0 && proceedData.length) {
      console.log({id :Number(params?.id) , approx_budget : grandTotal  , platform_fees : Number(adminFees)} )
      const resData = await assign_booking_request({ id: Number(params?.id) ,approx_budget :grandTotal ,platform_fees : Number(adminFees)});
      if (resData?.statuscode === 200) {
        toast.success(resData?.message);
        setTimeout(() => {
          navigation("/Booking-Assigned", { replace: true });
        }, 2500);
      } else {
        toast.error(resData?.message);
      }
      console.log("result final submit", resData);
      }
    )();
  };


  return (
    <div className="table-responsive product-table">
      <Breadcrumbs
        parent="Booking"
        title="Pending Booking"
        mainTitle={`Required Workers : ${disPlayData}`}
      />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <p
                  style={{
                    margin: "10px",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Assign Vendor
                </p>
                <DataTable
                  noHeader
                  // pagination
                  // paginationServer
                  columns={productColumns}
                  data={ProductList}
                  highlightOnHover={true}
                  striped={true}
                  responsive={true}
                  expandableRows
                  expandableRowsComponent={ExpandedComponent}
                  className="custom-data-table"
                />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <p
                  style={{
                    margin: "10px",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Pending Vendor
                </p>
                <DataTable
                  noHeader
                  // pagination
                  // paginationServer
                  columns={productColumnsTable2}
                  data={ProductList2}
                  highlightOnHover={true}
                  striped={true}
                  responsive={true}
                  // expandableRows
                  // expandableRowsComponent={ExpandedComponent}
                  className="custom-data-table"
                />
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <p
                  style={{
                    margin: "10px",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Available Vendor
                </p>
                <DataTable
                  noHeader
                  // pagination
                  // paginationServer
                  columns={productColumnsTable3}
                  data={ProductList3}
                  highlightOnHover={true}
                  striped={true}
                  responsive={true}
                  // expandableRows
                  // expandableRowsComponent={ExpandedComponent}
                  className="custom-data-table"
                />
              </CardBody>
              {showAddBtn && (
                <div style={{ textAlign: "center", margin: "20px" }}>
                  <Button
                    style={
                      isDisabled
                        ? { ...buttonStyle, ...buttonDisabledStyle }
                        : buttonStyle
                    }
                    onMouseOver={(e) =>
                      !isDisabled &&
                      (e.target.style.backgroundColor =
                        buttonHoverStyle.backgroundColor)
                    }
                    onMouseOut={(e) =>
                      !isDisabled &&
                      (e.target.style.backgroundColor =
                        buttonStyle.backgroundColor)
                    }
                    disabled={isDisabled}
                    onClick={finalSubmit}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </Card>
            <Card>
              <CardBody>
              <p
                  style={{
                    margin: "10px",
                    fontSize: "20px",
                    fontWeight: "600",
                  }}
                >
                  Total Cost
                </p>
              <div style={{ width: "100%", border: "1px solid grey" }}>
      {/* <Table striped> 
        <thead className="expand-table">
          <tr className="table-secondary" style={{ fontWeight: "900" }}>
            <th>#</th>
            <th style={{ fontWeight: "900" }}>First Name</th>
            <th style={{ fontWeight: "900" }}>Last Name</th>
            <th style={{ fontWeight: "900" }}>Par Day Fees</th>
            <th style={{ fontWeight: "900" }}>Total Amount</th>

          </tr>
        </thead>
        <tbody>
          { FeeData?.map((data, index) => (
            <tr>
              <th scope="row">{index + 1}</th>
              <td>{data?.firstname}</td>
              <td>{data?.lastname}</td>
              <td>{parseFloat(data?.work_fees_per_day).toFixed(2)}</td>
              <td>{parseFloat(data?.work_fees_per_day * totalDays).toFixed(2) }</td>

           
            </tr>
          ))}
        </tbody>
      </Table> */}
       <Table striped bordered hover>
      <thead className="expand-table">
        <tr className="table-secondary" style={{ fontWeight: "900" }}>
          <th>#</th>
          <th style={{ fontWeight: "900" }}>Company Name</th>
          <th style={{ fontWeight: "900" }}>Worker Name</th>
          <th style={{ fontWeight: "900" }}>Per Day Fees</th>
          <th style={{ fontWeight: "900" }}>Total Days</th>
          <th style={{ fontWeight: "900" }}>Total Amount</th>
        </tr>
      </thead>
      <tbody>
        {FeeData?.map((data, index) => (
          <tr key={index}>
            <th scope="row">{index + 1}</th>
            <td>{data?.company_name}</td>
            <td>{data?.firstname+" "+data?.lastname}</td>
            <td>{" ﷼ " + parseFloat(data?.work_fees_per_day).toFixed(2) }</td>
            <td>{totalDays}</td>
            <td>{" ﷼ " + parseFloat(data?.work_fees_per_day * totalDays).toFixed(2)}</td>
          </tr>
        ))}
        {/* Row for Total Amount */}
        <tr>
          <td colSpan="5" style={{ fontWeight: "bold", textAlign: "right" }}>Total Amount:</td>
          <td>{  " ﷼ " + parseFloat(totalAmount).toFixed(2) }</td>
        </tr>

        {/* Row for Admin Fees (input field) */}
        <tr>
          <td colSpan="5" style={{ fontWeight: "bold", textAlign: "right" }}>Admin Fees:</td>
          <td>
            <Form.Control
              type="number"
              value={adminFees}
              onChange={(e) => setAdminFees(e.target.value)}
              placeholder="Enter Admin Fees"
              min="0"
              step="0.01"
            />
          </td>
        </tr>

        {/* Row for Grand Total */}
        <tr>
          <td colSpan="5" style={{ fontWeight: "bold", textAlign: "right" }}>Grand Total:</td>
          <td>{grandTotal.toFixed(2)+ " ﷼ "}</td>
        </tr>
      </tbody>
    </Table>

      {/* Add more details as needed */}
    </div>
              </CardBody>
            </Card>
            <div style={{ textAlign: "center", margin: "1rem" }}>
              <Button
                // disabled={rejDisBtn || loading}
                style={{
                  background:
                    "linear-gradient(225deg,  #ac1600 0%, #500303 91.25%)",
                  display: disPlayData == 0 ? "" : "none",
                }}
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "10px",
                  m: "10px",
                  p: "10px 30px",
                  fontSize: "14px",
                  color: "#fff !important",
                }}
                variant="contained"
                className="mr-10px"
                // onClick={handleReject}
                onClick={assignCustomer}
              >
                {/* {vendorData?.status === "Rejected" ? "Rejected" : "Reject"} */}
                Assign To Customer
              </Button>
            </div>

          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PendingBookingDetails;
