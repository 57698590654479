import * as actionType from '../Constant';


const ProductReducer = (state = { productData: null }, action) => {
  switch (action.type) {
    case actionType.PRODUCT:
      return { ...state, product: action.payload }
    case actionType.PRODUCT_LIST_VENDOR:
      return { ...state, product_list_vendor: action.payload }
    case actionType.PRODUCT_LIST:
      return { ...state, product_list: action.payload }
    case actionType.PRODUCT_DETAILS:
      return { ...state, product_details: action.payload }
    case actionType.PRODUCT_NAME:
      return { ...state, product_name: action.payload }
    default:
      return state;
  }
};

export default ProductReducer;
