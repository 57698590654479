// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../AbstractElements";
import pic1 from "../../Images/download.png";
import pic2 from "../../Images/photo.png";
import * as Action from "../../actions/category";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { IMAGE } from "../../api";
import SweetAlert from "sweetalert2";
import * as HomeAction from "../../actions/booking";
import { Button } from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CREATECATEGORY } from "../../Constant";
import PopupForm from "../../utils/PopupForm";

const BookingVendorRequest = () => {
  const dispatch = useDispatch();
  const { booking_request_assign_list } = bindActionCreators(HomeAction, dispatch);
  const [requestData, setRequestData] = useState([]);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [form, setForm] = useState({});
  const user = useSelector((state) => state?.layout?.user);
  const navigation = useNavigate()

  useEffect(() => {
    if (user) {
      (async () => {
        // const data = new FormData();
        // data.append("user", producer_id);
        // dispatch(bank_detail_list(data));
        const resData = await booking_request_assign_list({ id: user?.id });
        setRequestData(resData);
      })();
    }
  }, [user]);
  const loggedIn = useSelector((state) => state?.layout?.loggedIn);
  const handleEdit = (ele) => {
    setOpenPopUp(true);
    console.log("Checking", ele)
    setForm(ele)
  };
  console.log(user, loggedIn);
  const [formStructure, setFormStructure] =
    useState([
      {
        id: "1",
        type: "select_multiple",
        title: "Vendor",
        name: "vendor",
        maxSelections: "3",
        options: ["English", "Hindi", "Gujarati"],
        errorText: "You can select max 5 Vendor at a time",
        required: true,
        size: "12"
        // isPlusBtnOpen :true,
      },
      {
        id: "2",
        type: "button",
        title: "Create",
      }
    ])
  const productColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Contact",
      selector: (row) => row.Contact,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    // {
    //   name: "Contact At",
    //   selector: (row) => row.contact,
    //   // sortable: true,
    //   center: true,
    //   wrap: true,
    //   // minWidth: "350px",
    // },
    {
      name: "Address",
      selector: (row) => row.Address,
      // sortable: true,
      center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Category",
      selector: (row) => row.Category,
      // sortable: true,
      center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Total Worker",
      selector: (row) => row.worker,
      // sortable: true,
      center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "From",
      selector: (row) => row.StartDate,
      // sortable: true,
      center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "To",
      selector: (row) => row.EndDate,
      // sortable: true,
      center: true,
      wrap: true,
      // minWidth: "350px",
    },

    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      center: true,
    },
  ];
  const style = {
    width: 55,
    height: 40,
    borderRadius: 5,
  };
  const style2 = { width: 60, fontSize: 14, padding: 4 };
  const ProductList =
    requestData &&
    requestData?.data?.map((value, index) => ({
      name: (
        <div>
          <H6>{value?.booking_request?.name}</H6>
        </div>
      ),
      Contact: (
        <div>
          <p>{value?.booking_request?.email}</p>
          <p>{value?.booking_request?.contact}</p>
        </div>
      ),
      contact: <div></div>,
      Address: (
        <div>
          <p>{value?.booking_request?.house_no} - {value?.booking_request?.residence_name}</p>
          <p>{value?.booking_request?.area_or_street},{value?.booking_request?.city?.city_name}</p>
          {/* <p>{value?.booking_request?.city?.city_name}</p>
          <p>{value?.booking_request?.city?.city_name}</p> */}
          {/* <p>{value?.booking_request?.city + "," + value?.booking_request?.pincode}</p> */}
        </div>
      ),
      Category: (
        <div>
          <p>{value?.booking_request?.subcategory?.subcategory_name}</p>
          <p>({value?.booking_request?.category?.category_name})</p>
        </div>
      ),
      worker: (
        <div>
          <p>{value?.total_worker} workers</p>
        </div>
      ),
      StartDate: (
        <div>
          <p>{value?.booking_request?.from_date}</p>
        </div>
      ),
      EndDate: (
        <div>
          <p>{value?.booking_request?.to_date}</p>
        </div>
      ),
      action: (
        <div>
          <span>
            <Button
              // onClick={() => handleEdit(value?.id)}
              onClick={() => navigation(`/Booking-Request-Vendor/Details/${value?.id}`, { replace: true })}
              style={{
                background: "rgba(235, 97, 6, 0.1)",
                color: "#e34e2c",
                padding: "5px 13px",
                borderRadius: "4px",
              }}
            >
              {" "}
              View{" "}
            </Button>
          </span>
        </div>
      ),
    }));

  // amount: '$10',
  // stock: <div className='font-success'>In Stock</div>,
  // start_date: '2022/4/19',

  // ]
  const handleSubmit = (event) => {
    event.preventDefault();
    // const data = new FormData();
    // Object.keys(form)?.map((key) => data.append(key, form?.[key]));
    // data.append("user", user?.id);


    // dispatch(payment_provider_update(data));
    setForm({})
    setOpenPopUp(false);
  };
  return (
    <div className="table-responsive product-table">
      <Breadcrumbs
        parent="Booking"
        title="Booking Request List"
        mainTitle="Booking Request List"
      />
      <PopupForm
        open={openPopUp}
        setOpen={setOpenPopUp}
        // content={content}
        formStructure={formStructure}
        handleSubmit={handleSubmit}
        formTitle={"Select Vendor"}
        key={"Form"}
        setForm={setForm}
        form={form}

      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  noHeader
                  pagination
                  paginationServer
                  columns={productColumns}
                  data={ProductList}
                  highlightOnHover={true}
                  striped={true}
                  responsive={true}
                  className="custom-data-table-vendor"

                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BookingVendorRequest;
