import * as actionType from '../Constant';


const companyServicesReducer = (state = { companyServicesData: null }, action) => {
  switch (action.type) {
    case actionType.CREATE_COMPANY_SERVICES:
      return { ...state, company_services: action.payload };
    case actionType.COMPANY_SERVICES:
      return {...state, company_services_list: action.payload}

    default:
      return state;
  }
};

export default companyServicesReducer;
