import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, Table } from 'reactstrap';
import { H5, Image } from '../../../AbstractElements';
import { DailyDropdown, ValuableCustomerTitle } from '../../../Constant';
import { ValuableCustomerData } from '../../../Data/Ecommerce';
import DropdownCommon from '../../Common/Dropdown';
import { useDispatch, useSelector } from "react-redux";
import { dashboard_graph_valuable_user } from '../../../actions/dashboard';
import { Avatar } from '@mui/material';

const ValuableCustomer = () => {
  const ValuableUser = useSelector((state)=>state?.dashboard?.valuableusers)
  console.log("ValuableUser" , ValuableUser)
  const dispatch = useDispatch()
  const userInfo = JSON.parse(sessionStorage.getItem("user"))
  useMemo(()=>{
    if(userInfo?.id?.id)
      userInfo?.id?.userrole?.rolename == "Admin" ?
    dispatch(dashboard_graph_valuable_user({admin_id :userInfo?.id?.id , user_type :"admin"}))
    :
    dispatch(dashboard_graph_valuable_user({vendor_id :userInfo?.id?.id , user_type :"vendor"}))
  },[])
  return (
    <div className='appointment'>
      <Card>
        <CardHeader className='card-no-border'>
          <div className='header-top'>
            <H5 className='m-0'>{ValuableCustomerTitle}</H5>
            {/* <div className='card-header-right-icon'>
              <DropdownCommon dropdownMain={{ className: 'icon-dropdown', direction: 'start' }} options={DailyDropdown} iconName='icon-more-alt' btn={{ tag: 'span' }} />
            </div> */}
          </div>
        </CardHeader>
        <div className='card-body pt-0'>
          <div className='appointment-table customer-table '>
            <Table borderless responsive>
              <tbody>
                {ValuableUser?.data?.map((item, i) => (
                  <div style={{display:"flex" ,width:"100%"}} key={i}>
                    <div>
                      {/* <Image attrImage={{ className: 'img-fluid img-40 rounded-circle me-2', src: require(`../../../assets/images/dashboard/user/${item.image}`), alt: 'user' }} /> */}
                
                  <Avatar
                    sx={{
                      bgcolor: "#1e88e5",
                      width: 35,
                      height: 35,
                      fontSize: 15,
                    }}
                  >
                    {item?.name?.substring(0,1)}
                  </Avatar>
              
                    </div>
                    <div className='img-content-box' style={{width:"100%"}}>
                      <Link className='f-w-500' to={'/customer/details'} state={{id:item?.id}} style={{display:"flex",justifyContent:"space-between" , color:"#575757"}}>
                     <span style={{textAlign:"left"}}>{item.name}</span> <span >{parseFloat(item.amount_spend).toFixed(2)}</span>
                      </Link>
                      <span className='f-light'>{item?.user_email}</span>
                    </div>
                  </div>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ValuableCustomer;
