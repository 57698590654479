// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CREATECATEGORY, SUBCATEGORY } from "../../Constant";
import { all_vendor_list, delete_vendor } from "../../actions/vendor";
import ListTable from "../../utils/Table";



const Vendor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const vendor_data = useSelector((state)=>state?.vendor?.vendor_list)
  const message = useSelector((state)=>state?.vendor?.vendor)
  console.log(vendor_data)
  useMemo(()=>{
    dispatch(all_vendor_list())
  },[])
  const tempTableData = {
    tableTitle: "Total Vendors",
    deleteRecord: delete_vendor,
    // updateRecord: subcategory_update,
    // deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    onDeleteText: "Are you sure want to delete?",
    onUpdateText:
      "Videos are associated with this category, are you still want to change it.?",
    tableHead: [
      {
        id: "company_name",
        label: "Company Name",
      },
      {
        id: "vendor_name",
        label: "Vendor Name",
      },
      {
        id: "email",
        label: "Contact At",
        subText : "mobile_number"
      },
      // {
      //   id: "commission_percentage",
      //   label:"Commission (%)"
      // },
      {
        id: "status",
        label: "Status",
        isButtonDisplay: true,
      },
      {
        id: "edit",
        label: "Update",
        edit_path :"/update-vendor",
        // id : "id",
        // handleEdit : (value) => {navigate("/Update-Category" , {state:{id:value?.id}})}
        // access: rights?.["Masters"]?.["edit"] == "true",
      },
    ],
    tableBody: [],
    filterColumn: [],
  };
  const [tableData, setTableData] = useState({...tempTableData})

  useMemo(()=>{
    if(vendor_data?.statuscode == 200){
      const temp = tableData
      temp.tableBody = vendor_data?.data?.map((ele)=>({
        ...ele,
    countryCode : ele?.countryCode
      })) || []
      setTableData({...temp})
    }
  },[vendor_data])
  useMemo(()=>{
    if(message?.statuscode == 200){
      toast.success(message?.message);
      dispatch(all_vendor_list())
      dispatch({ type: CREATECATEGORY, payload: undefined })
    }
  },[message])
 
  return (
    <div className="">
      <Breadcrumbs
        parent="Vendor"
        title="Vendor List"
        mainTitle="Vendor List"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
              <ListTable
              tableData={tableData}
              key={"ListTable"}
              // setForm={setForm}
              setTableData={setTableData}
              // setIsEdit={setIsEdit}
              />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Vendor;
