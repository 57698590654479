import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import * as Action from "../../actions/gst_rate";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { IMAGE } from "../../api";
import { CREATECATEGORY, GST_RATE } from "../../Constant";
import { Breadcrumbs, H5 } from "../../AbstractElements";
import Form from "../../utils/Form"
const CreateGstRate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "newLOCS");
  const isEdit = location?.state?.isEdit;

  const {create_gst_rate,update_gst_rate } = bindActionCreators(
    Action,
    dispatch
  );


  const [file, setFile] = useState(null);
  const [form ,setForm] = useState({})

  useEffect(() => {
    if (location?.state?.form) {
      setForm((location?.state?.form && JSON.parse(location?.state?.form)))
      // const data = location?.state?.data;
      // setForm(data)
    }
  }, [location?.state?.form]);

  



  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    formStructure.map((element) => {
			if (element.type == "image" && form?.[element.name] && typeof (form?.[element.name]) != "string") {
				const temp = form;
				temp["temp" + element.name] = form?.[element.name];
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});
  
      if (isEdit) {
        const UpdateGstRateApi = await update_gst_rate(data);
        if (UpdateGstRateApi?.statuscode == 200) {
          toast.success(UpdateGstRateApi?.message);
          setTimeout(() => {
            navigate("/GstRate");
            dispatch({ type: GST_RATE, payload: undefined });
          }, 1000);
          setForm({});
        } else {
          toast.error(UpdateGstRateApi?.message);
        }
      }
       else {
        const createGstRateApi = await create_gst_rate(data);
        if (createGstRateApi?.statuscode == 200) {
          toast.success(createGstRateApi?.message);
          setTimeout(() => {
            navigate("/GstRate");
            dispatch({ type: GST_RATE, payload: undefined });
          }, 1000);
          setForm({});
        } else {
          toast.error(createGstRateApi?.message);
        }
      }
    } 


 const formStructure=[
    {
        id:"1",
        type:"inputBox",
        maxLength:2,
        title:"Gst Rate (%)",
        name:"gst_rate",
        regex : /^[0-9\s\&]+$/,
        isCaps:true,
        required:true
    },
    {
        id:"2",
        type:"button",
        title:"Submit",
        
    }
]
const formTitle = isEdit ? "Edit Gst Rate" : "Create Gst Rate";
  return (
    <div>
       <Breadcrumbs
        parent="Gst Rate"
        title={ isEdit ? "Edit GstRate":"Create GstRate"}
        mainTitle={isEdit ? "Edit GstRate":"Create GstRate"}
      />
  
              <Form
					handleSubmit={handleSubmit}
					formStructure={formStructure}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					// tableData={tableData}
					// setTableData={setTableData}
					isEdit={isEdit}
					// setIsEdit={setIsEdit}
				/>
            
    </div>
  );
};

export default CreateGstRate;
