import { ASSIGNED_BOOKING, BOOKING_HISTORY_ADMIN, BOOKINGREQUESR_DETAILS, BOOKINGREQUESR_LIST, BOOKINGREQUESR_VENDOR, CONFIRMED_BOOKING_REQUEST, DELETE_VENDOR_BOOKING, FINAL_SUBMIT, FINAL_VENDOR_SUBMIT, PENDING_BOOKING_DETAILS, REJECTED_BOOKING_REQUEST, TODAYS_BOOKING_ADMIN, VENDOR_ASSIGNED_BOOKING } from '../Constant';
import * as api from '../api/index';

export const booking_request_list = (formData) => async (dispatch) => {
  try {
    const { data } = await api.booking_request_list(formData);
    dispatch({ type: BOOKINGREQUESR_LIST, payload: data });
    return data


  } catch (error) {
    console.log(error);
  }
};
export const booking_request_details = (formData) => async (dispatch) => {
  try {
    const { data } = await api.booking_request_details(formData);
    dispatch({ type: BOOKINGREQUESR_DETAILS, payload: data });
    return data


  } catch (error) {
    console.log(error);
  }
};
export const booking_request_assign_list = (formData) => async (dispatch) => {
  try {
    const { data } = await api.vendor_booking_request_assign_list(formData);
    dispatch({ type: BOOKINGREQUESR_VENDOR, payload: data });
    return data


  } catch (error) {
    console.log(error);
  }
};
export const getBookingInfo = (formData) => async (dispatch) => {
  try {
    const { data } = await api.vendor_list_for_booking_request(formData);
    dispatch({ type: BOOKINGREQUESR_VENDOR, payload: data });
    return data


  } catch (error) {
    console.log(error);
  }
};
export const finalAssignRequest = (formData) => async (dispatch) => {
  try {
    const { data } = await api.vendor_assign_to_booking_request(formData);
    dispatch({ type: FINAL_SUBMIT, payload: data });
    return data


  } catch (error) {
    console.log(error);
  }
};
export const getAssignedBooking = (formData) => async (dispatch) => {
  try {
    const { data } = await api.booking_request_assigned_list(formData);
    dispatch({ type: ASSIGNED_BOOKING, payload: data });
    return data


  } catch (error) {
    console.log(error);
  }
};
export const getTodaysbookingAdmin = (formData) => async (dispatch) => {
  try {
    const { data } = await api.admin_booking_request_today_list(formData);
    dispatch({ type: TODAYS_BOOKING_ADMIN, payload: data });
    return data


  } catch (error) {
    console.log(error);
  }
};
export const getBookingHistoryAdmin = (formData) => async (dispatch) => {
  try {
    const { data } = await api.admin_booking_request_history_list(formData);
    dispatch({ type: BOOKING_HISTORY_ADMIN, payload: data });
    return data


  } catch (error) {
    console.log(error);
  }
};
export const getPendingBookingAdmin = (formData) => async (dispatch) => {
  try {
    const { data } = await api.pending_booking_request_admin(formData);
    dispatch({ type: BOOKING_HISTORY_ADMIN, payload: data });
    return data


  } catch (error) {
    console.log(error);
  }
};
export const getPendingBookingDetailsAdmin = (formData) => async (dispatch) => {
  try {
    const { data } = await api.pending_booking_request_details_admin(formData);
    dispatch({ type: PENDING_BOOKING_DETAILS, payload: data });
    return data
  } catch (error) {
    console.log(error);
  }
};
export const vendor_delete_booking_request = (formData) => async (dispatch) => {
  try {
    const { data } = await api.vendor_delete_booking_request(formData);
    dispatch({ type: DELETE_VENDOR_BOOKING, payload: data });
    return data
  } catch (error) {
    console.log(error);
  }
};
export const assign_booking_request = (formData) => async (dispatch) => {
  try {
    const { data } = await api.assign_booking_request(formData);
    dispatch({ type: FINAL_VENDOR_SUBMIT, payload: data });
    return data
  } catch (error) {
    console.log(error);
  }
};
export const vendor_assign_worker_booking_request_list = (formData) => async (dispatch) => {
  try {
    const { data } = await api.vendor_assign_worker_booking_request_list(formData);
    dispatch({ type: VENDOR_ASSIGNED_BOOKING, payload: data });
    return data
  } catch (error) {
    console.log(error);
  }
};
export const confirmed_booking_request_list = (formData) => async (dispatch) => {
  try {
    const { data } = await api.confirmed_booking_request_list(formData);
    dispatch({ type: CONFIRMED_BOOKING_REQUEST, payload: data });
    return data
  } catch (error) {
    console.log(error);
  }
};
export const rejected_booking_request_list = (formData) => async (dispatch) => {
  try {
    const { data } = await api.rejected_booking_request_list(formData);
    dispatch({ type: REJECTED_BOOKING_REQUEST, payload: data });
    return data
  } catch (error) {
    console.log(error);
  }
};