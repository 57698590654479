import React, { Fragment } from 'react'
import { useLocation } from 'react-router'
import { Breadcrumbs, Btn, H3, H4, H5, H6, Image, LI, P, UL } from '../../AbstractElements'
import { Card, Col, Row } from 'reactstrap';
import { MarkJecno } from '../../Constant';
import blog from '../../assets/images/blog/blog.jpg';
import blog2 from '../../assets/images/blog/blog-2.jpg';
import blog3 from '../../assets/images/blog/blog-3.jpg';
import { API_LINK } from '../../api';
import SocialWidget from '../Common/CommonWidgets/SocialWidget'

export default function TaskInfo() {

    const location = useLocation()
    console.log(location?.state)
    const start_date = new Date(location?.state?.start_date);
    const end_date = new Date(location?.state?.end_date);
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const startday = start_date.getDate(); // Get the day of the month (1-31)
    const startMonthIndex = start_date.getMonth(); // Get the month (0-11)
    const startYear = start_date.getFullYear(); // Get the full year (YYYY)

    const endday = end_date.getDate(); // Get the day of the month (1-31)
    const endMonthIndex = end_date.getMonth(); // Get the month (0-11)
    const endYear = end_date.getFullYear(); // Get the full year (YYYY)

    const item = {
        title: "Status",
        image: "1.png",
        gros: 22.9,
        // total: 12_098,
        subTitle: "",
        status: "success",
        chart: {
            color: ["#f73164"],
            series: [63],
        },
    }


    const handleClick = () => {
        window.open(API_LINK + location?.state?.project?.documents, "_blank")
    }
    return (
        <div>
            <Breadcrumbs
                parent="Tasks"
                title="Task Details"
                mainTitle="Task Details"
            />
            <Fragment>
                <Row>
                    <Col xl='12' className='set-col-12 box-col-12'>
                        <Card>
                            <div className="blog-box blog-list row p-20">
                                <Col sm="12">
                                    <div className="">
                                        {/* <H3>Project Information</H3> */}
                                        <H5><span>{location?.state?.project_name}</span></H5>
                                        <hr style={{ opacity: "1" }} />

                                        <h6 dangerouslySetInnerHTML={{ __html: location?.state?.project?.description }}></h6>
                                        <Btn attrBtn={{ onClick: handleClick }} k>View Document</Btn>
                                    </div>
                                </Col>
                            </div>
                        </Card>
                    </Col>
                    <Col xl='6' className='set-col-12 box-col-12'>
                        <Card>
                            <div className="blog-box blog-list row p-20">
                                <Col sm="12">
                                    <H5><span>Assigned work and time period</span></H5>
                                    <hr style={{ opacity: "1" }} />
                                    <Row>
                                        <Col sm="8" style={{alignContent:"center"}}>
                                            <div className="blog-date digits">From <span>{startday}</span> {`${monthNames[startMonthIndex] + " " + startYear}`} to <span>{endday}</span> {`${monthNames[endMonthIndex] + " " + endYear}`}</div>
                                            <div className='mt-3'>Service : <b>{location?.state?.service?.service_name}</b></div>
                                        </Col>
                                        <Col sm="4">
                                            <SocialWidget data={item} />
                                        </Col>
                                    </Row>
                                    <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-3' }}>Update status</Btn>

                                </Col>

                            </div>
                        </Card>

                    </Col>
                    <Col xl='6' className='set-col-12 box-col-12'>
                        <Card>
                            <div className="blog-box blog-list row p-20">
                                <Col sm="12">
                                    <H5><span>Important note for you</span></H5>
                                    <hr style={{ opacity: "1" }} />
                                    <h6 dangerouslySetInnerHTML={{ __html: location?.state?.description }}></h6>
                                    {/* <Image attrImage={{ className: 'img-fluid sm-100-w', src: blog2, alt: '' }} /> */}
                                </Col>

                            </div>
                        </Card>

                    </Col>
                </Row>
            </Fragment>
        </div>
    )
}
