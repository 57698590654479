import * as actionType from '../Constant';


const ClientReducer = (state = { clientata: null }, action) => {
  switch (action.type) {
    case actionType.CREATE_CLIENT:
      return { ...state, client: action.payload };
    case actionType.CLIENT:
      return {...state, client_list: action.payload}

    default:
      return state;
  }
};

export default ClientReducer;
