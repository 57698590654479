// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import ListTable from "../../utils/Table";
import { all_category_list, category_delete } from "../../actions/category";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { CREATECATEGORY } from "../../Constant";

const Category2 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const category_data = useSelector((state)=>state?.category?.category_list)
  const message = useSelector((state)=>state?.categort?.category)
  console.log(category_data)
  useMemo(()=>{
    dispatch(all_category_list())
  },[])
  const tempTableData = {
    tableTitle: "Categories",
    deleteRecord: category_delete,
    // updateRecord: subcategory_update,
    // deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    onDeleteText: "Are you sure want to delete?",
    onUpdateText:
      "Videos are associated with this category, are you still want to change it.?",
    tableHead: [
      {
        id: "category_name",
        label: "Category Name",
      },
      {
        id: "set_sequence",
        label: "Sequence",
      },
      {
        id: "category_image",
        label: "Category Image",
        isImage : true
      },
      {
        id: "status",
        label: "Status",
        isButtonDisplay: true,
      },
      {
        id: "edit",
        label: "Update",
        edit_path :"/Update-Category",
        // id : "id",
        // handleEdit : (value) => {navigate("/Update-Category" , {state:{id:value?.id}})}
        // access: rights?.["Masters"]?.["edit"] == "true",
      },
    ],
    tableBody: [
      {
        category :"1",
        set_sequence :"1",
        subcategory_name :"78",
        status : "Active",
      },
      {
        category :"1",
        set_sequence :"1",
        subcategory_name :"79",
        status : "Active",
      }
    ],
    filterColumn: [],
  };
  const [tableData, setTableData] = useState({...tempTableData})

  useMemo(()=>{
    if(category_data?.statuscode == 200){
      const temp = tableData
      temp.tableBody = category_data?.data || []
      setTableData({...temp})
    }
  },[category_data])
  useMemo(()=>{
    if(message?.statuscode == 200){
      toast.success(message?.message);
      dispatch(all_category_list())
      dispatch({ type: CREATECATEGORY, payload: undefined })
    }
  },[message])
  return (
    <div >
      <Breadcrumbs
        parent="Category"
        title="Categories"
        mainTitle="Categories"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
              <ListTable
              tableData={tableData}
              key={"ListTable"}
              // setForm={setForm}
              setTableData={setTableData}
              // setIsEdit={setIsEdit}
              />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Category2;
