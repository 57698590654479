import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import * as Action from "../../../actions/offeres/promocode";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { IMAGE } from "../../../api";
import { CREATECATEGORY, PROMOCODE } from "../../../Constant";
import { Breadcrumbs, H5 } from "../../../AbstractElements";
import Form from "../../../utils/Form"
const CreatePromocode = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "newLOCS");
  const isEdit = location?.state?.isEdit;
  const createCategory = useSelector((state) => state);

  const {promocode_create,promocode_update } = bindActionCreators(
    Action,
    dispatch
  );


  const [file, setFile] = useState(null);
  const [form ,setForm] = useState({})

  useEffect(() => {
    if (location?.state?.form) {
      setForm((location?.state?.form && JSON.parse(location?.state?.form)))
      // const data = location?.state?.data;
      // setForm(data)
    }
  }, [location?.state?.form]);

  



  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    formStructure.map((element) => {
			if (element.type == "image" && form?.[element.name] && typeof (form?.[element.name]) != "string") {
				const temp = form;
				temp["temp" + element.name] = form?.[element.name];
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});
  
      if (isEdit) {
        const UpdatePromocodeApi = await promocode_update(data);
        if (UpdatePromocodeApi?.statuscode == 200) {
          toast.success(UpdatePromocodeApi?.message);
          setTimeout(() => {
            navigate("/promocode");
            dispatch({ type: PROMOCODE, payload: undefined });
          }, 1000);
          setForm({});
        } else {
          toast.error(UpdatePromocodeApi?.message);
        }
      }
       else {
        const createPromocodeApi = await promocode_create(data);
        if (createPromocodeApi?.statuscode == 200) {
          toast.success(createPromocodeApi?.message);
          setTimeout(() => {
            navigate("/promocode");
            dispatch({ type: PROMOCODE, payload: undefined });
          }, 1000);
          setForm({});
        } else {
          toast.error(createPromocodeApi?.message);
        }
      }
    } 


    const [formStructure, setFormStructure] = useState([
      {
        id: "1",
        type: "inputBox",
        title: "Promocode Title",
        name: "title",
        maxLength: "20",
        regex: /^[a-zA-Z0-9\s\&]+$/,
        required: true,
      },
  
      {
        id: "2",
        type: "inputBox",
        title: "Promocode",
        name: "promocode",
        maxLength: "15",
        regex: /^[a-zA-Z0-9]+$/,
        isCaps: true,
        required: true,
      },
      {
        id: "3",
        type: "select",
        title: "Select Promocode Type",
        name: "promocode_type",
        options: ["Fixed", "Percentage"],
      },
      {
        id: "4",
        type: "inputBox",
        // variant: "number",
        title: "Discount Amount",
        name: "promocode_value",
        regex: /^[0-9]+$/,
        required: true,
      },
      {
        id: "5",
        type: "inputBox",
        // variant: "number",
        title: "Minimum Amount",
        name: "minimum_amount",
        regex: /^[0-9]+$/,
        required: true,
      },
      {
        id: "6",
        type: "inputBox",
        variant: "date",
        title: "Expiry Date",
        min: new Date().toISOString().split("T")[0],
        default: " ",
        name: "expairy_date",
        required: true,
      },
  
      {
        id: "7",
        type: "inputBox",
        title: "No of user Limit",
        name: "limit",
        maxLength: "4",
        regex: /^[0-9]+$/,
        required: true,
      },
      {
        id: "8",
        type: "toggle",
        title: "Status",
        name: "status",
        default: "Active",
        display: isEdit ? "block" : "none",
        size: "6",
        options: [
          { value: "Active", color: "#00B69B" },
          { value: "Inactive", color: "#EE368C" },
        ],
      },
      {
        id: "8",
        type: "button",
        title: "Submit",
      },
    ]);
    useMemo(() => {
      if (form?.promocode_type == "Percentage") {
      const temp = formStructure
      temp[3]["title"] = "Discount Percentage"
      temp[3]["maxLength"] = "2"
      setFormStructure([...temp])
      }else{
      const temp = formStructure
      temp[3]["title"] = "Discount Amount"
      temp[3]["maxLength"] = "4"
      setFormStructure([...temp])
    }
    }, [form?.promocode_type]);
const formTitle = isEdit ? "Edit Promocode" : "Create Promocode";
  return (
    <div>
       <Breadcrumbs
        parent="Promocode"
        title={ isEdit ? "Edit Promocode":"Create Promocode"}
        mainTitle={isEdit ? "Edit Promocode":"Create Promocode"}
      />
  
              <Form
					handleSubmit={handleSubmit}
					formStructure={formStructure}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					// tableData={tableData}
					// setTableData={setTableData}
					isEdit={isEdit}
					// setIsEdit={setIsEdit}
				/>
            
    </div>
  );
};

export default CreatePromocode;
