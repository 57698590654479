// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import ListTable from "../../utils/Table";
import { all_category_list, category_delete } from "../../actions/category";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { CREATECATEGORY, PRODUCT, PRODUCTS } from "../../Constant";
import {
  all_product_list,
  delete_product,
  update_product,
} from "../../actions/product";
import { all_vendor_list } from "../../actions/vendor";


const AllProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const category_data = useSelector((state) => state?.category?.category_list);
  const login_details = JSON.parse(sessionStorage.getItem("user"));
  const role = login_details?.id?.userrole?.rolename;
  const message = useSelector((state) => state?.product?.product);
  console.log(category_data);
  useMemo(() => {
    dispatch(all_product_list());
    dispatch(all_category_list());
    dispatch(all_vendor_list());
  }, []);

  const VendorProduct = useSelector((state) => state?.product?.product_list);
  const AllProduct = useSelector((state) => state?.product?.product_list);
  const vendor_data = useSelector((state) => state?.vendor?.vendor_list);
  const tempTableData = {
    tableTitle: "Total Products",
    deleteRecord: delete_product,
    disableDelete: true,
    updateRecord: update_product,
    // deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    onDeleteText: "Are you sure want to delete?",
    onUpdateText:
      "Inactive this product will remove it from the product list and make it unavailable for purchase. Are you sure you want to proceed?",
    tableHead: [
      {
        id: "product_name",
        label: "Product Name",
        link: "/product_details",
      },
      {
        id: "created_by",
        label: "Ownership",
        subText :"uploaded_by_name"
      },
      {
        id: "category",
        label: "Category",
        // subText: "category",
      },
     
      // {
      //   id: "uploaded_by_name",
      //   label: "Uploaded By",
      //   subText: "company_name",
      // },
      {
        id: "packaging_size",
        label: "Packaging Size",

      },
      // {
      //   id: "image_0",
      //   label: "Image",
      //   isImage: true,
      // },
      {
        id: "quantity",
        label: "Available Quantity",
      },
      // {
      //   id: "price",
      //   label: "Actual Price",
      // },
      // {
      //   id : "discount",
      //   label:"Discount"
      // },
      //   {
      //     id : "discounted_price",
      //     label:"Discounted Price"
      //   },
      //   {
      //     id : "gst_price",
      //     label:"Gst Price"
      //   },
      {
        id: "final_price",
        label: "Selling Price",
      },
      // {
      //   id : "created_at",
      //   label:"Created At"
      // },
      {
        id: "status",
        label: "Status",
        // isButtonDisplay: true,
      },
      //   {
      //     id: "edit",
      //     label: "Update",
      //     edit_path :"/update-product",
      //     // id : "id",
      //     // handleEdit : (value) => {navigate("/Update-Category" , {state:{id:value?.id}})}
      //     // access: rights?.["Masters"]?.["edit"] == "true",
      //   },
    ],
    tableBody: [],
    filterColumn: [
      {
        id: "1",
        title: "Owneship",
        name: "created_by",
        options: ["Admin", "Vendor"],
      },
      {
        id: "2",
        title: "Category",
        name: "category",
        options: [],
      },
      { 
        id: "3", 
        title: "Vendor", 
        name: "uploaded_by_name", 
        options: ["Admin", "Vendor"] 
      },
    ],
  };
  const [tableData, setTableData] = useState({ ...tempTableData });

  useMemo(() => {
    if (VendorProduct?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = VendorProduct?.data?.map((ele) => ({
        ...ele,
        // category: ele?.category?.category_name,
        sub_category: ele?.subcategory?.sub_category_name,
        gst_rate: ele?.gst?.gst_rate,
        packaging_size : ele?.packaging_size +" "+ ele?.unit,
        // unit: ele?.unit?.unit_name,
        price: parseFloat(ele?.price).toFixed(2),
        discount: parseFloat(ele?.discount).toFixed(2),
        // ownership : ele?.created_by,
        uploaded_by_name:
          ele?.created_by == "Vendor"
            ? ele?.vendor
            : ele?.admin,
        company_name:
          ele?.created_by == "Vendor" ? ele?.vendor?.company_name : " - ",
      }));
      setTableData({ ...temp });
    }
  }, [VendorProduct]);
  useMemo(() => {
    if (message?.statuscode == 200) {
      toast.success(message?.message);
      dispatch(all_product_list());
      dispatch({ type: PRODUCT, payload: undefined });
    }
  }, [message]);
  console.log(category_data, "ddffd");
  useMemo(() => {
    if (category_data?.statuscode == 200) {
      console.log("chchc");
      const tempFilter = tableData;
      tempFilter["filterColumn"][1]["options"] = category_data?.data.map(
        (category) => category?.category_name
      );

      setTableData({ ...tempFilter });
    }
  }, [category_data]);
  useMemo(() => {
    if (vendor_data?.statuscode == 200) {
      const tempFilter = tableData;
      tempFilter["filterColumn"][2]["options"] = vendor_data?.data?.map(
        (vendor) => vendor?.vendor_name
      );

      setTableData({ ...tempFilter });
    }
  }, [vendor_data]);
  return (
    <div>
      <Breadcrumbs
        parent="Product"
        title="Product"
        mainTitle="Total Products"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              {/* <CardBody> */}
              <ListTable
                tableData={tableData}
                key={"ListTable"}
                // setForm={setForm}
                setTableData={setTableData}
                // setIsEdit={setIsEdit}
              />
              {/* </CardBody> */}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AllProduct;
