// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../AbstractElements";
import pic1 from "../../Images/download.png";
import pic2 from "../../Images/photo.png";
import * as TagAction from "../../actions/tags";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { IMAGE } from "../../api";
import SweetAlert from 'sweetalert2';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CREATECATEGORY, TAGS } from "../../Constant";

const Tags = () => {
  const dispatch = useDispatch();
  const [tagsData, setTagsData] = useState([]);
  const {tag_list , tags_delete } = bindActionCreators(
    TagAction,
    dispatch
  );
  useEffect(() => {
    (async () => {
      // const data = new FormData();
      // data.append("user", producer_id);
      // dispatch(bank_detail_list(data));
      const resData = await tag_list();
      setTagsData(resData);
    })();
  }, []);
  console.log(tagsData);
  const deleteTask = async(userId) => {
    SweetAlert.fire({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this Tags!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'cancel',
        reverseButtons: true
    }).then(async (result) => {
        if (result.value) {
            // RemoveTask(userId);
            const fData = new FormData()
            fData.append("id",userId)

            const deleteApi = await tags_delete(fData)
            console.log(deleteApi,"fdfzfz")
            if(deleteApi?.statuscode == 200){
              toast.success(deleteApi?.message);
              setTimeout(async () => {
                  const resData = await tag_list();
                  setTagsData(resData);
                dispatch({ type: TAGS, payload: undefined });
              }, 1000);
            }else{
              toast.error(deleteApi?.message);
            }
            console.log(result,result.value,deleteApi,userId,"nfsdf")
            SweetAlert.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
            );
        } else {
            SweetAlert.fire(
                'Your imaginary file is safe!'
            );
        }
    });
};
  const productColumns = [
    {
      name: "Tag Name",
      selector: (row) => row.Details,
      sortable: true,
      // center: true,
      wrap: true,
      minWidth: "350px",
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.Status,
    //   sortable: false,
    //   center: true,
    //   minWidth: "200px",
    //   maxWidth: "250px",
    // },
    // {
    //   name: 'Stock',
    //   selector: (row) => row.stock,
    //   sortable: true,
    //   center: true,
    //   minWidth: '120px',
    //   maxWidth: '150px',
    // },
    // {
    //   name: 'Start_date',
    //   selector: (row) => row.start_date,
    //   sortable: true,
    //   center: true,
    //   minWidth: '120px',
    //   maxWidth: '150px',
    // },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      center: true,
      minWidth: "160px",
      maxWidth: "160px",
    },
  ];
  const style = {
    width: 55,
    height: 40,
    borderRadius: 5,
  };
  const style2 = { width: 60, fontSize: 14, padding: 4 };
  const ProductList =
    tagsData &&
    tagsData?.data?.map((value ,index) => ({
     
      Details: (
        <div>
          <H6>{value?.tag_name}</H6>
        </div>
      ),
    
      action: (
        <div>
          <span>
            <Btn
              attrBtn={{
                style: style2,
                color: "danger",
                className: "btn btn-xs",
                type: "button",
                onClick : () => deleteTask(value?.id)
              }}
             
            >
              Delete
            </Btn>
          </span>{" "}
          &nbsp;&nbsp;
          <span>
            <Btn
              attrBtn={{
                style: style2,
                color: "success",
                className: "btn btn-xs",
                type: "button",
              }}
            >
             <Link style={{color:"inherit"}} to={"/Update-Tags"} state={{data :tagsData?.data[index] , isEdit: true}}>Edit</Link>
            </Btn>
          </span>
        </div>
      ),
    }));

  // amount: '$10',
  // stock: <div className='font-success'>In Stock</div>,
  // start_date: '2022/4/19',

  // ]
  return (
    <div className="table-responsive product-table">
      <Breadcrumbs
        parent="Tags"
        title="Tags List"
        mainTitle="Tags List"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  noHeader
                  pagination
                  paginationServer
                  columns={productColumns}
                  data={ProductList}
                  highlightOnHover={true}
                  striped={true}
                  responsive={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Tags;
