import * as api from '../api/index';
import { EMPLOYEE_TASKS, MANAGER_PROJECTS } from '../Constant';



export const all_task_list = (formData) => async (dispatch) => {
    try {
        const { data } = await api.user_project_list(formData);
        dispatch({ type: EMPLOYEE_TASKS, payload: data });
        return data


    } catch (error) {
        console.log(error);
    }
};
export const all_projects_of_manager = (formData) => async (dispatch) => {
    try {
        const { data } = await api.manager_project_list(formData);
        dispatch({ type: MANAGER_PROJECTS, payload: data });
        return data


    } catch (error) {
        console.log(error);
    }
};