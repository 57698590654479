import * as actionType from '../Constant';


const AuthReducer = (state = { company: null, change_password: null, message: null }, action) => {
    switch (action.type) {
        case actionType.COMPANY_LOGIN:
            return { ...state, company: action.payload };
        case actionType.CHANGE_PASSWORD:
            return { ...state, change_password: action.payload, company: null };
        case actionType.MESSAGE:
            return { ...state, message: action.payload };
        case actionType.RESET:
            return { ...state, message: null, change_password: null };

        default:
            return state;
    }
};

export default AuthReducer;
