
import { TAGS,TAGSLIST } from '../Constant/index.jsx';
import * as api from '../api/index.jsx';

export const tags_create  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.tags_create(formData);
      dispatch({ type: TAGS, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };

  export const tags_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.tags_update(formData);
      dispatch({ type: TAGS, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };
  export const tags_delete  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.tags_delete(formData);
      dispatch({ type: TAGS, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };
  export const tag_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.tag_list(formData);
      
      dispatch({ type: TAGSLIST, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };
  