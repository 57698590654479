import React from "react";
import { RecentOrderTable } from "../../../Data/Ecommerce";
import { Link } from "react-router-dom";
import { TabContent, Table, TabPane } from "reactstrap";
import { Image, H6 } from "../../../AbstractElements";
import SvgIcon from "../../Common/Component/SvgIcon";
import { IMAGE } from "../../../api";
const RecentOrderContentTab = ({
  RecentOrdersNav,
  isActive,
  show,
  productData,
}) => {
  return (
    <div className="recent-table table-responsive">
      
      <Table>
        <thead>
          <tr>
            <th className="f-light">Order ID</th>
            <th className="f-light">Image</th>
            <th className="f-light">Product</th>
            <th className="f-light">User</th>
            <th className="f-light">Contact No.</th>
            <th className="f-light">Email</th>
            <th className="f-light">Qty</th>
            <th className="f-light">Price</th>
            {/* <th className="f-light">Status</th> */}
            <th className="f-light">Total Price</th>
          </tr>
        </thead>
        <tbody>
          {productData &&
            productData?.map((item, j) => (
              console.log("item" ,item),
              <tr key={j}>
                 <td className="f-w-500">
                  {
                    <div>
                      <H6 attrH6={{ className: "f-14 mb-0" }}>
                        <Link
                          to={`/order-details`}
                          state={{ id: item?.orderID }}
                          style={{color:"inherit"}}
                        >
                          {item?.order_id}
                        </Link>
                      </H6>
                      {/* <span className='f-light f-12'>Id : {item.id}</span> */}
                    </div>
                  }
                </td>
                <td>
                  <div className="product-content">
                    <div className="">
                      <img
                        src={IMAGE + item?.image}
                        alt="product"
                        height="30px"
                        width="30px"
                      />
                    </div>
                  </div>
                </td>
                <td className="f-w-500">
                  {
                    <div>
                      {/* <H6 attrH6={{ className: "f-14 mb-0" }}> */}
                        <Link
                          to={`/product_details`}
                          state={{ id: item?.product_id }}
                          style={{color:"inherit"}}


                        >
                          {item?.product}
                        </Link>
                      {/* </H6> */}
                      {/* <span className='f-light f-12'>Id : {item.id}</span> */}
                    </div>
                  }
                </td>
                <td className="f-w-500">
                  {
                    <div>
                      {/* <H6 attrH6={{ className: "f-14 mb-0" }}> */}
                        <Link
                          to={`/customer/details`}
                          state={{ id: item?.user_id }}
                          style={{color:"inherit"}}

                        >
                          {item?.user}
                        </Link>
                      {/* </H6> */}
                      {/* <span className='f-light f-12'>Id : {item.id}</span> */}
                    </div>
                  }
                </td>
                <td className="f-w-500">{item.mobile_number}</td>
                <td className="f-w-500">{item.email}</td>
                <td className="f-w-500">{item.quantity}</td>
                <td className="f-w-500">₹ {item.purchased_product_price}</td>
                <td className="f-w-500">₹ {item.purchased_product_price *item.quantity }</td>
                {/* <td className="f-w-500">
                  <div className={`recent-status font-${item.statusCode}`}>
                    <SvgIcon iconId={item.iconName} className="me-1" />
                    {item.status}
                  </div>
                </td> */}
                {/* <td className="f-w-500">${item.purchased_product_price}</td> */}
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default RecentOrderContentTab;
