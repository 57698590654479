import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { IMAGE } from "../../api";
import { CREATE_EMPLOYEES, CREATECATEGORY, UPDATE_WORK_ASSIGN } from "../../Constant";
import { Breadcrumbs, H5 } from "../../AbstractElements";
import * as Action from "../../actions/workAssign";
import Form from "../../utils/Form"
import { company_services_list } from "../../actions/companyServices";
import { company_user_list } from "../../actions/employee";
import { all_client_list } from "../../actions/client";
import { company_project_list } from "../../actions/project";
const UpdateWorkAssign = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "newLOCS");
  const isEdit = location?.state?.isEdit;
  const user = useSelector((state) => state?.layout?.user);
  const Employees = useSelector((state) => state?.employees?.employee_list);
  const Client = useSelector((state) => state?.client?.client_list);
  console.log(Employees, "test");
  const [form, setForm] = useState({});
  const { project_work_assign_update } = bindActionCreators(Action, dispatch);

  useEffect(() => {
    if (location?.state) {
      const data = location?.state?.data;
      setForm({
        ...data,
        team_member : data?.team_member?.map((ele)=>ele?.name),
        service : data?.service?.service_name,
        project_id : data?.project?.id
        
        // service: data?.service?.map((ele) => ele?.service_name),
      });
    }
  }, [location?.state]);
  useEffect(() => {
    if (user) {
      const data = new FormData();
      data.append("company", user?.company);
      dispatch(company_services_list(data));
      dispatch(company_user_list(data));
      dispatch(all_client_list(data));
      dispatch(company_project_list(data));
    }
  }, [user]);
  const Company_services_list = useSelector(
    (state) => state?.company_services?.company_services_list
  );
  const Project_list = useSelector((state) => state?.project?.project_list);
    const [formStructure ,setFormStructure] = useState([
        {
            id: "1",
            type: "inputBox",
            title: "Service",
            name: "service",
            required: true,
            disabled: true,
          },
          {
            id: '2',
            type: "select_multiple",
            title: "Select Members For Service",
            name: "team_member",
            // options: Employees && Employees?.data?.map((ele) => ele?.name),
            options:["parth","p[art"],
            required: true,
          },
          {
            id: "3",
            type: "inputBox",
            variant: "date",
            title: 'Start Date For Service',
            default: " ",
            min: new Date().toISOString().split("T")[0],
            name: "start_date",
            required: true,
          },
          {
            id: '4',
            type: "inputBox",
            variant: "date",
            title: "End Date For Service",
            default: " ",
            min: new Date().toISOString().split("T")[0],
            name: "end_date",
            required: true,
          },
          {
            id: '5',
            type: "description",
            title: "Description For Service",
            name: "description",
            required: true,
          },
        {
          id: "6",
          type: "button",
          title: "Create",
        },
      ]);

    useMemo(() => {
        if (Employees?.statuscode == 200) {
          console.log(Employees, "newc2");
    
          const temp = formStructure;
          temp[1]["options"] = Employees?.data?.map((ele) => ele?.name);
          setFormStructure([...temp]);
          console.log(formStructure);
        }
      }, [Employees]);
      const formTitle = "Edit Category" ;

      const handleSubmit = async(event) => {
        event.preventDefault();
        const temp_form = form;
        temp_form["temp_service"] = form?.["service"]
        temp_form["temp_team_member"] = form?.["team_member"]
        temp_form["service"] = Company_services_list?.data?.map(
            (option) =>
              form?.["service"]?.includes(option?.service_name) && option?.id
          )
          .filter((e) => e)[0];
        temp_form["team_member"] = Employees?.data?.map(
            (option) =>
              form?.["team_member"]?.includes(option?.name) && option?.id
          )
          .filter((e) => e);
        setForm({
          ...temp_form,
        });
        const data = new FormData();
        Object.keys(form).map((key) => data.append(key, form?.[key]));
        // data.append("")
        formStructure.map((element) => {
                if (element.type == "image" && form?.[element.name] && typeof (form?.[element.name]) != "string") {
                    const temp = form;
                    temp["temp" + element.name] = form?.[element.name];
                    temp[element.name] = URL.createObjectURL(form?.[element.name]);
    
                    setForm({
                        ...temp,
                    });
                }
            });   
            const UpdateWorkAssignyApi = await project_work_assign_update(data);  
            if (UpdateWorkAssignyApi?.statuscode == 200) {
              toast.success(UpdateWorkAssignyApi?.message);
              setTimeout(() => {
                navigate("/team-leader-projects/view-team-member" , {state:{project_name : form?.project_name , id : form?.project_id }});
                dispatch({ type: UPDATE_WORK_ASSIGN, payload: undefined });

              }, 1000);
              setForm({});
            } else {
              toast.error(UpdateWorkAssignyApi?.message);
            }
          }
         
        
  return (
    <>
       <Breadcrumbs
        parent="Employees"
        title={ "Edit Employees"}
        mainTitle={ "Edit Employees"}
      />
   <Form
        handleSubmit={handleSubmit}
        formStructure={formStructure}
        formTitle={formTitle}
        key={"Form"}
        setForm={setForm}
        form={form}
        // tableData={tableData}
        // setTableData={setTableData}
        // isEdit={isEdit}
        // setIsEdit={setIsEdit}
      />

            
    </>
  );
};

export default UpdateWorkAssign;
