import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import * as Action from "../../actions/category";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { IMAGE } from "../../api";
import { CREATECATEGORY } from "../../Constant";
import { Breadcrumbs, H5 } from "../../AbstractElements";
import Form from "../../utils/Form"
const CreateCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "newLOCS");
  const isEdit = location?.state?.isEdit;
  const createCategory = useSelector((state) => state);

  const { category_create, category_update } = bindActionCreators(
    Action,
    dispatch
  );


  const [file, setFile] = useState(null);
  const [form ,setForm] = useState({})

  useEffect(() => {
    if (location?.state?.form) {
      setForm((location?.state?.form && JSON.parse(location?.state?.form)))
      // const data = location?.state?.data;
      // setForm(data)
    }
  }, [location?.state?.form]);

  



  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    formStructure.map((element) => {
			if (element.type == "image" && form?.[element.name] && typeof (form?.[element.name]) != "string") {
				const temp = form;
				temp["temp" + element.name] = form?.[element.name];
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});
  
      if (isEdit) {
        const UpdateCategoryApi = await category_update(data);
        if (UpdateCategoryApi?.statuscode == 200) {
          toast.success(UpdateCategoryApi?.message);
          setTimeout(() => {
            navigate("/Category");
            dispatch({ type: CREATECATEGORY, payload: undefined });
          }, 1000);
          setForm({});
        } else {
          toast.error(UpdateCategoryApi?.message);
        }
      }
       else {
        const createcategoryApi = await category_create(data);
        if (createcategoryApi?.statuscode == 200) {
          toast.success(createcategoryApi?.message);
          setTimeout(() => {
            navigate("/Category");
            dispatch({ type: CREATECATEGORY, payload: undefined });
          }, 1000);
          setForm({});
        } else {
          toast.error(createcategoryApi?.message);
        }
      }
    } 


 const formStructure=[
    {
        id:"1",
        type:"inputBox",
        maxLength:30,
        title:"Category Name",
        name:"category_name",
        regex : /^[a-zA-Z\s\&]+$/,
        isCaps:true,
        required:true
    },
    {
        id:"1",
        type:"inputBox",
        maxLength:30,
        title:"Color Code",
        name:"color_code",
        // regex : /^[a-zA-Z\s\&]+$/,
        isCaps:true,
        required:true
    },
    // {
    //     id:"2",
    //     type:"select",
    //     title:"Display Type",
    //     name:"display_type",
    //     options:["Portrait","Landscape"]
    // }
    // ,
    {
        id:"2",
        type:"lockedInput",
        variant:"number",
        min:0,
        max:99,
        title:"Sequence",
        display: isEdit? "block" : "none",
        name:"set_sequence",
        required:true
    }
    ,
    {
      id: "3",
      type: "toggle",
      title: "Status",
      name: "status",
      default: "Active",
      display: isEdit? "block" : "none",
      size: "6",
      options: [{ value: "Active", color: "#00B69B" }, { value: "Inactive", color: "#EE368C" }],
  },
    {
        id:"4",
        type:"image",
        title:"Upload Image",
        subtitle:"(Resolution : 512px x 512px) *",
        subsubtitle:"Max File Size 1MB",
        subsubsubtitle:"Support only JPG,PNG,JPEG",
        name:"category_image"
    }
    ,
   
    ,
    {
        id:"5",
        type:"button",
        title:"Submit",
        
    }
]
const formTitle = isEdit ? "Edit Category" : "Create Category";
  return (
    <div>
       <Breadcrumbs
        parent="Category"
        title={ isEdit ? "Edit Category":"Create Category"}
        mainTitle={isEdit ? "Edit Category":"Create Category"}
      />
  
              <Form
					handleSubmit={handleSubmit}
					formStructure={formStructure}
					formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					// tableData={tableData}
					// setTableData={setTableData}
					isEdit={isEdit}
					// setIsEdit={setIsEdit}
				/>
            
    </div>
  );
};

export default CreateCategory;
