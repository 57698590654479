import React, { Fragment, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn, H4, H6, P, Image } from '../../../AbstractElements';
import logoWhite from '../../../assets/images/logo/logo.png';
// import logoDark from '../../../assets/images/logo/logo_dark.png';
import AlertMessage from '../../Common/Component/AlertMessage';
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from '../../../actions/AuthAction';

const NewPassword = ({ logoClassMain }) => {
    const [togglePassword, setTogglePassword] = useState(false);
    const [toggleNewPassword, setToggleNewPassword] = useState(false);
    const [toggleConfirmPassword, setToggleConfirmPassword] = useState(false);
    const location = useLocation()
    const authStore = useSelector((state) => state?.auth?.change_password)
    const [message, setMessage] = useState(null)
    console.log(location?.state);
    
    const [form, setForm] = useState({
        user_type: location?.state?.userrole?.rolename,
        password_flag: "change",
        email: location?.state?.email
    })
    const [open, setOpen] = useState(false)
    const [type, setType] = useState(null)
    const dispatch = useDispatch()
    const navigation = useNavigate()
    console.log("authStore", authStore)

    useMemo(() => {
        if (authStore?.statuscode == 200) {
            setType("success")
            setOpen(true)
            setMessage(authStore?.message)
            setTimeout(() => {
                navigation('/login', { replace: true })
            }, 2000)
        }
        else if (authStore && authStore?.statuscode !== 200) {
            setType("error")
            setOpen(true)
            setMessage(authStore?.message)
        }
    }, [authStore])

    if (open) {
        setTimeout(() => {
            setOpen(false)
        }, 2000)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (form.new_password == form.confirm_password) {
            console.log(form)
            dispatch(changePassword(form))

        }
        else {
            setType("error")
            setOpen(true)
            setMessage("Password and confirm password must be same")
        }

    }
    return (
        <Fragment>
            <AlertMessage message={message} open={open} type={type} />
            <section>
                <Container className='p-0 login-page' fluid={true}>
                    <Row className='m-0'>
                        <Col className='p-0'>
                            <div className='login-card'>
                                <div>
                                    <div>
                                        <Link className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL}>
                                            <Image attrImage={{ className: 'img-fluid for-light', style:{height:"70px"}, src: logoWhite, alt: 'looginpage' }} />
                                            <Image attrImage={{ className: 'img-fluid for-dark', style:{height:"70px"}, src: logoWhite, alt: 'looginpage' }} />
                                        </Link>
                                    </div>
                                    <div className='login-main'>
                                        <Form className='theme-form login-form' onSubmit={handleSubmit}>
                                            <H4>Create Your Password</H4>
                                            {/* <H6 attrH6={{ className: 'mt-4' }}>Create Your Password</H6> */}
                                            <FormGroup className='position-relative'>
                                                <Label className='col-form-label m-0'>Old Password</Label>
                                                <div className='position-relative'>
                                                    <Input onChange={(e) => setForm({ ...form, old_password: e.target.value })} className='form-control' type={togglePassword ? 'text' : 'password'} name='login[password]' required placeholder='*********' />
                                                    <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
                                                        <span className={togglePassword ? '' : 'show'}></span>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                            <FormGroup className='position-relative'>
                                                <Label className='col-form-label m-0'>New Password</Label>
                                                <div className='position-relative'>
                                                    <Input onChange={(e) => setForm({ ...form, new_password: e.target.value })} className='form-control' type={toggleNewPassword ? 'text' : 'password'} name='login[password]' required placeholder='*********' />
                                                    <div className='show-hide' onClick={() => setToggleNewPassword(!toggleNewPassword)}>
                                                        <span className={toggleNewPassword ? '' : 'show'}></span>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                            <FormGroup className='position-relative'>
                                                <Label className='col-form-label m-0'>Retype Password</Label>
                                                <div className='position-relative'>
                                                    <Input onChange={(e) => setForm({ ...form, confirm_password: e.target.value })} className='form-control' type={toggleConfirmPassword ? 'text' : 'password'} name='login[password]' required placeholder='*********' />
                                                    <div className='show-hide' onClick={() => setToggleConfirmPassword(!toggleConfirmPassword)}>
                                                        <span className={toggleConfirmPassword ? '' : 'show'}></span>
                                                    </div>
                                                </div>
                                            </FormGroup>


                                            <FormGroup>
                                                <Btn attrBtn={{ color: 'primary', className: 'btn d-block w-100', type: 'submit' }}>Done</Btn>
                                            </FormGroup>

                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    );
};

export default NewPassword;
