import {  CLIENT, CREATE_CLIENT } from '../Constant';
import * as api from '../api/index';

export const client_create  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.client_create (formData);
      dispatch({ type: CREATE_CLIENT, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };
export const all_client_list  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_client_list (formData);
      dispatch({ type:CLIENT, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };