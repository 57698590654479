import * as actionType from '../Constant';


const transactionReducer = (state = { transactionData: null }, action) => {
  switch (action.type) {
    case actionType.TRANSACTION_LIST:
      return { ...state, transaction_list: action.payload };

    default:
      return state;
  }
};

export default transactionReducer;
