import { EXPIRED_PROMOCODE_LIST, PROMOCODE, PROMOCODE_HISTORY, PROMOCODE_LIST } from "../../Constant";
import * as api from '../../api/index';

export const promocode_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.promocode_create(formData);
      dispatch({ type: PROMOCODE, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const promocode_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.promocode_update(formData);
      dispatch({ type: PROMOCODE, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const promocode_delete = (formData) => async (dispatch) => {
    try {
      const { data } = await api.promocode_delete(formData);
      dispatch({ type: PROMOCODE, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const all_promocode_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_promocode_list(formData);
      dispatch({ type: PROMOCODE_LIST, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const all_expired_promocode_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_expired_promocode_list(formData);
      dispatch({ type: EXPIRED_PROMOCODE_LIST, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const all_promocode_history = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_promocode_history(formData);
      dispatch({ type: PROMOCODE_HISTORY, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };