import * as actionType from '../Constant';


const workerReducer = (state = { workerData: null }, action) => {
  switch (action.type) {
    case actionType.WORKER_DETAILS:
      return { ...state, worker_details_data: action.payload }
    default:
      return state;
  }
};

export default workerReducer;
