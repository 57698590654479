import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as BookingAction from '../../actions/booking'
import { Breadcrumbs, H6 } from '../../AbstractElements'
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
const VendorAssignedBooking = () => {
    const dispatch = useDispatch()
    const { vendor_assign_worker_booking_request_list } = bindActionCreators(BookingAction, dispatch)
    const [bookingList, setBookingList] = useState()
    const vendor = JSON.parse(sessionStorage.getItem('user'))
    useEffect(() => {
        (async () => {
            const resData = await vendor_assign_worker_booking_request_list({id: vendor?.data?.id})
            console.log("ResData: ", resData)
            setBookingList(resData)
        })();
    }, [])
    const productColumns = [
        {
            name: "Name",
            selector: (row) => row.Name,
            sortable: true,
            // center: true,
        },
        {
            name: "Contact",
            selector: (row) => row.Basic,
            sortable: true,
            // center: true,
        },
        {
            name: "Workers",
            selector: (row) => row.TotalWorker,
            sortable: true,
            // center: true,
        },
        {
            name: "Location",
            selector: (row) => row.Address,
            sortable: true,
            // center: true,
        },
        {
            name: "From",
            selector: (row) => row.StartDate,
            sortable: true,
            // center: true,
        },
        {
            name: "To",
            selector: (row) => row.EndDate,
            sortable: true,
            // center: true,
        },

    ];
    const ProductList =
    bookingList &&
    bookingList?.data?.map((value, index) => ({
        Name: (
            <div>
                <H6>{value?.booking_request?.name}</H6>
            </div>
        ),
        Basic: (
            <div>
                <p>{value?.booking_request?.email}</p>
                <p>{value?.booking_request?.contact}</p>
            </div>
        ),
        TotalWorker: (
            <div>
                <p>{value?.worker?.length} Workers</p>
            </div>
        ),
        Address: (
            <div>
                {/* <p>{value?.address}</p>
                <p>{value?.city}</p>
                <p>{value?.pincode}</p> */}
                <p>{value?.booking_request?.house_no} - {value?.booking_request?.residence_name}</p>
                <p>{value?.booking_request?.area_or_street},{value?.booking_request?.city?.city_name}</p>
            </div>
        ),
        StartDate: (
            <div>
                <p>{value?.from_date}</p>
            </div>
        ),
        EndDate: (
            <div>
                <p>{value?.to_date}</p>
            </div>
        ),
        worker: value?.worker

    }));

    const ExpandedComponent = ({ data }) =>
// console.log(data?.worker,"ShoeSdaa")
        (
            <div style={{ width: "100%", border: "1px solid grey" }}>
                <Table striped>
                    <thead className='expand-table'>
                        <tr className="table-secondary" style={{ fontWeight: "900" }}>
                            <th>
                                #
                            </th>
                            <th style={{ fontWeight: "900" }}>
                                Worker Name
                            </th>
                            {/* <th style={{ fontWeight: "900" }}>
                                Owner Name
                            </th> */}
                            <th style={{ fontWeight: "900" }}>
                                Email
                            </th>
                            <th style={{ fontWeight: "900" }}>
                                Mobile
                            </th>
                            {/* <th style={{ fontWeight: "900" }}>
                                Assigned Workers
                            </th> */}
    
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.worker?.map((data, index) => (
                                // console.log(data?.worker?.firstname, "fhghgfhfh"),
                                <tr>
                                    <th scope="row">
                                        {index + 1}
                                    </th>
                                    <td>
                                        <Link to={"/Worker/Worker-Details"} state={{id:data?.worker?.id}}>{data?.worker?.firstname + " " + data?.worker?.lastname}</Link>
                                    </td>
                                    {/* <td>
                                        {data?.worker?.contact_person_name}
                                    </td> */}
                                    <td>
                                        {data?.worker?.email}
                                    </td>
    
                                    <td>
                                        {data?.worker?.mobile_number}
                                    </td>
                                    {/* <td>
                                        {data?.total_worker} Workers
                                    </td> */}
    
                                </tr>
                            ))
                        }
    
    
                    </tbody>
                </Table>
    
                {/* Add more details as needed */}
            </div>
        );
  return (
    <div className="table-responsive product-table">
    <Breadcrumbs
        parent="Booking"
        title="Assigned Booking"
        mainTitle="Assigned Bookings"
    />
    <Container fluid={true}>
        <Row>
            <Col sm="12">
                <Card>
                    <CardBody>
                        <DataTable
                            noHeader
                            pagination
                            paginationServer
                            columns={productColumns}
                            data={ProductList}
                            highlightOnHover={true}
                            striped={true}
                            responsive={true}
                            expandableRows
                            expandableRowsComponent={ExpandedComponent}
                            className="custom-data-table"

                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </Container>
</div>
  )
}

export default VendorAssignedBooking