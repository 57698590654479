import {  EXPIRE_SLIDER_BANNER_LIST, SLIDER_BANNER, SLIDER_BANNER_LIST } from "../Constant";
import * as api from '../api/index.jsx';


export const slider_banner_create = (formData) => async (dispatch) => {
    try {
      const { data } = await api.slider_banner_create(formData);
      
      dispatch({ type: SLIDER_BANNER, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };
  
export const all_slider_banner_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_slider_banner_list(formData);
      
      dispatch({ type: SLIDER_BANNER_LIST, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };
  
export const slider_banner_update = (formData) => async (dispatch) => {
    try {
      const { data } = await api.slider_banner_update(formData);
      
      dispatch({ type: SLIDER_BANNER, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };
  
export const slider_banner_delete = (formData) => async (dispatch) => {
    try {
      const { data } = await api.slider_banner_delete(formData);
      
      dispatch({ type: SLIDER_BANNER, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };
  
export const all_expired_slider_banner_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_expired_slider_banner_list(formData);
      
      dispatch({ type: EXPIRE_SLIDER_BANNER_LIST, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };
  