import * as api from '../api/index';
import {
  CHANGE_PASSWORD,
  COMPANY_LOGIN,
  MESSAGE
} from '../Constant';


export const companyLogin = (formData) => async (dispatch) => {
  try {
    const { data } = await api.company_login(formData);
    dispatch({ type: COMPANY_LOGIN, payload: data });
    return data


  } catch (error) {
    console.log(error);
  }
};
export const employeeLogin = (formData) => async (dispatch) => {
  try {
    const { data } = await api.vendor_login(formData);
    dispatch({ type: COMPANY_LOGIN, payload: data });
    return data


  } catch (error) {
    console.log(error);
  }
};

export const changePassword = (formData) => async (dispatch) => {
  try {
    const { data } = await api.change_password(formData);
    dispatch({ type: CHANGE_PASSWORD, payload: data });
    return data


  } catch (error) {
    console.log(error);
  }
};

export const sendOtp = (formData) => async (dispatch) => {
  try {
    const { data } = await api.send_otp(formData);
    dispatch({ type: MESSAGE, payload: data });
    return data


  } catch (error) {
    console.log(error);
  }
};
export const verifyOtp = (formData) => async (dispatch) => {
  try {
    const { data } = await api.verify_otp(formData);
    dispatch({ type: MESSAGE, payload: data });


  } catch (error) {
    console.log(error);
  }
};
export const update_password = (formData) => async (dispatch) => {
  try {
    const { data } = await api.update_password(formData);
    dispatch({ type: CHANGE_PASSWORD, payload: data });


  } catch (error) {
    console.log(error);
  }
};