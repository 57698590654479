import {  CREATE_WORK_ASSIGN, PROJECT_WORK_ASSIGN, UPDATE_WORK_ASSIGN } from '../Constant';
import * as api from '../api/index';

export const project_work_assign_create  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.project_work_assign_create (formData);
      dispatch({ type: CREATE_WORK_ASSIGN, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };
export const company_project_details  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.company_project_details (formData);
      dispatch({ type: PROJECT_WORK_ASSIGN, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };
  export const project_work_assign_update  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.project_work_assign_update (formData);
      dispatch({ type: UPDATE_WORK_ASSIGN, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };