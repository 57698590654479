import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import * as Action from "../../actions/category";
import * as SubCatAction from "../../actions/vendor";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { IMAGE } from "../../api";
import { CREATECATEGORY, SUBCATEGORY, VENDOR } from "../../Constant";
import { Breadcrumbs, H5 } from "../../AbstractElements";
import Form from "../../utils/Form"
import { all_city_list } from "../../actions/city";
const CreateVendor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location, "newLOCS");
  const isEdit = location?.state?.isEdit;
  const [categoryData , setCategoryData ] = useState([])
  const cityList = useSelector((state)=> state?.city?.city_list)
  const { all_category_list} = bindActionCreators(
    Action,
    dispatch
  );
  const {vendor_register, vendor_update} = bindActionCreators(
    SubCatAction,
    dispatch
  ); 

  const [file, setFile] = useState(null);
  const [form ,setForm] = useState({})

  useEffect(() => {
    if (location?.state) {
      const data = location?.state?.data;
      setForm((location?.state?.form && JSON.parse(location?.state?.form)))
    }
  }, [location?.state]);
  useEffect(() => {
    (async () => {
      // const data = new FormData();
      // data.append("user", producer_id);
      // dispatch(bank_detail_list(data));
      const resData = await all_category_list();
      dispatch(all_city_list())
      if(resData?.statuscode == 200){

        setCategoryData(resData?.data);
      }
    })();
  }, []);
console.log(categoryData,"dsfsd")
  const [formStructure, setFormStructure] = useState([

    {
        id: "1",
        type: "inputBox",
        title: "Company Name",
        name: "company_name",
        maxLength: 50,
        regex: /^[a-zA-Z0-9\s]+$/,
        // isCapitalise: true,
        required: true,
      },
      {
          id: "2",
          type: "inputBox",
          title: "Vendor Name",
          name: "vendor_name",
          maxLength: 50,
          regex: /^[a-zA-Z\s]+$/,
          // isCapitalise: true,
          required: true,
        },
    {
        id: "3",
        type: "inputBox",
        title: "Email",
        name: "email",
        // maxLength: 30,
        // regex: /^[a-zA-Z\s]+$/,
        // isCapitalise: true,
        required: true,
      },
      {
        id: "4",
        type: "phone",
        title: "Mobile Number",
        maxLength: 12,
        name: "mobile_number",
        required: true,
      },
      {
        id: "5",
        type: "inputBox",
        title: "Gst Number",
        name: "gst_number",
        maxLength: 15,
        // regex: /^[a-zA-Z\s]+$/,
        // isCapitalise: true,
        required: true,
      },
      {
        id: "6",
        type: "inputBox",
        title: "Address",
        name: "address",
        // maxLength: 30,
        // regex: /^[a-zA-Z\s]+$/,
        // isCapitalise: true,
        required: true,
      },
      {
        id: "7",
        type: "inputBox",
        title: "Commission On 100₹ To 500₹",
        name: "comission_profit_100_to_500",
        maxLength: 2,
        regex: /^[0-9\s]+$/,
        // isCapitalise: true,
        required: true,
      },
      {
        id: "8",
        type: "inputBox",
        title: "Commission On 501₹ To 1000₹",
        name: "comission_profit_501_to_1000",
        maxLength: 3,
        regex: /^[0-9\s]+$/,
        // isCapitalise: true,
        required: true,
      },
      {
        id: "9",
        type: "inputBox",
        title: "Commission On 1001₹ To 5000₹",
        name: "comission_profit_1001_to_5000",
        maxLength: 3,
        regex: /^[0-9\s]+$/,
        // isCapitalise: true,
        required: true,
      },
      {
        id: "10",
        type: "inputBox",
        title: "Commission On 5001₹ To 1000₹",
        name: "comission_profit_5001_to_10000",
        maxLength: 4,
        regex: /^[0-9\s]+$/,
        // isCapitalise: true,
        required: true,
      },
      {
        id: "11",
        type: "inputBox",
        title: "Commission On 10001₹ Or Above",
        name: "comission_profit_10001_to_unlimited",
        maxLength: 5,
        regex: /^[0-9\s]+$/,
        // isCapitalise: true,
        required: true,
      },
    //   {
    //     id: "7",
    //     type: "inputBox",
    //     title: "Street Name",
    //     name: "street_name",
    //     // maxLength: 30,
    //     // regex: /^[a-zA-Z\s]+$/,
    //     // isCapitalise: true,
    //     required: true,
    //   },
    //   {
    //     id: "8",
    //     type: "select",
    //     title: "Select City",
    //     name: "city",
    //     options:[],
    //     // maxLength: 30,
    //     // regex: /^[a-zA-Z\s]+$/,
    //     // isCapitalise: true,
    //     required: true,
    //   },
    //   {
    //     id: "9",
    //     type: "inputBox",
    //     title: "Zip Code",
    //     name: "pincode",
    //     maxLength: 6,
    //     regex: /^[0-9\s]+$/,
    //     // isCapitalise: true,
    //     required: true,
    //   },
    // {
    //     id: "10",
    //     type: "select_multiple",
    //     title: "Category",
    //     name: "category",
    //     // maxSelections: "3",
    //     options: ["English", "Hindi", "Gujarati"],
    //     // errorText: "You can select max 3 category at a time",
    //     required: true,
    // },

    {
      id:"12",
      type:"undefined",
      size:"6",
      display: isEdit ? "none" : "block"
    },
    // {
    //   id: "4",
    //   type: "lockedInput",
    //   variant: "number",
    //   title: "Set Sequence",
    //   name: "set_sequence",
    //   min: "1",
    //   max: "99",
    //   required: true,
    // },
    {
      id: "11",
      type: "toggle",
      title: "Status",
      name: "status",
      default: "Active",
      display: isEdit? "block" : "none",
      size: "6",
      options: [{ value: "Active", color: "#00B69B" }, { value: "Inactive", color: "#EE368C" }],
  },
  {
    id: "12",
    type: "file1",
    title: "Upload Company Logo",
    // subtitle:"(Resolution : 512px x 512px) *",
    // subsubtitle:"Max File Size 1MB",
    // subsubsubtitle:"Support only JPG,PNG,JPEG",
    name: "company_logo",
    accept: ".jpg , .jpeg , png",
    size:"4",
    required: true,
  },
  {
    id: "13",
    type: "file1",
    title: "Upload Company Registration Certificate",
    // subtitle:"(Resolution : 512px x 512px) *",
    // subsubtitle:"Max File Size 1MB",
    // subsubsubtitle:"Support only JPG,PNG,JPEG",
    name: "company_registration_certificate",
    size:"4",
    accept: ".pdf , .jpg , .jpeg , png",
    required: true,
  },
  {
    id: "14",
    type: "file1",
    title: "Upload Contract Agreement",
    // subtitle:"(Resolution : 512px x 512px) *",
    // subsubtitle:"Max File Size 1MB",
    // subsubsubtitle:"Support only JPG,PNG,JPEG",
    name: "contract_agreement",
    accept: ".pdf , .jpg , .jpeg , png",
    size:"4",
    required: true,

  },
    {
      id: "15",
      type: "button",
      title: "Submit",
    },
  ]);



  const handleSubmit = async(event) => {
    event.preventDefault();
    const temp_form = form;
    const countryCode = form?.countryCode || '';
    let mobileNumber = form?.mobile_number || '';
    
    // Remove the country code from mobile number if it exists
    if (countryCode && mobileNumber.startsWith(countryCode)) {
      mobileNumber = mobileNumber.slice(countryCode.length);
    } else if (mobileNumber.startsWith(countryCode.replace('+', ''))) {
      mobileNumber = mobileNumber.slice(countryCode.length - 1);
    }
    temp_form["tempmobile_number"] = form?.mobile_number;
    temp_form["mobile_number"] = mobileNumber;
    setForm({ ...temp_form });
    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    formStructure.map((element) => {
			if (element.type == "image" && form?.[element.name] && typeof (form?.[element.name]) != "string") {
				const temp = form;
				temp["temp" + element.name] = form?.[element.name];
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});
  
      if (isEdit) {
        const UpdateVendorApi = await vendor_update(data);  
        if (UpdateVendorApi?.statuscode == 200) {
          toast.success(UpdateVendorApi?.message);
          setTimeout(() => {
            navigate("/vendor");
            dispatch({ type: VENDOR, payload: undefined });
          }, 1000);
          setForm({});
        } else {
          toast.error(UpdateVendorApi?.message);
        }
      }
       else {
        const CreateVendorApi = await vendor_register(data);
        console.log(CreateVendorApi,"sfddffdf")
        if (CreateVendorApi?.statuscode == 200) {
          toast.success(CreateVendorApi?.message);
          setTimeout(() => {
            navigate("/vendor");
            dispatch({ type: VENDOR, payload: undefined });
          }, 1000);
          setForm({});
        } else {
          toast.error(CreateVendorApi?.message);
        }
      }
    } 


  
  return (
    <div>
       <Breadcrumbs
        parent="vendor"
        title={ isEdit ? "Edit Vendor":"Create Vendor"}
        mainTitle={isEdit ? "Edit Vendor":"Create Vendor"}
      />
  
              <Form
					handleSubmit={handleSubmit}
					formStructure={formStructure}
					// formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					// tableData={tableData}
					// setTableData={setTableData}
					isEdit={isEdit}
					// setIsEdit={setIsEdit}
				/>
            
    </div>
  );
};

export default CreateVendor;
