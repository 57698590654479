import {  ADMIN_VENDOR_EXPIRED_OFFERS_LIST, ADMIN_VENDOR_OFFERS_LIST, EXPIRED_OFFERS_LIST, OFFERS, OFFERS_LIST } from "../../Constant";
import * as api from '../../api/index';

export const create_offers = (formData) => async (dispatch) => {
    try {
      const { data } = await api.create_offers(formData);
      dispatch({ type: OFFERS, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const update_offers = (formData) => async (dispatch) => {
    try {
      const { data } = await api.update_offers(formData);
      dispatch({ type: OFFERS, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const delete_offers = (formData) => async (dispatch) => {
    try {
      const { data } = await api.delete_offers(formData);
      dispatch({ type: OFFERS, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const all_offers_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_offers_list(formData);
      dispatch({ type: OFFERS_LIST, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const all_expired_offers_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_expired_offers_list(formData);
      dispatch({ type: EXPIRED_OFFERS_LIST, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const admin_vendor_wise_offers_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.admin_vendor_wise_offers_list(formData);
      dispatch({ type: ADMIN_VENDOR_OFFERS_LIST, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };
export const admin_vendor_wise_expire_offers_list = (formData) => async (dispatch) => {
    try {
      const { data } = await api.admin_vendor_wise_expire_offers_list(formData);
      dispatch({ type: ADMIN_VENDOR_EXPIRED_OFFERS_LIST, payload: data });
      return data
  
  
    } catch (error) {
      console.log(error);
    }
  };