import { WORKER  , WORKER_DETAILS, WORKER_LIST} from '../Constant';
import * as api from '../api/index';

export const worker_register  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.worker_register (formData);
      dispatch({ type: WORKER, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };
export const worker_update  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.worker_update(formData);
      dispatch({ type: WORKER, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };
export const worker_delete  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.worker_delete(formData);
      dispatch({ type: WORKER, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };
export const worker_list  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.worker_list (formData);
      dispatch({ type: WORKER_LIST, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };
export const worker_details  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.worker_details (formData);
      dispatch({ type: WORKER_DETAILS, payload:data });
      return data
  
   
    } catch (error) {
      console.log(error);
    }
  };