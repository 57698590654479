
import { UNIT, UNIT_LIST } from '../Constant/index.jsx';
import * as api from '../api/index.jsx';

export const all_unit_list  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_unit_list(formData);
      dispatch({ type: UNIT_LIST, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };
export const create_unit  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.create_unit(formData);
      dispatch({ type: UNIT, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };
export const update_unit  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.update_unit(formData);
      dispatch({ type: UNIT, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };
export const delete_unit  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.delete_unit(formData);
      dispatch({ type: UNIT, payload:data });
      return data
      
   
    } catch (error) {
      console.log(error);
    }
  };