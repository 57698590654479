import * as api from '../api/index';
import { PRODUCT, PRODUCT_DETAILS, PRODUCT_LIST, PRODUCT_LIST_VENDOR, PRODUCT_NAME} from '../Constant';


export const create_product = (formData) => async (dispatch) => {
    try {
        const { data } = await api.create_product(formData);
        dispatch({ type: PRODUCT, payload: data });
        return data


    } catch (error) {
        console.log(error);
    }
};

export const update_product = (formData) => async (dispatch) => {
    try {
        const { data } = await api.update_product(formData);
        dispatch({ type: PRODUCT, payload: data });
        return data


    } catch (error) {
        console.log(error);
    }
};

export const delete_product = (formData) => async (dispatch) => {
    try {
        const { data } = await api.delete_product(formData);
        dispatch({ type: PRODUCT, payload: data });
        return data


    } catch (error) {
        console.log(error);
    }
};

export const all_product_vendor_list = (formData) => async (dispatch) => {
    try {
        const { data } = await api.all_product_vendor_list(formData);
        dispatch({ type: PRODUCT_LIST_VENDOR, payload: data });
        return data


    } catch (error) {
        console.log(error);
    }
};

export const all_product_list = (formData) => async (dispatch) => {
    try {
        const { data } = await api.all_product_list(formData);
        dispatch({ type: PRODUCT_LIST, payload: data });
        return data


    } catch (error) {
        console.log(error);
    }
};
export const product_details = (formData) => async (dispatch) => {
    try {
        const { data } = await api.product_details(formData);
        dispatch({ type: PRODUCT_DETAILS, payload: data });
        return data


    } catch (error) {
        console.log(error);
    }
};
export const product_list_special_offer = (formData) => async (dispatch) => {
    try {
        const { data } = await api.product_list_special_offer(formData);
        dispatch({ type: PRODUCT_NAME, payload: data });
        return data


    } catch (error) {
        console.log(error);
    }
};