// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import ListTable from "../../../utils/Table";
import { all_category_list, category_delete } from "../../../actions/category";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { CREATECATEGORY, VENDOR_DEAL_OF_THE_DAY_LIST } from "../../../Constant";
import {
  admin_vendor_wise_deal_of_the_day_list,
  all_deal_of_the_day_list,
  delete_deal_of_the_day,
} from "../../../actions/offeres/deal_of_the_day";
import { all_vendor_list } from "../../../actions/vendor";

const DealOfDay = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const DealOfTheDay = useSelector(
    (state) => state?.offers?.vendor_admin_deal_of_the_day_list
  );
  const message = useSelector((state) => state?.offers?.deal_of_the_day);
  const login_details = JSON.parse(sessionStorage.getItem("user"));
  const role = login_details?.id?.userrole?.rolename;
  const vendor_data = useSelector((state) => state?.vendor?.vendor_list);
  console.log(DealOfTheDay);
  useMemo(() => {
    if (login_details) {
      dispatch(
        admin_vendor_wise_deal_of_the_day_list({
          user_id: login_details?.id?.id,
          user_type: role,
        })
      );
      dispatch(all_vendor_list());
    }
  }, []);
  const tempTableData = {
    tableTitle: "Deals",
    deleteRecord: delete_deal_of_the_day,
    // updateRecord: subcategory_update,
    // deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    customisedStatusUpdateMessage: true,
    onDeleteText: "Are you sure want to delete?",
    onInactiveText:
      "Inactive this Deal of the Day will remove it from the active deals and make it unavailable for customers. Are you sure you want to proceed?",
    onActiveText:
      "Inactive this Deal of the Day will allow you to update its details. Once updated, you can reactivate it and make it available for customers again. Are you sure you want to proceed?",
    tableHead: [
      {
        id: "product",
        label: "Product",
        // link:""
      },
      {
        id: "product_category",
        label: "Category Name",
        // subText: "",
      },
      role == "Admin" && {
        id: "created_by",
        label: "Ownership",
        subText: "uploaded_by_name",
      },
      // role == "Admin" &&   {
      //   id: "uploaded_by_name",
      //   label: "Uploaded By",
      //   subText: "company_name",
      // },
      {
        id: "start_date",
        label: "Start On",
      },
      // {
      //   id: "product_final_price",
      //   label: "Deal Before Price",
      // },
      {
        id: "deal_of_the_day_type",
        label: "Type",
      },
      {
        id: "deal_of_the_day_final_amount",
        label: "Price",
      },
      {
        id: "status",
        label: "Status",
        // isButtonDisplay: true,
      },
      {
        id: "edit",
        label: "Update",
        edit_path: "/update_deal_of_the_day",
        ErrorMsg:
          "This Deal of the Day has expired and cannot be edited. Please create a new deal.",
        // id : "id",
        // handleEdit : (value) => {navigate("/Update-Category" , {state:{id:value?.id}})}
        // access: rights?.["Masters"]?.["edit"] == "true",
      },
    ].filter((e) => e),
    tableBody: [],
    filterColumn: [
      role == "Admin" && {
        id: "1",
        title: "Owneship",
        // title1: "Vendor List",
        name: "created_by",
        // name1: "uploaded_by_name",
        options: ["Admin", "Vendor"],
        // options1: ["Admin1", "Vendor1"],
        // isownshipfilter : true
      },
      role == "Admin" && {
        // isownshipfilter : true,
        id: "2",
        title: "Vendor List",
        name: "uploaded_by_name",
        options: ["Admin", "Vendor"],
        // display:"none"
      },
      {
        // isownshipfilter : true,
        id: "3",
        title: "Status",
        name: "status",
        options: ["Active", "Inactive", "Expired"],
        // display:"none"
      },
    ].filter((e) => e),
  };
  const [tableData, setTableData] = useState({ ...tempTableData });

  useMemo(() => {
    if (DealOfTheDay?.statuscode == 200) {
      const temp = tableData;
      temp.tableBody = DealOfTheDay?.data?.map((ele) => ({
        ...ele,
        product: ele?.product?.product_name,
        product_category: ele?.product?.category,
        product_sub_category: ele?.product_sub_category?.sub_category_name,
        uploaded_by_name:
          ele?.created_by == "Vendor"
            ? ele?.vendor?.vendor_name
            : ele?.admin?.name,
        company_name:
          ele?.created_by == "Vendor" ? ele?.vendor?.company_name : " - ",
        edit: ele?.status == "Expired" ? ele?.status : "",
      }));
      setTableData({ ...temp });
    }
  }, [DealOfTheDay]);
  useMemo(() => {
    if (message?.statuscode == 200) {
      toast.success(message?.message);
      if (login_details) {
        dispatch(
          admin_vendor_wise_deal_of_the_day_list({
            user_id: login_details?.id?.id,
            user_type: role,
          })
        );
      }
      dispatch({ type: VENDOR_DEAL_OF_THE_DAY_LIST, payload: undefined });
    }
  }, [message]);
  useMemo(() => {
    if (vendor_data?.statuscode == 200 && role == "Admin") {
      console.log(vendor_data);
      const tempFilter = tableData;
      tempFilter["filterColumn"][1]["options"] = vendor_data?.data?.map(
        (ele) => ele?.vendor_name
      );
      setTableData({ ...tempFilter });
    }
  }, [vendor_data, role]);
  console.log(tableData?.filterColumn[0], "nder5traef");
  return (
    <div>
      <Breadcrumbs
        parent="Deal Of The Day"
        title="Deals List"
        mainTitle="Deals List"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <ListTable
                  tableData={tableData}
                  key={"ListTable"}
                  // setForm={setForm}
                  setTableData={setTableData}
                  // setIsEdit={setIsEdit}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DealOfDay;
