// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mySwiper{
  height: 100%;
  display: flex;
  justify-items: center;
}
.mySwiper img{
  display: block;
  margin: 0 auto;
}
.mySwiper p{
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/Products/ProductDetails/ProductDetailsContent.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,qBAAqB;AACvB;AACA;EACE,cAAc;EACd,cAAc;AAChB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".mySwiper{\n  height: 100%;\n  display: flex;\n  justify-items: center;\n}\n.mySwiper img{\n  display: block;\n  margin: 0 auto;\n}\n.mySwiper p{\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
