import {CUSTOMER_DETAILS, CUSTOMER_NAME, CUSTOMERS} from '../Constant';
import * as api from '../api/index';

export const customer_list_admin  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.customer_list_admin(formData);
      dispatch({ type: CUSTOMERS, payload:data })
      return data
    } catch (error) {
      console.log(error);
    }
  };
export const customer_detail_admin  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.customer_detail_admin(formData);
      dispatch({ type: CUSTOMER_DETAILS, payload:data })
      return data
    } catch (error) {
      console.log(error);
    }
  };
export const customer_name_list  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.customer_name_list(formData);
      dispatch({ type: CUSTOMER_NAME, payload:data })
      return data
    } catch (error) {
      console.log(error);
    }
  };