import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from 'react-dom/client';
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as BookingAction from "../../actions/booking";
import { Breadcrumbs, H6 } from "../../AbstractElements";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import DataTable from "react-data-table-component";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { IMAGE } from "../../api";

const BookingDetails = () => {
  const params = useParams();
  const location = useLocation()
  console.log("para", params?.id);
  const dispatch = useDispatch();
  const { getBookingInfo, finalAssignRequest } = bindActionCreators(
    BookingAction,
    dispatch
  );
  const [bookingInfo, setBookingInfo] = useState();
  const [allInfo, setAllInfo] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [proceedData, setProceedData] = useState([]);
  const [inputErrors, setInputErrors] = useState({});
  const [localArr, setLocalArr] = useState([]);
  const navigation = useNavigate();
  const Audio = location?.state?.audio
  useEffect(() => {
    (async () => {
      if (params?.id) {
        const resData = await getBookingInfo({ id: params?.id });
        console.log("Res", resData);
        setBookingInfo(resData?.data);
        const totalWorker = resData?.total_workers;
        setAllInfo(Number(totalWorker));
        // const maxTotal = totalWorker
      }
    })();
  }, []);
  const buttonStyle = {
    backgroundColor: "#ec707a",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    padding: "5px 10px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  };

  const buttonHoverStyle = {
    backgroundColor: "#e95953",
  };

  const countStyle = {
    margin: "0 8px",
    fontWeight: "bold",
    fontSize: "1.2em",
  };
  const buttonDisabledStyle = {
    backgroundColor: "#d3d3d3", // Gray background for disabled state
    color: "#8a8a8a", // Lighter text color
    cursor: "not-allowed", // Disable pointer interaction
    opacity: "0.6", // Slightly reduce opacity
  };
  // const handleProceed = (vendor_id, total_worker) => {
  //   setLocalArr([...localArr, vendor_id])
  //   setAllInfo(allInfo - Number(total_worker))
  //   let localNum = allInfo - Number(total_worker)
  //   setProceedData(prev => [...prev, { vendor_id, total_worker }]);
  // };
  const [counts, setCounts] = useState(() => {
    return bookingInfo?.map((item) => ({ id: item.id, count: 0 })) || [];
  });
  useEffect(() => {
    console.log("BookingInfo:", bookingInfo);
    if (bookingInfo) {
      const initialCounts = bookingInfo?.map((item) => ({
        id: item.id,
        count: 0,
      }));
      console.log("Initial Counts:", initialCounts); // Log the initial counts
      setCounts(initialCounts);
    }
  }, [bookingInfo]);

  console.log(counts, bookingInfo, "chepomfg");
  // const handleInputChange = (value, workerCount, vendorId) => {

  //   let localCount = allInfo - Number(value)
  //   console.log(localCount);
  //   if (value > workerCount || value <= 0 || localCount < 0) {
  //     if (localCount < 0) {
  //       setInputErrors(prev => ({ ...prev, [vendorId]: `Required only ${allInfo}` }));
  //     }
  //     else {
  //       setInputErrors(prev => ({ ...prev, [vendorId]: `Available workers ${workerCount}` }));

  //     }
  //   } else {
  //     setInputErrors(prev => ({ ...prev, [vendorId]: null }));
  //     bookingInfo.find(info => info.id === vendorId).inputValue = value;
  //   }
  // };

  // useMemo(() => {
  //   (async () => {
  //     if (allInfo <= 0 && proceedData.length) {
  //       let userObj = {
  //         booking_request: Number(params?.id),
  //         // total_workers: allInfo,
  //         vendor_list: proceedData,
  //       }

  //       console.log(userObj)
  //       const resData = await finalAssignRequest(userObj)
  //       if (resData?.statuscode === 200) {
  //         toast.success(resData?.message)
  //         setTimeout(() => {
  //           navigation('/Booking-Request', { replace: true })
  //         }, 2500)
  //       }
  //       else {
  //         toast.error(resData?.message)
  //       }
  //       console.log("result final submit", resData)
  //     }
  //   })()

  // }, [allInfo])

  // total_worker: "1"
  // vendor_id: 8

  const productColumns = [
    {
      name: "Company Name",
      selector: (row) => row.c_name,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Vendor Details",
      selector: (row) => row.name,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Available Workers",
      selector: (row) => row.staff,
      sortable: true,
      // center: true,
      wrap: true,
      // minWidth: "350px",
    },
    {
      name: "Location",
      selector: (row) => row.city,
      sortable: true,
      // center: true,
      wrap: true,
    },
    {
      name: "Add Worker",
      selector: (row) => row.count,
      sortable: false,
      center: true,
    },
    // {
    //   name: "Assign Workers",
    //   selector: (row) => row.action,
    //   sortable: true,
    //   center: true,
    // },
  ];
  useEffect(() => {
    console.log("Updated counts:", counts);
  }, [counts]); // Define the max total
  const totalCount = counts.reduce((acc, item) => acc + item.count, 0);
  const handleIncrement = (id, maxCount) => {
    // Check if the total count exceeds maxTotal
    if (totalCount >= allInfo) {
      console.error(`Max total of ${allInfo} reached.`);
      alert(
        `Max total count of ${allInfo} reached. No more increments allowed.`
      );
      return; // Prevent further increments
    }

    setCounts((prevCounts) =>
      prevCounts.map((item) => {
        if (item.id === id) {
          if (item.count < maxCount) {
            return { ...item, count: item.count + 1 };
          } else {
            console.error(`Max count of ${maxCount} reached for ID: ${id}`);
            alert(`Max count of ${maxCount} reached for this item.`);
          }
        }
        return item;
      })
    );
  };
  const handleDecrement = (id, maxCount) => {
    console.log("Decrementing ID:", id);
    setCounts((prevCounts) =>
      prevCounts.map((item) =>
        item.id === id
          ? { ...item, count: item.count > 0 ? item.count - 1 : 0 }
          : item
      )
    );
  };
  useMemo(() => {
    if (totalCount == allInfo) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [totalCount]);
  const finalSubmit = async () => {
    // Filter and transform data
    const transformedData = counts
      .map((item) => ({
        vendor_id: item.id,
        total_worker: item.count,
      }))
      .filter((item) => item.total_worker > 0);

    console.log("finalSubmit Test", transformedData); // Should log [{vendor_id: 7, total_worker: 2}, {vendor_id: 8, total_worker: 2}]

    try {
      if (params?.id) {
        console.log("Submitting request..."); // Make sure this is logged
        const resData = await finalAssignRequest({
          booking_request: params?.id,
          vendor_list: transformedData,
        });

        if (resData?.statuscode === 200) {
          toast.success(resData?.message);
          navigation("/Booking-Request/");
          // const resData1 = getBookingInfo({ id: params?.id })
          // setBookingInfo(resData1?.data)
          // const totalWorker = resData1?.total_workers
          // setAllInfo(Number(totalWorker))
          // const maxTotal = totalWorker  resData1
        } else {
          toast.error(resData?.message);
        }
      }
    } catch (error) {
      console.error("Error in finalSubmit:", error);
    }
  };
  const ProductList =
    bookingInfo &&
    bookingInfo?.map((value, index) => {
      const currentCount =
        counts.find((item) => item.id === value.id)?.count || 0;
      return {
        c_name: (
          <div>
            <H6>{value?.company_name}</H6>
          </div>
        ),
        name: (
          <div>
            <H6>{value?.contact_person_name}</H6>
            <p>{value?.email}</p>
            <p>{value?.contact_person_phone_number}</p>
          </div>
        ),
        staff: (
          <div>
            <H6>{value?.worker?.length} Workers</H6>
          </div>
        ),
        city: (
          <div>
            <p>{value?.address1}</p>
            <p>{value?.city?.city_name}</p>
            <p>{value?.pincode}</p>
          </div>
        ),
        count:
          currentCount === 0 ? (
            <button
              style={buttonStyle}
              onMouseOver={(e) =>
                (e.target.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              }
              onMouseOut={(e) =>
                (e.target.style.backgroundColor = buttonStyle.backgroundColor)
              }
              onClick={() => handleIncrement(value.id, value.worker?.length)}
            >
              + Add
            </button>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                style={buttonStyle}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor =
                    buttonHoverStyle.backgroundColor)
                }
                onMouseOut={(e) =>
                  (e.target.style.backgroundColor = buttonStyle.backgroundColor)
                }
                onClick={() => handleDecrement(value.id, value.worker?.length)}
              >
                -
              </button>
              <span style={countStyle}>{currentCount}</span>
              <button
                style={buttonStyle}
                onMouseOver={(e) =>
                  (e.target.style.backgroundColor =
                    buttonHoverStyle.backgroundColor)
                }
                onMouseOut={(e) =>
                  (e.target.style.backgroundColor = buttonStyle.backgroundColor)
                }
                onClick={() => handleIncrement(value.id, value.worker?.length)}
              >
                +
              </button>
            </div>
          ),
        action:
          // <div>
          //   <span style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
          //     <input
          //       type="number"
          //       placeholder=""
          //       style={{
          //         border: "1px solid #000000",
          //         width: "30%",
          //         height: "30px",
          //       }}
          //       disabled=
          //       {
          //         allInfo <= 0 ||
          //           localArr.includes(value.id)
          //           ? true : false
          //       }
          //       onChange={(e) => handleInputChange(e.target.value, value?.worker?.length, value.id)}
          //     /><br />

          //     {
          //       " "
          //     }
          //     <Button
          //       style={{
          //         background: "rgba(235, 97, 6, 0.1)",
          //         color: "#ffffff",
          //         padding: "5px 13px",
          //         borderRadius: "4px",
          //       }}
          //       disabled=
          //       {
          //         inputErrors[value.id] ||
          //           allInfo <= 0 ||
          //           localArr.includes(value.id)
          //           ? true : false
          //       }
          //       onClick={() => handleProceed(value.id, value.inputValue)}
          //     >
          //       {" "}
          //       Save{" "}
          //     </Button>
          //     <div>

          //     </div>
          //   </span>
          //   {inputErrors[value.id] && (
          //     <small style={{ color: 'red' }}>{inputErrors[value.id]}</small>
          //   )}
          // </div>
          "check",
        workers: value?.worker,
      };
    });

  console.log("handleProceed", proceedData);

  const ExpandedComponent = ({ data }) => (
    <div style={{ width: "100%", border: "1px solid grey" }}>
      <Table striped>
        <thead className="expand-table">
          <tr className="table-secondary" style={{ fontWeight: "900" }}>
            <th>#</th>
            <th style={{ fontWeight: "900" }}>First Name</th>
            <th style={{ fontWeight: "900" }}>Last Name</th>
            <th style={{ fontWeight: "900" }}>Email</th>
            <th style={{ fontWeight: "900" }}>Mobile</th>
            <th style={{ fontWeight: "900" }}>Nationality</th>
            <th style={{ fontWeight: "900" }}>Work Permit Expiry</th>
          </tr>
        </thead>
        <tbody>
          {data?.workers?.map((data, index) => (
            <tr>
              <th scope="row">{index + 1}</th>
              <td>{data?.firstname}</td>
              <td>{data?.lastname}</td>
              <td>{data?.email}</td>

              <td>{data?.mobile_number}</td>
              <td>{data?.nationality}</td>
              <td>{data?.work_permit_expire_at}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Add more details as needed */}
    </div>
  );

  return (
    <div className="table-responsive product-table">
      <Breadcrumbs
        parent="Booking"
        title="Confirm Booking"
        mainTitle={`Required Workers : ${allInfo} ${location?.state?.category} (${location?.state?.subCategory})`}
      />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
        { Audio && <Card>
              <CardBody>
                {/* Audio Player Section */}
                <div className="container">
                  <h5 className="m-3">Description In Audio</h5>
                  <AudioPlayer
                    // src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-9.mp3"
                    // src="http://122.170.109.73:7577/haloulapp/static/audio/booking_request/file_example_MP3_700KB.mp3"
                    src={IMAGE + Audio}
                    volume={0.5}
                  />
                  {/* <p>
                    <a
                      href="https://github.com/lhz516/react-h5-audio-player"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Read Docs on Github
                    </a>
                  </p>
                  <p>
                    <span>Author: </span>
                    <a
                      href="https://hanzluo.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Hanz Luo
                    </a>
                  </p> */}
                </div>
              </CardBody>
            </Card>}
            <Card>
              <CardBody>
                <DataTable
                  noHeader
                  // pagination
                  // paginationServer
                  columns={productColumns}
                  data={ProductList}
                  highlightOnHover={true}
                  striped={true}
                  responsive={true}
                  // expandableRows
                  // expandableRowsComponent={ExpandedComponent}
                  className="custom-data-table"
                />
              </CardBody>
              <div style={{ textAlign: "center", margin: "20px" }}>
                <Button
                  style={
                    isDisabled
                      ? { ...buttonStyle, ...buttonDisabledStyle }
                      : buttonStyle
                  }
                  onMouseOver={(e) =>
                    !isDisabled &&
                    (e.target.style.backgroundColor =
                      buttonHoverStyle.backgroundColor)
                  }
                  onMouseOut={(e) =>
                    !isDisabled &&
                    (e.target.style.backgroundColor =
                      buttonStyle.backgroundColor)
                  }
                  disabled={isDisabled}
                  onClick={finalSubmit}
                >
                  Submit
                </Button>
              </div>
            </Card>

          </Col>
        </Row>
      </Container>
    </div>
  );
};


export default BookingDetails;
