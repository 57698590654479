import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import * as Action from "../../actions/category";
import * as SubCatAction from "../../actions/subcategory";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { IMAGE } from "../../api";
import { CREATECATEGORY, SUBCATEGORY } from "../../Constant";
import { Breadcrumbs, H5 } from "../../AbstractElements";
import Form from "../../utils/Form"
const CreateSubCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(JSON.parse(location?.state?.form), "newLOCS");
  const isEdit = location?.state?.isEdit;
  const [categoryData , setCategoryData ] = useState([])

  const { all_category_list} = bindActionCreators(
    Action,
    dispatch
  );
  const { subcategory_create ,subcategory_update} = bindActionCreators(
    SubCatAction,
    dispatch
  ); 

  const [file, setFile] = useState(null);
  const [form ,setForm] = useState({})

  useEffect(() => {
    if (location?.state?.form) {
      setForm((location?.state?.form && JSON.parse(location?.state?.form)))
    }
  }, [location]);
  useEffect(() => {
    (async () => {
      // const data = new FormData();
      // data.append("user", producer_id);
      // dispatch(bank_detail_list(data));
      const resData = await all_category_list();
      if(resData?.statuscode == 200){

        setCategoryData(resData?.data);
      }
    })();
  }, []);
console.log(categoryData,"dsfsd")
  const [formStructure, setFormStructure] = useState([
    {
      id: "1",
      type: "select",
      title: "Select Category",
      name: "category_id",
      options: ["Portrait", "Landscape"],
      // symbol123:"yes",
      required: true,
    },
   

    {
      id: "2",
      type: "inputBox",
      title: "Sub Category",
      name: "sub_category_name",
      maxLength: 30,
      regex: /^[a-zA-Z\s]+$/,
      isCapitalise: true,
      required: true,
    },
    // {
    //   id: "4",
    //   type: "lockedInput",
    //   variant: "number",
    //   title: "Set Sequence",
    //   name: "set_sequence",
    //   min: "1",
    //   max: "99",
    //   required: true,
    // },
    {
      id: "10",
      type: "toggle",
      title: "Status",
      name: "status",
      default: "Active",
      display: isEdit? "block" : "none",
      size: "6",
      options: [{ value: "Active", color: "#00B69B" }, { value: "Inactive", color: "#EE368C" }],
  },

    {
      id: "5",
      type: "button",
      title:"Submit",
    },
  ]);


  useMemo(()=>{
    if (categoryData && categoryData?.length > 0){
        const temp = formStructure
        temp[0]["options"] =  categoryData.map((ele) => ele?.category_name)
        setFormStructure([...temp])
    }
  },[categoryData])

  const handleSubmit = async(event) => {
    event.preventDefault();
    const temp_form = form;
    temp_form["category_id"] = categoryData?.map(
        (option) =>
          form?.["category_id"]?.includes(option?.category_name) && option?.id
      )
      .filter((e) => e)[0];
    setForm({
      ...temp_form,
    });
    const data = new FormData();
    Object.keys(form).map((key) => data.append(key, form?.[key]));
    formStructure.map((element) => {
			if (element.type == "image" && form?.[element.name] && typeof (form?.[element.name]) != "string") {
				const temp = form;
				temp["temp" + element.name] = form?.[element.name];
				temp[element.name] = URL.createObjectURL(form?.[element.name]);

				setForm({
					...temp,
				});
			}
		});
  
      if (isEdit) {
        const UpdateSubCategoryApi = await subcategory_update(data);  
        if (UpdateSubCategoryApi?.statuscode == 200) {
          toast.success(UpdateSubCategoryApi?.message);
          setTimeout(() => {
            navigate("/Sub-Category");
            dispatch({ type: SUBCATEGORY, payload: undefined });
          }, 1000);
          setForm({});
        } else {
          toast.error(UpdateSubCategoryApi?.message);
        }
      }
       else {
        const createSubcategoryApi = await subcategory_create(data);
        console.log(createSubcategoryApi,"sfddffdf")
        if (createSubcategoryApi?.statuscode == 200) {
          toast.success(createSubcategoryApi?.message);
          setTimeout(() => {
            navigate("/Sub-Category");
            dispatch({ type: SUBCATEGORY, payload: undefined });
          }, 1000);
          setForm({});
        } else {
          toast.error(createSubcategoryApi?.message);
        }
      }
    } 


  
  return (
    <div>
       <Breadcrumbs
        parent="Sub-Category"
        title={ isEdit ? "Edit Sub-Category":"Create Sub-Category"}
        mainTitle={isEdit ? "Edit Sub-Category":"Create Sub-Category"}
      />
  
              <Form
					handleSubmit={handleSubmit}
					formStructure={formStructure}
					// formTitle={formTitle}
					key={"Form"}
					setForm={setForm}
					form={form}
					// tableData={tableData}
					// setTableData={setTableData}
					isEdit={isEdit}
					// setIsEdit={setIsEdit}
				/>
            
    </div>
  );
};

export default CreateSubCategory;
