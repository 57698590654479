import { Button, Grid } from '@mui/material'
import React, { useState } from 'react'
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import logo from "../../../assets/images/logo/logo.png"
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
// import "./ProductDetailsContent.css";
import { useLocation } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import {order_detail,order_list,order_status_update} from "../../../actions/order"
import { ORDER } from '../../../Constant';
const OrderDetails = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const login_details = JSON.parse(sessionStorage.getItem("user"))
    const role = login_details?.id?.userrole?.rolename
    // const path = location.pathname.split("/").filter((x) => x)[1];
    const [openAccess, setOpenAccess] = useState(false);
    const [contentAccess, setContentAccess] = useState();
    const message = useSelector((state) => state?.order?.order);
  
    useMemo(() => {
      if (location.state?.id) {
        const formData = new FormData();
        formData.append("id", location.state?.id);
        dispatch(order_detail(formData));
      }
    }, [location.state?.id]);
    const Product_data = useSelector((state) => state?.order?.order_details?.data);
    const Order_data = useSelector((state) => state?.order?.order_details?.order_detail);
    console.log(Order_data ,Product_data,"Order_data")
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
  
    const [appDisBtn, setAppDisBtn] = useState(false);
    const [rejDisBtn, setRejDisBtn] = useState(false);
    // const [isSee , setIsSee] = useState(true)
  
    // const userData = data?.data?.user
    // console.log(data)
  
    useMemo(() => {
      if (Order_data?.status === "Delivered") {
        setRejDisBtn(true);
      } else {
        setRejDisBtn(false);
      }
    }, [Order_data]);
    const handleOnTheWay = () => {
        setLoading(true);
        const data = new FormData();
        data.append("id", Order_data?.id);
        data.append("status", "On the way");
        dispatch(order_status_update(data));
        //  dispatch(order_list());
    };
    const handleDelivered = () => {
        setLoading(true);
        const data = new FormData();
        data.append("id", Order_data?.id);
        data.append("status", "Delivered");
        dispatch(order_status_update(data));
        //  dispatch(order_list());
    };
    useMemo(() => {
      if (message?.statuscode == 200) {
        setLoading(false);
        const formData = new FormData();
        formData.append("id", location.state?.id);
        dispatch(order_list());
        // formData.append("status" , "Pending")
        dispatch(order_detail(formData));
        // dispatch((formData))
        dispatch({ type: ORDER, payload: undefined });
      }
    }, [message]);
    const generatePDF = () => {
        const input = document.getElementById("invoice");
    
        html2canvas(input, {
          scale: 3, // Increase scale for higher resolution
          useCORS: true, // Allow cross-origin images to render correctly
        }).then((canvas) => {
          const pdf = new jsPDF("p", "mm", "a4", true);
    
          const imgData = canvas.toDataURL("image/png");
          const imgWidth = 208; // A4 size: 210mm width, minus margins
          const imgHeight = (canvas.height * imgWidth) / canvas.width; // Calculate proportional height
    
          const pageHeight = 295; // A4 page height in mm
          let heightLeft = imgHeight;
          let position = 0;
    
          // Add first page
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
    
          // Add additional pages if the content overflows
          while (heightLeft > 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
    
          pdf.save(`${Order_data?.order_id}.pdf`);
        });
      };
      console.log("loading",loading)
  return (
    <Grid
    container
    rowSpacing={1}
    justifyContent="center"
    columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
  >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{marginTop:"1rem"}} textAlign="right">
          <Button
            onClick={generatePDF}
            variant="contained"
            color="primary"
            startIcon={<PictureAsPdfIcon />}
            style={{
              backgroundColor: "#1976d2",
              color: "#fff",
              padding: "10px 20px",
              borderRadius: "5px",
              fontSize: "16px",
              textTransform: "none",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            Download Invoice
          </Button>
        </Grid>
        <Grid
        // textAlign="center"
        >
          <div>
            {/* Off-screen Invoice */}
            <div
              id="invoice"
              style={{
                padding: "20px",
                backgroundColor: "#fff",
                width: "210mm",
                minHeight: "297mm",
                // position: 'absolute',
                // left: '-9999px',
                fontFamily: "'Arial', sans-serif",
                fontSize: "12px",
                lineHeight: "1.6",
              }}
            >
              {/* Header Section */}
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <img src={logo} alt="Company Logo" style={{ width: "100px" }} />
                <h2 style={{ fontSize: "24px", fontWeight: "600" }}>
                OSM AGRO CHEMICALS PRIVATE LIMITED
                </h2>
                <p>
                S.Y. NO 215/C, AYODHAYA X ROAD, KANDLAKOYA, MEDCHAL,
                </p>
                <p>RANGAREDDI, TELANGANA, INDIA, 501401</p>
                <p>Contact Info: osmagrimart@gmail.com</p>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "30px",
                  padding: "20px",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  backgroundColor: "#f9f9f9",
                }}
              >
                {/* Customer Details */}
                <div style={{ width: "48%" }}>
                  <h5
                    style={{
                      marginBottom: "10px",
                      borderBottom: "1px solid #ddd",
                      paddingBottom: "5px",
                    }}
                  >
                    Customer Details
                  </h5>
                  <p style={{ margin: "5px 0" }}>
                    <strong>Name:</strong>{" "}
                    {Order_data?.user?.name 
                    }
                  </p>
                  <p style={{ margin: "5px 0" }}>
                    <strong>Mobile:</strong> {Order_data?.user?.mobile_number}
                  </p>
                  <p style={{ margin: "5px 0" }}>
                    <strong>Email:</strong> {Order_data?.user?.email}
                  </p>
                  <p style={{ margin: "5px 0" }}>
                    <strong>Delivered Address:</strong>{" "}
                    {Order_data?.house_no +
                      ", " +
                      Order_data?.house_name +
                      ", " +
                      Order_data?.street_road}
                  </p>
                  <p style={{ margin: "5px 0" }}>
                    <strong>Landmark:</strong> {Order_data?.landmark}
                  </p>
                  <p style={{ margin: "5px 0" }}>
                    <strong>City:</strong> {Order_data?.city}
                  </p>
                  <p style={{ margin: "5px 0" }}>
                    <strong>State:</strong>{" "}
                    {Order_data?.state + " - " + Order_data?.pincode}
                  </p>
                </div>

                {/* Order Details */}
                <div style={{ width: "48%" }}>
                  <h5
                    style={{
                      marginBottom: "10px",
                      borderBottom: "1px solid #ddd",
                      paddingBottom: "5px",
                    }}
                  >
                    Order Details
                  </h5>
                  <p style={{ margin: "5px 0" }}>
                    <strong>Order ID:</strong> {Order_data?.order_id}
                  </p>
                  <p style={{ margin: "5px 0" }}>
                    <strong>Order Date:</strong> {Order_data?.created_at}
                  </p>
                  <p style={{ margin: "5px 0" }}>
                    <strong>Status:</strong> {Order_data?.status}
                  </p>
                </div>
              </div>
              {/* Table for Products */}
              <h3>Products</h3>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  marginBottom: "20px",
                }}
              >
                <thead>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <th
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        textAlign: "left",
                      }}
                    >
                      No.
                    </th>
                    <th
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        textAlign: "left",
                      }}
                    >
                      Product
                    </th>
                    <th
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        textAlign: "left",
                      }}
                    >
                      Category
                    </th>
                    <th
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        textAlign: "right",
                      }}
                    >
                      Quantity
                    </th>
                    <th
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        textAlign: "right",
                      }}
                    >
                      Price
                    </th>
                    <th
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        textAlign: "right",
                      }}
                    >
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Product_data?.map((item, index) => (
                    <tr key={index}>
                      <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                        {index + 1}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                        {item?.product?.product_name}
                      </td>
                      <td style={{ border: "1px solid #ddd", padding: "10px" }}>
                        {item?.product.category?.category_name}
                      </td>
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "10px",
                          textAlign: "right",
                        }}
                      >
                        {item?.quantity}
                      </td>
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "10px",
                          textAlign: "right",
                        }}
                      >
                        {item?.purchased_product_price}
                      </td>
                      <td
                        style={{
                          border: "1px solid #ddd",
                          padding: "10px",
                          textAlign: "right",
                        }}
                      >
                        {(
                          parseFloat(item?.purchased_product_price) *
                          parseInt(item?.quantity)
                        ).toFixed(2)}
                      </td>
                    </tr>
                  ))}
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      colSpan="5"
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        textAlign: "left",
                      }}
                    >
                      Total Product Quantity :
                    </td>

                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        textAlign: "right",
                      }}
                    >
                      {Order_data?.total_product_quantity}
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      colSpan="5"
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        textAlign: "left",
                      }}
                    >
                      Total Product Amount :
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        textAlign: "right",
                      }}
                    >
                      {" ₹ " + parseFloat(Order_data?.total_amount).toFixed(2)}
                    </td>
                  </tr>
                  {Order_data?.discount_price &&
                    Number(Order_data?.discount_price) > 0 && (
                      <tr style={{ backgroundColor: "#f2f2f2" }}>
                        <td
                          colSpan="5"
                          style={{
                            border: "1px solid #ddd",
                            padding: "10px",
                            textAlign: "left",
                          }}
                        >
                          Discount Amount :
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "10px",
                            textAlign: "right",
                          }}
                        >
                          {" ₹ " +
                            parseFloat(Order_data?.discount_price).toFixed(2)}
                        </td>
                      </tr>
                    )}
                  {Order_data?.total_gst_amount &&
                    Number(Order_data?.total_gst_amount) > 0 && (
                      <tr style={{ backgroundColor: "#f2f2f2" }}>
                        <td
                          colSpan="5"
                          style={{
                            border: "1px solid #ddd",
                            padding: "10px",
                            textAlign: "left",
                          }}
                        >
                          Total GST Amount :
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "10px",
                            textAlign: "right",
                          }}
                        >
                          {" ₹ " +
                            parseFloat(Order_data?.total_gst_amount).toFixed(2)}
                        </td>
                      </tr>
                    )}
                  {Order_data?.promocode_discount_price &&
                    Number(Order_data?.promocode_discount_price) > 0 && (
                      <tr style={{ backgroundColor: "#f2f2f2" }}>
                        <td
                          colSpan="5"
                          style={{
                            border: "1px solid #ddd",
                            padding: "10px",
                            textAlign: "left",
                          }}
                        >
                          Promocode Discount ( {Order_data?.coupon_code})
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "10px",
                            textAlign: "right",
                          }}
                        >
                          {" ₹ " +
                            parseFloat(
                              Order_data?.promocode_discount_price
                            ).toFixed(2)}
                        </td>
                      </tr>
                    )}
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      colSpan="5"
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        textAlign: "left",
                      }}
                    >
                      Shipping Price{" "}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        textAlign: "right",
                      }}
                    >
                      {" ₹ " +
                        parseFloat(Order_data?.shipping_price).toFixed(2)}
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "#f2f2f2" }}>
                    <td
                      colSpan="5"
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        textAlign: "left",
                      }}
                    >
                      Total Paid Amount{" "}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: "10px",
                        textAlign: "right",
                      }}
                    >
                      {" ₹ " + parseFloat(Order_data?.paid_amount).toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Footer Section */}
              <div style={{ marginTop: "30px", textAlign: "center" }}>
                <p>Thank you for your purchase!</p>
                <p>
                  If you have any questions, contact us at
                  osmagrimart@gmail.com
                </p>
              </div>
            </div>

            {/* Only the button is visible */}
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{marginBottom:"1rem"}}  textAlign="center">
          {role !== "Vendor" && (
              <>
                <LoadingButton
                  // disabled={appDisBtn}
                  loading={loading}
                  style={{
                    background:
                      "linear-gradient(225deg,  #ac1600 0%, #500303 91.25%)",
                    display: Order_data?.status === "Accepted" ? "" : "none",
                  }}
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: "10px",
                    mt: "10px",
                    p: "10px 30px",
                    fontSize: "14px",
                    color: "#fff !important",
                  }}
                  variant="contained"
                  className="mr-10px"
                  onClick={handleOnTheWay}
                >
                  {/* {data?.data?.status === "Approved" ? "Approved" : "Approve"} */}
                  {loading ? (
                    <span style={{ paddingRight: "1rem" }}>Sending...</span>
                  ) : (
                    <span>On the way</span>
                  )}

                  {/* On the way */}
                </LoadingButton>
                <LoadingButton
                  disabled={rejDisBtn}
                  loading={loading}
                  style={{
                    background:
                      "linear-gradient(225deg,  #ac1600 0%, #500303 91.25%)",
                    display: Order_data?.status == "On the way" ? "" : "none",
                  }}
                  sx={{
                    textTransform: "capitalize",
                    borderRadius: "10px",
                    mt: "10px",
                    p: "10px 30px",
                    fontSize: "14px",
                    color: "#fff !important",
                  }}
                  variant="contained"
                  className="mr-10px"
                  onClick={handleDelivered}
                >
                  {/* {data?.data?.status === "Rejected" ? "Rejected" : "Reject"} */}
                  {loading ? (
                    <span style={{ paddingRight: "1rem" }}>Sending...</span>
                  ) : (
                    <span>Delivered</span>
                  )}
                </LoadingButton>
              </>
            )}
        </Grid>
  </Grid>
  )
}

export default OrderDetails