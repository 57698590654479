import * as actionType from '../Constant';


const BookingReducer = (state = { BookingData: null, finalsubmit: null, assignedbooking: null }, action) => {

  switch (action.type) {
    case actionType.BOOKINGREQUESR_LIST:
      return { ...state, booking_request_list: action.payload };
    case actionType.BOOKINGREQUESR_DETAILS:
      return { ...state, booking_request_details: action.payload };
    case actionType.BOOKINGREQUESR_VENDOR:
      return { ...state, booking_request_vendor: action.payload };
    case actionType.FINAL_SUBMIT:
      return { ...state, finalsubmit: action.payload };
    case actionType.ASSIGNED_BOOKING:
      return { ...state, assignedbooking: action.payload };
    case actionType.TODAYS_BOOKING_ADMIN:
      return { ...state, todaybookingAdmin: action.payload };
    case actionType.BOOKING_HISTORY_ADMIN:
      return { ...state, bookinghistoryAdmin: action.payload };
    case actionType.PENDING_BOOKIND:
      return { ...state, pending_booking_request: action.payload };
    case actionType.PENDING_BOOKING_DETAILS:
      return { ...state, pending_booking_request_details: action.payload };
    case actionType.FINAL_VENDOR_SUBMIT:
      return { ...state, final_vendor_submit: action.payload };
    default:
      return state;
  }
};

export default BookingReducer;
