import * as actionType from '../Constant';


const ProjectReducer = (state = { ProjectData: null }, action) => {
  switch (action.type) {
    case actionType.CREATE_PROJECT:
      return { ...state, projects: action.payload }
    case actionType.PROJECT:
      return { ...state, project_list: action.payload }
    default:
      return state;
  }
};

export default ProjectReducer;
