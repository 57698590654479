// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import ListTable from "../../utils/Table";
import { all_category_list, category_delete } from "../../actions/category";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { CREATECATEGORY, NOTIFICATION, SUBCATEGORY } from "../../Constant";
import { all_notification_list, notification_delete} from "../../actions/notifications";
import { Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EnlargedView from "../../utils/EnlargedView";

const Notification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [content, setContent] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const NotificationList = useSelector((state)=>state?.notification?.notification_list)
  const message = useSelector((state)=>state?.notification?.notification)

  useMemo(()=>{
    dispatch(all_notification_list())
  },[])
  const tempTableData = {
    tableTitle: "All Notification",
    deleteRecord: notification_delete,
    openModal: handleOpen,
    // disableDelete : true,
    // updateRecord: subcategory_update,
    // deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    onDeleteText: "Are you sure want to delete?",
    // onUpdateText:
    //   "Videos are associated with this category, are you still want to change it.?",
    tableHead: [{
          id: "notification_title",
          label: "Notification Title",
          // isSpecial: true,
          // align:"left"
          // color: viewColor,
        },

      {
          id: "created_at",
          label: "Sent On",
        },
      {
        id: "notification_image",
        label: "Image",
        isImage: true,
      },
      {
        id: "description",
        label: "Description",
        isModal: true,
        readNotification: true,
        n_id: "noti_id",
        default: (
          <>
            <button
              style={{
                padding: "5px 15px",
                color: "rgb(238, 127, 37)",
                background: "transparent",
                border: "1px solid rgb(238, 127, 37)",
                borderRadius: "5px",
              }}
              // value="noti_id"
              // onClick={() => ReadNotification("noti_id")}
            >
              VIEW
            </button>
          </>
        ),
      },
         {
          id: "customers",
          label: "Users",
          isModal: true,

          default: (
            <>
              <button
                style={{
                  padding: "5px 15px",
                  color: "rgb(238, 127, 37)",
                  background: "transparent",
                  border: "1px solid rgb(238, 127, 37)",
                  borderRadius: "5px",
                }}
              >
                VIEW
              </button>
            </>
          ),
        },
    ].filter((e) => e),
    tableBody: [],
    filterColumn: [
      
      ],
     
  };
  const [tableData, setTableData] = useState({...tempTableData})

  useMemo(()=>{
    if(NotificationList?.statuscode == 200){
      const temp = tableData
      temp.tableBody = NotificationList?.data?.map((ele)=>({
        ...ele,
        noti_id: ele?.id,
        customers:
          ele?.user  ? (
            ele?.user?.map((customer) => (
              <>
                <p style={{ color: "#fff" }}>
                  {customer?.name}
                </p>
                <p style={{ color: "var(--themeFontColor)" }}>
                  {temp?.tableHead?.default}
                </p>
              </>
            ))
          ) : (
            <>
              <p style={{ color: "var(--themeFontColor)" }}>{"All Customer"}</p>
              <p style={{ color: "var(--themeFontColor)" }}>
                {temp?.tableHead?.default}
              </p>
            </>
          ),
        description: (
          <>
            <div
              dangerouslySetInnerHTML={{ __html: ele?.description }}
              style={{ color: "var(--themeFontColor)" }}
            />
            <p style={{ color: "var(--themeFontColor)" }}>
              {temp?.tableHead?.default}
            </p>{" "}
          </>
        ),
      }))
      setTableData({...temp})
    }
  },[NotificationList])
  useMemo(()=>{
    if(message?.statuscode == 200){
      toast.success(message?.message);
      dispatch(all_notification_list())
      dispatch({ type: NOTIFICATION, payload: undefined })
    }
  },[message])
  return (
    <div >
      <EnlargedView open={open} setOpen={setOpen} content={content} />

      <Breadcrumbs
        parent="Notification"
        title="Notification List"
        mainTitle="Notification List"
      />
      <Container fluid={true}>
        <Row>
          <div style={{width:"100%" ,textAlign:"right"}}>
                <Button
                style={{
                  background:  "linear-gradient(225deg,  #ac1600 0%, #500303 91.25%)"
                }}
                 sx={{
                    textTransform: "capitalize",
                    borderRadius: "10px",
                    mb: "1rem",
                    p: "10px 30px",
                    fontSize: "14px",
                    color: "#fff !important",
                  }}
                  onClick={()=> navigate('/create-notification')}
                >
                  <AddIcon/>  Add
                </Button>
                </div>
          <Col sm="12">
            <Card>
              <CardBody>
              <ListTable
              tableData={tableData}
              key={"ListTable"}
              setTableData={setTableData}
              setContent={setContent}
              />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Notification;
