import * as actionType from '../Constant';


const gstRateReducer = (state = { gstRateData: null }, action) => {
  switch (action.type) {
    case actionType.GST_RATE:
      return { ...state, gst_rate: action.payload };
    case actionType.GST_RATE_LIST:
      return {...state, gst_rate_list: action.payload}

    default:
      return state;
  }
};

export default gstRateReducer;
