import React from 'react';
import ReactDOM from "react-dom/client";
import './index.scss';
import App from './App';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import {thunk} from 'redux-thunk';
import { reducers } from "./reducers";
import './i18n';
import reportWebVitals from './reportWebVitals';

// ReactDOM.render(<App />, document.getElementById('root'));
// const store = createStore(reducers);
const store = createStore(reducers, compose(applyMiddleware(thunk)));

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
    // <React>
    // <React.StrictMode>
    <Provider store={store}>
        <App />
    </Provider>
// </React.StrictMode>
//   </React>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.scss';
// import App from './App';
// import { Provider } from 'react-redux';
// import { createStore, applyMiddleware, compose } from 'redux'; // Import applyMiddleware and compose
// import {thunk} from 'redux-thunk';
// import { reducers } from "./reducers";
// import './i18n';
// import reportWebVitals from './reportWebVitals';

// const store = createStore(
//   reducers,
//   compose(
//     applyMiddleware(thunk),
//     // Add other enhancers here if needed
//   )
// );

// const root = ReactDOM.createRoot(document.getElementById("root"));

// root.render(
//   <Provider store={store}>
//     <App />
//   </Provider>
// );

// reportWebVitals();
