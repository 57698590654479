import * as actionType from '../Constant';


const CategoryReducer = (state = { categoryData: null }, action) => {
  switch (action.type) {
    case actionType.CREATECATEGORY:
      return { ...state, category: action.payload };
    case actionType.CATEGORYLIST:
      return {...state, category_list: action.payload}

    default:
      return state;
  }
};

export default CategoryReducer;
