// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../AbstractElements";
import pic1 from "../../Images/download.png";
import pic2 from "../../Images/photo.png";
import * as Action from "../../actions/category";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { IMAGE } from "../../api";
import SweetAlert from 'sweetalert2';
import * as LevelAction from "../../actions/level";


import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CREATECATEGORY } from "../../Constant";

const Levels = () => {
  const dispatch = useDispatch();
  const [levelsData, setLevelsData] = useState([]);
  const {level_list} = bindActionCreators(
    LevelAction,
    dispatch
  );
  useEffect(() => {
    (async () => {
      const resData = await level_list();
      setLevelsData(resData);
    })();
  }, []);
  console.log(levelsData);

  const productColumns = [
    {
      name: "Levels",
      selector: (row) => row.Details,
      sortable: true,
      // center: true,
      wrap: true,
      minWidth: "350px",
    }
  ];
  const style = {
    width: 55,
    height: 40,
    borderRadius: 5,
  };
  const style2 = { width: 60, fontSize: 14, padding: 4 };
  const ProductList =
    levelsData &&
    levelsData?.data?.map((value ,index) => ({
     
      Details: (
        <div>
          <H6>{value?.level_name}</H6>
        </div>
      ),
    
    }));

  // amount: '$10',
  // stock: <div className='font-success'>In Stock</div>,
  // start_date: '2022/4/19',

  // ]
  return (
    <div className="table-responsive product-table">
      <Breadcrumbs
        parent="Levels"
        title="Levels List"
        mainTitle="Levels List"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  noHeader
                  pagination
                  paginationServer
                  columns={productColumns}
                  data={ProductList}
                  highlightOnHover={true}
                  striped={true}
                  responsive={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Levels;
