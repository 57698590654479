// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import ListTable from "../../utils/Table";
import { all_category_list, category_delete } from "../../actions/category";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { CREATECATEGORY, SUBCATEGORY } from "../../Constant";
import {order_list ,vendor_order_list } from "../../actions/order";

const Order = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const userInfo = JSON.parse(sessionStorage.getItem("user"))

  const OrderList = userInfo?.id?.userrole?.rolename == "Admin" ?  useSelector((state)=>state?.order?.order_list) : useSelector((state)=>state?.order?.vendor_order) 
  useMemo(()=>{
    userInfo?.id?.userrole?.rolename == "Admin"?
    dispatch(order_list()) :
    dispatch(vendor_order_list({vendor_id:userInfo?.id?.id}))
  },[])
  const tempTableData = {
    tableTitle: "Order List",
    // deleteRecord: subcategory_delete,
    disableDelete : true,
    // updateRecord: subcategory_update,
    // deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    // onDeleteText: "Are you sure want to delete?",
    // onUpdateText:
    //   "Videos are associated with this category, are you still want to change it.?",
    tableHead: [
      {
        id: "order_id",
        label: "Order Number",
         link:"/order-details"
      },
      {
        id:"user",
        label:"Customer",
       
      },
      {
        id: "email",
        label: "Contact Info",
        subText :"mobile"
      },
 
      {
        id: "payment_id",
        label: "Payment ID",
      },
      {
        id: "paid_amount",
        label: "Amount",
      },
      {
        id: "created_at",
        label: "Date",
      },
      {
        id: "status",
        label: "Status",
        isButtonDisplay: true,
      },
    
    ],
    tableBody: [],
    filterColumn: [
        {
          id: "1",
          title: "Status",
          name: "status",
          options: ["Delivered", "Accepted" , "On the way"],
        },
      ],
      isDateRangeFilter: "created_at",
  };
  const [tableData, setTableData] = useState({...tempTableData})

  useMemo(()=>{
    if(OrderList?.statuscode == 200){
      const temp = tableData
      temp.tableBody = OrderList?.data?.map((ele)=>({
        ...ele,
        user : ele?.user?.name,
        email : ele?.user?.email,
        mobile : ele?.user?.mobile_number,
        // sub_category_name : ele?.subcategory_name

      }))
      setTableData({...temp})
    }
  },[OrderList])
  return (
    <div >
      <Breadcrumbs
        parent="Order"
        title="Order List"
        mainTitle="Order List"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
              <ListTable
              tableData={tableData}
              key={"ListTable"}
              // setForm={setForm}
              setTableData={setTableData}
              // setIsEdit={setIsEdit}
              />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Order;
