import {COUNTRY, COUNTRY_LIST} from '../Constant';
import * as api from '../api/index';

export const country_create  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.country_create(formData);
      dispatch({ type: COUNTRY, payload:data })
      return data
    } catch (error) {
      console.log(error);
    }
  };
export const country_update  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.country_update(formData);
      dispatch({ type: COUNTRY, payload:data })
      return data
    } catch (error) {
      console.log(error);
    }
  };
export const all_country_list  = (formData) => async (dispatch) => {
    try {
      const { data } = await api.all_country_list(formData);
      dispatch({ type: COUNTRY_LIST, payload:data })
      return data
    } catch (error) {
      console.log(error);
    }
  };