import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

// import SRTFile from "./../../../images/SRTFile.png"
import { useSelector } from "react-redux";
export const FormStructure = (isEdit) => {
  const role = useSelector((state) => state.layout.role);

  return [
    [
      {
        id: "11",
        type: "inputBox",
        maxLength: 20,
        title: "First Name",
        name: "firstname",
        regex: /^[a-zA-Z\s\&]+$/,
        // isCaps:true,
        required: true,
      },
      {
        id: "12",
        type: "inputBox",
        maxLength: 20,
        title: "Last Name",
        name: "lastname",
        regex: /^[a-zA-Z\s\&]+$/,
        // isCaps:true,
        required: true,
      },
      {
        id: "13",
        type: "inputBox",
        variant: "date",
        title: "Date Of Birth (DOB)",
        default: " ",
        max: new Date(new Date().setFullYear(new Date().getFullYear() - 15))
        .toISOString()
        .split("T")[0],
        name: "date_of_birth",
        // display: "none",
        required: true,
      },
      {
        id: "14",
        type: "inputBox",
        // maxLength: 20,
        title: "Age",
        name: "age",
        disabled : true
        // regex: /^[a-zA-Z\s\&]+$/,
        // isCaps:true,
        // required: true,
      },
      {
        id: "15",
        type: "inputBox",
        maxLength: 30,
        title: "Email",
        name: "email",
        // regex : /^[a-zA-Z\s\&]+$/,
        // isCaps:true,
        required: true,
      },
      {
        id: "16",
        type: "phone",
        title: "Mobile Number",
        maxLength: 12,
        name: "mobile_number",
        required: true,
      },
      {
        id: "17",
        type: "select",
        title: "Educational Background",
        name: "educational_background",
        options: [
          "Primary Education",
          "Middle School",
          "Secondary School",
          "Senior Secondary",
          "graduate",
          "Postgraduate",
        ],
        required: true,
      },
      {
        id: "18",
        type: "select",
        title: "Primary Language",
        name: "primary_language",
        // maxSelections: "3",
        options: ["English", "Hindi", "Gujarati"],
        // errorText: "You can select max 3 langauge at a time",
        required: true,
      },
      {
        id: "19",
        type: "select_multiple",
        name: "language",
        title: "Languages Speak",
        maxSelections: "3",
        options: ["English", "Hindi", "Gujarati"],
        errorText: "You can select max 3 langauge at a time",
        required: true,
      },
      {
        id: "20",
        type: "image",
        title: "Upload Profile Photo",
        subtitle: "(Resolution : 512px x 512px) *",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        name: "profile_photo",
        size: "4",
        required: true,
      },
    ],
    [
      {
        id: "21",
        type: "select",
        maxLength: 30,
        title: "Nationality",
        name: "nationality",
        options:[],
        // regex : /^[a-zA-Z\s\&]+$/,
        // isCaps:true,
        required: true,
      },
      {
        id: "22",
        type: "inputBox",
        maxLength: 30,
        title: "House or Building Name & No",
        name: "house_no",
        // regex : /^[a-zA-Z\s\&]+$/,
        // isCaps:true,
        required: true,
      },
      {
        id: "23",
        type: "inputBox",
        // maxLength: 30,
        title: "Area Name",
        name1: "residence_name",
        name: "area_name",
        // regex : /^[a-zA-Z\s\&]+$/,
        // isCaps:true,
        required: true,
      },
      {
        id: "24",
        type: "inputBox",
        maxLength: 30,
        title: "Street Name",
        name1: "area_or_street",
        name: "street_name",
        // regex : /^[a-zA-Z\s\&]+$/,
        // isCaps:true,
        required: true,
      },
      {
        id: "26",
        type: "select",
        // maxLength: 30,
        title: "State",
        name: "state",
        options:[],
        // regex: /^[a-zA-Z\s\&]+$/,
        // isCaps: true,
        required: true,
      },
      {
        id: "25",
        type: "select",
        // maxLength: 30,
        title: "City",
        name: "city",
        options:[],
        // regex: /^[a-zA-Z\s\&]+$/,
        // isCaps: true,
        required: true,
      },

      // {
      //   id: "26",
      //   type: "inputBox",
      //   maxLength: 30,
      //   title: "State Code",
      //   name: "state_code",
      //   regex: /^[0-9\s\&]+$/,
      //   // isCaps: true,
      //   // required: true,
      // },
      {
        id: "27",
        type: "inputBox",
        maxLength: 6,
        title: "Pincode",
        name: "pincode",
        regex: /^[0-9\s\&]+$/,
        // isCaps: true,
        required: true,
      },

      {
        id: "28",
        type: "inputBox",
        variant: "date",
        title: "Work Permit Expiry Date",
        default: " ",
        min: new Date().toISOString().split("T")[0],
        name1: "work_permit_expire_at",
        name: "work_permit_expire_at",
        // display: "none",
        required: true,
      },
      {
        id: "29",
        type: "image",
        title: "Upload Passport Photo",
        subtitle: "(Resolution : 512px x 512px) *",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        name: "passport_photo",
        size: "4",
        required: true,

      },
      {
        id: "210",
        type: "image",
        title: "Upload Goverment Id Photo",
        subtitle: "(Resolution : 512px x 512px) *",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        name: "goverment_id_photo",
        size: "4",
        required: true,

      },
      {
        id: "211",
        type: "image",
        title: "Upload Work Permit Photo",
        subtitle: "(Resolution : 512px x 512px) *",
        subsubtitle: "Max File Size 1MB",
        subsubsubtitle: "Support only JPG,PNG,JPEG",
        name: "work_permit",
        size: "4",
        required: true,

      },
    ],
    [
      {
        id: "31",
        type: "inputBox",
        maxLength: 50,
        title: "Company Name",
        name: "previous_company_name",
        regex: /^[a-zA-Z0-9\s\&]+$/,
        // isCaps:true,
        // required: true,
      }, 
      {
        id: "32",
        type: "inputBox",
        maxLength: 20,
        title: "Company Join Date",
        name: "previous_company_join_date",
        variant: "date",
        default: " ",
        max: new Date().toISOString().split("T")[0],
        // isCaps:true,
        // required: true,
      }, 
      {
        id: "33",
        type: "inputBox",
        maxLength: 20,
        title: "Company Leave Date",
        name: "previous_company_leave_date",
        variant: "date",
        default: " ",
        max: new Date().toISOString().split("T")[0],
        // isCaps:true,
        // required: true,
      }, 
      {
        id: "34",
        type: "inputBox",
        maxLength: 20,
        title: "Your Profession In Company",
        name: "previous_company_profession",
        regex: /^[a-zA-Z\s\&]+$/,
        // isCaps:true,
        // required: true,
      }, 
    ],
    [
      {
        id: "31",
        type: "button",
        title: <AddIcon sx={{ color: "#000 !important" }} />,
        align: "left",
        position:0,
        forceShow: true,
      },
      {
        id: "32",
        type: "select",
        title: "Category",
        name: "category_0",
        // maxSelections: "3",
        position:0,
        options: ["English", "Hindi", "Gujarati"],
        // errorText: "You can select max 3 langauge at a time",
        size: "2.2",
        required: true,
      },
      {
        id: "33",
        type: "select2",
        title: "Sub Category",
        name: "subcategory_0",
        position:0,
        options: ["English", "Hindi", "Gujarati"],
        size: "2.2",
        required: true,
      },
      {
        id: "34",
        type: "inputBox",
        title: "Expertise",
        name: "expertise_0",
        // regex: /^[0-9\s\&]+$/,
        size: "1.7",
        position:0,
        // isCaps: true,
        required: true,
      },
      {
        id: "35",
        type: "inputBox",
        maxLength: 4,
        title: "Hourly Pay",
        name: "work_fees_per_hour_0",
        regex: /^[0-9\s\&]+$/,
        size: "1.3",
        position:0,
        symbol:"﷼ ",
        // isCaps: true,
        required: true,
      },
      {
        id: "36",
        type: "inputBox",
        maxLength: 4,
        title: "Daily Pay",
        name: "work_fees_per_day_0",
        regex: /^[0-9\s\&]+$/,
        size: "1.3",
        symbol:"﷼ ",
        position:0,

        // isCaps: true,
        required: true,
      },
      {
        id: "37",
        type: "inputBox",
        maxLength: 4,
        title: "Weekly Pay",
        symbol:"﷼ ",
        name: "work_fees_per_week_0",
        regex: /^[0-9\s\&]+$/,
        size: "1.3",
        position:0,

        // isCaps: true,
        required: true,
      },
      {
        id: "38",
        type: "inputBox",
        maxLength: 4,
        title: "Monthly Pay",
        name: "work_fees_per_month_0",
        regex: /^[0-9\s\&]+$/,
        symbol:"﷼ ",
        size: "1.5",
        position:0,

        // isCaps: true,
        required: true,
      },
      //   {
      //     id: "23",
      //     type: "button",
      //     title: <RemoveIcon sx={{ color: "#fff !important" }} />,

      //     align: "right",
      //     size: ".5",
      //     padding: "2px 2px",
      //     position: randomName,
      //     forceShow: true,
      //     handleClick: (e) => {

      //         setOpen(true);
      //         setAdPosition(randomName);
      //         setPopupContent(
      //             "Do you want to remove this advertisement from this movie?"
      //         );
      //     },
      // }
    ],
    // []
  ];
};
