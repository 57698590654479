// import { productColumns, productData } from '../../Data/Ecommerce/ProductList';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Breadcrumbs, Btn, H6, Image } from "../../AbstractElements";
import { useDispatch, useSelector } from "react-redux";
import ListTable from "../../utils/Table";
import { all_category_list, category_delete } from "../../actions/category";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { CREATECATEGORY, SUBCATEGORY } from "../../Constant";
import { all_successfull_transaction } from "../../actions/transaction";

const Transactions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const TransactionList = useSelector((state)=>state?.transaction?.transaction_list)
  useMemo(()=>{
    dispatch(all_successfull_transaction())
  },[])
  const tempTableData = {
    tableTitle: "All Transactions",
    // deleteRecord: subcategory_delete,
    disableDelete : true,
    // updateRecord: subcategory_update,
    // deleteAccess: rights?.["Masters"]?.["delete"] == "true",
    // onDeleteText: "Are you sure want to delete?",
    // onUpdateText:
    //   "Videos are associated with this category, are you still want to change it.?",
    tableHead: [

      {
        id: "user_email",
        label: "Customer Info",
        subText :"user_mobile"
      },
      {
        id: "order_id",
        label: "Order Number",
      },
      {
        id: "payment_id",
        label: "Payment ID",
      },
      {
        id: "amount",
        label: "Amount",
      },
      {
        id: "created_at",
        label: "Order On",
      },

    
    ],
    tableBody: [],
    filterColumn: [

      ],
      isDateRangeFilter: "created_at",
  };
  const [tableData, setTableData] = useState({...tempTableData})

  useMemo(()=>{
    if(TransactionList?.statuscode == 200){
      const temp = tableData
      temp.tableBody = TransactionList?.data?.map((ele)=>({
        ...ele,
        amount : parseFloat(ele?.amount).toFixed(2),
        id : ele?.user_id,
        // sub_category_name : ele?.subcategory_name

      }))
      setTableData({...temp})
    }
  },[TransactionList])
  return (
    <div >
      <Breadcrumbs
        parent="Transactions"
        title="Transactions List"
        mainTitle="Transactions List"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
              <ListTable
              tableData={tableData}
              key={"ListTable"}
              // setForm={setForm}
              setTableData={setTableData}
              // setIsEdit={setIsEdit}
              />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Transactions;
